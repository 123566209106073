export const FILE_UPLOAD_LIMIT = "15Mb";
export const COMMODITY_CODES_URL = "https://www.trade-tariff.service.gov.uk/sections";

export const ITEM_QUERY_PARAM = "item";
export const PAGE_QUERY_PARAM = "page";
export const TAB_QUERY_PARAM = "tab";
export const ELEMENT_QUERY_PARAM = "element";
export const DECLARATION_ID_QUERY_PARAM = "declarationId";
export const FILE_ID_QUERY_PARAM = "fileId"

export const LS_THEME_KEY = "theme";
export const LS_LANGUAGE_KEY = "language";
export const LS_ACCESS_TOKEN_KEY = "access_token";
export const LS_REFRESH_TOKEN_KEY = "refresh_token";
export const LS_ID_TOKEN_KEY = "ID_TOKEN";
export const LS_SELECTED_WORKSPACE_KEY = 'SELECTED_WORKSPACE';

export const FILE_SIZE_LIMIT = 100;

export const DECLARATION_STATUSES = {
	INCOMPLETE: "incomplete",
	SUBMITTED: "submitted",
	ACCEPTED: "accepted",
	REJECTED: "rejected",
	PENDING_CANCEL: "pendingCancel",
	CANCELLED: "cancelled",
	CLEARED: "cleared"
};

export const STATUS_NAME_CODES = {
	39: "accepted",
};

export const SEARCH_TYPES = {
	CONTACTS: "contacts",
	GOODS_LOCATIONS: "locationOfGoods",
	COMMODITY_CODES: "commodityCodes",
};

export const SEARCH_TYPE_ELEMENTS = {
	CONTACTS: [
		{ name: "contactName", isRequired: true, type: 'text' },
		{ name: "contactSurname",type: 'text' },
		{ name: "contactCountry", type: 'pulldown' },
		{ name: "contactPostCode", type: 'text' },
		{ name: "contactEori", type: 'text'  },
		{ name: "contactCity", type: 'text' },
		{ name: "contactAddressLine01", type: 'text' },
		{ name: "contactAddressLine02", type: 'text' },
		{ name: "contactAddressLine03", type: 'text' },
		{ name: "contactAddressLine04", type: 'text' },
		{ name: "contactPhone01", type: 'text' },
		{ name: "contactPhone02", type: 'text' },
		{ name: "contactPhone03", type: 'text' },
		{ name: "contactEmail", type: 'text' },
		{ name: "contactRole", type: 'text' },
		{ name: "description", type: 'text' },
	],
	COMMODITY_CODES: [
		{ name: "commodityCodeId", isRequired: true, type: 'text'  },
		{ name: "commodityCodeType", isRequired: true, type: 'pulldown' },
	],
	GOODS_LOCATIONS: [
		{ name: "locationOfGoodsId", isRequired: true, type: 'pulldown' },
		{ name: "locationOfGoodsType", isRequired: true, type: 'pulldown' },
		{ name: "locationOfGoodsCountry", type: 'pulldown' },
		{ name: "locationOfGoodsAddress", type: 'text' },
		{ name: "locationOfGoodsCity", type: 'text' },
		{ name: "locationOfGoodsPostCode", type: 'text' },
		{ name: "contactRole", type: 'text' },
		{ name: "qualifierOfIdentification", type: 'pulldown' },
		{ name: "goodslocation", type: 'pulldown' },
		{ name: "locationOfGoodsName", type: 'text' },
	],
};
