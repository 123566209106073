import { useContext, useEffect, useState } from "react";
import ElementLabel from "../ElementLabel";
import ThemeContext from "../../../context/theme/ThemeContext";
import { startCase } from "lodash";
import { getElementValue } from "../../modals/DeclarationDetailsModal/utils";
import { getQueryParam } from "../../../router/utils";
import { ELEMENT_QUERY_PARAM } from "../../../constants/GlobalConstants";
import Highlighter from "react-highlight-words";

const ArrayElement = ({ label, data, element }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div id={element?.elementName} style={{ width: "100%", marginBottom: 16 }}>
			<ElementLabel label={label ? startCase(label) : null} element={element} />

			<div style={{ marginTop: 12 }} className={withTheme("table-container")}>
				<table className={withTheme("table")}>
					<thead className={withTheme("table-head")}>
						<tr>
							{Object.keys(data[0]).map((key) => (
								<TableHead name={key} />
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => (
							<tr key={index}>
								{Object.values(item).map((value) => (
									<td>{getElementValue(value)}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const TableHead = ({ name }) => {
	const [isHighlighted, setIsHighlighted] = useState(false);

	useEffect(() => {
		const checkIsHighlighted = () => {
			return getQueryParam(ELEMENT_QUERY_PARAM) === name;
		};

		setIsHighlighted(checkIsHighlighted());

		const eventHandler = () => {
			setIsHighlighted(checkIsHighlighted());
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, []);

	return (
		<th id={name} scope="col">
			<Highlighter
				highlightStyle={{ backgroundColor: "yellow" }}
				searchWords={isHighlighted ? [startCase(name)] : []}
				autoEscape={true}
				textToHighlight={startCase(name)}
			/>
		</th>
	);
};

export default ArrayElement;
