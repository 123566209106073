import { useContext, useRef, useState } from "react";
import { PiDotsThreeVertical } from "react-icons/pi";
import Popover from "../../../common/Popover/Popover";
import Button from "../../../common/Button";
import PopoverOption from "../../../common/Popover/PopoverOption";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import FilesContext from "../../../../context/files/FilesContext";

const FileOptionsPopover = ({ file, isAssociatedWithDeclaration, onRenameClick }) => {
	const { t } = useTranslation();
	const { state } = useContext(DeclarationsContext);
	const { updateFile, deleteFile } = useContext(FilesContext);
	const [isOpen, setIsOpen] = useState(false);
	const [loadingAction, setLoadingAction] = useState(false);
	const targetRef = useRef(null);

	const handleDetachClick = async () => {
		setLoadingAction("detach");

		let updatedDeclarations = [...file.declarations];
		const declarationIndex = updatedDeclarations.findIndex((dec) => dec._id === state.declaration._id);

		updatedDeclarations.splice(declarationIndex, 1);

		await updateFile(
			file,
			{ declarations: updatedDeclarations },
		);

		setLoadingAction(null);
		setIsOpen(false);
	};

	const handleDeleteClick = async () => {
		setLoadingAction("delete");

		await deleteFile(file);

		setLoadingAction(null);
	};

	return (
		<>
			<Popover
				isOpen={isOpen}
				targetRef={targetRef}
				onClose={() => setIsOpen(false)}
				style={{ paddingRight: 0, paddingLeft: 0, paddingTop: 8, paddingBottom: 8 }}
			>
				{onRenameClick && <PopoverOption onClick={onRenameClick}>{t("rename")}</PopoverOption>}

				{isAssociatedWithDeclaration && state.declaration && (
					<PopoverOption
						onClick={handleDetachClick}
						isLoading={loadingAction === "detach"}
					>
						{t("detachFromDeclaration")}
					</PopoverOption>
				)}

				<PopoverOption onClick={handleDeleteClick} isLoading={loadingAction === "delete"}>
					{t("deleteFile")}
				</PopoverOption>
			</Popover>

			<div ref={targetRef} style={{ width: "fit-content", marginLeft: "auto" }}>
				<Button type="secondary-icon" onClick={() => setIsOpen(true)}>
					<PiDotsThreeVertical />
				</Button>
			</div>
		</>
	);
};

export default FileOptionsPopover;
