const measurmentUnitCodes = [
  {
    "key": "DEFAULT",
    "value": "Please Select"
  },
  {
    "key": "ASV",
    "value": "% volume (Alcoholic strength per volume)"
  },
  {
    "key": "ASV#X",
    "value": "% volume Hectolitre ({Alcoholic strength per volume} X {no. of hectolitres})"
  },
  {
    "key": "CCT",
    "value": "Carrying capacity in metric tonnes"
  },
  {
    "key": "CEN",
    "value": "100 items"
  },
  {
    "key": "CTM",
    "value": "Carats (one metric carat = 2x10$-4kg)"
  },
  {
    "key": "DAP",
    "value": "Decatonne, corrected according to polarisation"
  },
  {
    "key": "DHS",
    "value": "Kilogram of dihydrostreptomycin"
  },
  {
    "key": "DTN",
    "value": "Hectokilogram"
  },
  {
    "key": "DTN#E",
    "value": "Hectokilogram net of drained weight"
  },
  {
    "key": "DTN#F",
    "value": "Hectokilogram of common wheat"
  },
  {
    "key": "DTN#G",
    "value": "Hectokilogram Gross"
  },
  {
    "key": "DTN#L",
    "value": "Hectokilogram of live weight"
  },
  {
    "key": "DTN#M",
    "value": "Hectokilogram net of dry matter"
  },
  {
    "key": "DTN#R",
    "value": "Hectokilogram of the standard quality"
  },
  {
    "key": "DTN#S",
    "value": "Hectokilogram of sugar with a yield in white sugar of 92%"
  },
  {
    "key": "DTN#Z",
    "value": "Hectokilogram per 1% by weight of sucrose"
  },
  {
    "key": "EUR",
    "value": "Euro (used for statistical surveillance)"
  },
  {
    "key": "FC1",
    "value": "Factor"
  },
  {
    "key": "FC1#X",
    "value": "Factor Hectolitre"
  },
  {
    "key": "GBP",
    "value": "Pounds (£) Sterling"
  },
  {
    "key": "GFI",
    "value": "Gram of fissile isotopes"
  },
  {
    "key": "GP1",
    "value": "Gross Production"
  },
  {
    "key": "GP1#X",
    "value": "Gross Production Hectolitre"
  },
  {
    "key": "GRM",
    "value": "Gram"
  },
  {
    "key": "GRT",
    "value": "Gross tonnage"
  },
  {
    "key": "HLT",
    "value": "Hectolitre"
  },
  {
    "key": "HMT",
    "value": "Hectometre"
  },
  {
    "key": "KAC",
    "value": "Kilogram net if acesulfame potassium"
  },
  {
    "key": "KCC",
    "value": "Kilogram of choline chloride"
  },
  {
    "key": "KCL",
    "value": "Tonne of potassium chloride"
  },
  {
    "key": "KGM",
    "value": "Kilogram"
  },
  {
    "key": "KGM#A",
    "value": "Kilogram Total alcohol"
  },
  {
    "key": "KGM#E",
    "value": "Kilogram net of drained weight"
  },
  {
    "key": "KGM#G",
    "value": "Kilogram Gross"
  },
  {
    "key": "KGM#P",
    "value": "Kilogram of lactic matter"
  },
  {
    "key": "KGM#S",
    "value": "Kilogram of sugar with a yield in white sugar of 92%"
  },
  {
    "key": "KGM#T",
    "value": "Kilogram of dry lactic matter"
  },
  {
    "key": "KLT",
    "value": "1000 litres"
  },
  {
    "key": "KMA",
    "value": "Kilogram of methylamines"
  },
  {
    "key": "KMT",
    "value": "Kilometre"
  },
  {
    "key": "KNI",
    "value": "Kilogram of nitrogen"
  },
  {
    "key": "KNS",
    "value": "Kilogram of hydrogen peroxide"
  },
  {
    "key": "KPH",
    "value": "Kilogram of potassium hydroxide (caustic potash)"
  },
  {
    "key": "KPO",
    "value": "Kilogram of potassium oxide"
  },
  {
    "key": "KPP",
    "value": "Kilogram of diphosphorus pentaoxide"
  },
  {
    "key": "KSD",
    "value": "Kilogram of substance 90% dry"
  },
  {
    "key": "KSH",
    "value": "Kilogram of sodium hydroxide (caustic soda)"
  },
  {
    "key": "KUR",
    "value": "Kilogram of uranium"
  },
  {
    "key": "KW1",
    "value": "Kilowatt hourv"
  },
  {
    "key": "LHC",
    "value": "Standard litre (of hydrocarbon oil)"
  },
  {
    "key": "LPA",
    "value": "Litre pure (100%) alcohol"
  },
  {
    "key": "LTR",
    "value": "Litre"
  },
  {
    "key": "LTR#A",
    "value": "Litre Total alcohol"
  },
  {
    "key": "MCG",
    "value": "Microgram"
  },
  {
    "key": "MCL",
    "value": "Microlitre"
  },
  {
    "key": "MGM",
    "value": "Milligram"
  },
  {
    "key": "MIL",
    "value": "1000 items"
  },
  {
    "key": "MLT",
    "value": "Millilitre"
  },
  {
    "key": "MTK",
    "value": "Square metre"
  },
  {
    "key": "MTQ",
    "value": "Cubic metre"
  },
  {
    "key": "MTQ#C",
    "value": "Cubic metre 1000"
  },
  {
    "key": "MTR",
    "value": "Metre"
  },
  {
    "key": "MWH",
    "value": "100 kilowatt hours"
  },
  {
    "key": "NAR",
    "value": "Number of items"
  },
  {
    "key": "NAR#B",
    "value": "Number of items per flask"
  },
  {
    "key": "NCL",
    "value": "Number of cells"
  },
  {
    "key": "NPR",
    "value": "Number of pairs"
  },
  {
    "key": "RET",
    "value": "Retail Price"
  },
  {
    "key": "TCE",
    "value": "Tonne of CO2 equivalent"
  },
  {
    "key": "TJO",
    "value": "Terajoule (gross calorific value)"
  },
  {
    "key": "TNE",
    "value": "Tonne"
  },
  {
    "key": "TNE#E",
    "value": "Tonne net of drained weight"
  },
  {
    "key": "TNE#I",
    "value": "Tonne of biodiesel content"
  },
  {
    "key": "TNE#J",
    "value": "Tonne of fuel content (usually in form of tallow, stearin, paraffin wax or other waxes, including the wick)"
  },
  {
    "key": "TNE#K",
    "value": "Tonne of bioethanol content"
  },
  {
    "key": "TNE#M",
    "value": "Tonne net of dry matter"
  },
  {
    "key": "TNE#R",
    "value": "Tonne of the standard quality"
  },
  {
    "key": "TNE#Z",
    "value": "Tonne per 1% by weight of sucrose"
  },
  {
    "key": "WAT",
    "value": "Number of Watt"
  }
];

export default measurmentUnitCodes;