import { useReducer } from "react";
import workspacesReducer from "./WorkspacesReducer";
import { SET_SELECTED_WORKSPACE } from "../types";
import WorkspacesContext from "./WorkspacesContext";
import { LS_SELECTED_WORKSPACE_KEY } from "../../constants/GlobalConstants";

const initialState = {
	workspaces: [],
	selectedWorkspaceId: localStorage.getItem(LS_SELECTED_WORKSPACE_KEY),
};

const WorkspacesState = (props) => {
	const [state, dispatch] = useReducer(workspacesReducer, initialState);

	const setSelectedWorkspaceId = (id) => {
		dispatch({
			type: SET_SELECTED_WORKSPACE,
			payload: id,
		});
	};

	return (
		<WorkspacesContext.Provider    
			value={{
				...state,
				setSelectedWorkspaceId,
			}}
		>
			{props.children}
		</WorkspacesContext.Provider>
	);
};

export default WorkspacesState;
