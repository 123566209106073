import { Route, Switch } from "react-router-dom";
import SettingsLayout from "../pages/Settings/SettingsLayout";
import DashboardLayout from "../pages/DashboardLayout";
import Login from "../pages/AuthPages/Login";
import ProtectedRoute from "../components/common/ProtectedRoute";
import Contacts from "../pages/Contacts";
import GoodsLocations from "../pages/GoodsLocations";
import CommodityCodes from "../pages/CommodityCodes";
import Register from "../pages/AuthPages/Register/index";
import DeclarationLayout from "../pages/DeclarationPages/DeclarationLayout";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useContext } from "react";
import ThemeContext from "../context/theme/ThemeContext";
import { LS_LANGUAGE_KEY } from "../constants/GlobalConstants";
import Files from "../pages/Files";

const Router = () => {
	const { withTheme } = useContext(ThemeContext);

	const { i18n } = useTranslation();

	// On first load, get the user's last language from localStorage if one is selected
	useEffect(() => {
		i18n.changeLanguage(localStorage.getItem(LS_LANGUAGE_KEY) || "en");
	}, []);

	return (
		<div style={{ textAlign: "center" }} className={withTheme("app-container")}>
			<Switch>
				<ProtectedRoute exact path="/" component={DashboardLayout} />
				<ProtectedRoute exact path="/declarations" component={DeclarationLayout} />
				<ProtectedRoute exact path="/settings" component={SettingsLayout} />
				<ProtectedRoute exact path="/contacts" component={Contacts} />
				<ProtectedRoute exact path="/goodsLocations" component={GoodsLocations} />
				<ProtectedRoute exact path="/commodity-codes" component={CommodityCodes} />
				<ProtectedRoute exact path="/files" component={Files} />
				{/* TODO: Currently not in use */}
				{/* <ProtectedRoute exact path='/gvms/register' component={GvmsRegister} />
                <ProtectedRoute exact path='/gvms/gvmr' component={GvmrEntries} /> */}
				<Route exact path="/login" component={Login} />
				<Route exact path="/register" component={Register} />
			</Switch>
		</div>
	);
};

export default Router;
