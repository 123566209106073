import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import LoadingSpinner from "./LoadingSpinner";

const ProtectedRoute = ({ component: Component, exact, path }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { getUserInfo, getSettings, user, settings } = useContext(UserContext);

	const loadUserData = async () => {
		setIsLoading(true);
		let loadedUserDataResult = null;

		if (!user) {
			loadedUserDataResult = await getUserInfo();
		}

		if (!settings && (loadedUserDataResult || user)) {
			await getSettings();
		}

		setIsLoading(false);
	};

	useEffect(() => {
		loadUserData();
	}, []);

	if (isLoading) {
		return (
			<div style={{ width: "fit-content", padding: 62, marginLeft: "auto", marginRight: "auto" }}>
				<LoadingSpinner />
			</div>
		);
	}

	return <Route exact={exact} path={path} component={Component} />;
};

export default ProtectedRoute;
