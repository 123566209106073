import { useContext, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import ThemeContext from "../../context/theme/ThemeContext";

const Tooltip = ({ isCompact, isDisabled, openOnHover, children, text, placement, onOpen, onClose }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const overlayTarget = useRef(null);

	const handleOpen = () => {
		if (isDisabled) {
			return null;
		}

		onOpen?.();

		setIsOpen(true);
	};

	const handleClose = () => {
		onClose?.();

		setIsOpen(false);
	};

	if (!text) {
		return null;
	}

	return (
		<>
			<Overlay
				rootClose
				target={overlayTarget.current}
				show={isOpen}
				placement={placement || "right-end"}
				onHide={handleClose}
			>
				{({ ...props }) => (
					<div
						{...props}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							e.nativeEvent.stopImmediatePropagation();
						}}
						className={`${withTheme("tooltip-container")} ${
							isCompact ? withTheme("tooltip-container-compact") : ""
						}`}
						style={{
							...props.style,
						}}
					>
						{text}
					</div>
				)}
			</Overlay>

			<div
				onMouseLeave={() => openOnHover && handleClose()}
				onMouseEnter={() => openOnHover && handleOpen()}
				onClick={() => !openOnHover && handleOpen()}
				ref={overlayTarget}
			>
				{children}
			</div>
		</>
	);
};

export default Tooltip;
