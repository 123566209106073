let config = {
    apiUrl: 'https://jc194fat2m.execute-api.eu-west-2.amazonaws.com/v1',
    cognitoAuthUrl: 'https://tax2cargo10.auth.eu-west-2.amazoncognito.com',
    userPoolId: 'eu-west-2_Az3YOHtkZ',
    cognitoClientId: '5raotaqhsrheo9s4q5kpv3v09t',
    redirect_uri: 'http://localhost:3033/login'
};

if (process.env.NODE_ENV === 'development') {
} else {
    config = {
        ...config,
        cognitoClientId: '5raotaqhsrheo9s4q5kpv3v09t',
        redirect_uri: "https://main.tax2cargo.com/login"
    }
}

export default config;