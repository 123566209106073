import additionalInformationCodes from "./validation-pulldown-options/additionalInformationCodes";
import airports from "./validation-pulldown-options/airports";
import commodityCodeAdditionalNationalCode from "./validation-pulldown-options/commodityCodeAdditionalNationalCode";
import countries from "./validation-pulldown-options/countries";
import currencies from "./validation-pulldown-options/currencies";
import documentsProducedAdditionalCategoryCode from "./validation-pulldown-options/documentsProducedAdditionalCategoryCode";
import goodsMeasureTypesOfPackages from "./validation-pulldown-options/goodsMeasureTypesOfPackages";
import tariffOffices from "./validation-pulldown-options/tariffOffices";
import commodityCodeTypes from './validation-pulldown-options/commodityCodeTypes';
import measurmentUnitCodes from "./validation-pulldown-options/measurmentUnitCodes";
import documentsProducedAdditionalDocStatus from "./validation-pulldown-options/documentsProducedAdditionalDocStatus";
import qualifiers from "./validation-pulldown-options/qualifiers";
import requestedAdditionalProceedure from "./validation-pulldown-options/requestedAdditionalProceedure";
import identificationOfLocation from './validation-pulldown-options/identificationOfLocation';

const validation = [
  {
    "elementName": "deferredPayment",
    "textDescription": "Deferred Payment",
    "description" : "Group Level - Deferred Payment. Where a DUCR is provided in D.E. Please refer to the User Manual for further assistance.",
    "type": "multiLine",
    "limit": 2,
    "components": [
      {
        "elementName": "deferredPaymentId",
        "description": "The reference number of the deferred payment authorisation in question.",
      },
      {
        "elementName": "deferredPaymentType",
        "description" : "Code DAN should be used specifying the category of a Deferred Payment. Please refer to the User Manual for further assistance.",
      }
    ]
  },
  {
    "elementName": "holderofAuthIdent",
    "textDescription": "Holder of Authorization Identification",
    "description" : "This data element is only mandatory where an authorisation is required to declare the goods.",
    "type": "multiLine",
    "limit": 999,
    "components": [
      {
        "elementName": "holderOfAuthorisationIdentificationId",
        "description" : "Enter the EORI number of the authorised party.",
      },
      {
        "elementName": "holderOfAuthorisationIdentificationTypeCode",
        "description" : "Enter the type of authorisation using the relevant code",
      }
    ]
  },
  {
    "elementName": "commodityCodes",
    "textDescription": "Commodity Codes",
    "description" : "Commodity codes are internationally recognised reference numbers. A commodity code describes a specific product when importing or exporting goods.",
    "type": "multiline",
    "limit": 999,
    "components": [
      {
        "elementName": "commodityCodeId",
        "description" : "The Customs Union and Statistics (CUS) number is the identifier assigned within the European Customs Inventory of Chemical Substances (ECICS) to mainly chemical substances and preparations.",
      },
      {
        "elementName": "commodityCodeType"
      }
    ]
  },
  {
    "elementName": "countriesOfRoutingCodes",
    "textDescription": "Countries of Routing Codes",
    "type": "multiline",
    "limit": 999,
    "components": [
      {
        "elementName": "sequenceNumeric"
      },
      {
        "elementName": "routingCountryCode"
      }
    ]
  },
  {
    "elementName": "guaranteeReferenceMulti",
    "textDescription": "Guarantee Reference",
    "description" : "Group Level item - Guarantee Reference",
    "type": "multiLine",
    "limit": 9,
    "components": [
      {
        "elementName": "guaranteeType",
        "description" : "The type of guarantee used for the operation using the relevant Union codes in the tab 'Guarantee Codes'.",
      },
      {
        "elementName": "guaranteeReferenceNumber",
        "description" : "Guarantee reference number by which the guarantee is identified UCC Annex B: 8/3 - GRN.",
      },
      {
        "elementName": "otherGuaranteeReference",
        "description" : "Other reference number by which the guarantee is identified UCC Annex B: 8/3 – Other guarantee reference.",
      },
      {
        "elementName": "accessCode",
        "description" : "The access code as associated with a given guarantee reference number",
      },
      {
        "elementName": "guaranteeReferenceAmount",
        "description" : "Monetary value of import duty and, where Article 89(2) first subparagraph of the Code applies, other charges.",
      },
      {
        "elementName": "guaranteeReferenceCurrency",
        "description" : "Should use the standard ISO 3 character Currency Code for the value of the guarantee. Please refer to the User Manual for further assistance.",
      },
      {
        "elementName": "customsOfficeToGuarantee",
        "description" : "Customs office of guarantee.",
      }
    ]
  },
  {
    "elementName": "requestedAdditionalProceedureMultiline",
    "textDescription": "Additional Procedure",
    "description" : "It should only be used as a separate occurrence of the Government Procedure for that particular goods item. This part designates whether it is an EU or UK based procedure.",
    "type": "multiline",
    "limit": 999,
    "components": [
      {
        "elementName": "requestedAdditionalProceedure"
      }
    ]
  },
  {
    "elementName": "containerIdentificationNumberMultiline",
    "textDescription": "Container Identification Numbers",
    "description" : "Marks (letters and/or numbers) which identify the transport container. A SequenceNumeric tag must be declared within the parent tag of this Data Element.",
    "type": "multiline",
    "limit": 999,
    "components": [
      {
        "elementName": "containerIdentificationNumber"
      }
    ]
  },
  {
    "elementName": "additionalFiscalReferences",
    "textDescription": "Additional Fiscal References",
    "description" : "Group Level item - Additional fiscal references identification No",
    "limit": 99,
    "components": [
      {
        "elementName": "additionalFiscalId",
        "description" : " Unique VAT identifier. Please refer to the User Manual for further assistance.",
      },
      {
        "elementName": "additionalFiscalTypeCode",
        "description": "This data element must be completed when Onward Supply Relief (OSR) is claimed at either Header or Item level, or Postponed VAT Accounting (PVA) is claimed at Header level.",
      }
    ]
  },
  {
    "elementName": "simplifiedDeclaration",
    "textDescription": "Simplified Declaration",
    "description" : "This is made up of 4 components and used to identify a previous declaration or previous document(s) which is identified by class, type, reference and goods item number.",
    "limit": 999,
    "components": [
      {
        "elementName": "documentCategory",
        "description" : "The First Component: Identifies the class or kind of document being declared."
      },
      {
        "elementName": "previousDocumentType",
        "description" : "The Second Component (an..3), consists of a combination of digits and/ or letters, serves to identify the type of document"
      },
      {
        "elementName": "previousDocumentReference",
        "description" : "The Third Component represents the data needed to recognise and identify the document. The identification number or another recognisable reference of the document."
      },
      {
        "elementName": "goodsItemIdentifier",
        "description" : " The Fourth Component (n..3) is used to identify which item of the previous document is being referred to."
      }
    ]
  },
  {
    "elementName": "taxBase",
    "textDescription": "Tax Base",
    "description" : "The measurement units and qualifiers should only be completed where they are required to support the calculation of the tax amounts.",
    "limit": 999,
    "components": [
      {
        "elementName": "specificTaxBaseQuantity",
        "description" : "The composite quantity or amount on which the tax or duty is based.",
      },
      {
        "elementName": "taxBasedUnit",
        "description" : "The measurement units and qualifiers defined in TARIC should be used.",
      },
      {
        "elementName": "taxBaseQualifier",
        "description" : "The measurement units and qualifiers defined in TARIC should be used.",
      }
    ]
  },
  {
    "elementName": "calculationOfTaxes",
    "textDescription": "Calculation of Taxes",
    "description" : "The declared amount of revenue due for a Tax Line on an Entry.",
    "limit": 999,
    "components": [
      {
        "elementName": "payableTaxAmount",
        "description" : "The declared amount of revenue due for a Tax Line on an Entry.",
      },
      {
        "elementName": "payableTaxAmountCurrency",
        "desciption": "The currency used for the declared amount of revenue due for a Tax Line on an Entry.",
      }
    ]
  },
  {
    "elementName": "calculationOfTaxesTotal",
    "textDescription": "Calculation of Taxes Total",
    "description" : "Group Level item - Calculation of Taxes - Total. Please refer to the User Manual for further assistance.",
    "limit": 999,
    "components": [
      {
        "elementName": "totalPayableTaxAmount",
        "description": "The total amount of duties and taxes for the goods concerned.",
      },
      {
        "elementName": "totalpayableTaxCurrency",
        "description": "The currency used for the Tax Assessed Amount data.",
      }
    ]
  },
  {
    "elementName": "documentsProduced",
    "textDescription": "Documents Produced",
    "description" : "Group Level item - Documents Produced, certificates and authorisation, additional references",
    "limit": 999,
    "components": [
      {
        "elementName": "documentsProducedAdditionalCategoryCode",
        "description" : " Specifies whether the code is EU or National. DMS uses the CodeList ImportCertificates for these items",
      },
      {
        "elementName": "documentsProducedAdditionalTypeCode",
        "description" : "Specifies whether the code is EU or National. DMS uses the CodeList ImportCertificates for these items",
      },
      {
        "elementName": "documentsProducedAdditionalDocId",
        "description" : "An identifier for the document at item level. This may hold the concatenation of the Document Part (where necessary)",
      },
      {
        "elementName": "documentsProducedAdditionalDocStatus",
        "description" : "Code for the availability and reason for not supplying a document or the action to be taken with the document",
      },
      {
        "elementName": "documentsProducedAdditionalDocStatusReason",
        "description" : "A free text description of the Reason where required by the Document Code or Document Status Code",
      },
      {
        "type": "text",
        "elementName": "documentsProducedAWrittingOffIssuingAuth",
        "description" : "The details related to the writing-off of the goods declared in the declaration concerned, in relation with the import/export licences and certificates",
      },
      {
        "type": "datePicker",
        "elementName": "documentsProducedAWrittingOffValidityDate",
        "description" : "The details related to the writing-off of the goods declared in the declaration concerned, in relation with the import/export licences and certificates",
      },
      {
        "type": "text",
        "elementName": "documentsProducedAWrittingOffQuantity",
        "description" : "The details related to the writing-off of the goods declared in the declaration concerned, in relation with the import/export licences and certificates.",
      },
      {
        "type": "pulldown",
        "elementName": "writingOffMeasurmentUnitAndQualifier",
        "description" : "The details related to the writing-off of the goods declared in the declaration concerned, in relation with the import/export licences and certificates",
      }
    ]
  },
  {
    "elementName": "typeCodeTaxTypesMultiline",
    "textDescription": "Tax Code Types",
    "description" : "Identifies the Tax Type code for each type of duty or tax applicable to the goods concerned",
    "limit": 999,
    "components": [
      {
        "elementName": "typeCodeTaxTypes",
      }
    ]
  },
  {
    "elementName": "additionalInformation",
    "textDescription": "Additional Information",
    "description" : "Group Level Item - Additional Information. Please refer to the User Manual for further assistance.",
    "limit": 999,
    "components": [
      {
        "elementName": "additionalInformationCode",
        "description" : "Identifies an additional information statement at item level (the CodeList ImportSpecialMentions) and header level (the CodeList GVMSAIcodes).",
      },
      {
        "elementName": "additionalInformationDescription",
        "description" : "A single line of text describing the value of the AI statement code.",
      }
    ]
  },
  {
    "elementName": "additionalSupplyChainActors",
    "textDescription": "Additional Supply Chain Actions",
    "description" : "Group Level item - Additional supply chain actor(s), identification No",
    "limit": 999,
    "components": [
      {
        "elementName": "additionalSupplyChainIdentifier",
        "description" : "Unique identification number assigned to an economic operator of a third country in the framework of a trade partnership program. Please refer to the User Manual for further assistance.",
      },
      {
        "elementName": "additionalSupplyChainRoleCode",
        "description" : "Specifies the third party's role in the supply chain. Only used in conjunction with the Additional Supply Chain actor identification No. Please refer to the User Manual for further assistance.",
      }
    ]
  },
  {
    "elementName": "additionsAndDeductions",
    "textDescription": "Additions and Deductions",
    "description" : "Group Level Item - Additions and Deductions",
    "limit": 999,
    "components": [
      {
        "elementName": "additionsAndDeductionsValue",
        "description" : "The corresponding amount in National currency that has not yet been included in or deducted from the item price. Please refer to the User Manual for further assistance.",
      },
      {
        "elementName": "additionsAndDeductionsCurrency",
        "description" : "ISO Currency code of the Other Charge Deduction Amount value. Please refer to the User Manual for further assistance.",
      },
      {
        "elementName": "additionsAndDeductionsType",
        "description" : "For each type of addition or deduction relevant for a given goods item, enter the relevant code. Please refer to the User Manual for further assistance.",
      }
    ]
  },
  {
    "elementName": "descriptionOfPackaging",
    "textDescription": "Description of Packaging",
    "description" : "Packaging description",
    "limit": 999,
    "components": [
      {
        "elementName": "goodsMeasureTypeOfPackages",
        "description"  : "Code specifying the type of package. DMS uses the codelist PackageTypes for these items. ",
      },
      {
        "elementName": "goodsMeasureNoOfPackages",
        "description" : "Total number of packages based on the smallest external packing unit. Please refer to the User Manual for further assistance.",
      },
      {
        "elementName": "goodsMeasureShippingMarks",
        "description" : "Free form description of the marks and numbers on transport units or packages. Enables the packages of the given kind to be identified.",
      }
    ]
  },
  {
    "elementName": "nationalAdditionalCodes",
    "textDescription": "National Additional Codes",
    "description" : "Group Level item - Commodity Code - National Additional code(s)",
    "limit": 999,
    "components": [
      {
        "elementName": "commodityCodeAdditionalNationalCode" ,
        "description" : "Optional in UCC for HMRC to decide to use if necessary to code (National product group code) corresponding to the item in question.",
      },
      {
        "elementName": "nationalAdditionalCodeTypeCode",
        "description" : "For D/E 6/17 IdentificationTypeCode = GN. Please refer to the User Manual for further assistance.",
      }
    ]
  },
  {
    "elementName": "service",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "Import"
      },
      {
        "key": "1",
        "value": "Post VAT Accounting"
      },
      {
        "key": "2",
        "value": "Export"
      }
    ]
  },
  {
    "elementName": "declarationType",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "IM",
        "value": "Import"
      },
      {
        "key": "EX",
        "value": "Export"
      },
      {
        "key": "CO",
        "value": "CO - Customs Only"
      }
    ],
    "parenthesisCode": "(1/1)",
    "borderColor": "border-primary",
    "textDescription": "Declaration Type",
    "description" : "The Type of Declaration being made. This Data Element cannot be amended. Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "declarationCategory",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "H1",
        "value": "H1"
      },
      {
        "key": "H2",
        "value": "H2"
      },
      {
        "key": "H3",
        "value": "H3"
      },
      {
        "key": "H4",
        "value": "H4"
      },
      {
        "key": "H5",
        "value": "H5"
      },
      {
        "key": "H8",
        "value": "H8"
      },

      {
        "key": "I1",
        "value": "I1"
      },
      {
        "key": "C21I",
        "value": "C21I"
      },
      {
        "key": "C21IEIDR",
        "value": "C21IEIDR"
      },
      {
        "key": "FSD",
        "value": "FSD"
      },
      {
        "key": "SRDS",
        "value": "SRDS"
      }
    ],
    "parenthesisCode": "",
    "textDescription": "Declaration Category",
    "description" : "The data element completion rules provided in this document indicate for which types (categories) of declarations the data element is required.",
  },
  {
    "elementName": "additionalDeclarationType",
    "description" : "Character specifies the Category of the Declaration. Please refer to the User Manual for further assistance.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A",
        "value": "Standard customs declaration (frontier)."
      },
      {
        "key": "B",
        "value": "Simplified declaration on an occasional basis (frontier)."
      },
      {
        "key": "C",
        "value": "Simplified declaration with regular use, pre-authorised (frontier)."
      },
      {
        "key": "D",
        "value": "Standard customs declaration (pre-lodged)."
      },
      {
        "key": "E",
        "value": "Simplified declaration on an occasional basis (pre-lodged)."
      },
      {
        "key": "F",
        "value": "Simplified declaration with regular use, pre-authorised (pre-lodged)."
      },
      {
        "key": "Y",
        "value": "Supplementary declaration covered by types C and F."
      },
      {
        "key": "Z",
        "value": "Supplementary declaration (entry into declarant's records)."
      } 
    ],
    "parenthesisCode": "(1/2)",
    "textDescription": "Additional Declaration Type",
  },
  {
    "elementName": "registrationNationalityCode",
    "pullDownMenu": countries,
    "parenthesisCode": "(7/15)",
    "textDescription": "Registration Nationality Code",
    "description" : "Using the ISO Country code, enter the nationality of the active means of transport crossing the Union's external frontier." ,
  },
  {
    "elementName": "borderTransportMeans",
    "description": "The relevant Union code for the mode of transport corresponding to the active means of transport with which the goods entered the customs territory of the Union.",
     "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "1",
        "value": "Maritime (Sea) transport"
      },
      {
        "key": "2",
        "value": "Rail transport"
      },
      {
        "key": "3",
        "value": "Road transport"
      },
      {
        "key": "4",
        "value": "Air transport"
      },
      {
        "key": "5",
        "value": "Postal (Mail) consignment"
      },
      {
        "key": "7",
        "value": "Fixed transport installations"
      },
      {
        "key": "8",
        "value": "Inland waterway transport"
      },
      {
        "key": "9",
        "value": "Mode unknown (for instance, own propulsion)"
      },
      {
        "key": "6",
        "value": "RoRo"
      }
    ],
    "parenthesisCode": "(7/4)",
    "textDescription": "Border Transport Means",
     },
  {
    "elementName": "totalNoOfItems",
    "parenthesisCode": "(1/9)",
    "borderColor": "border-warning",
    "textDescription": "Total No Of Items",
    "description" : "Count of the total number of goods items within a document. Specified by DMS." ,
  },
  {
    "elementName": "totalNoOfItems",
    "parenthesisCode": "(1/9)",
    "borderColor": "border-warning",
    "textDescription": "Total No Of Items",
    "description" : "Count of the total number of goods items within a document. Specified by DMS." ,
  },
  {
    "elementName": "ucrReferenceNumber",
    "parenthesisCode": "(2/4)",
    "textDescription": "UCR Reference Number",
    "description" : "For optional use by the declarant to record a commercial reference for their own purposes." ,
    "format": "an..35"
  },
  {
    "elementName": "typeCodeTaxTypes",
    "description": "Identifies the Tax Type code for each type of duty or tax applicable to the goods concerned.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A00",
        "value": "Customs duties"
      },
      {
        "key": "A20",
        "value": "Additional Duties"
      },
      {
        "key": "A30",
        "value": "Definitive antidumping duties (ADD)"
      },
      {
        "key": "A35",
        "value": "Provisional antidumping duties (ADD)"
      },
      {
        "key": "A40",
        "value": "Definitive countervailing duties"
      },
      {
        "key": "A45",
        "value": "Provisional countervailing duties"
      },
      {
        "key": "B00",
        "value": "Value Added Tax (VAT)"
      },
      {
        "key": "407",
        "value": "Beer made in UK (standard rate, that is, annual production more than 60,000 hectolitres)"
      },
      {
        "key": "411",
        "value": "Wine [sparkling], of fresh grape, 8.5% vol. and above, but not exceeding 15% vol."
      },
      {
        "key": "412",
        "value": "Wine [sparkling], of fresh grape, exceeding 5.5% vol. but less than 8.5% vol."
      },
      {
        "key": "413",
        "value": "Wine [still], exceeding 5.5% vol. but not exceeding 15% vol."
      },
      {
        "key": "415",
        "value": "Wine [still or sparkling], exceeding 15% vol. but not exceeding 22% vol."
      },
      {
        "key": "419",
        "value": "Wine [still or sparkling], exceeding 22% vol."
      },
      {
        "key": "421",
        "value": "Made-wine [sparkling], exceeding 8.5% vol. but not exceeding 15% vol."
      },
      {
        "key": "422",
        "value": "Made-wine [sparkling], exceeding 5.5% vol. but less than 8.5% vol."
      },
      {
        "key": "423",
        "value": "Made-wine [still], exceeding 5.5% vol. but not exceeding 15% vol."
      },
      {
        "key": "425",
        "value": "Made-wine [still or sparkling], exceeding 15% vol. but not exceeding 22% vol."
      },
      {
        "key": "429",
        "value": "Made-wine [still or sparkling], exceeding 22% vol."
      },
      {
        "key": "431",
        "value": "Low alcohol beverage of less than 1.2% vol."
      },
      {
        "key": "433",
        "value": "Wine, spirit based beverage exceeding 1.2% vol., but not exceeding 4% vol."
      },
      {
        "key": "435",
        "value": "Wine, spirit based beverage exceeding 4% vol., but not exceeding 5.5% vol."
      },
      {
        "key": "438",
        "value": "Spirit-based beverages exceeding, 1.2% vol. but not exceeding 8% vol."
      },
      {
        "key": "440",
        "value": "Beer made in UK - small brewery beer eligible to reduced rates (variable rate, that is, annual production more than 5,000 hectolitres but not exceeding 60,000 hectolitres)"
      },
      {
        "key": "441",
        "value": "Imported beer - small brewery beer eligible to reduced rates (variable rate, that is, annual production more than 5,000 hectolitres but not exceeding 60,000 hectolitres)"
      },
      {
        "key": "442",
        "value": "Beer made in UK - small brewery beer eligible to reduced rates (variable rate, that is, annual production no more than 5,000 hectolitres)"
      },
      {
        "key": "443",
        "value": "Imported beer - small brewery beer eligible to reduced rates (variable rate, that is, annual production no more than 5,000 hectolitres)"
      },
      {
        "key": "444",
        "value": "Beer made in UK - reduced rate of general beer duty (applies to beer exceeding 1.2% abv but not exceeding 2.8% abv)"
      },
      {
        "key": "445",
        "value": "Beer made in UK - high strength beer duty (applies to beer exceeding 7.5% abv - general beer duty is also due on all UK produced high strength beer)"
      },
      {
        "key": "446",
        "value": "Imported beer - reduced rate of general beer duty (applies to beer exceeding 1.2% abv but not exceeding 2.8% abv)"
      },
      {
        "key": "447",
        "value": "Imported beer - high strength beer duty (applies to beer exceeding 7.5% abv - general beer duty is also due on all imported high strength beer)"
      },
      {
        "key": "451",
        "value": "Spirits other than UK produced whisky"
      },
      {
        "key": "461",
        "value": "UK produced Whisky - wholly malt"
      },
      {
        "key": "462",
        "value": "UK produced Whisky - wholly grain"
      },
      {
        "key": "463",
        "value": "UK produced Whisky - blended"
      },
      {
        "key": "473",
        "value": "Beer based beverage exceeding 1.2% vol."
      },
      {
        "key": "481",
        "value": "Cider and Perry [sparkling], made in UK or imported, exceeding 1.2% but not exceeding 5.5%, Cider and Perry [still], made in the UK or imported exceeding 1.2% but not exceeding than 6.9% "
      },
      {
        "key": "483",
        "value": "Cider and Perry [still], made in the UK or imported exceeding 7.5% but not exceeding 8.5%"
      },
      {
        "key": "485",
        "value": "Cider and Perry [sparkling], made in the UK or imported exceeding 5.5% but not exceeding 8.5%"
      },
      {
        "key": "487",
        "value": "Cider and Perry [still], made in the UK or imported exceeding 6.9% but not exceeding 7.5%"
      },
      {
        "key": "511",
        "value": "Light oil (unmarked) - aviation gasoline (including light oil aviation turbine fuel)"
      },
      {
        "key": "520",
        "value": "Light oil (unmarked) - other unrebated light oil"
      },
      {
        "key": "521",
        "value": "Light oil, furnace fuel"
      },
      {
        "key": "522",
        "value": "Light oil, unleaded fuel"
      },
      {
        "key": "540",
        "value": "Heavy oil - other (unmarked) heavy oil (other than kerosene) intended for use as heating fuel, or as fuel for an engine; which would otherwise be eligible for a full rebate"
      },
      {
        "key": "541",
        "value": "Heavy oil (unmarked; including Diesel Engine Road Vehicle (DERV) or road fuel extender and unmarked kerosene or unmarked gas oil for which no marking waiver has been granted"
      },
      {
        "key": "542",
        "value": "Heavy oil kerosene to be used as motor fuel off road or in an excepted vehicle "
      },
      {
        "key": "551",
        "value": "Heavy Oil kerosene (marked/unmarked under marking waiver, including heavy oil aviation turbine fuel) to be used other than as motor fuel off-road or in an excepted vehicle"
      },
      {
        "key": "556",
        "value": "Heavy gas oil (marked/unmarked under marking waiver)"
      },
      {
        "key": "561",
        "value": "Heavy oil, Fuel oil (unmarked)"
      },
      {
        "key": "570",
        "value": "Heavy oil, other (unmarked)"
      },
      {
        "key": "571",
        "value": "Biodiesel to be used as motor fuel off road or in an excepted vehicle"
      },
      {
        "key": "572",
        "value": "Biodiesel blended with kerosene for use as heating fuel"
      },
      {
        "key": "589",
        "value": "Biodiesel"
      },
      {
        "key": "591",
        "value": "Road fuel gases - natural gas including biogas"
      },
      {
        "key": "592",
        "value": "Road fuel gases - other than natural gas, e.g. LPG"
      },
      {
        "key": "595",
        "value": "Bioethanol"
      },
      {
        "key": "611",
        "value": "Cigarettes"
      },
      {
        "key": "615",
        "value": "Cigars"
      },
      {
        "key": "619",
        "value": "Hand rolling tobacco"
      },
      {
        "key": "623",
        "value": "Smoking tobacco - other"
      },
      {
        "key": "627",
        "value": "Chewing tobacco"
      },
      {
        "key": "633",
        "value": "Tobacco for heating"
      },
      {
        "key": "99A",
        "value": "Solid fuels i.e. coal and lignite, coke and semi-coke of coal or lignite, and petroleum coke"
      },
      {
        "key": "99B",
        "value": "Any petroleum gas, or other gaseous hydrocarbon supplied in a liquid state"
      },
      {
        "key": "99C",
        "value": "Gas supplied by a gas utility or any gas supplied in a gaseous state that is of a kind supplied by a gas utility in Great Britain"
      },
      {
        "key": "99D",
        "value": "Electricity"
      }
    ],
    "parenthesisCode": "(4/3)",
    "textDescription": "Tax Type Code",
  },
  {
    "elementName": "dutyRegimentCode",
    "description": "This data element concerns information on the tariff treatment of the goods. Please refer to the User Manual for further assistance.",
        "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "100",
        "value": "Normal third country tariff duty (including ceilings)."
      },
      {
        "key": "110",
        "value": "Tariff suspension."
      },
      {
        "key": "115",
        "value": "Tariff suspension subject to a specific end-use."
      },
      {
        "key": "118",
        "value": "Tariff suspension subject to the production of a special certificate."
      },
      {
        "key": "119",
        "value": "Tariff suspension subject to Authorised Release Certificate formerly known as Airworthiness Certificates."
      },
      {
        "key": "120",
        "value": "Tariff quota, notably General Agreement on Tariffs and Trade (GATT) quota."
      },
      {
        "key": "123",
        "value": "Tariff quota, notably GATT quota, subject to a specific end-use."
      },
      {
        "key": "125",
        "value": "Tariff quota, notably GATT quota, subject to the production of a special certificate."
      },
      {
        "key": "128",
        "value": "Tariff quota following outward processing."
      },
      {
        "key": "140",
        "value": "Exemption for end-use resulting from the Community Customs Tariff (CCT)."
      },
      {
        "key": "150",
        "value": "Admission to certain CN subheadings subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "200",
        "value": "Generalised Systems of Preferences (GSP) rate without conditions or limits (including ceilings)."
      },
      {
        "key": "215",
        "value": "Tariff suspension subject to specific end-use under GSP."
      },
      {
        "key": "218",
        "value": "Tariff suspension subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "220",
        "value": "Tariff quota. The GSP rate is only applicable within the limits of a tariff quota."
      },
      {
        "key": "223",
        "value": "Tariff quota subject to a specific end-use."
      },
      {
        "key": "225",
        "value": "Tariff quota subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "240",
        "value": "Exemption for end-use resulting from the CCT."
      },
      {
        "key": "250",
        "value": "Application of GSP rates subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "300",
        "value": "Tariff preference without conditions or limits (including ceilings)."
      },
      {
        "key": "310",
        "value": "Tariff suspension under preferential agreements."
      },
      {
        "key": "315",
        "value": "Tariff suspension subject to a specific end-use under preferential agreements."
      },
      {
        "key": "318",
        "value": "Tariff suspension subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "320",
        "value": "Tariff quota. The preferential rate is only applicable within the limits of a quota."
      },
      {
        "key": "323",
        "value": "Tariff quota subject to a specific end-use."
      },
      {
        "key": "325",
        "value": "Tariff quota subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "340",
        "value": "Exemption for end-use resulting from the CCT."
      },
      {
        "key": "350",
        "value": "Application of preferential duty rates subject to a certificate confirming the special nature of the goods."
      },
      {
        "key": "400",
        "value": "Non imposition of customs duties under the provisions of Customs Union."
      },
      {
        "key": "420",
        "value": "Tariff Quota. Non imposition of duty within the limits of annual tariff quotas for certain products from Turkey"
      }
    ],
    "parenthesisCode": "(4/17)",
    "borderColor": "bg-primary",
    "textDescription": "Duty Regiment Code",
  },
  {
    "elementName": "quotaOrderId01",
    "description": "The Quota Order Number of the quota against which a claim for relief from Customs Duty is to be applied.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "Line 1 declarationTypePullDownMenu"
      },
      {
        "key": "1",
        "value": "Line 2 declarationTypePullDownMenu"
      },
      {
        "key": "2",
        "value": "Line 3 declarationTypePullDownMenu"
      },
      {
        "key": "3",
        "value": "Line 4 declarationTypePullDownMenu"
      }
    ],
    "parenthesisCode": "(8/1)",
    "borderColor": "bg-primary",
    "textDescription": "Quota Code ID 0",
  },
  {
    "elementName": "quotaOrderId02",
    "description": "The Quota Order Number of the quota against which a claim for relief from Customs Duty is to be applied.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "Line 1 declarationTypePullDownMenu"
      },
      {
        "key": "1",
        "value": "Line 2 declarationTypePullDownMenu"
      },
      {
        "key": "2",
        "value": "Line 3 declarationTypePullDownMenu"
      },
      {
        "key": "3",
        "value": "Line 4 declarationTypePullDownMenu"
      }
    ],
    "parenthesisCode": "(8/1)",
    "borderColor": "bg-primary",
    "textDescription": "Quota Order ID 02",
  },
  {
    "elementName": "proceeduralCodeCurrentCode",
    "description": "The procedure codes identify the customs and/or excise regimes which goods are being entered into and removed from (where this applies).",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "Line 1 declarationTypePullDownMenu"
      },
      {
        "key": "1",
        "value": "Line 2 declarationTypePullDownMenu"
      },
      {
        "key": "2",
        "value": "Line 3 declarationTypePullDownMenu"
      },
      {
        "key": "3",
        "value": "Line 4 declarationTypePullDownMenu"
      }
    ],
    "parenthesisCode": "(1/1)",
    "borderColor": "bg-primary",
    "textDescription": "Proceedurat Code - Current Code",
  },
  {
    "elementName": "registrationNationalityCode",
    "pullDownMenu": countries,
    "parenthesisCode": "(7/15)",
    "textDescription": "Registration Nationality Code",
    "description" : "Using the ISO Country code, enter the nationality of the active means of transport crossing the Union's external frontier.",
  },
  {
    "elementName": "countryOfDestinationCode",
    "pullDownMenu": countries,
    "parenthesisCode": "(5/8)",
    "textDescription": "Country of Destination Code",
    "description" : "ISO Country code for the Member State where the goods are located at the time of release into the customs procedure.",
  },
  {
    "elementName": "placeOfLoadingAirportCode",
    "pullDownMenu": airports,
    "parenthesisCode": "(5/21)",
    "textDescription": "Place of Loading Airport Code",
    "description" : "Identifies the foreign airport as required for CHIEF to calculate the air transport cost that can be included in the value for Customs duty.",
  },
  {
    "elementName": "customsOfficeOfPreservation",
    "pullDownMenu": tariffOffices,
    "parenthesisCode": "(5/26)",
    "textDescription": "Customs Office Preservation",
    "description" : "Using the relevant Union code, indicate the customs office where the goods are presented for the purpose of placing them under a customs procedure.",
  },
  {
    "elementName": "supervisingCustomsOffice",
    "pullDownMenu": tariffOffices,
    "parenthesisCode": "(5/27)",
    "textDescription": "Supervising Customs Office",
    "description" : "Using the relevant Union code, specify the customs office indicated in the respective authorisation to supervise the procedure.",
  },
  {
    "elementName": "inlandModeOfTransport",
    "parenthesisCode": "(7/5)",
    "textDescription": "Inland Mode of Transport",
    "description" : " The relevant Union code, of the mode of transport upon arrival. DMS uses the CodeList TransportModeTypes for these items.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "1",
        "value": "Maritime (Sea) transport"
      },
      {
        "key": "2",
        "value": "Rail transport"
      },
      {
        "key": "3",
        "value": "Road transport"
      },
      {
        "key": "4",
        "value": "Air transport"
      },
      {
        "key": "5",
        "value": "Postal (Mail) consignment"
      },
      {
        "key": "7",
        "value": "Fixed transport installations"
      },
      {
        "key": "8",
        "value": "Inland waterway transport"
      },
      {
        "key": "9",
        "value": "Mode unknown (for instance, own propulsion)"
      },
      {
        "key": "6",
        "value": "RoRo"
      }
    ]
  },
  {
    "elementName": "containerIdentificationNumber",
    "parenthesisCode": "(7/10)",
    "textDescription": "Container Identification Number",
    "description" : "Marks (letters and/or numbers) which identify the transport container. Please refer to the User Manual for further assistance.",
    "format": "an..17"
  },
  {
    "elementName": "eidr",
    "parenthesisCode": "(3/41)",
    "textDescription": "Person Representing the goods to Customs",
    "description" : "EORI number of the person presenting the goods to customs in cases where the declaration is made by entry in the declarant's records.",
  },
  {
    "elementName": "guaranteeType",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "For guarantee waiver"
      },
      {
        "key": "1",
        "value": "For comprehensive guarantee (Article 89(5) of the Code"
      },
      {
        "key": "2",
        "value": "For individual guarantee in the form of an undertaking by a guarantor (Article 92(1)(b) of the Code)"
      },
      {
        "key": "3",
        "value": "For individual guarantee in cash or other means of payment recognised by the customs authorities as being equivalent to a cash deposit, made in euro or in the currency of the Member State in which the guarantee is required (Article 92(1)(a) of the Code"
      },
      {
        "key": "4",
        "value": "For individual guarantee in the form of vouchers (Article 92(1)(b) of the Code and Article 160)"
      },
      {
        "key": "5",
        "value": "For guarantee waiver where the amount of import or export duty to be secured does not exceed the statistical value threshold for declarations laid down in accordance with Article 3(4) of Regulation (EC) No 471/2009 of the European Parliament and of the Council (15) (Article 89(9) of the Code)"
      },
      {
        "key": "7",
        "value": "For individual guarantee in another form which provides equivalent assurance that the amount of import or export duty corresponding to the customs debt and other charges will be paid (Article 92(1)(c) of the Code)"
      },
      {
        "key": "8",
        "value": "For guarantee not required for certain public bodies (Article 89(7) of the Code)"
      },
      {
        "key": "B",
        "value": "For guarantee furnished for goods dispatched under TIR procedure"
      },
      {
        "key": "C",
        "value": "For guarantee not required for goods carried by fix transport installations (Article 89(8)(b) of the Code)"
      },
      {
        "key": "D",
        "value": "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(a) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)"
      },
      {
        "key": "E",
        "value": "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(b) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)"
      },
      {
        "key": "F",
        "value": "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(c) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)"
      },
      {
        "key": "G",
        "value": "For guarantee not required for goods placed under the temporary admission procedure in accordance with Article 81(d) of Delegated Regulation (EU) 2015/2446 (Article 89(8)(c) of the Code)"
      },
      {
        "key": "H",
        "value": "For guarantee not required for goods placed under the Union transit procedure in accordance with Article 89(8)(d) of the Code"
      },
      {
        "key": "Y",
        "value": "Cash accounts (to be used for payment or security purposes)"
      }
    ],
    "parenthesisCode": "(8/2)",
    "textDescription": "Guarantee Type",
    "description" : "The type of guarantee used for the operation using the relevant Union codes in the tab 'Guarantee Codes'. Please refer to the User Manual for further assistance.",
    "format": "an1",
    "tableColMinWidth": 280
  },
  {
    "elementName": "natureOfTransaction",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "1",
        "value": "Transactions involving actual or intended transfer of ownership from residents to non-residents against financial or other compensation (except the transactions listed under 2, 7, 8)"
      },
      {
        "key": "2",
        "value": "Return and replacement of goods free of charge after registration of the original transaction"
      },
      {
        "key": "3",
        "value": "Transactions involving transfer of ownership without financial or in-kind compensation (for example, aid shipments)"
      },
      {
        "key": "4",
        "value": "Operations with a view to processing** under contract (no transfer of ownership to the processor)"
      },
      {
        "key": "5",
        "value": "Operations following processing under contract (no transfer of ownership to the processor)"
      },
      {
        "key": "6",
        "value": "Particular transactions recorded for national purposes"
      },
      {
        "key": "7",
        "value": "Operations under joint defence projects or other joint intergovernmental production programmes"
      },
      {
        "key": "8",
        "value": "Transactions involving the supply of building materials and technical equipment under a general construction or civil engineering contract for which no separate invoicing of the goods is required and an invoice for the total contract is issued"
      },
      {
        "key": "9",
        "value": "Other transactions which cannot be classified under other codes"
      },
      {
        "key": "11",
        "value": "Outright purchase/sale"
      },
      {
        "key": "12",
        "value": " Supply for sale on approval or after trial, for consignment or with the intermediation of a commission agent"
      },
      {
        "key": "13",
        "value": "Barter trade (compensation in kind)"
      },
      {
        "key": "14",
        "value": "Financial leasing (hire-purchase)"
      },
      {
        "key": "19",
        "value": "Transactions involving actual or intended transfer of ownership from residents to non-residents against financial or other compensation (except the transactions listed under 2, 7, 8) - Other"
      },
      {
        "key": "21",
        "value": "Return of goods"
      },
      {
        "key": "22",
        "value": "Replacement for returned goods"
      },
      {
        "key": "23",
        "value": "Replacement (for example, under warranty) for goods not being returned"
      },
      {
        "key": "29",
        "value": "Return and replacement of goods free of charge after registration of the original transaction - Other"
      },
      {
        "key": "41",
        "value": "Goods expected to return to the initial country of export"
      },
      {
        "key": "42",
        "value": "Goods not expected to return to the initial country of export"
      },
      {
        "key": "51",
        "value": "Goods returning to the initial country of export"
      },
      {
        "key": "52",
        "value": "Goods not returning to the initial country of export"
      },
      {
        "key": "91",
        "value": "Hire, loan, and operational leasing longer than 24 months"
      },
      {
        "key": "99",
        "value": "Other transactions which cannot be classified under other codes - Other"
      },
    ],
    "parenthesisCode": "(8/5)",
    "textDescription": "Nature of Transaction Code",
    "description": "The relevant Union codes and headings for the type of transaction concerned at Item level. Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "contactsCountries",
    "pullDownMenu": countries,
    "parenthesisCode": "",
    "textDescription": "Contacts Country List",
    "description" : "Country of the entity. Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "contactName",
    "parenthesisCode": "",
    "textDescription": "Contact Name",
    "description" : "Contact Name",
    "format": "an..70"
  },
  {
    "elementName": "contactSurname",
    "parenthesisCode": "",
    "formElementId": "contactSurname",
    "textDescription": "Contact Surname",
    "description": "Contact Surname",
    "format": "an..70"
  },
  {
    "elementName": "contactEori",
    "parenthesisCode": "",
    "textDescription": "Contact EORI",
    "description": "Contact EORI",
    "format": "an..17",
  },
  {
    "elementName": "contactAddressLine01",
    "parenthesisCode": "",
    "textDescription": "First Line of Address",
    "description": "First Line of Address.",
    "format": "an..70",
  },
  {
    "elementName": "contactAddressLine02",
    "parenthesisCode": "",
    "textDescription": "Second Line of Address",
    "description" : "Second Line of Address.",
    "format": "an..70"
  },
  {
    "elementName": "contactAddressLine03",
    "parenthesisCode": "",
    "textDescription": "Third Line of Address",
    "description": "Third Line of Address.",
    "format": "an..70"
  },
  {
    "elementName": "contactCity",
    "parenthesisCode": "",
    "textDescription": "City",
    "description" : "City",
    "format": "an..35"
  },
  {
    "elementName": "contactAddressLine04",
    "parenthesisCode": "",
    "textDescription": "Fourth Line of Address",
    "description": "Fourth Line of Address.",
    "format": "an..70"
  },
  {
    "elementName": "contactPostCode",
    "parenthesisCode": "",
    "textDescription": "Post Code",
    "description" : "Postal Code",
    "format": "an..9"
  },
  {
    "elementName": "contactPhone01",
    "parenthesisCode": "",
    "textDescription": "Telephone Number",
    "description" : "Telephone Number",
  },
  {
    "elementName": "contactPhone02",
    "parenthesisCode": "",
    "textDescription": "Telephone Number",
    "description": "Telephone Number",
  },
  {
    "elementName": "contactPhone03",
    "parenthesisCode": "",
    "textDescription": "Telephone Number",
    "description": "Telephone Number",
  },
  {
    "elementName": "contactEmail",
    "parenthesisCode": "",
    "textDescription": "Email",
    "description": "Email",
  },
  {
    "elementName": "contactRole",
    "parenthesisCode": "",
    "textDescription": "Role",
    "description": "Role",
  },
  {
    "elementName": "exchangeRate",
    "placeholder": "eg. 1USD = 0.71GBP",
    "parenthesisCode": "(4/15)",
    "textDescription": "Exchange Rate",
    "description" : "The rate of exchange fixed in advance by a contract between the parties concerned.",
    "format": "n..12,5",
  },
  {
    "elementName": "totalNoOfItems2",
    "parenthesisCode": "(1/9)",
    "borderColor": "border-warning",
    "textDescription": "Total No Of Items",
    "description" : "Count of the total number of goods items within a document. Specified by DMS.",
  },
  {
    "elementName": "grossMassMeasure",
    "parenthesisCode": "(6/5)",
    "textDescription": "Gross Mass Measure",
    "description" : "The gross mass, expressed in kilograms, of the goods concerned by the relevant item of goods. NB This cannot be zero (0).",
    "format": "n..16,6"
  },
  {
    "elementName": "netWeightMeasure",
    "parenthesisCode": "(6/1)",
    "textDescription": "Net NetWeight Measure",
    "description" : "The net mass, expressed in kilograms, of the goods concerned by the relevant declaration goods item. Please refer to the User Manual for further assistance.",
    "format": "n..16,6"
  },
  {
    "elementName": "tariffQuantity",
    "parenthesisCode": "(6/2)",
    "textDescription": "Tariff Quantity",
    "description" : " The quantity of goods items specified in the unit type as indicated by the Taric goods nomenclature as specified in SAD box 41.",
  },
  {
    "elementName": "typeCode",
    "description": "Identifies the Tax Type code for each type of duty or tax applicable to the goods concerned.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A00",
        "value": "Customs duties"
      },
      {
        "key": "A20",
        "value": "Additional Duties"
      },
      {
        "key": "A30",
        "value": "Definitive antidumping duties (ADD)"
      },
      {
        "key": "A35",
        "value": "Provisional antidumping duties (ADD)"
      },
      {
        "key": "A40",
        "value": "Definitive countervailing duties"
      },
      {
        "key": "A45",
        "value": "Provisional countervailing duties"
      },
      {
        "key": "B00",
        "value": "Value Added Tax (VAT)"
      },
      {
        "key": "407",
        "value": "Beer made in UK (standard rate, that is, annual production more than 60,000 hectolitres)"
      },
      {
        "key": "411",
        "value": "Wine [sparkling], of fresh grape, 8.5% vol. and above, but not exceeding 15% vol."
      },
      {
        "key": "412",
        "value": "Wine [sparkling], of fresh grape, exceeding 5.5% vol. but less than 8.5% vol."
      },
      {
        "key": "413",
        "value": "Wine [still], exceeding 5.5% vol. but not exceeding 15% vol."
      },
      {
        "key": "415",
        "value": "Wine [still or sparkling], exceeding 15% vol. but not exceeding 22% vol."
      },
      {
        "key": "419",
        "value": "Wine [still or sparkling], exceeding 22% vol."
      },
      {
        "key": "421",
        "value": "Made-wine [sparkling], exceeding 8.5% vol. but not exceeding 15% vol."
      },
      {
        "key": "422",
        "value": "Made-wine [sparkling], exceeding 5.5% vol. but less than 8.5% vol."
      },
      {
        "key": "423",
        "value": "Made-wine [still], exceeding 5.5% vol. but not exceeding 15% vol."
      },
      {
        "key": "425",
        "value": "Made-wine [still or sparkling], exceeding 15% vol. but not exceeding 22% vol."
      },
      {
        "key": "429",
        "value": "Made-wine [still or sparkling], exceeding 22% vol."
      },
      {
        "key": "431",
        "value": "Low alcohol beverage of less than 1.2% vol."
      },
      {
        "key": "433",
        "value": "Wine, spirit based beverage exceeding 1.2% vol., but not exceeding 4% vol."
      },
      {
        "key": "435",
        "value": "Wine, spirit based beverage exceeding 4% vol., but not exceeding 5.5% vol."
      },
      {
        "key": "438",
        "value": "Spirit-based beverages exceeding, 1.2% vol. but not exceeding 8% vol."
      },
      {
        "key": "440",
        "value": "Beer made in UK - small brewery beer eligible to reduced rates (variable rate, that is, annual production more than 5,000 hectolitres but not exceeding 60,000 hectolitres)"
      },
      {
        "key": "441",
        "value": "Imported beer - small brewery beer eligible to reduced rates (variable rate, that is, annual production more than 5,000 hectolitres but not exceeding 60,000 hectolitres)"
      },
      {
        "key": "442",
        "value": "Beer made in UK - small brewery beer eligible to reduced rates (variable rate, that is, annual production no more than 5,000 hectolitres)"
      },
      {
        "key": "443",
        "value": "Imported beer - small brewery beer eligible to reduced rates (variable rate, that is, annual production no more than 5,000 hectolitres)"
      },
      {
        "key": "444",
        "value": "Beer made in UK - reduced rate of general beer duty (applies to beer exceeding 1.2% abv but not exceeding 2.8% abv)"
      },
      {
        "key": "445",
        "value": "Beer made in UK - high strength beer duty (applies to beer exceeding 7.5% abv - general beer duty is also due on all UK produced high strength beer)"
      },
      {
        "key": "446",
        "value": "Imported beer - reduced rate of general beer duty (applies to beer exceeding 1.2% abv but not exceeding 2.8% abv)"
      },
      {
        "key": "447",
        "value": "Imported beer - high strength beer duty (applies to beer exceeding 7.5% abv - general beer duty is also due on all imported high strength beer)"
      },
      {
        "key": "451",
        "value": "Spirits other than UK produced whisky"
      },
      {
        "key": "461",
        "value": "UK produced Whisky - wholly malt"
      },
      {
        "key": "462",
        "value": "UK produced Whisky - wholly grain"
      },
      {
        "key": "463",
        "value": "UK produced Whisky - blended"
      },
      {
        "key": "473",
        "value": "Beer based beverage exceeding 1.2% vol."
      },
      {
        "key": "481",
        "value": "Cider and Perry [sparkling], made in UK or imported, exceeding 1.2% but not exceeding 5.5%, Cider and Perry [still], made in the UK or imported exceeding 1.2% but not exceeding than 6.9% "
      },
      {
        "key": "483",
        "value": "Cider and Perry [still], made in the UK or imported exceeding 7.5% but not exceeding 8.5%"
      },
      {
        "key": "485",
        "value": "Cider and Perry [sparkling], made in the UK or imported exceeding 5.5% but not exceeding 8.5%"
      },
      {
        "key": "487",
        "value": "Cider and Perry [still], made in the UK or imported exceeding 6.9% but not exceeding 7.5%"
      },
      {
        "key": "511",
        "value": "Light oil (unmarked) - aviation gasoline (including light oil aviation turbine fuel)"
      },
      {
        "key": "520",
        "value": "Light oil (unmarked) - other unrebated light oil"
      },
      {
        "key": "521",
        "value": "Light oil, furnace fuel"
      },
      {
        "key": "522",
        "value": "Light oil, unleaded fuel"
      },
      {
        "key": "540",
        "value": "Heavy oil - other (unmarked) heavy oil (other than kerosene) intended for use as heating fuel, or as fuel for an engine; which would otherwise be eligible for a full rebate"
      },
      {
        "key": "541",
        "value": "Heavy oil (unmarked; including Diesel Engine Road Vehicle (DERV) or road fuel extender and unmarked kerosene or unmarked gas oil for which no marking waiver has been granted"
      },
      {
        "key": "542",
        "value": "Heavy oil kerosene to be used as motor fuel off road or in an excepted vehicle"
      },
      {
        "key": "551",
        "value": "Heavy Oil kerosene (marked/unmarked under marking waiver, including heavy oil aviation turbine fuel) to be used other than as motor fuel off-road or in an excepted vehicle"
      },
      {
        "key": "556",
        "value": "Heavy gas oil (marked/unmarked under marking waiver)"
      },
      {
        "key": "561",
        "value": "Heavy oil, Fuel oil (unmarked)"
      },
      {
        "key": "570",
        "value": "Heavy oil, other (unmarked)"
      },
      {
        "key": "571",
        "value": "Biodiesel to be used as motor fuel off road or in an excepted vehicle"
      },
      {
        "key": "572",
        "value": "Biodiesel blended with kerosene for use as heating fuel"
      },
      {
        "key": "589",
        "value": "Biodiesel"
      },
      {
        "key": "591",
        "value": "Road fuel gases - natural gas including biogas"
      },
      {
        "key": "592",
        "value": "Road fuel gases - other than natural gas, e.g. LPG"
      },
      {
        "key": "595",
        "value": "Bioethanol"
      },
      {
        "key": "611",
        "value": "Cigarettes"
      },
      {
        "key": "615",
        "value": "Cigars"
      },
      {
        "key": "619",
        "value": "Hand rolling tobacco"
      },
      {
        "key": "623",
        "value": "Smoking tobacco - other"
      },
      {
        "key": "627",
        "value": "Chewing tobacco"
      },
      {
        "key": "633",
        "value": "Tobacco for heating"
      },
      {
        "key": "99A",
        "value": "Solid fuels i.e. coal and lignite, coke and semi-coke of coal or lignite, and petroleum coke"
      },
      {
        "key": "99B",
        "value": "Any petroleum gas, or other gaseous hydrocarbon supplied in a liquid state"
      },
      {
        "key": "99C",
        "value": "Gas supplied by a gas utility or any gas supplied in a gaseous state that is of a kind supplied by a gas utility in Great Britain"
      },
      {
        "key": "99D",
        "value": "Electricity"
      },
      {
        "key": "B05",
        "value": "B05"
      },
      {
        "key": "C10",
        "value": "C10"
      },
      {
        "key": "E00",
        "value": "E00"
      },
      {
        "key": "487",
        "value": "487"
      },
      {
        "key": "A50",
        "value": "A50"
      },
      {
        "key": "A70",
        "value": "A70"
      },
      {
        "key": "A80",
        "value": "A80"
      },
      {
        "key": "A85",
        "value": "A85"
      },
      {
        "key": "A90",
        "value": "A90"
      },
      {
        "key": "A95",
        "value": "A95"
      }
    ],
    "parenthesisCode": "(4/3)",
    "textDescription": "Type Code",
  },
  
  {
    "elementName": "adValoremTaxBaseAmount",
    "parenthesisCode": "",
    "textDescription": "Ad Valorem Tax Base Amount",
    "description" : "The composite quantity or amount on which the tax or duty is based.",
  },
  {
    "elementName": "deductAmount",
    "parenthesisCode": "",
    "textDescription": "Deduct Amount",
    "description" : " The corresponding amount in National currency that has not yet been included in or deducted from the item price. Please refer to the User Manual for further info.",
  },
  {
    "elementName": "dutyRegime",
    "parenthesisCode": "",
    "textDescription": "Duty Regime",
    "description" : "This data element concerns information on the tariff treatment of the goods. Please refer to the User Manual for further info."
  },
  {
    "elementName": "quotaOrderIdA",
    "parenthesisCode": "(8/1)",
    "textDescription": "Quota Order ID A",
    "description": "The Quota Order Number of the quota against which a claim for relief from Customs Duty is to be applied.",
    "format": "an6"
  },
  {
    "elementName": "quotaOrderIdB",
    "parenthesisCode": "(8/1)",
    "textDescription": "Quora Order ID B",
    "description" : "The Quota Order Number of the quota against which a claim for relief from Customs Duty is to be applied.",
    "format": "an6"
  },
  {
    "elementName": "methodCode",
    "description": "The method by which the revenue is to be paid or secured.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A",
        "value": "Immediate payment in cash"
      },
      {
        "key": "B",
        "value": "Immediate payment by corporate credit card"
      },
      {
        "key": "C",
        "value": "Immediate payment by cheque"
      },
      {
        "key": "D",
        "value": "Not currently in use"
      },
      {
        "key": "E",
        "value": "Deferred payment (deferment account)"
      },
      {
        "key": "G",
        "value": "Postponed payment: VAT system (Article 211 of EU Directive 2006/112/EC)"
      },
      {
        "key": "H",
        "value": "Immediate payment by electronic credit transfer (faster payment, CHAPS, BACS)"
      },
      {
        "key": "M",
        "value": "Security deposit: immediate payment (cash deposit)"
      },
      {
        "key": "N",
        "value": "Security deposit: immediate payment (CDS Cash Account deposit)"
      },
      {
        "key": "O",
        "value": "Export refunds and/or CAP licence, security held by RPA"
      },
      {
        "key": "P",
        "value": "Immediate payment from CDS Cash Account"
      },
      {
        "key": "R",
        "value": "Security deposit: deferred payment (deferment account deposit)"
      },
      {
        "key": "S",
        "value": "Security Importers general guarantee account ( self-representation)"
      },
      {
        "key": "T",
        "value": "Security Agent’s general guarantee account"
      },
      {
        "key": "U",
        "value": "Security: Importers guarantee account - standing authority (direct/indirect representation)"
      },
      {
        "key": "V",
        "value": "Security: Importers guarantee account - individual/specific authority (direct/ indirect representation)"
      },
      {
        "key": "X",
        "value": "SASP"
      },
      {
        "key": "Z",
        "value": "Security Importers individual guarantee (e.g. C&E 250)"
      }
    ],
    "parenthesisCode": "(4/8)",
    "textDescription": "Method Code",
  },
  {
    "elementName": "paymentAmount",
    "parenthesisCode": "(4/6)",
    "textDescription": "Tax Rate Numeric",
    "description" : "Group Level item - Calculation of Taxes. The declared amount of revenue due for a Tax Line on an Entry.",
  },
  {
    "elementName": "taxAssessedAmount",
    "parenthesisCode": "(4/7)",
    "textDescription": "Tax Assessed Amount",
    "description": "Group Level item - Calculation of Taxes - Total.",
  },
  {
    "elementName": "documentCategory",
    "description": "The First Component: Identifies the class or kind of document being declared.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "X",
        "value": "declaration for temporary storage"
      },
      {
        "key": "Y",
        "value": "simplified declaration or the entry in the declarant's records"
      },
      {
        "key": "Z",
        "value": "previous document"
      }
    ],
    "parenthesisCode": "(2/1)",
    "textDescription": "Document Category",
  },
  {
    "elementName": "previousDocumentType",
    "description": "The Second  Component (an..3), consists of a combination of digits and/ or letters, serves to identify the type of document.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "235",
        "value": "Container List"
      },
      {
        "key": "270",
        "value": "Delivery Note"
      },
      {
        "key": "271",
        "value": "Packing List"
      },
      {
        "key": "325",
        "value": "Proforma Invoice"
      },
      {
        "key": "337",
        "value": "Temporary Storage Declaration"
      },
      {
        "key": "355",
        "value": "Entry Summary Declaration (ENS)"
      },
      {
        "key": "380",
        "value": "Commercial Invoice"
      },
      {
        "key": "703",
        "value": "House Waybill"
      },
      {
        "key": "704",
        "value": "Master Bill of Lading"
      },
      {
        "key": "705",
        "value": "Bill of Lading"
      },
      {
        "key": "714",
        "value": "House Bill of Lading"
      },
      {
        "key": "720",
        "value": "Rail Consignment Note"
      },
      {
        "key": "722",
        "value": "Road list SMGS"
      },
      {
        "key": "730",
        "value": "Road Consignment Note"
      },
      {
        "key": "740",
        "value": "Air Waybill"
      },
      {
        "key": "741",
        "value": "Master Air Waybill"
      },
      {
        "key": "750",
        "value": "Despatch Note (Post Parcels)"
      },
      {
        "key": "760",
        "value": "Multimodal/ Combined Transport Document"
      },
      {
        "key": "785",
        "value": "Cargo Manifest"
      },
      {
        "key": "787",
        "value": "Bordereau"
      },
      {
        "key": "820",
        "value": "Union/ Common Transit Declaration — Mixed Consignments (T)"
      },
      {
        "key": "821",
        "value": "External Union/ Common Transit Declaration (T1)"
      },
      {
        "key": "822",
        "value": "Internal Union/ Common Transit Declaration (T2)"
      },
      {
        "key": "825",
        "value": "Proof of the Customs Status of Union Goods T2L"
      },
      {
        "key": "952",
        "value": "TIR Carnet"
      },
      {
        "key": "955",
        "value": "ATA Carnet"
      },
      {
        "key": "CLE",
        "value": "Reference/Date of Entry in the Declarant’s Records (EIDR)*"
      },
      {
        "key": "IF3",
        "value": "Information Sheet INF3"
      },
      {
        "key": "MNS",
        "value": "Cargo Manifest — Simplified Procedure"
      },
      {
        "key": "MRN",
        "value": "Declaration/ Notification MRN (including reference no. of any EAD)"
      },
      {
        "key": "T2F",
        "value": "Internal Union Transit Declaration — Article 227 of the Code"
      },
      {
        "key": "T2G",
        "value": "Proof of the Customs Status of Union Goods T2LF"
      },
      {
        "key": "T2M",
        "value": "T2M Proof"
      },
      {
        "key": "SDE",
        "value": "Simplified Declaration** for Simplified Declaration Procedure (SDP)"
      },
      {
        "key": "CSE",
        "value": "Customs Supervised Export"
      },
      {
        "key": "MCR",
        "value": "Master Unique Consignment Reference (MUCR) (Including any inventory reference where applicable)"
      },
      {
        "key": "DCR",
        "value": "Declaration Unique Consignment Reference (DUCR)"
      },
      {
        "key": "DCS",
        "value": "Declaration Unique Consignment Reference Part Number (DUCR Part)"
      },
      {
        "key": "ZZZ",
        "value": "Other"
      },
      {
        "key": "AAD",
        "value": "Administrative Accompanying Document"
      },
      {
        "key": "MOU",
        "value": "Memorandum of Understanding (approved exporters only)"
      },
      {
        "key": "CPD",
        "value": "Carnet de Passages en Douane (CPD) "
      }
    ],
    "parenthesisCode": "(2/1)",
    "textDescription": "Previous Document Type",
  },
  {
    "elementName": "previousDocumentReference",
    "parenthesisCode": "(2/1)",
    "textDescription": "Previous Document Reference",
    "description" : "The Third Component (an..35) represents the data needed to recognise and identify the document.",
    "regex": new RegExp(/^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9])+[a-zA-Z]{0,30}))-((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9])+[a-zA-Z]{0,5}))$/),
  },
  {
    "elementName": "goodsItemIdentifier",
    "parenthesisCode": "(2/1)",
    "textDescription": "Goods Item Identifier",
    "description" : "The Fourth Component (n..3) is used to identify which item of the previous document is being referred to.",
    "placeholder": "Leave blank if single goods item",
    "format": "n..3"
  },
  {
    "elementName": "additionalInformationCode",
    "parenthesisCode": "(2/2)",
    "pullDownMenu": additionalInformationCodes,
    "textDescription": "Code",
    "description" : " Identifies an additional information statement at item level and header level.",
  },
  {
    "elementName": "additionalInformationDescription",
    "parenthesisCode": "(2/2)",
    "textDescription": "Description",
    "description" : "A single line of text describing the value of the AI statement code. Please refer to the User Manual for further assistance.",
    "format": "an..512"
  },
  {
    "elementName": "deferredPaymentId",
    "includeKeyInTable": true,
    "placeholder": "eg. 000000",
    "parenthesisCode": "(2/6)",
    "textDescription": "Deferred Payment ID",
    "description" : "The reference number of the deferred payment authorization in question.",
    "format": "n7"
  },
  {
    "elementName": "deferredPaymentType",
    "description": "Code 'DAN' should be used specifying the category of a Deferred Payment.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "1DAN",
        "value": "The deferment account to be used for all charges, unless 2DAN is also declared."
      },
      {
        "key": "2DAN",
        "value": "Only import VAT will be charged to Deferment account number"
      }
    ],
    "parenthesisCode": "(2/6)",
    "textDescription": "Deferred Payment Type",
  },
  {
    "elementName": "warehouseId",
    "parenthesisCode": "(2/7)",
    "textDescription": "Warehouse ID",
    "description" : "The approved premise identifier consists of the identification number for the warehouse issued by the authorizing member state and the Country Code.",
    "format": "an..35",
    "pullDownMenu": [
      {
        "key": "A",
        "value": "A"
      },
      {
        "key": "C",
        "value": "C"
      },
      {
        "key": "E",
        "value": "E"
      },
      {
        "key": "R",
        "value": "R"
      },
      {
        "key": "U",
        "value": "U"
      }
    ]
  },
  {
    "elementName": "warehouseType",
    "description": "Using the relevant Union code, enter the type of the storage facility, followed by the authorisation number of the warehouse or temporary storage facility concerned.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "R",
        "value": "Public customs warehouse type I"
      },
      {
        "key": "S",
        "value": "Public customs warehouse type II*"
      },
      {
        "key": "T",
        "value": "Public customs warehouse type III*"
      },
      {
        "key": "U",
        "value": "Private customs warehouse"
      },
      {
        "key": "Y",
        "value": "Non-customs warehouse"
      },
      {
        "key": "Z",
        "value": "Free zone"
      }
    ],
    "parenthesisCode": "(2/7)",
    "textDescription": "Warehouse Type",
  },
  {
    "elementName": "addionalSupplyChainActorId",
    "parenthesisCode": "(3/37)",
    "textDescription": "ID",
    "description" : "Unique identification number assigned to an economic operator of a third country in the framework of a trade partnership programme. Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "addionalSupplyChainActorRoleCode",
    "description": "Specifies the third party's role in the supply chain. Only used in conjunction with the Additional Supply Chain actor identification No. id.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "Line 1"
      },
      {
        "key": "1",
        "value": "Line 2"
      }
    ],
    "parenthesisCode": "(3/37)",
    "textDescription": "Type",
  },
  {
    "elementName": "holderOfAuthorisationIdentificationId",
    "placeholder": "eg. 000000",
    "parenthesisCode": "(3/39)",
    "textDescription": "ID",
    "descripion" : "This data element is only mandatory where an authorization is required to declare the goods. Please refer to the User Manual for further assistance.",
    "format": "an..17"
  },
  {
    "elementName": "holderOfAuthorisationIdentificationTypeCode",
    "description": "This data element is only mandatory where an authorisation is required to declare the goods. Please refer to the User Manual for further assistance.",
    "pullDownMenu": [
      {
        "key": "ACE",
        "value": "Authorised consignee for Union transit"
      },
      {
        "key": "ACP",
        "value": "Authorised issuer to establish the proof of the customs status of Union goods"
      },
      {
        "key": "ACR",
        "value": "Authorised consignor for Union transit"
      },
      {
        "key": "ACT",
        "value": "Authorised consignee for TIR procedure"
      },
      {
        "key": "AEOC",
        "value": "Authorised Economic Operator — Customs simplifications"
      },
      {
        "key": "AEOF",
        "value": "Authorised Economic Operator — Customs simplifications/Security and safety"
      },
      {
        "key": "AEOS",
        "value": "Authorised Economic Operator — Security and safety"
      },
      {
        "key": "APEX",
        "value": "Approved Exporter"
      },
      {
        "key": "AWB",
        "value": "Authorised Weigher ofBananas"
      },
      {
        "key": "BOI",
        "value": "Binding Origin Information"
      },
      {
        "key": "BRD",
        "value": "Bulk Import Reduced Data Set Authorisation"
      },
      {
        "key": "BTI",
        "value": "Binding Tariff Information"
      },
      {
        "key": "CCL",
        "value": "Authorisation for centralised clearance"
      },
      {
        "key": "CGU",
        "value": "Customs comprehensiveguarantee"
      },
      {
        "key": "CSDR",
        "value": "Community System of Duty Reliefs"
      },
      {
        "key": "CSE",
        "value": "CustomsSupervised Exports"
      },
      {
        "key": "CVA",
        "value": "Simplification of the determination of amounts being part of the customs value of goods"
      },
      {
        "key": "CW1",
        "value": "Operation of storage facilities for the customs warehousing of goods in a public customs warehouse type I"
      },
      {
        "key": "CW2",
        "value": "Operation of storage facilities for the customs warehousing of goods in a public customs warehouse type II."
      },
      {
        "key": "CWP",
        "value": "Operation of storage facilities for the customs warehousing of goods in a private customs warehouse"
      },
      {
        "key": "DEP",
        "value": "Designated Export Place"
      },
      {
        "key": "DPO",
        "value": "Deferment of payment authorisation"
      },
      {
        "key": "EIR",
        "value": "Authorisation for the use of customs declaration through an entry of data in the declarant’s records(EIDR)"
      },
      {
        "key": "EORI",
        "value": "Economic Operator Registration and Identification"
      },
      {
        "key": "EXRR",
        "value": "RORO Exporter"
      },
      {
        "key": "EPSS",
        "value": "Excise Payment Security System"
      },
      {
        "key": "ETD",
        "value": "ElectronicTransportDocument (authorised for use as a customs declaration)"
      },
      {
        "key": "EUS",
        "value": "EndUse authorisation"
      },
      {
        "key": "EXEE",
        "value": "Registered Consignee (Excise)"
      },
      {
        "key": "EXOR",
        "value": "Registered Consignor (Excise)"
      },
      {
        "key": "EXW",
        "value": "Excise Warehouse"
      },
      {
        "key": "EXWH",
        "value": "Excise Warehouse – Hydrocarbon Oils"
      },
      {
        "key": "FAS",
        "value": "Flexible Accounting System"
      },
      {
        "key": "FP",
        "value": "Freeport Authorisation"
      },
      {
        "key": "FTI",
        "value": "Fixed Transport Installation "
      },
      {
        "key": "FZ",
        "value": "Free Zone (only for the use of the Isle of Man)"
      },
      {
        "key": "GGA",
        "value": "General Guarantee Account"
      },
      {
        "key": "GVS",
        "value": "General Valuation Statement"
      },
      {
        "key": "IOSI",
        "value": "Import One Stop Shop (IOSS)-VAT Identification Number issued to an Intermediary"
      },
      {
        "key": "IOST",
        "value": "Import One Stop Shop (IOSS)-VAT Identification Number issued to a Taxable Person"
      },
      {
        "key": "IPO",
        "value": "InwardProcessing authorisation"
      },
      {
        "key": "MOU",
        "value": "Memorandum of Understanding(approved exporters only)"
      },
      {
        "key": "OPO",
        "value": "OutwardProcessing authorisation"
      },
      {
        "key": "REM",
        "value": "Remission of the amounts of import or export duty"
      },
      {
        "key": "REP",
        "value": "Repayment of the amounts of import or export duty"
      },
      {
        "key": "REX",
        "value": "Registered Exporter"
      },
      {
        "key": "RSS",
        "value": "RegularShippingServices authorisation"
      },
      {
        "key": "SAS",
        "value": "Authorisation for self-assessment"
      },
      {
        "key": "SASP",
        "value": "Single Authorisation for Simplified Procedures"
      },
      {
        "key": "SDE",
        "value": "SimplifiedDeclarationProcedureauthorisation (SDP)"
      },
      {
        "key": "SIVA",
        "value": "Simplified Import VAT Accounting"
      },
      {
        "key": "SSE",
        "value": "Authorisation for the use of seals of a special type"
      },
      {
        "key": "TEA",
        "value": "TemporaryAdmission authorisation"
      },
      {
        "key": "TEAH",
        "value": "Temporary Admission authorisation – Auction Houses (no guarantee for VAT required)"
      },
      {
        "key": "TRD",
        "value": "Authorisation to use transit declaration with a reduced dataset"
      },
      {
        "key": "TST",
        "value": "Authorisation to operate storage facilities for the temporary storage of goods"
      },
      {
        "key": "UKCS",
        "value": "UKContinentalShelf"
      },
      {
        "key": "UKD",
        "value": "UK Destination Authorisation"
      },
      {
        "key": "UKTS",
        "value": "UK Trader Scheme Authorisation"
      }
    ],
    "parenthesisCode": "(3/39)",
    "textDescription": "Type",
  },
  {
    "elementName": "deliveryTermsCityCode",
    "parenthesisCode": "(4/1)",
    "textDescription": "City",
    "description" : "A free text data item consisting of 2 characters country code from any country followed by a 3 character code identifying the specific city, town or village.",
  },
  {
    "elementName": "incoTermCode",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "CFR",
        "value": "Cost and freight"
      },
      {
        "key": "CIF",
        "value": "Cost, insurance and freight"
      },
      {
        "key": "CIP",
        "value": "Carriage and insurance paid to"
      },
      {
        "key": "CPT",
        "value": "Carriage paid to"
      },
      {
        "key": "DAF",
        "value": "Delivered at frontier"
      },
      {
        "key": "DAP",
        "value": "Delivered at place"
      },
      {
        "key": "DAT",
        "value": "Delivered at terminal"
      },
      {
        "key": "DDP",
        "value": "Delivered duty paid"
      },
      {
        "key": "DDU",
        "value": "Delivered duty unpaid"
      },
      {
        "key": "DEQ",
        "value": "Delivered ex quay"
      },
      {
        "key": "DES",
        "value": "Delivered ex ship"
      },
      {
        "key": "EXW",
        "value": "Ex works"
      },
      {
        "key": "FAS",
        "value": "Free alongside ship"
      },
      {
        "key": "FCA",
        "value": "Free carrier"
      },
      {
        "key": "FOB",
        "value": "Free on board"
      },
      {
        "key": "XXX",
        "value": "Delivery terms other than those previously listed"
      }
    ],
    "parenthesisCode": "(4/1)",
    "textDescription": "Condition Code",
    "description" : "International Commercial Terms for the Declaration. Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "unLocode",
    "placeholder": "eg. XX XX XX",
    "parenthesisCode": "(4/1)",
    "textDescription": "UN/LOCODE",
    "description" : "United Nations Code for Trade and Transport Locations.",
    "format": "an..17"
  },
  {
    "elementName": "deliveryTermsCountryCode",
    "pullDownMenu": countries,
    "parenthesisCode": "(4/1)",
    "textDescription": "Country",
    "description" : "A free text data item consisting of 2 characters country code from any country followed by a 3 character code identifying the specific city, town or village.",
  },
  {
    "elementName": "specificTaxBaseQuantity",
    "parenthesisCode": "(4/4)",
    "textDescription": "Specific Tax Base Quantity",
    "description" : "The composite quantity or amount on which the tax or duty is based.",
    "format": "n..16,6"
  },
  {
    "elementName": "taxBasedUnit",
    "description": "The measurement units and qualifiers defined in TARIC should be used.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "ASV",
        "value": "% volume (Alcoholic strength per volume)"
      },
      {
        "key": "CCT",
        "value": "Carrying capacity in metric tonnes"
      },
      {
        "key": "CEN",
        "value": "100 items"
      },
      {
        "key": "CTM",
        "value": "Carats (one metric carat : 2x10$-4kg)"
      },
      {
        "key": "DAP",
        "value": "Decatonne, corrected according to polarisation"
      },
      {
        "key": "DHS",
        "value": "Kilogram of dihydrostreptomycin"
      },
      {
        "key": "DTN",
        "value": "Hectokilogram"
      },
      {
        "key": "EUR",
        "value": "Euro (used for statistical surveillance)"
      },
      {
        "key": "FC1",
        "value": "Factor"
      },
      {
        "key": "GBP*",
        "value": "Pounds (£) Sterling"
      },
      {
        "key": "GFI",
        "value": "Gram of fissile isotopes"
      },
      {
        "key": "GP1",
        "value": "Gross Production"
      },
      {
        "key": "GRM",
        "value": "Gram"
      },
      {
        "key": "GRT",
        "value": "Gross tonnage"
      },
      {
        "key": "HLT",
        "value": "Hectolitre"
      },
      {
        "key": "HMT",
        "value": "Hectometre"
      },
      {
        "key": "KAC",
        "value": "Kilogram net if acesulfame potassium"
      },
      {
        "key": "KCC",
        "value": "Kilogram of choline chloride"
      },
      {
        "key": "KCL",
        "value": "Tonne of potassium chloride"
      },
      {
        "key": "KGM",
        "value": "Kilogram"
      },
      {
        "key": "KLT",
        "value": "1000 litres"
      },
      {
        "key": "KMA",
        "value": "Kilogram of methylamines"
      },
      {
        "key": "KMT",
        "value": "Kilometre"
      },
      {
        "key": "KNI",
        "value": "Kilogram of nitrogen"
      },
      {
        "key": "KNS",
        "value": "Kilogram of hydrogen peroxide"
      },
      {
        "key": "KPH",
        "value": "Kilogram of potassium hydroxide (caustic potash)"
      },
      {
        "key": "KPO",
        "value": "Kilogram of potassium oxide"
      },
      {
        "key": "KPP",
        "value": "Kilogram of diphosphorus pentaoxide"
      },
      {
        "key": "KSD",
        "value": "Kilogram of substance 90% dry"
      },
      {
        "key": "KSH",
        "value": "Kilogram of sodium hydroxide (caustic soda)"
      },
      {
        "key": "KUR",
        "value": "Kilogram of uranium"
      },
      {
        "key": "KW1",
        "value": "Kilowatt hour"
      },
      {
        "key": "LHC",
        "value": "Standard litre (of hydrocarbon oil)"
      },
      {
        "key": "LPA",
        "value": "Litre pure (100%) alcohol"
      },
      {
        "key": "LTR",
        "value": "Litre"
      },
      {
        "key": "MCG",
        "value": "Microgram"
      },
      {
        "key": "MCL",
        "value": "Microlitre"
      },
      {
        "key": "MGM",
        "value": "Milligram"
      },
      {
        "key": "MIL",
        "value": "1000 items"
      },
      {
        "key": "MLT",
        "value": "Millilitre"
      },
      {
        "key": "MTK",
        "value": "Square metre"
      },
      {
        "key": "MTQ",
        "value": "Cubic metre"
      },
      {
        "key": "MTR",
        "value": "Metre"
      },
      {
        "key": "MWH",
        "value": "100 kilowatt hours"
      },
      {
        "key": "NAR",
        "value": "Number of items"
      },
      {
        "key": "NCL",
        "value": "Number of cells"
      },
      {
        "key": "NPR",
        "value": "Number of pairs"
      },
      {
        "key": "RET",
        "value": "Retail Price"
      },
      {
        "key": "TCE",
        "value": "Tonne of CO2 equivalent"
      },
      {
        "key": "TJO",
        "value": "Terajoule (gross calorific value)"
      },
      {
        "key": "TNE",
        "value": "Tonne"
      },
      {
        "key": "WAT",
        "value": "Number of Watt"
      }
    ],
    "parenthesisCode": "(4/4)",
    "textDescription": "Tax Base Unit",
    "dependentOn": "76"
  },
  {
    "elementName": "taxBaseQualifier",
    "isHidden": true,
    "pullDownMenu": qualifiers,
    "parenthesisCode": "(4/4)",
    "textDescription": "Qualifier",
    "description" : "The measurement units and qualifiers defined in TARIC should be used.",
  },
  {
    "elementName": "payableTaxAmount",
    "parenthesisCode": "(4/6)",
    "textDescription": "Payable Tax Amount",
    "description" : "The declared amount of revenue due for a Tax Line on an Entry.",
    "format": "n..16,2"
  },
  {
    "elementName": "payableTaxAmountCurrency",
    "description": "The currency used for the declared amount of revenue due for a Tax Line on an Entry.",
    "pullDownMenu": [
      {
        "key": "GBP",
        "value": "GBP"
      }
    ],
    "parenthesisCode": "(4/6)",
    "textDescription": "Currency",
  },
  {
    "elementName": "taxRate",
    "parenthesisCode": "(4/5)",
    "textDescription": "Tax Rate",
    "description" : "Group Level item - Calculation of Taxes - Tax Rate",
  },
  {
    "elementName": "totalPayableTaxAmount",
    "parenthesisCode": "(4/7)",
    "textDescription": "Tax Total Amount",
    "description" : "The total amount of duties and taxes for the goods concerned.",
    "format": "n..16,2"
  },
  {
    "elementName": "totalpayableTaxCurrency",
    "pullDownMenu": [
      {
        "key": "GBP",
        "value": "GBP"
      }
    ],
    "parenthesisCode": "(4/7)",
    "textDescription": "Total Taxes Currency",
    "description" : " The currency used for the Tax Assessed Amount data.",
  },
  {
    "elementName": "totalTaxesMethodOfPayment",
    "description": "The method by which the revenue is to be paid or secured.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A",
        "value": "Immediate payment in cash"
      },
      {
        "key": "B",
        "value": "Immediate payment by corporate credit card"
      },
      {
        "key": "C",
        "value": "Immediate payment by cheque"
      },
      {
        "key": "D",
        "value": "Not currently in use"
      },
      {
        "key": "E",
        "value": "Deferred payment (deferment account)"
      },
      {
        "key": "G",
        "value": "Postponed payment: VAT system (Article 211 of EU Directive 2006/112/EC)"
      },
      {
        "key": "H",
        "value": "Immediate payment by electronic credit transfer (faster payment, CHAPS, BACS)"
      },
      {
        "key": "M",
        "value": "Security deposit: immediate payment (cash deposit)"
      },
      {
        "key": "N",
        "value": "Security deposit: immediate payment (CDS Cash Account deposit)"
      },
      {
        "key": "O",
        "value": "Export refunds and/or CAP licence, security held by RPA"
      },
      {
        "key": "P",
        "value": "Immediate payment from CDS Cash Account"
      },
      {
        "key": "R",
        "value": "Security deposit: deferred payment (deferment account deposit)"
      },
      {
        "key": "S",
        "value": "Security Importers general guarantee account ( self-representation)"
      },
      {
        "key": "T",
        "value": "Security Agent’s general guarantee account"
      },
      {
        "key": "U",
        "value": "Security: Importers guarantee account - standing authority (direct/indirect representation)"
      },
      {
        "key": "V",
        "value": "Security: Importers guarantee account - individual/specific authority (direct/ indirect representation)"
      },
      {
        "key": "X",
        "value": "SASP"
      },
      {
        "key": "Z",
        "value": "Security Importers individual guarantee (e.g. C&E 250)"
      }
    ],
    "parenthesisCode": "(4/8)",
    "textDescription": "Method of Payment",
  },
  {
    "elementName": "additionsAndDeductionsValue",
    "parenthesisCode": "(4/9)",
    "textDescription": "Value",
    "description" : "The corresponding amount in National currency that has not yet been included in or deducted from the item price.",
    "format": "n..16,2"
  },
  {
    "elementName": "additionsAndDeductionsCurrency",
    "pullDownMenu": [
      {
        "key": "GBP",
        "value": "GBP"
      }
    ],
    "parenthesisCode": "(4/9)",
    "textDescription": "Currency",
    "description" : "ISO Currency code of the Other Charge Deduction Amount value.",
  },
  {
    "elementName": "additionsAndDeductionsType",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "AB",
        "value": "Commissions and brokerage, except buying commissions, declared as a monetary amount"
      },
      {
        "key": "AC",
        "value": "Commissions and brokerage, except buying commissions, declared as a percentage addition"
      },
      {
        "key": "AD",
        "value": "Containers and packing"
      },
      {
        "key": "AE",
        "value": "Materials, components, parts and similar items incorporated in the imported goods"
      },
      {
        "key": "AF",
        "value": "Tools, dies, moulds and similar items used in the production of the imported goods, declared as a monetary amount"
      },
      {
        "key": "AG",
        "value": "Materials consumed in the production of the imported goods"
      },
      {
        "key": "AH",
        "value": "Engineering, development, artwork, design work and plans and sketches undertaken elsewhere than in the EU and necessary for the production of the imported goods, declared as a monetary amount"
      },
      {
        "key": "AI",
        "value": "Royalties and license fees, declared as a monetary amount. For percentage adjustments use code AM instead"
      },
      {
        "key": "AJ",
        "value": "Proceeds of any subsequent resale, disposal or use accruing to the seller"
      },
      {
        "key": "AK",
        "value": "Insurance Costs"
      },
      {
        "key": "AL",
        "value": "Indirect payments and other payments (Article 70 of the code)"
      },
      {
        "key": "AM",
        "value": "Royalties and license fees, declared as a percentage adjustment. For additions declared as a monetary amount rather than a percentage use code AI"
      },
      {
        "key": "AP",
        "value": "Transport costs, loading and handling charges up to the place of introduction in the EU. "
      },
      {
        "key": "AQ",
        "value": "Transport costs, loading and handling charges up to the place of introduction in the EU."
      },
      {
        "key": "AR",
        "value": "Air Transport Costs up to the place of introduction in the European Union which have not already been included in the invoice price."
      },
      {
        "key": "AS",
        "value": "Air Transport Costs up to the place of introduction in the European Union which have not already been included in the invoice price."
      },
      {
        "key": "AT",
        "value": "Other charges not elsewhere declared"
      },
      {
        "key": "AV",
        "value": "Adjustment for VAT Value e.g. freight charges within the EU. "
      },
      {
        "key": "AW",
        "value": "Adjustment for VAT Value e.g. freight charges within the EU. "
      },
      {
        "key": "AX",
        "value": "AX"
      },
      {
        "key": "AZ",
        "value": "AZ"
      },
      {
        "key": "BA",
        "value": "  Costs of transport and insurance after arrival at the place of introduction, which have been included in the declared value."
      },
      {
        "key": "BB",
        "value": "Charges for construction, erection, assembly, maintenance or technical assistance undertaken after importation"
      },
      {
        "key": "BC",
        "value": "Import duties or other charges payable in the Union for reason of the import or sale of goods"
      },
      {
        "key": "BD",
        "value": "Interest charges declared as a monetary amount"
      },
      {
        "key": "BF",
        "value": "Buying commissions, to be declared as a percentage adjustment"
      },
      {
        "key": "BH",
        "value": "Discount (amount) not elsewhere declared. "
      },
      {
        "key": "BI",
        "value": "Discount (Percentage) not elsewhere declared. "
      },
      {
        "key": "BL",
        "value": "Interest charges declared as a percentage adjustment"
      },
      {
        "key": "BM",
        "value": "Buying commissions, to be declared as a monetary amount"
      },
      {
        "key": "BR",
        "value": "Air transport costs up to the place of introduction in the European Union which have been included in the declared invoice price. "
      },
      {
        "key": "BS",
        "value": "  Air transport costs up to the place of introduction in the European Union which have been included in the declared invoice price. "
      },
      {
        "key": "BT",
        "value": "Other deductions not elsewhere declared"
      },
      {
        "key": "BU",
        "value": "  Costs of transport and insurance after arrival at the place of introduction, which have been included in the declared value. "
      }
    ],
    "parenthesisCode": "(4/9)",
    "textDescription": "Type Codes",
    "description" : "For each type of addition or deduction relevant for a given goods item, enter the relevant code.",
  },
  {
    "elementName": "totalAmountInvoiced",
    "placeholder": "eg. 10,000,000",
    "parenthesisCode": "(4/11)",
    "textDescription": "Amount Invoiced",
    "description" : "The invoiced price for all goods declared in the declaration expressed in the currency unit declared in Invoice currency.",
    "format": "n..16,2",
    "disabled": true
  },
  {
    "elementName": "totalAmountInvoicedCurrency",
    "parenthesisCode": "(4/11)",
    "textDescription": "Invoiced Currency",
    "description" : "The invoiced price for all goods declared in the declaration expressed in the currency unit declared in Invoice currency.",
    "disabled": true,
    "pullDownMenu": currencies
  },
  {
    "elementName": "locationOfGoodsId",
    "parenthesisCode": "(5/23)",
    "textDescription": "Location of Goods ID",
    "description" : "Additional identifier that can be used to enable the unambiguous identification of the location playing a role in the declaration",
    "format": "an..35",
    "pullDownMenu": identificationOfLocation,
  },
  {
    "elementName": "locationOfGoodsType",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A",
        "value": "Designated location"
      },
      {
        "key": "B",
        "value": "Authorised place"
      },
      {
        "key": "C",
        "value": "Approved place"
      },
      {
        "key": "D",
        "value": "Other"
      }
    ],
    "parenthesisCode": "(5/23)",
    "textDescription": "Location of Goods Type",
    "description" : "For the type of location, use the codes specified below: A (Designated loc.), B (Authorised place), C (Approved place), D (Other).  Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "qualifierOfIdentification",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "U",
        "value": "UN/LOCODE"
      },
      {
        "key": "Y",
        "value": "Authorisation number"
      },
    ],
    "parenthesisCode": "(5/23)",
    "textDescription": "Qualifier of Identification",
    "description" : "UCC Qualifier Codes: Enter either: U (UN/LOCODE) OR Y (Authorisation number)",
  },
  {
    "elementName": "locationOfGoodsCountry",
    "pullDownMenu": countries,
    "parenthesisCode": "(5/23)",
    "textDescription": "Country",
    "description" : "ISO Country code of the Country where the goods may be examined.",
  },
  {
    "elementName": "locationOfGoodsCity",
    "parenthesisCode": "(5/23)",
    "textDescription": "City",
    "description" : "City of the address where the goods may be examined.",
    "format": "an..35"
  },
  {
    "elementName": "locationOfGoodsName",
    "parenthesisCode": "(5/23)",
    "textDescription": "Location Of Goods Name",
    "description" : "A alphanumeric  identifier to give a unique position of the location. Please refer to the User Manual for further assistance.",
    "format": "an..35"
  },
  {
    "elementName": "locationOfGoodsPostCode",
    "parenthesisCode": "(5/23)",
    "textDescription": "PostCode",
    "description" : "Postcode of the address where the goods may be examined.",
    "format": "an..9"
  },
  {
    "elementName": "identityOfMeansId",
    "parenthesisCode": "(7/9)",
    "textDescription": "ID",
    "description" : "Textual identity of the means of transport on which the goods are directly loaded.",
    "format": "an..35"
  },
  {
    "elementName": "identityOfMeansIdentificationType",
    "description": "DMS uses the codelist TransportMeansIdentificationTypes for these items.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "10",
        "value": "IMO ship identification number"
      },
      {
        "key": "11",
        "value": "Name of the sea-going vessel"
      },
      {
        "key": "20",
        "value": "Wagon number"
      },
      {
        "key": "30",
        "value": "Registration number of the road vehicle"
      },
      {
        "key": "40",
        "value": "IATA Flight Number"
      },
      {
        "key": "41",
        "value": "Registration Number of the Aircraft"
      },
      {
        "key": "80",
        "value": "European Vessel Identification Number (ENI Code)"
      },
      {
        "key": "81",
        "value": "Name of the inland waterways vessel"
      },

    ],
    "parenthesisCode": "(7/9)",
    "textDescription": "Type",
  },
  {
    "elementName": "guaranteeReferenceNumber",
    "parenthesisCode": "(8/3)",
    "textDescription": "Guarantee Reference Number",
    "description" : "Guarantee reference number by which the guarantee is identified UCC Annex B: 8/3 - GRN ",
    "format": "an..24"
  },
  {
    "elementName": "guaranteeReferenceAmount",
    "parenthesisCode": "(8/3)",
    "textDescription": "Invoice Line Item Charge Amount",
    "description" : "Monetary value of import duty and, where Article 89(2) first subparagraph of the Code applies, other charges.",
    "format": "n..16,2"
  },
  {
    "elementName": "otherGuaranteeReference",
    "parenthesisCode": "(8/3)",
    "textDescription": "Other Guarantee Reference",
    "description" : "Other reference number by which the guarantee is identified UCC Annex B: 8/3 Other guarantee reference",
    "format": "an..35"
  },
  {
    "elementName": "accessCode",
    "parenthesisCode": "(8/3)",
    "textDescription": "Access Code",
    "description" : "The access code as associated with a given guarantee reference number",
    "format": "an..4"
  },
  {
    "elementName": "guaranteeReferenceCurrency",
    "pullDownMenu": currencies,
    "parenthesisCode": "(8/3)",
    "textDescription": "Currency",
    "description" : "Should use the standard ISO 3 character Currency Code for the value of the guarantee.",
    "format": "a3"
  },
  {
    "elementName": "customsOfficeToGuarantee",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0",
        "value": "Line 1"
      },
      {
        "key": "1",
        "value": "Line 2"
      }
    ],
    "parenthesisCode": "(8/3)",
    "textDescription": "Customs Office To Guarantee",
    "description" : "Customs office of guarantee.",
  },
  {
    "elementName": "itemPriceCurrency",
    "pullDownMenu": currencies,
    "parenthesisCode": "(4/14)",
    "textDescription": "Currency",
    "description" : "The invoiced value of the item expressed in the currency unit.",
  },
  {
    "elementName": "itemPriceAmount",
    "parenthesisCode": "(4/14)",
    "textDescription": "Item Price Amount",
    "description" : "The invoiced value of the item expressed in the currency unit.",
    "format": "n..16,2"
  },
  {
    "elementName": "itemPriceValuationMethod",
    "parenthesisCode": "(4/16)",
    "textDescription": "Valuation Method",
    "description" : "A code identifying the valuation method used: Code Valuation Method (VM)",
  },
  {
    "elementName": "itemPriceValuationPreference",
    "parenthesisCode": "(4/17)",
    "textDescription": "Valuation Method",
    "description" : "This data element concerns information on the tariff treatment of the goods. Enter the relevant Union code.",
  },
  {
    "elementName": "commodityCodeType",
    "pullDownMenu": commodityCodeTypes,
    "parenthesisCode": "(6/13)",
    "textDescription": "Code Type",
    "description" : "For D/E 6/13 IdentificationTypeCode = CV",
  },
  {
    "elementName": "combinedNomenclatureTypeCode",
    "pullDownMenu": commodityCodeTypes,
    "parenthesisCode": "(6/14)",
    "textDescription": "Code Type",
    "description" : "Group Level item - Commodity Code - Combined Nomenclature code",
  },
  {
    "elementName": "taricIdentificationTypeCode",
    "pullDownMenu": commodityCodeTypes,
    "parenthesisCode": "(6/15)",
    "textDescription": "Code Type",
    "description" : "For D/E 6/15 IdentificationTypeCode = TRC",
  },
  {
    "elementName": "additionalTaricTypeCode",
    "pullDownMenu": commodityCodeTypes,
    "parenthesisCode": "(6/16)",
    "textDescription": "Code Type",
    "description" : "For D/E 6/16 IdentificationTypeCode = TRA",
  },
  {
    "elementName": "nationalAdditionalCodeTypeCode",
    "pullDownMenu": commodityCodeTypes,
    "parenthesisCode": "(6/17)",
    "textDescription": "Code Type",
    "description" : "For D/E 6/14 IdentificationTypeCode = TSP",
  },
  {
    "elementName": "commodityCodeAdditionalNationalCode",
    "pullDownMenu": commodityCodeAdditionalNationalCode,
    "parenthesisCode": "(6/17)",
    "textDescription": "Additional National Code",
    "description" : "For D/E 6/13 IdentificationTypeCode = CV",
  },
  {
    "elementName": "cusCommodityCodeId",
    "parenthesisCode": "(6/13)",
    "textDescription": "ID",
    "description" : "The Customs Union and Statistics (CUS) number is the identifier assigned within the European Customs Inventory of Chemical Substances (ECICS).",
  },
  {
    "elementName": "commodityCodeId",
    "parenthesisCode": "(6/13)",
    "textDescription": "ID",
    "description" : "For all commodity codes (D/E 6/13 - D/E 6/17), Each data item should be declared in its own instance. Please refer to the User Manual for further assistance.",
    "format": "an8"
  },
  {
    "elementName": "statisticalValueCurrency",
    "pullDownMenu": currencies,
    "parenthesisCode": "(8/6)",
    "textDescription": "Currency",
    "description" : "ISO Currency code for Statistical Value ",
  },
  {
    "elementName": "statisticalValueAmount",
    "parenthesisCode": "(8/6)",
    "textDescription": "Amount",
    "description" : "Enter the statistical value for the goods item in GBP (£ sterling), irrespective of the currency used in Invoice Currency.",
    "format": "n..16,2"
  },
  {
    "elementName": "additionalFiscalTypeCode",
    "description" : "This data element must be completed when Onward Supply Relief (OSR) is claimed at either Header or Item level, or Postponed VAT Accounting (PVA) is claimed at Header level.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "FR1",
        "value": "Importer (replaces document code YO40)"
      },
      {
        "key": "FR2",
        "value": "Customer (replaces document code YO41)"
      },
      {
        "key": "FR3",
        "value": "Tax Representative (replaces document code YO42)"
      },
      {
        "key": "FR4",
        "value": "Holder of the deferred payment authorisation"
      }
    ],
    "parenthesisCode": "(3/40)",
    "textDescription": "Fiscal type code",
  },
  {
    "elementName": "additionalFiscalId",
    "parenthesisCode": "(3/40)",
    "textDescription": "Fiscal ID",
    "description" : "This data element must be completed when Onward Supply Relief (OSR) is claimed at either Header or Item level, or Postponed VAT Accounting (PVA) is claimed at Header level.",
    "format": "an..17"
  },
  {
    "elementName": "evaluationIndicators",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0000",
        "value": "0000"
      },
      {
        "key": "0001",
        "value": "0001"
      },
      {
        "key": "0010",
        "value": "0010"
      },
      {
        "key": "0011",
        "value": "0011"
      },
      {
        "key": "0100",
        "value": "0100"
      },
      {
        "key": "0101",
        "value": "0101"
      },
      {
        "key": "0110",
        "value": "0110"
      },
      {
        "key": "0111",
        "value": "0111"
      },
      {
        "key": "1000",
        "value": "1000"
      },
      {
        "key": "1001",
        "value": "1001"
      },
      {
        "key": "1010",
        "value": "1010"
      },
      {
        "key": "1011",
        "value": "1011"
      },
      {
        "key": "1100",
        "value": "1100"
      },
      {
        "key": "1101",
        "value": "1101"
      },
      {
        "key": "1110",
        "value": "1110"
      },
      {
        "key": "1111",
        "value": "1111"
      }
    ],
    "parenthesisCode": "(4/13)",
    "textDescription": "Valuation Indicators",
    "description" : "Allows by a combination of indicators to declare whether the value of the goods is determined by specific factors",
  },
  {
    "elementName": "valuationMethod",
    "description": "A code identifying the valuation method used: Code Valuation Method (VM).",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "Valuation Method",
        "value": "ValuationMethodTypes (4/16)"
      },
      {
        "key": "2",
        "value": "Transaction value of identical goods"
      },
      {
        "key": "3",
        "value": "Transaction value of similar goods"
      },
      {
        "key": "4",
        "value": "The Deductive Method"
      },
      {
        "key": "5",
        "value": "The Computed Value Method"
      },
      {
        "key": "6",
        "value": "The 'Fall-back’ Method"
      },
      {
        "key": "1",
        "value": "Transaction value"
      }
    ],
    "parenthesisCode": "(4/16)",
    "textDescription": "Valuation Method",
  },
  {
    "elementName": "supplementaryUnits",
    "parenthesisCode": "6/2",
    "textDescription": "Supplementary Units",
    "description" : "The quantity of goods items specified in the unit type as indicated by the Taric goods nomenclature as specified in SAD box 41.",
    "format": "n..16,6"
  },
  {
    "elementName": "RefNumberUcr",
    "parenthesisCode": "2/4",
    "textDescription": "UCR Number",
    "description" : "For optional use by the declarant to record a commercial reference for their own purposes. Please refer to the User Manual for further assistance.",
  },
  {
    "elementName": "requestedProceedureCode",
    "parenthesisCode": "1/10",
    "textDescription": "Procedure Code",
    "description" : "The procedure codes identify the customs and/or excise regimes which goods are being entered into and removed from (where this applies).",
  },
  {
    "elementName": "requestedPreviousProceedureCode",
    "parenthesisCode": "1/10",
    "textDescription": "Previous Procedure Code",
    "description" : "This is a procedure under which the goods were placed before being placed under the procedure requested.",
  },
  {
    "elementName": "procedureCode",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0100",
        "value": "Free circulation with onward dispatch"
      },
      {
        "key": "0121",
        "value": "Re-import after outward processing (OP) with onward dispatch"
      },
      {
        "key": "0151",
        "value": "Release to free circulation after inward processing (IP) with onward dispatch"
      },
      {
        "key": "0153",
        "value": "Release to free circulation after temporary admission (TA) with onward dispatch"
      },
      {
        "key": "0154",
        "value": "Release to free circulation after IP in another Member State with onward dispatch"
      },
      {
        "key": "0171",
        "value": "Release to free circulation from a customs warehouse (CW) with onward dispatch"
      },
      {
        "key": "0178",
        "value": "Release to free circulation from a free zone (FZ) with onward dispatch"
      },
      {
        "key": "0700",
        "value": "Release for free circulation with simultaneous entry to an excise warehouse"
      },
      {
        "key": "0721",
        "value": "Release for free circulation with simultaneous entry to an excise warehouse for goods re-imported after OP"
      },
      {
        "key": "0751",
        "value": "Release for free circulation with simultaneous entry to an excise warehouse after IP"
      },
      {
        "key": "0753",
        "value": "Release for free circulation with simultaneous entry to an excise warehouse after TA"
      },
      {
        "key": "0771",
        "value": "Release for free circulation from a CW with simultaneous entry to an excise warehouse"
      },
      {
        "key": "0754",
        "value": "Release for free circulation with simultaneous entry to an excise warehouse after IP in another Member State"
      },
      {
        "key": "0778",
        "value": "Release for free circulation from a FZ with simultaneous entry to an excise warehouse"
      },
      {
        "key": "4000",
        "value": "Release to free circulation"
      },
      {
        "key": "4051",
        "value": "Release to free circulation after IP"
      },
      {
        "key": "4053",
        "value": "Release to free circulation after TA"
      },
      {
        "key": "4071",
        "value": "Release to free circulation from a CW"
      },
      {
        "key": "4054",
        "value": "Release to free circulation after IP in another Member State"
      },
      {
        "key": "4078",
        "value": "Release to free circulation from a FZ"
      },
      {
        "key": "4200",
        "value": "Free circulation with onward supply"
      },
      {
        "key": "4221",
        "value": "Re-import after OP with onward supply"
      },
      {
        "key": "4251",
        "value": "Release to free circulation after IP with onward supply"
      },
      {
        "key": "4253",
        "value": "Release to free circulation after TA with onward supply"
      },
      {
        "key": "4254",
        "value": "Release to free circulation after IP in another Member State with onward supply"
      },
      {
        "key": "4271",
        "value": "Release to free circulation from a CW with onward supply"
      },
      {
        "key": "4278",
        "value": "Release to free circulation from a FZ with onward supply"
      },
      {
        "key": "4400",
        "value": "End-use relief"
      },
      {
        "key": "4421",
        "value": "End-use relief after OP"
      },
      {
        "key": "4422",
        "value": "End-use relief after OP, not covered under 4421"
      },
      {
        "key": "4451",
        "value": "End-use relief after IP"
      },
      {
        "key": "4471",
        "value": "End-use relief on goods released from a CW"
      },
      {
        "key": "4453",
        "value": "End-use relief after TA"
      },
      {
        "key": "4454",
        "value": "End-use relief after IP in another Member State"
      },
      {
        "key": "4478",
        "value": "End-use relief on goods released from a FZ"
      },
      {
        "key": "5100",
        "value": "Entry to IP"
      },
      {
        "key": "5111",
        "value": "Import of replacement goods to IP following the prior export of equivalent goods."
      },
      {
        "key": "5121",
        "value": "Entry to IP after OP"
      },
      {
        "key": "5151",
        "value": "Transfer of goods between IP authorisation holders"
      },
      {
        "key": "5153",
        "value": "Entry to IP after TA"
      },
      {
        "key": "5154",
        "value": "Transfer of goods between IP authorisation holders in different Member States"
      },
      {
        "key": "5171",
        "value": "Entry to IP on release from a CW"
      },
      {
        "key": "5178",
        "value": "Entry to IP on release from a FZ"
      },
      {
        "key": "5300",
        "value": "Entry to TA"
      },
      {
        "key": "5351",
        "value": "Entry to TA after IP"
      },
      {
        "key": "5353",
        "value": "Transfer of goods between TA authorisation holders"
      },
      {
        "key": "5354",
        "value": "Entry to TA after IP in another Member State"
      },
      {
        "key": "5371",
        "value": "Entry to TA on release from a CW"
      },
      {
        "key": "5378",
        "value": "Entry to TA on release from a FZ"
      },
      {
        "key": "6110",
        "value": "Release to RGR for goods previously permanently exported"
      },
      {
        "key": "6111",
        "value": "Release to RGR for IP equivalent goods previously permanently exported"
      },
      {
        "key": "6123",
        "value": "Release to RGR"
      },
      {
        "key": "6131",
        "value": "Re-import of previously exported IP goods"
      },
      {
        "key": "6121",
        "value": "Re-import after OP"
      },
      {
        "key": "6122",
        "value": "Re-import after OP for goods not covered by 61 21 (for example, OP textiles)"
      },
      {
        "key": "7100",
        "value": "Entry to a CW"
      },
      {
        "key": "7110",
        "value": "Entry to a CW for goods previously permanently exported"
      },
      {
        "key": "7121",
        "value": "Entry to CW after OP"
      },
      {
        "key": "7122",
        "value": "Entry to CW after OP, for goods not covered by 7121 (for example OP textiles)"
      },
      {
        "key": "7123",
        "value": "Entry to CW after RGR"
      },
      {
        "key": "7151",
        "value": "Entry to CW after IP"
      },
      {
        "key": "7153",
        "value": "Entry to CW after TA"
      },
      {
        "key": "7171",
        "value": "Transfer between different customs warehouses"
      },
      {
        "key": "7178",
        "value": "Entry to CW on release from a FZ"
      },
      {
        "key": "7154",
        "value": "Entry to CW after IP in another Member State"
      }
    ],
    "parenthesisCode": "(1/10)",
    "textDescription": "Procedure Code",
    "description": "The procedure codes identify the customs and/or excise regimes which goods are being entered into and removed from (where this applies).",
  },
  {
    "elementName": "requestedAdditionalProceedure",
    "parenthesisCode": "(1/11)",
    "textDescription": "Additional Procedure",
    "description" : "This should only be used as a separate occurrence of the Government Procedure for that particular goods item.",
    "pullDownMenu": requestedAdditionalProceedure,
  },
  {
    "elementName": "documentsProducedAdditionalCategoryCode",
    "parenthesisCode": "(8/7)",
    "pullDownMenu": documentsProducedAdditionalCategoryCode,
    "textDescription": "Additional Category Code",
    "description" :  "Specifies  whether the code is EU or National.",
  },
  {
    "elementName": "documentsProducedAdditionalTypeCode",
    "parenthesisCode": "(8/7)",
    "textDescription": "Additional Type Code",
    "description" : "Specifies  whether the code is EU or National.",
    "format": "an3",
  },
  {
    "elementName": "documentsProducedAdditionalDocId",
    "parenthesisCode": "(8/7)",
    "textDescription": "Additional Document ID",
    "description" : "An identifier for the document at item level. This may hold the concatenation of the Document Part  (where necessary).",
    "format": "an..35"
  },
  {
    "elementName": "documentsProducedAdditionalDocStatus",
    "parenthesisCode": "(8/7)",
    "textDescription": "Additional Document Status",
    "description" : "Code for the availability and reason for not supplying a document or the action to be taken with the document.",
    "pullDownMenu": documentsProducedAdditionalDocStatus
  },
  {
    "elementName": "documentsProducedAWrittingOffIssuingAuth",
    "parenthesisCode": "(8/7)",
    "textDescription": "Writing Off - Issuing Authority",
    "description" : "The details related to the writing-off of the goods declared in the declaration concerned.",
    "format": "an..70"
  },
  {
    "elementName": "documentsProducedAWrittingOffValidityDate",
    "parenthesisCode": "(8/7)",
    "textDescription": "Writing Off - Date Of Validity",
    "description" : "The details related to the writing-off of the goods declared in the declaration concerned.",
    "type": "datePicker"
  },
  {
    "elementName": "documentsProducedAWrittingOffQuantity",
    "parenthesisCode": "(8/7)",
    "textDescription": "Writing Off - Quantity",
    "description" : "The details related to the writing-off of the goods declared in the declaration concerned.",
    "format": "n..16,6"
  },
  {
    "elementName": "writingOffMeasurmentUnitAndQualifier",
    "parenthesisCode": "(8/7)",
    "pullDownMenu": measurmentUnitCodes,
    "textDescription": "Measurement Unit & Qualifier",
    "description" : "The details related to the writing-off of the goods declared in the declaration concerned.",
  },
  {
    "elementName": "exporterName",
    "parenthesisCode": "(8/7)",
    "textDescription": "Measurement Unit and Qualifier",
    "description" : "The details related to the writing-off of the goods declared in the declaration concerned.",
  },
  {
    "elementName": "countryOfOrigin",
    "pullDownMenu": countries,
    "parenthesisCode": "(5/15)",
    "borderColor": "bg-primary",
    "textDescription": "Country Of Origin",
    "description" : "The ISO code for the country from which the goods originated.",
  },
  {
    "elementName": "countryOfPreferentialOrigin",
    "pullDownMenu": countries,
    "parenthesisCode": "(5/16)",
    "borderColor": "bg-primary",
    "textDescription": "Country of Preferential Origin",
    "description" : "The ISO code for the country from which the goods originated.",
  },
  {
    "elementName": "originTypeCode",
    "description": "Populate with 1 (Non-preferential origin) to indicate that D/E 5/15 has been declared, Populate with 2 (Preferential origin) to indicate that D/E 5/16 has been declared.",
    "pullDownMenu": [
      { "key": "1", "value": 'Country of Origin' },
      { "key": "2", "value": 'Country of Preferential Origin' },
    ],
    // "parenthesisCode": "(5/16)",
    "borderColor": "bg-primary",
    "textDescription": "Origin Type Code",
  },
  {
    "elementName": "countryOfDispatchExportCode",
    "pullDownMenu": countries,
    "parenthesisCode": "(5/14)",
    "borderColor": "bg-primary",
    "textDescription": "Country of Dispatch",
    "description" : "The ISO code for the country from which the goods were originally dispatched.",
  },
  {
    "elementName": "goodsMeasureDescriptionOfGoods",
    "parenthesisCode": "(6/8)",
    "textDescription": "Description Of Goods",
    "description" : "The normal trade description. Except for non-Union goods placed under the customs warehousing procedure in a public customs warehouse type I, II or III or a private customs warehouse.",
    "format": "an..512"
  },
  {
    "elementName": "goodsMeasureTypeOfPackages",
    "parenthesisCode": "(6/9)",
    "pullDownMenu": goodsMeasureTypesOfPackages,
    "textDescription": "Package Types",
    "description" : "Code specifying the type of package.",
  },
  {
    "elementName": "goodsMeasureNoOfPackages",
    "parenthesisCode": "(6/10)",
    "textDescription": "Package Number",
    "description" : "Total number of packages based on the smallest external packing unit.",
    "format": "n..8"
  },
  {
    "elementName": "goodsMeasureShippingMarks",
    "parenthesisCode": "(6/11)",
    "textDescription": "Shipping Marks",
    "description" : "Free form description of the marks and numbers on transport units or packages. Enables the packages of the given kind to be identified.",
    "format": "an..512"
  },
  {
    "elementName": "invoiceLineItemChargeAmount",
    "parenthesisCode": "(8/3)",
    "textDescription": "Invoice Line Item Charge Amount ",
    "description" : "Monetary value of import duty and, where Article 89(2) first subparagraph of the Code applies, other charges.",
  },
  {
    "elementName": "totalPackageQuantity",
    "parenthesisCode": "(6/18)",
    "textDescription": "Total Package Quantity",
    "description" : "If goods are packaged in such a way that goods belonging to more than one goods item are packaged together, then the actual number of these packages is entered on the first goods item and for the other goods items.",
    "format": "n..8"
  },
  {
    "elementName": "consignmentContainerCode",
    "parenthesisCode": "(7/2)",
    "textDescription": "Consignment Container Code",
    "description" : "The situation when crossing the external frontier of the Union using the relevant Union code: A code indicating whether or not goods are transported in a container.",
    "format": "n1"
  },
  {
    "elementName": "valuationMethodCode",
    "description": "A code identifying the valuation method used: Code Valuation Method (VM).",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "1",
        "value": "Valuation Method 1 - Transaction value"
      },
      {
        "key": "2",
        "value": "Valuation Method 2 - Transaction value of identical goods"
      },
      {
        "key": "3",
        "value": "Valuation Method 3 - Transaction value of similar goods"
      },
      {
        "key": "4",
        "value": "Valuation Method 4 - The Deductive Method"
      },
      {
        "key": "5",
        "value": "Valuation Method 5 - The Computed Value Method"
      },
      {
        "key": "6",
        "value": "Valuation Method 6 - The 'Fall-back' Method"
      }
    ],
    "parenthesisCode": "(4/16)",
    "textDescription": "Valuation Method",
  },
  {
    "elementName": "documentsProducedAdditionalDocStatusReason",
    "parenthesisCode": "(8/7)",
    "textDescription": "Document Status Reason",
    "description" : "A free text description of the Reason where required by the Document Code or Document Status Code.",
    "format": "an..35"
  },
  {
    "elementName": "contactCountry",
    "pullDownMenu": countries,
    "parenthesisCode": "",
    "textDescription": "Country Code",
    "description": "Country Code",
  },
  {
    "elementName": "commodidyCodeCombinedNomenclatureCodeId",
    "parenthesisCode": "(6/14)",
    "textDescription": "Combined Nomenclature Code",
    "description" : "The Combined Nomenclature code number corresponding to the item in question. Please refer to the User Manual for further assistance.",
    "format": "an8"
  },
  {
    "elementName": "taricCodeId",
    "parenthesisCode": "(6/15)",
    "textDescription": "TARIC Code",
    "description" : "The TARIC code (two characters for the application of specific Union measures in respect of formalities to be completed at destination).",
    "format": "an2"
  },
  {
    "elementName": "additionalTaricCodeId",
    "parenthesisCode": "(6/16)",
    "textDescription": "Additional TARIC Code",
    "description" : "Completed in accordance with the TARIC codes (additional codes).",
    "format": "an4"
  },
  {
    "elementName": "locationOfGoodsAddress",
    "parenthesisCode": "(5/23)",
    "textDescription": "Location of Goods Address",
    "description" : "Street and Number of the address where the goods may be examined.",
    "format": "an..70"
  },
  {
    "elementName": "description",
    "textDescription": "Description",
    "description": "Description",
  },
  {
    "elementName": "dateFrom",
    "textDescription": "Date From",
    "type": "datePicker",
    "description": "Date From",
  },
  {
    "elementName": "dateTo",
    "textDescription": "Date To",
    "type": "datePicker",
    "description": "Date To",
  },
  {
    "elementName": "status",
    "description": "Status",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "complete",
        "value": "Complete"
      },
      {
        "key": "incomplete",
        "value": "Incomplete"
      },
      {
        "key": "rejected",
        "value": "Rejected"
      }
    ]
  },
  {
    "elementName": "functionCode",
    "textDescription": "Function Code",
    "description": "Declares the way in which the declarant is representing the Importer.",
    "readOnly": true,
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": 3,
        "value": "Code 3"
      },
      {
        "key": 9,
        "value": "Code 9"
      }
    ]
  },
  {
    "elementName": "functionalReferenceId",
    "textDescription": "Functional Reference ID",
    "description": "Nationally defined and allocated by the declarant in agreement with HMRC to identify each single declaration.",
  },
  {
    "elementName": "transportCostsToFinalDestAmount",
    "textDescription": "Transport Costs to Final Dest. Amount",
    "description" : "This data element concerns information on the overall transport/postal costs incurred in transporting the goods declared on the Declaration.",
    "parenthesisCode": "(4/19)",
    "format": "n..16,2"
  },
  {
    "elementName": "transportCostsToFinalDestCurrency",
    "textDescription": "Transport Costs to Final Dest. Currency",
    "description" : "This data element concerns information on the overall transport/postal costs incurred in transporting the goods declared on the Declaration.",
    "parenthesisCode": "(4/19)",
    "pullDownMenu": currencies
  },
  {
    "elementName": "declaredCustomsValueAmount",
    "textDescription": "Declared Customs Value Amount",
    "description" : "This data element concerns information on the overall value of the goods declared on the declaration, expressed in the currency unit.",
    "parenthesisCode": "(4/18)",
    "format": "n..16,2"
  },
  {
    "elementName": "acceptanceDate",
    "textDescription": "Acceptance Date",
    "description": "The Accept Date and Entry in Declarant’s Records Supplementary Declarations is only required to be populated when submitting a Supplementary Declaration.",
    "type": "datePicker",
  },
  {
    "elementName": "declarantId",
    "textDescription": "Declarant ID",
    "description": "The unique identifier (Economic Operator Registration & Identification) of the Declarant.",
  },
  {
    "elementName": "representativeId",
    "textDescription": "Representative ID",
    "description": "The unique identifier (Economic Operator Registration & Identification) of the Representative.",
  },
  {
    "elementName": "declaredCustomsValueCurrency",
    "textDescription": "Declared Customs Value Currency",
    "description" : "This data element concerns information on the overall value of the goods declared on the declaration, expressed in the currency unit.",
    "parenthesisCode": "(4/18)",
    "pullDownMenu": currencies
  },
  {
    "elementName": "representativeStatusCode",
    "textDescription": "Representative Status Code",
    "description": "Declares the way in which the declarant is representing the Importer.",
    "pullDownMenu": [
      {
        "key": "2",
        "value": "Direct Representation",
      },
      {
        "key": "3",
        "value": "Indirect Representation"
      }
    ]
  },
  {
    "elementName": "lrn",
    "textDescription": "LRN",
    "description": "Nationally defined and allocated by the declarant in agreement with HMRC to identify each single declaration.",
    "readOnly": true
  },
  {
    "elementName": "additionalSupplyChainIdentifier",
    "textDescription": "Supply Chain Identifier",
    "description" : "Unique identification number assigned to an economic operator of a third country in the framework of a trade partnership programme.",
    "parenthesisCode": "(3/37)",
    "format": "an..17"
  },
  {
    "elementName": "additionalSupplyChainRoleCode",
    "textDescription": "Supply Chain Role Code",
    "description" : "Specifies the third party's role in the supply chain. Only used in conjunction with the Additional Supply Chain actor identification No. id.",
    "parenthesisCode": "(3/37)",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "CS",
        "value": "Consolidator",
      },
      {
        "key": "MF",
        "value": "Manufacturer"
      },
      {
        "key": "FW",
        "value": "Freight forwarder"
      },
      {
        "key": "WH",
        "value": "Warehousekeeper"
      }
    ]
  },
  {
    "elementName": "changeReasonCode",
    "textDescription": "Change Reason Code",
    "description" : "A free text description of the Reason where required by the Document Code or Document Status Code",

    "parenthesisCode": '(06A)',
    "format": "an..3"
  },
  {
    "elementName": "statementDescription",
    "textDescription": "Statement Description",
    "format": "an..512",
    "parenthesisCode": '(225)',
    "description" : "A single line of text describing the value of the AI statement code.",
    "rows": 6
  },
  {
    "elementName": "sequenceNumeric",
    "textDescription": "Sequence Numeric",
    "description" : "Sequential number of the current item in the declaration.",
    "format": "n..5",
    "parenthesisCode": '(5/18)',
  },
  {
    "elementName": "documentSectionCode",
    "textDescription": "Document Section Code",
    "format": "an..3",
    "parenthesisCode": '(375)',
    "description" : "A free text description of the Reason where required by the Document Code or Document Status Code.",
  },
  {
    "elementName": "tagId",
    "textDescription": "Tag ID",
    "description": "Tag ID",
    "format": "an..4",
    "parenthesisCode": '(361)',
  },
  {
    "elementName": "consignorName",
    "placeholder": "eg. Poshmark",
    "parenthesisCode": "(3/7)",
    "textDescription": "Consignor Name",
    "description" : "Consignor Name",
    "format": "an..70"
  },
  {
    "elementName": "consignorAddressLine",
    "placeholder": "eg. 203 Redwood Shores",
    "parenthesisCode": "(3/7)",
    "textDescription": "Consignor Street and Number",
    "description" : "Consignor -  address - street name",
    "format": "an..35"
  },
  {
    "elementName": "consignorPostcode",
    "placeholder": "eg. 94065",
    "parenthesisCode": "(3/7)",
    "textDescription": "Consignor Postcode",
    "description" : "Consignor- address - postcode",
    "format": "an..9"
  },
  {
    "elementName": "consignorCity",
    "placeholder": "eg. London",
    "parenthesisCode": "(3/7)",
    "textDescription": "Consignor City",
    "description" : "Consignor address - city or town",
    "format": "an..35"
  },
  {
    "elementName": "consignorId",
    "placeholder": "eg. A00000",
    "parenthesisCode": "(3/8)",
    "textDescription": "Consignor Identification Number",
    "description" : "The unique identifier (Economic Operator Registration & Identification) of the Consignor.",
    "format": "an..17"
  },
  {
    "elementName": "consigneeName",
    "placeholder": "eg. Frank Leery",
    "parenthesisCode": "(3/9)",
    "textDescription": "Consignee Name",
    "description" : "Consignee Name",
    "format": "an..35"
  },
  {
    "elementName": "consigneeAddressLine",
    "placeholder": "eg. 45 Apper Ground",
    "parenthesisCode": "(3/9)",
    "textDescription": "Consignee Street and Number",
    "description" : "Consignee -  address - street name",
    "format": "an..35"
  },
  {
    "elementName": "consigneePostcode",
    "placeholder": "eg. E1w 3aw",
    "parenthesisCode": "(3/9)",
    "textDescription": "Consignee Postcode",
    "description" : "Consignee - address - postcode",
    "format": "an..9"
  },
  {
    "elementName": "consigneeCity",
    "placeholder": "eg. Oslo",
    "parenthesisCode": "(3/9)",
    "textDescription": "Consignee City",
    "description" : "Consignee address - city or town",
    "format": "an..35"
  },
  {
    "elementName": "consigneeId",
    "placeholder": "eg. AB0000",
    "parenthesisCode": "(3/10)",
    "textDescription": "Consignee Identification Number",
    "description" : "The unique identifier (Economic Operator Registration & Identification) for the Consignee.",
    "format": "an..17"
  },
  {
    "elementName": "carrierName",
    "placeholder": "eg. Poshmark",
    "parenthesisCode": "(3/31)",
    "textDescription": "Carrier Name",
    "description" : "Carrier - Name",
    "format": "an..70"
  },
  {
    "elementName": "carrierAddressLine",
    "placeholder": "eg. 203 Redwood Shores",
    "parenthesisCode": "(3/31)",
    "textDescription": "Carrier Street and Number",
    "description" : "Carrier - address - street and number",
    "format": "an..70"
  },
  {
    "elementName": "carrierPostcode",
    "placeholder": "eg. 94065",
    "parenthesisCode": "(3/31)",
    "textDescription": "Carrier Postcode",
    "description" : "Carrier - address - postcode",
    "format": "an..9"
  },
  {
    "elementName": "carrierCity",
    "placeholder": "eg. London",
    "parenthesisCode": "(3/31)",
    "textDescription": "Carrier City",
    "description" : "Carrier - address - city or town",
    "format": "an..35"
  },
  {
    "elementName": "carrierId",
    "placeholder": "eg. A00000",
    "parenthesisCode": "(3/32)",
    "textDescription": "Carrier Identification Number",
    "description" : "The unique identifier (Economic Operator Registration & Identification) of the Carrier.",
    "format": "an..17"
  },
  {
    "elementName": "exitOffice",
    "placeholder": "BG004211",
    "parenthesisCode": "(5/12)",
    "textDescription": "Customs Office of Exit",
    "description" : "The Office of Exit should be completed for both direct and indirect exports (meaning those exiting via another Member State).",
    "format": "an8"
  },
  {
    "elementName": "routingCountrySeqNumeric",
    "placeholder": "eg. 4",
    "parenthesisCode": "(5/18)",
    "textDescription": "Number in a sequence of the countries in the route",
    "description" : "Number indicating the position in a sequence of the countries in the route.",
    "format": "n..2"
  },
  {
    "elementName": "UNDGID",
    "description": "The UN dangerous goods code must be declared when applicable to the goods.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "0014",
        "value": "0014"
      }
    ],
    "parenthesisCode": "(6/12)",
    "textDescription": "Code of UN Dangerous Goods",
    "format": "n..4"
  },
  {
    "elementName": "departureTranspMeansID",
    "placeholder": "eg. A123B456C7",
    "parenthesisCode": "(7/7)",
    "textDescription": "Means of Transport at Departure ID",
    "description" : "The transport identification number. Please refer to the User Manual for further assistance.",
    "format": "an..27"
  },
  {
    "elementName": "borderTranspMeansID",
    "placeholder": "eg. D123E456F7",
    "parenthesisCode": "(7/14)",
    "textDescription": "Means of Transport Crossing the Border ID",
    "description" : "The transport identification number",
    "format": "an..35"
  },
  {
    "elementName": "sealId",
    "placeholder": "eg. SE8259",
    "parenthesisCode": "(7/18)",
    "textDescription": "Seal ID",
    "description" : "The individual seal identification number (where applicable)",
    "format": "an..20"
  },
  {
    "elementName": "SpecificCircumIndicator",
    "description": "Using the relevant codes, indicate the special circumstance the benefit of which is claimed by the declarant.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A20",
        "value": "declaration relates to Express Consignments"
      }
    ],
    "parenthesisCode": "(1/7)",
    "textDescription": "Specific Circumstance Indicator",
  },
  {
    "elementName": "consignorCountry",
    "pullDownMenu": countries,
    "parenthesisCode": "(3/7)",
    "textDescription": "Consignor Country",
    "description" : "Consignor - address - country",
  },
  {
    "elementName": "consigneeCountry",
    "pullDownMenu": countries,
    "parenthesisCode": "(3/9)",
    "textDescription": "Consignee Country",
    "description" : "Consignee - address - country",
  },
  {
    "elementName": "carrierCountry",
    "pullDownMenu": countries,
    "parenthesisCode": "(3/31)",
    "textDescription": "Carrier Country",
    "description" : "Carrier - address - country",
  },
  {
    "elementName": "paymentMethodCode",
    "description": "The transport charges method of payment is to be provided where available.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "A",
        "value": "Payment in cash"
      },
      {
        "key": "B",
        "value": "Payment by credit card"
      },
      {
        "key": "C",
        "value": "Payment by cheque"
      },
      {
        "key": "D",
        "value": "Other (e.g. direct debit to cash account)"
      },
      {
        "key": "H",
        "value": "Electronic funds transfer"
      },
      {
        "key": "Y",
        "value": "Account holder with carrier"
      },
      {
        "key": "Z",
        "value": "Not pre-paid"
      }
    ],
    "parenthesisCode": "(4/2)",
    "textDescription": "Transport Charges Method of Payment",
  },
  {
    "elementName": "routingCountryCode",
    "pullDownMenu": countries,
    "parenthesisCode": "(5/18)",
    "textDescription": "Routing Country Code",
    "description" : "The ISO codes for the country from which the goods routing.",
  },
  {
    "elementName": "departureTranspMeansIDCode",
    "description": "The type of the means of transport on which the goods are directly loaded at the time of export or transit formalities.",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "10",
        "value": "IMO ship identification number"
      },
      {
        "key": "11",
        "value": "name of the seagoing vessel"
      },
      {
        "key": "20",
        "value": "wagon number"
      },
      {
        "key": "30",
        "value": "vehicle registration number of the road vehicle"
      },
      {
        "key": "40",
        "value": "IATA flight number"
      },
      {
        "key": "41",
        "value": "registration number of the aircraft"
      },
      {
        "key": "80",
        "value": "european vessel identification number (ENI code)"
      },
      {
        "key": "81",
        "value": "name of the inland waterway's vessel "
      }
    ],
    "parenthesisCode": "(7/7)",
    "textDescription": "Identity of Means of Transport at Departure - Type of Identification",
  },
  {
    "elementName": "borderTranspMeansIDCode",
    "pullDownMenu": [
      {
        "key": "DEFAULT",
        "value": "Please Select"
      },
      {
        "key": "10",
        "value": "IMO ship identification number"
      },
      {
        "key": "11",
        "value": "name of the seagoing vessel"
      },
      {
        "key": "20",
        "value": "wagon number"
      },
      {
        "key": "30",
        "value": "vehicle registration number of the road vehicle"
      },
      {
        "key": "40",
        "value": "IATA flight number"
      },
      {
        "key": "41",
        "value": "registration number of the aircraft"
      },
      {
        "key": "80",
        "value": "european vessel identification number (ENI code)"
      },
      {
        "key": "81",
        "value": "name of the inland waterway's vessel "
      }
    ],
    "parenthesisCode": "(7/14)",
    "textDescription": "Identity of Active Means of Transport Crossing the Border - Type of Identification",
    "description" : "The type of the means of transport on which the goods are directly loaded at the time of export or transit formalities.",
  },
  {
    "elementName": "routingCountry",
    "textDescription": "Countries of Routing Codes",
    "description" : "Group Level item - Countries of routing codes",
    "type": "multiLine",
    "limit": 99,
    "components": [
      {
        "elementName": "routingCountrySeqNumeric"
      },
      {
        "elementName": "routingCountryCode"
      }
    ]
  },
  {
    "elementName": "sealNumber",
    "textDescription": "Seal Number",
    "type": "multiLine",
    "limit": 99,
    "parenthesisCode": "(7/18)",
    "components": [
      {
        "elementName": "sealId"
      },
    ]
  },

  {
    "elementName": "departureTranspMeans",
    "textDescription": "Identity of Means of Transport at Departure",
    "description" : "Group Level Item - Identity of means of transport at departure",
    "type": "multiLine",
    "limit": 1,
    "components": [
      {
        "elementName": "departureTranspMeansIDCode"
      },
      {
        "elementName": "departureTranspMeansID"
      }
    ]
  },
  {
    "elementName": "borderTranspMeans",
    "textDescription": "Identity of Active Means of Transport Crossing the Border",
    "description" : "Group Level Item - Identity of active means of transport crossing the border",
    "type": "multiLine",
    "limit": 1,
    "components": [
      {
        "elementName": "borderTranspMeansIDCode"
      },
      {
        "elementName": "borderTranspMeansID"
      }
    ]
  },
  {
    "elementName": "goodslocation",
    "textDescription": "Goods Location",
    "description" : "Group Level item - Location of Goods",
    "pullDownMenu": identificationOfLocation,
    "parenthesisCode": "5/23"
  }
];

export default validation;