import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/common/Button";
import MainNavBar from "../../../components/navigation/MainNavBar/MainNavBar"
import ThemeContext from "../../../context/theme/ThemeContext";
import UserContext from "../../../context/user/UserContext";
import RegistrationForm from "./Form";
import { validateEmail, validatePassword } from "../../../helpers";

const Register = () => {
    const [inputValues, setInputValues] = useState({});
    const { withTheme } = useContext(ThemeContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [confirmationResent, setConfirmationResent] = useState(false);
    const history = useHistory();
    const { register, user, resendConfirmation } = useContext(UserContext);

    const validateInputs = () => {
        const {
            name, email,
            password, confirmPassword
        } = inputValues;

        let errors = {};

        if (!name || name.length < 6) {
            errors.name = 'Name is too short';
        };

        if (!email) {
            errors.email = 'Email is required'
        } else if (!validateEmail(email)) {
            errors.email = 'Invalid email'
        };

        if (!validatePassword(password)) {
            errors.password = 'Password must contain at least 8 characters, capital letter, number and special character.';
        };

        if (confirmPassword !== password && password) {
            errors.confirmPassword = 'Passwords do not match';
        };

        setErrors({ ...errors });

        return Object.keys(errors).length > 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: '' })

        setInputValues({
            ...inputValues,
            [name]: value
        });
    };

    const handleSubmitClick = async () => {
        if (registerSuccess) {
            history.push('/login')
        } else {
            const { name, email, password } = inputValues;
            console.log(email, password);

            if (validateInputs()) return;
            setLoading(true);

            try {
                await register(name, email, password);
                setRegisterSuccess(true);
                setErrors({});
            } catch (e) {
                if (e.code === 'UsernameExistsException') {
                    setErrors({
                        ...errors,
                        email: "Email is already in use"
                    });
                }
            };

            setLoading(false)
        };
    };

    const handleResendClick = async () => {
        await resendConfirmation(inputValues.email);
        setConfirmationResent(true);
    };

    useEffect(() => {
        setInputValues({});
        setRegisterSuccess(false);
        setErrors({});
        if (user) {
            history.push('/');
        };
    }, [user]);

    return (
        <div>
            <MainNavBar />
            <div className={withTheme('register-page')}>
                <div
                    onClick={() => history.push('login')}
                    style={{ cursor: 'pointer' }}
                    className='d-flex align-items-center mb-4'>
                    <i
                        className='fas fa-chevron-left'
                        style={{ marginRight: 8, fontSize: 18 }}
                    />
                    <span style={{ fontSize: 12 }}>
                        Back to Login
                    </span>
                </div>

                <h4 className='mb-4'>{registerSuccess ? 'Registration Successful!' : 'Create Account'}</h4>

                {!registerSuccess &&
                    <RegistrationForm
                        errors={errors}
                        inputValues={inputValues}
                        onInputChange={handleInputChange}
                    />
                }

                {registerSuccess &&
                    <div style={{ paddingTop: 16, paddingBottom: 16, fontSize: 14 }}>
                        A confirmation e-mail has been sent to your e-mail. Please click on the provided link to continue<br />
                        Didn't receive e-mail?
                        {confirmationResent ?
                            'Resent!'
                            :
                            <div onClick={handleResendClick} className={withTheme('link-text')}>Resend</div>
                        }
                    </div>
                }

                <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                    <Button
                        disabled={loading}
                        onClick={() => handleSubmitClick()}>
                        {registerSuccess ? 'Login' : loading ? 'Loading... ' : 'Register'}
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default Register;