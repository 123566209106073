import { useTranslation } from "react-i18next";
import Button from "../../components/common/Button";
import { PiFloppyDisk } from "react-icons/pi";

const ActionButtons = ({ isSaveLoading, errors, onResetClick, onSaveClick }) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				width: "fit-content",
				marginLeft: "auto",
				display: "flex",
				alignItems: "center",
				gap: 12,
            marginTop: 52
			}}
		>
			<Button type="secondary" onClick={onResetClick} disabled={isSaveLoading}>
				{t("reset")}
			</Button>
			<Button
				isLoading={isSaveLoading}
				disabled={isSaveLoading || Object.keys(errors).length > 0}
				onClick={onSaveClick}
				LeftIcon={PiFloppyDisk}
			>
				{t("saveChanges")}
			</Button>
		</div>
	);
};

export default ActionButtons;
