const additionalInformationCodes = [
    {
      "key": "DEFAULT",
      "value": "Please Select"
    },
    {
      "key": "00100",
      "value": "Authorisation by Customs Declaration only: Special Procedure Authorisation granted on the declaration at the time of entry to the procedure."
    },
    {
      "key": "00200",
      "value": "Several occurrences of documents or parties."
    },
    {
      "key": "00300",
      "value": "Identity between declarant and consignor."
    },
    {
      "key": "00400",
      "value": "Identity between declarant and exporter."
    },
    {
      "key": "00500",
      "value": "Identity between declarant and importer."
    },
    {
      "key": "00600",
      "value": "Simplification of the drawing up of customs declaration or clearance requests for goods falling under different tariff subheadings."
    },
    {
      "key": "10200",
      "value": "Full authorisation holders only:"
    },
    {
      "key": "10300",
      "value": "Discharge of inward processing (specific commercial policy measures): Where goods placed under the inward processing procedure are subject to specific commercial policy measures and such measures continue to be applicable at the time when the goods, whether in the form of processed products or not, are placed under a subsequent customs procedure (refer to Notice 3001). n."
    },
    {
      "key": "10500",
      "value": "Full authorisation holders only:"
    },
    {
      "key": "10600",
      "value": "Situations where negotiable bills of lading that are ‘to order blank endorsed’ are concerned in the case of entry summary declarations, where consignee details are unknown."
    },
    {
      "key": "10700",
      "value": "Request to use the original tariff classification of the goods in situations provided for in Council Regulation (EU) No. 952/2013, Article 86(2)"
    },
    {
      "key": "30300",
      "value": "Export of goods subject to end use, Council Regulation (EU) No. 952/2013, Article 254(4)(b)"
    },
    {
      "key": "30400",
      "value": "The request to have an information sheet INF3 (Commission Delegated Regulation (EU) No. 2015/2446, Article 160)"
    },
    {
      "key": "30500",
      "value": "Request for the customs office where the goods are placed under a Single Transport Contract (STC) to be the customs office of exit."
    },
    {
      "key": "30600",
      "value": "Situations where negotiable bills of lading that are ‘to order blank endorsed’ are concerned, in the case of exit summary declarations, where the consignee details are unknown"
    },
    {
      "key": "AUC01",
      "value": "To be used by EIDR authorised auction houses only:"
    },
    {
      "key": "AUC02",
      "value": "To be used by authorised auction houses only:"
    },
    {
      "key": "BRnnn (001-999)",
      "value": "Branch Code selected by the importer/exporter to indicate a branch/division."
    },
    {
      "key": "Agnnn (001-999)",
      "value": "Branch Code selected by the agent to indicate a branch/division."
    },
    {
      "key": "BULKD",
      "value": "Bulking of consignments of documents only, where these goods are not subject to any import prohibitions and restrictions."
    },
    {
      "key": "CAP01",
      "value": "Code to be used to: Certify that the eggs will be marketed unaltered immediately after importation."
    },
    {
      "key": "CAP02",
      "value": "Code to be used to: Declare that the goods imported contain no sugar other than that naturally occurring in the goods."
    },
    {
      "key": "CAP03",
      "value": "Declare that all the goods covered by this entry will be weighed and sampled at… under the supervision of the Sugar Association of London/Lancashire/Greenock and the declarant is requesting acceptance of Sugar Association weights and polarisations."
    },
    {
      "key": "CAP04",
      "value": "Declare that the olive oil content does not exceed 3%."
    },
    {
      "key": "CAP05",
      "value": "Declare goods subject to safeguard charge where the invoice unit price will be used."
    },
    {
      "key": "CAP06",
      "value": "Declare goods subject to safeguard charge – reference price used"
    },
    {
      "key": "CAP09",
      "value": "Declare that the banana weighing certificates, required in accordance with Commission Implementing Regulation (EU) No. 2015/2447, Article 251) are attached."
    },
    {
      "key": "CAP10",
      "value": "This code is used to waive their attendance at any physical examination, substitution or a conformity check. The declarant may provide contact details to be informed of examination outcomes and sampling."
    },
    {
      "key": "CAP11",
      "value": "These goods are being imported or exported either with a CAP paper licence, or without any licence. Authentication of a copy of the declaration is required, under Commission Regulation (EC) No. 1301/2006, article 5, to support an application for an Import Tariff Quota licence."
    },
    {
      "key": "CAP29",
      "value": "Declare that peas or lupin seeds are to be placed under control."
    },
    {
      "key": "CCL14",
      "value": "Request for the 14-day election procedure by consignees registered for levy purposes. See Additional Procedure Code (DE 1/11) 1CL"
    },
    {
      "key": "CCLFC",
      "value": "Relief from the full rate of levy is being claimed. See Additional Procedure Code (DE 1/11) 1CL"
    },
    {
      "key": "CCLFL",
      "value": "Full rate of levy is being declared and paid at import. See Additional Procedure Code (DE 1/11) 1CL"
    },
    {
      "key": "CCLPR",
      "value": "Where partial relief from climate change levy is claimed for approved entities. See Additional Procedure Code (DE 1/11) 1CL"
    },
    {
      "key": "CCR01",
      "value": "For use on imports by charities free of duty and VAT. See Additional Procedure Code (DE 1/11) C10, C20, C49 and C50."
    },
    {
      "key": "ECO01",
      "value": "Goods of any kind the processing of goods not listed in Annex 71-02 to Commission Delegated Regulation (EU) No. 2015/2446."
    },
    {
      "key": "ECO02",
      "value": "Goods of any kind – Repair."
    },
    {
      "key": "ECO03",
      "value": "Processing of goods directly or indirectly put at the disposal of the holder of the authorisation, carried out according to specifications on behalf of a person established outside of the customs territory of the Union, generally against payment of processing costs alone."
    },
    {
      "key": "ECO04",
      "value": "The processing of durum wheat into pasta."
    },
    {
      "key": "ECO05",
      "value": "The placing of goods under inward processing within the limits of the quantity determined on the basis of a balance in accordance with Reg No. (EU) 510/2014, Article 18."
    },
    {
      "key": "ECO06",
      "value": "The processing of goods which are listed in Annex 71-02 to Commission Delegated Regulation (EU) No. 2015/2446, in case of unavailability of goods produced in the Union sharing the same 8-digit Combined Nomenclature code, the same commercial quality and technical characteristics as the goods intended to be imported for the processing operations envisaged."
    },
    {
      "key": "ECO07",
      "value": "The processing of goods which are listed in Annex 71-02 to Commission Delegated Regulation (EU) No. 2015/2446, provided there are differences in price between goods produced in the Union and those intended to be imported, where comparable goods can’t be used because their price wouldn’t make the proposed commercial operation economically viable."
    },
    {
      "key": "ECO08",
      "value": "The processing of goods which are listed in Annex 71-02 to Commission Delegated Regulation (EU) No. 2015/2446, provided there are contractual obligations, where comparable goods don’t conform to the contractual requirements of the third-country purchaser of the processed products, or where, in accordance with the contract, the processed products must be obtained from the goods intended to be placed under inward processing in order to comply with provisions concerning the protection of industrial or commercial property rights."
    },
    {
      "key": "ECO09",
      "value": "The processing of goods which are listed in Annex 71-02 to Commission Delegated Regulation (EU) No. 2015/2446, provided the aggregate value of the goods to be placed under the inward processing procedure per applicant and calendar year for each eight-digit Combined Nomenclature code doesn’t exceed €150,000."
    },
    {
      "key": "ECO10",
      "value": "The processing of goods to ensure their compliance with technical requirements for their release for free circulation:"
    },
    {
      "key": "ECO11",
      "value": "The processing of goods of a non-commercial nature:"
    },
    {
      "key": "ECO12",
      "value": "The processing of goods obtained under a previous authorisation, the issuing of which was subject to an examination of the economic conditions."
    },
    {
      "key": "ECO13",
      "value": "The processing of solid and fluid fractions of palm oil, coconut oil, fluid fractions of coconut oil, palm kernel oil, fluid fractions of palm kernel oil, babassu oil (see ECO12 for relevant CN codes) or castor oil (falling within CN code 1515 30 90) into products which aren’t destined for the food sector."
    },
    {
      "key": "ECO14",
      "value": "The processing into products to be incorporated in or used for civil aircraft for which an authorised release certificate (formerly known as an airworthiness certificate) has been issued."
    },
    {
      "key": "ECO15",
      "value": "The processing into products benefiting from the autonomous suspension of import duty on certain weapons and military equipment in accordance with Council Regulation (EC) No 150/2003."
    },
    {
      "key": "ECO16",
      "value": "The processing of goods into samples:"
    },
    {
      "key": "ECO17",
      "value": "The processing of any electronic type of components, parts, assemblies, or any other materials into information technology products."
    },
    {
      "key": "ECO18",
      "value": "The processing of goods falling within Combined Nomenclature codes 2707 or 2710 into products falling within Combined Nomenclature codes 2707, 2710 or 2902."
    },
    {
      "key": "ECO19",
      "value": "The reduction to waste and scrap, destruction, recovery of parts or components."
    },
    {
      "key": "ECO20",
      "value": "Denaturing."
    },
    {
      "key": "ECO21",
      "value": "Usual forms of handling referred to in Council Regulation (EU) 952/2013, Article 220."
    },
    {
      "key": "ECO22",
      "value": "The aggregate value of goods to be placed under the inward processing procedure per applicant and calendar year for each eight-digit Combined Nomenclature code doesn’t exceed €150,000 with regard to goods which are covered by Annex 71-02 to Commission Delegated Regulation (EU) No. 2015/2446 and €300,000 for other goods, except where the goods intended to be placed under the inward-processing procedure would be subject to a provisional or definitive anti-dumping duty, a countervailing duty, a safeguard measure or an additional duty resulting from a suspension of concessions if they were declared for release for free circulation."
    },
    {
      "key": "ECONE",
      "value": "Excise Registered Consignee"
    },
    {
      "key": "ECONR",
      "value": "Excise Registered Consignor"
    },
    {
      "key": "EPREM",
      "value": "Removals of excise goods from Registered Premises."
    },
    {
      "key": "FBK01",
      "value": "For use by Type 1 agents only in the event of Customs Handling of Import and Export Freight (CHIEF) failure and invocation of CCS-UK fallback procedures."
    },
    {
      "key": "FBK02",
      "value": "For use by Type 2 agents only in the event of Customs Handling of Import and Export Freight (CHIEF) failure and invocation of CCS-UK fallback procedures."
    },
    {
      "key": "FBK03",
      "value": "For use by Type 1 agents only in the event of Customs Declaration Service (CDS) failure and invocation of CCS-UK fallback procedures."
    },
    {
      "key": "FBK04",
      "value": "For use by Type 2 agents only in the event of Customs Declaration Service (CDS) failure and invocation of CCS-UK fallback procedures."
    },
    {
      "key": "FZPER",
      "value": "The number of days the goods have been held in the Free Zone."
    },
    {
      "key": "GCONT",
      "value": "To declare a Government Contractor on import declarations identified by UK EORI number (not prefixed with ‘GB’)."
    },
    {
      "key": "GEN01",
      "value": "Where applicable: Duty relief claimed on basis of an INF1."
    },
    {
      "key": "GEN02",
      "value": "Where applicable: Relief not claimed on the basis of an INF1."
    },
    {
      "key": "GEN03",
      "value": "Relief claimed under Returned Goods Relief. Waiver of time limit claimed."
    },
    {
      "key": "GEN3C",
      "value": "Relief claimed under Returned Goods Relief. RGR time limit extension to 6 years claimed by Crown Servants"
    },
    {
      "key": "GEN06",
      "value": "Biological Sample imported under the arrangements of BO 42324/53."
    },
    {
      "key": "GEN08",
      "value": "This importation of scrap metal is for smelting only"
    },
    {
      "key": "GEN09",
      "value": "Total dry extract does not exceed the permitted quantity"
    },
    {
      "key": "GEN10",
      "value": "I/We request delivery on deposit under the Customs & Excise Management Act 1979 Section 119 and Tariff Notes Chapter 7 special directive 4 pending determination of the quantity on which duty is to be charged"
    },
    {
      "key": "GEN11",
      "value": "Trailer imported under the terms of a general undertaking."
    },
    {
      "key": "GEN12",
      "value": "Home Office certificate/approval letter confirming the designation of an establishment under the terms of the Animals (Scientific Procedures) Act 1986."
    },
    {
      "key": "GEN13",
      "value": "NIRU certificate/approval letter or a declaration to a waiver from the requirement where shown in the Additional Procedure Code notes."
    },
    {
      "key": "GEN14",
      "value": "Supporting evidence for UN goods is attached"
    },
    {
      "key": "GEN16",
      "value": "Goods for test. Approval note from NIRU attached. See Additional Procedure Code (DE 1/11) C33."
    },
    {
      "key": "GEN18",
      "value": "CCL DEFRA facility. See Additional Procedure Code (DE 1/11) 1CL."
    },
    {
      "key": "GEN19",
      "value": "Where goods under Inward Processing were exported for further processing, and are now being re-imported"
    },
    {
      "key": "GEN21",
      "value": "Authorisation by Customs Declaration users only. Only to be completed where the party using the Temporary Admission goods is not the importer (DE 3/15 or 3/16)."
    },
    {
      "key": "GEN24",
      "value": "Temporary Admission: Goods for approval."
    },
    {
      "key": "GEN27",
      "value": "Holders of a full Temporary Admission authorisation only."
    },
    {
      "key": "GEN28",
      "value": "Temporary Admissions: State how long the goods will be used and the intended date for re-exportation. See Additional Procedure Codes (DE 1/11) D01-D13, D15-D22, D25-D26, D28-D30, and DE 1/11 Additional Procedure Codes for C21i: 50A, 50D, 50F, 50G, 51G, 50M, 50P, 51P, 52P, 50T and 50W"
    },
    {
      "key": "GEN29",
      "value": "Temporary Admissions: Enter the article under which the Temporary Admission relief is claimed."
    },
    {
      "key": "GEN30",
      "value": "Temporary Admissions: State the purpose of the importation including the sequence and locations where the goods are to be used. See Additional Procedure Codes (DE 1/11) D06-D13, D18, D20-D22, D28-D29, and DE 1/11 Additional Procedure Codes for C21i: 50A, 50D, 50F, 50G, 51G, 51P, 50T and 50W"
    },
    {
      "key": "GEN31",
      "value": "Temporary Admissions: Used to declare non-EU ownership of goods claiming temporary admission. See Additional Procedure Codes (DE 1/11) D08, D12, D13, D16, D17, and DE 1/11 Additional Procedure Codes for C21i: 50A, 50P, 51P and 50T"
    },
    {
      "key": "GEN32",
      "value": "Temporary Admissions: State what goods will be manufactured using the goods entered to temporary admission. See Additional Procedure Codes (DE 1/11) D16 and D17."
    },
    {
      "key": "GEN33",
      "value": "Temporary Admission for exhibitions. See Additional Procedure Code (DE 1/11) D23."
    },
    {
      "key": "GEN34",
      "value": "Temporary Admission for exhibition/auction. See Additional Procedure Code (DE 1/11) D25 and D26."
    },
    {
      "key": "GEN35",
      "value": "Temporary Admission for spare parts and accessories. See Additional Procedure Code (DE 1/11) D27."
    },
    {
      "key": "GEN36",
      "value": "For use with Temporary Admission Additional Procedure Codes (DE 1/11) D28, D29, and DE 1/11 Additional Procedure Codes for C21i: 50G and 51G."
    },
    {
      "key": "GEN39",
      "value": "Means of identifying the exported goods in the imported compensating products"
    },
    {
      "key": "GEN40",
      "value": "Claimants for hydrocarbon oil shipped as stores and who operate the “netting” scheme [Notice 179 section 12] are to enter “DRAWBACK SCHEDULER”."
    },
    {
      "key": "GEN43",
      "value": "ESA scientific apparatus under the arrangements of PRIV 4/15/6. See Additional Procedure Code (DE 1/11) 1ES."
    },
    {
      "key": "GEN44",
      "value": "PRIV33/BAS/381. See Additional Procedure Code (DE 1/11) 1ES."
    },
    {
      "key": "GEN45",
      "value": "Consignment/work number."
    },
    {
      "key": "GEN47",
      "value": "Authorisation by Customs Declaration only: Place where goods are to be put to the prescribed end use."
    },
    {
      "key": "GEN48",
      "value": "Authorisation by Customs Declaration only: Description of process, processed goods and the commodity code(s) if processing is a criterion for using the relevant commodity code."
    },
    {
      "key": "GEN49",
      "value": "For goods imported under commodity code 3604 only."
    },
    {
      "key": "GEN52",
      "value": "For use when relief or suspension of duty is claimed for goods to be used at a trade fair or similar event"
    },
    {
      "key": "GEN53",
      "value": "Goods which fulfil the description of investment gold:"
    },
    {
      "key": "GEN86",
      "value": "Use with A.86(3) UCC debt rules only:"
    },
    {
      "key": "GMSPR",
      "value": "General Marketing Standard (GMS) Products either processed, prepared or being imported for processing."
    },
    {
      "key": "IRQ02",
      "value": "To request that UK export documents, required by other countries, be endorsed by UK Customs."
    },
    {
      "key": "IRQ0A",
      "value": "Request for Delivery Verification Certificate and/or International Information Document"
    },
    {
      "key": "IRQ0B",
      "value": "Request for:"
    },
    {
      "key": "IRQ0C",
      "value": "Request for goods entered for warehousing to be removed under duty suspension for receipt and examination at an approved tax warehouse. This should not be used for a Customs warehouse. See Additional Procedure Code (DE 1/11) 1EX."
    },
    {
      "key": "IRQ0D",
      "value": "I/We request delivery on deposit of duty under Section 119 of the Customs and Excise management Act 1979, in view of the attached undertaking on Form C&E 894."
    },
    {
      "key": "IRQ0E",
      "value": "Request for certification of additional copy invoices for butter/cheese from Holland"
    },
    {
      "key": "IRQ0F",
      "value": "Request for certification of attached End Use /Destination control copy."
    },
    {
      "key": "IRQ0G",
      "value": "Request for return of CT receipt"
    },
    {
      "key": "IRQ0H",
      "value": "Request for release of goods with disputed classification against security"
    },
    {
      "key": "IRQ0S",
      "value": "Request for a laboratory analysis to be carried out in respect of processed CAP goods of variable composition, referred to in Regulation (EC) No. 1043/05, Article 51 and Annex IV, and List E of RPA External Trade leaflet ET 17 (section A – Declaration based on an analysis result)."
    },
    {
      "key": "MIB01",
      "value": "To declare commercial goods carried by passengers in accompanied baggage or in private vehicles for trade or business use."
    },
    {
      "key": "MORC1",
      "value": "Hydrocarbon ‘Tied Oils’ Scheme:"
    },
    {
      "key": "MOVE1",
      "value": "Details of the receiving Temporary Storage Facility"
    },
    {
      "key": "MOVE2",
      "value": "Used to specify the party taking liability for the goods during the Movement in TS (MiTS) where this is not the Dispatching TS Operator"
    },
    {
      "key": "MOVE3",
      "value": "Details of the authorised party moving the goods under special procedures"
    },
    {
      "key": "MOVE4",
      "value": "The receiving MOU Facility"
    },
    {
      "key": "NCGDS",
      "value": "SDP simplified declarations: for non-controlled goods only."
    },
    {
      "key": "NCSMA",
      "value": "• Goods owned by NICSMA and to be transported direct to NATO installations"
    },
    {
      "key": "NEATA",
      "value": "Non-EU issued ATA carnets. See Additional Procedure Codes (DE 1/11) 11A (non-licensable goods) or 12A (licensable goods)"
    },
    {
      "key": "NSTAT",
      "value": "Export, re-export or dispatch from a special procedure of goods of non-statistical interest"
    },
    {
      "key": "OPVAT",
      "value": "VAT only outward processing:"
    },
    {
      "key": "OSD01",
      "value": "Onward Supply Dispatch: For goods intended for supply to a Special Fiscal Territory or territory with which the EU has formed a Customs Union"
    },
    {
      "key": "OSR42",
      "value": "Onward Supply Relief using Requested or Previous Procedure Code 42."
    },
    {
      "key": "OVR01",
      "value": "Duty calculation override"
    },
    {
      "key": "OVR02",
      "value": "Exemption claimed from safeguarding duties"
    },
    {
      "key": "OVR03",
      "value": "Manual calculation required for tax type A20"
    },
    {
      "key": "OVR04",
      "value": "Manual calculation required for composite goods containing alcohol"
    },
    {
      "key": "OVR05",
      "value": "Manual calculation required for composite goods containing hydrocarbon oils"
    },
    {
      "key": "PAL01",
      "value": "Temporary Admission relief for Packings, Commission Delegated Regulation (EU) No. 2015/2446, Article 228. See Annex C, Notice 3001."
    },
    {
      "key": "PAL02",
      "value": "Temporary Admission relief for Pallets, Commission Delegated Regulation (EU) No. 2015/2446, Articles 208 and 209. See Annex C, Notice 3001."
    },
    {
      "key": "PAL03",
      "value": "Temporary Admission relief for Containers, Commission Delegated Regulation (EU) No. 2015/2446, Articles 210 and 211. See Annex C, Notice 3001."
    },
    {
      "key": "PAL05",
      "value": "Code used to declare:"
    },
    {
      "key": "PAL06",
      "value": "Temporary Admission relief for Packings, Commission Delegated Regulation (EU) No. 2015/2446, Article 228. See Annex C, Notice 3001."
    },
    {
      "key": "PAL07",
      "value": "Temporary Admission relief for Pallets, Commission Delegated Regulation (EU) No. 2015/2446, Articles 208 and 209. See Annex C, Notice 3001."
    },
    {
      "key": "PAL08",
      "value": "Temporary Admission relief for Containers, Commission Delegated Regulation (EU) No. 2015/2446, Articles 210 and 211. See Annex C, Notice 3001."
    },
    {
      "key": "PAL12",
      "value": "Code used to declare:"
    },
    {
      "key": "PAR01",
      "value": "The entered goods (falling outside Tariff headings 05.07 and 96.01) incorporate ivory or tortoise shell"
    },
    {
      "key": "PAR03",
      "value": "I declare that the species being imported, or from which the imported goods derive, are not restricted under the EU CITES Regulations and are not skins derived from Harp or Hooded seal pups"
    },
    {
      "key": "PAR06",
      "value": "Any imitation coin in this consignment is for smelting only. I request release under Section 119 of the Customs & Excise Management Act 1979 without payment of duty, under the importers general undertaking: TN Addendum B, paragraph 6."
    },
    {
      "key": "PAR07",
      "value": "Any imitation coin in this consignment is for smelting only. I request release under Section 119 of the Customs & Excise Management Act 1979 without payment of duty, imported under Treasury Direction – BO 28861/69."
    },
    {
      "key": "PAR09",
      "value": "Declaration that horse is not docked"
    },
    {
      "key": "PAR10",
      "value": "This consignment contains produce of phytosanitary relevance"
    },
    {
      "key": "PAR11",
      "value": "The wood in this consignment has been treated to a temperature of 176 degrees centigrade for 20 mins"
    },
    {
      "key": "POD06",
      "value": "Authorisation by Customs Declaration only: Throughput period for the customs procedure."
    },
    {
      "key": "POD07-POD24",
      "value": "Authorisation by Customs Declaration only: Throughput period for the customs procedure."
    },
    {
      "key": "PREMS",
      "value": "Premises Name and Address."
    },
    {
      "key": "PREMS",
      "value": "For goods imported under commodity code 3604 only:"
    },
    {
      "key": "PRO01",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of alcoholic goods."
    },
    {
      "key": "PRO02",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of tobacco goods."
    },
    {
      "key": "PRO03",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of chemicals and pharmaceuticals."
    },
    {
      "key": "PRO04",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of motor vehicles and parts."
    },
    {
      "key": "PRO05",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of maritime vessels and parts."
    },
    {
      "key": "PRO06",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of aircraft satellites and parts."
    },
    {
      "key": "PRO07",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of other industrial goods."
    },
    {
      "key": "PRO08",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of milk and milk products."
    },
    {
      "key": "PRO09",
      "value": "Authorisation by Customs Declaration only: Manufacture or process of sugar and sugar products"
    },
    {
      "key": "PRO10",
      "value": "Authorisation by Customs Declaration only: Other agricultural goods"
    },
    {
      "key": "PRO11-13",
      "value": "Authorisation by Customs Declaration only: End use Relief: type of process statement to cover PRO11-13 nature of end use"
    },
    {
      "key": "RCD01",
      "value": "Relief/Suspension of Customs Duty Claimed."
    },
    {
      "key": "RCONE",
      "value": "To declare a Registered Consignee on import declarations identified by UK EORI number (not prefixed with ‘GB’)"
    },
    {
      "key": "RCONR",
      "value": "To declare a Registered Consignor on export declaration identified by the UK EORI number (note: not prefixed with ‘GB’)."
    },
    {
      "key": "RED01",
      "value": "Relief/Suspension of Excise Duty Claimed."
    },
    {
      "key": "REJIM",
      "value": "Rejected imports – statement to show why the goods are rejected by the importer"
    },
    {
      "key": "REV01",
      "value": "The above-entered consignment of aviation gasoline will be delivered to name and address solely for use or supply as aircraft fuel"
    },
    {
      "key": "REV02",
      "value": "The above-entered consignment of light oil will be delivered direct to name and address for use as furnace fuel under furnace operator’s approval number and that the oil has been marked abroad to conform to the marking provisions of the current UK Hydrocarbon Oil Regulations"
    },
    {
      "key": "REV03",
      "value": "The above-entered consignment of light oil will be delivered direct to name and address for use as furnace under furnace operator’s approval number and that the oil:"
    },
    {
      "key": "REV04",
      "value": "The above-entered consignment of unmarked aviation turbine kerosene will be delivered under DEF.STAN conditions to name and address for use or supply as fuel for aircraft engines"
    },
    {
      "key": "REV05",
      "value": "The above-entered consignment of/containing kerosene/gas oil has been marked abroad to conform to the marking provisions of the current UK Hydrocarbon Oil Regulations"
    },
    {
      "key": "REV06",
      "value": "The above-entered consignment of/containing unmarked kerosene/gas oil is in closed containers not exceeding 25 litres capacity and is not for use as road fuel additive or substitute."
    },
    {
      "key": "REV08",
      "value": "The above-entered consignment of unmarked kerosene/gas oil:"
    },
    {
      "key": "REV09",
      "value": "Consignment of road fuel additive containing unmarked kerosene/gas oil packaged for addition to road fuel in a proportion not greater than 1 part/100. In this context, road fuel means any substance commonly added to DERV or motor spirit to improve its quality or characteristics as a fuel, but not intended to act simply as a fuel extender or substitute. The term includes products sold as upper cylinder lubricants."
    },
    {
      "key": "REV10",
      "value": "I/We declare that the goods are unfit for human consumption because of the presence of the constituent(s) named in the proportion stated here."
    },
    {
      "key": "REV11",
      "value": "I/We declare that the goods are unfit for human consumption because of the presence of the constituent(s) named in the proportion stated on the attached certificate"
    },
    {
      "key": "REV13",
      "value": "Wine to be warehoused in the containers, account to be taken at Import Station"
    },
    {
      "key": "REV14",
      "value": "I/We request that the particulars given on the attached certificate (Republic of Ireland Form C&E 75) be accepted for the purpose of the import account"
    },
    {
      "key": "ROY01",
      "value": "Authorisation by Customs Declaration only: Rate of yield."
    },
    {
      "key": "ROY02",
      "value": "Authorisation by Customs Declaration only: Rate of yield."
    },
    {
      "key": "RPTID",
      "value": "Enter:"
    },
    {
      "key": "RVAT1",
      "value": "Relief/Suspension of Import VAT claimed"
    },
    {
      "key": "SHNMO",
      "value": "Goods owned by SHNMO and to be transported direct to NATO installations. See Additional Procedure Code (DE 1/11) 1NC"
    },
    {
      "key": "TAEXP",
      "value": "Temporary Admission (TA): Re-exportation of goods previously imported to TA."
    },
    {
      "key": "TAPAR",
      "value": "Temporary Admission (TA) with Partial Relief."
    },
    {
      "key": "TOR01",
      "value": "Transfer of Residence (ToR) relief for household effects and personal property belonging to natural persons transferring their normal place of residence to the United Kingdom only."
    },
    {
      "key": "TRANS",
      "value": "This AI code should be used when goods have been given ‘Permission to Progress’ but the goods must remain under Customs control under one of the transit procedures, they must subsequently be declared to the NCTS system."
    },
    {
      "key": "TSPER",
      "value": "The number of days the goods have already been held in Temporary Storage"
    },
    {
      "key": "VAL4B",
      "value": "To declare the Valuation Method 4B is used: the customs value is based on the unit price of the actual sales of the imported goods which can take place up to 90 days after importation."
    },
    {
      "key": "VAT03",
      "value": "Authorisation by Customs Declaration only: Other agricultural goods"
    },
    {
      "key": "VAT04",
      "value": "The goods are chargeable with VAT at a positive rate. The tax will either be paid outright or accounted for under the single duty deferment number quoted hereon and each consignee described on the attached Forms C517 (and C517 continued if necessary) will be charged his/her proportion of the tax as shown on the forms."
    },
    {
      "key": "VAT05",
      "value": "General Undertaking dated and lodged with the local Excise officer."
    },
    {
      "key": "WHSRP",
      "value": "Claims to preference for goods being entered for customs warehousing where a preference claim will be made on their removal."
    },
    {
      "key": "AG185",
      "value": "Branch Code 185"
    },
    {
      "key": "AG186",
      "value": "Branch Code 186"
    },
    {
      "key": "AG187",
      "value": "Branch Code 187"
    },
    {
      "key": "AG188",
      "value": "Branch Code 188"
    },
    {
      "key": "AG189",
      "value": "Branch Code 189"
    },
    {
      "key": "AG190",
      "value": "Branch Code 190"
    },
    {
      "key": "AG191",
      "value": "Branch Code 191"
    },
    {
      "key": "AG192",
      "value": "Branch Code 192"
    },
    {
      "key": "AG193",
      "value": "Branch Code 193"
    },
    {
      "key": "AG194",
      "value": "Branch Code 194"
    },
    {
      "key": "AG195",
      "value": "Branch Code 195"
    },
    {
      "key": "AG196",
      "value": "Branch Code 196"
    },
    {
      "key": "AG197",
      "value": "Branch Code 197"
    },
    {
      "key": "AG198",
      "value": "Branch Code 198"
    },
    {
      "key": "AG199",
      "value": "Branch Code 199"
    },
    {
      "key": "AG200",
      "value": "Branch Code 200"
    },
    {
      "key": "AG201",
      "value": "Branch Code 201"
    },
    {
      "key": "AG202",
      "value": "Branch Code 202"
    },
    {
      "key": "AG203",
      "value": "Branch Code 203"
    },
    {
      "key": "AG204",
      "value": "Branch Code 204"
    },
    {
      "key": "AG205",
      "value": "Branch Code 205"
    },
    {
      "key": "AG206",
      "value": "Branch Code 206"
    },
    {
      "key": "AG207",
      "value": "Branch Code 207"
    },
    {
      "key": "AG208",
      "value": "Branch Code 208"
    },
    {
      "key": "AG209",
      "value": "Branch Code 209"
    },
    {
      "key": "AG210",
      "value": "Branch Code 210"
    },
    {
      "key": "AG211",
      "value": "Branch Code 211"
    },
    {
      "key": "AG212",
      "value": "Branch Code 212"
    },
    {
      "key": "AG213",
      "value": "Branch Code 213"
    },
    {
      "key": "AG214",
      "value": "Branch Code 214"
    },
    {
      "key": "AG215",
      "value": "Branch Code 215"
    },
    {
      "key": "AG216",
      "value": "Branch Code 216"
    },
    {
      "key": "AG217",
      "value": "Branch Code 217"
    },
    {
      "key": "AG218",
      "value": "Branch Code 218"
    },
    {
      "key": "AG219",
      "value": "Branch Code 219"
    },
    {
      "key": "AG220",
      "value": "Branch Code 220"
    },
    {
      "key": "AG221",
      "value": "Branch Code 221"
    },
    {
      "key": "AG222",
      "value": "Branch Code 222"
    },
    {
      "key": "AG223",
      "value": "Branch Code 223"
    },
    {
      "key": "AG224",
      "value": "Branch Code 224"
    },
    {
      "key": "AG225",
      "value": "Branch Code 225"
    },
    {
      "key": "AG226",
      "value": "Branch Code 226"
    },
    {
      "key": "AG227",
      "value": "Branch Code 227"
    },
    {
      "key": "AG228",
      "value": "Branch Code 228"
    },
    {
      "key": "AG229",
      "value": "Branch Code 229"
    },
    {
      "key": "AG230",
      "value": "Branch Code 230"
    },
    {
      "key": "AG231",
      "value": "Branch Code 231"
    },
    {
      "key": "AG232",
      "value": "Branch Code 232"
    },
    {
      "key": "AG233",
      "value": "Branch Code 233"
    },
    {
      "key": "AG234",
      "value": "Branch Code 234"
    },
    {
      "key": "AG235",
      "value": "Branch Code 235"
    },
    {
      "key": "AG236",
      "value": "Branch Code 236"
    },
    {
      "key": "AG237",
      "value": "Branch Code 237"
    },
    {
      "key": "AG238",
      "value": "Branch Code 238"
    },
    {
      "key": "AG239",
      "value": "Branch Code 239"
    },
    {
      "key": "AG240",
      "value": "Branch Code 240"
    },
    {
      "key": "AG241",
      "value": "Branch Code 241"
    },
    {
      "key": "AG242",
      "value": "Branch Code 242"
    },
    {
      "key": "AG243",
      "value": "Branch Code 243"
    },
    {
      "key": "AG244",
      "value": "Branch Code 244"
    },
    {
      "key": "AG245",
      "value": "Branch Code 245"
    },
    {
      "key": "AG246",
      "value": "Branch Code 246"
    },
    {
      "key": "AG247",
      "value": "Branch Code 247"
    },
    {
      "key": "AG248",
      "value": "Branch Code 248"
    },
    {
      "key": "AG249",
      "value": "Branch Code 249"
    },
    {
      "key": "AG250",
      "value": "Branch Code 250"
    },
    {
      "key": "AG251",
      "value": "Branch Code 251"
    },
    {
      "key": "AG252",
      "value": "Branch Code 252"
    },
    {
      "key": "AG253",
      "value": "Branch Code 253"
    },
    {
      "key": "AG254",
      "value": "Branch Code 254"
    },
    {
      "key": "AG255",
      "value": "Branch Code 255"
    },
    {
      "key": "AG256",
      "value": "Branch Code 256"
    },
    {
      "key": "AG257",
      "value": "Branch Code 257"
    },
    {
      "key": "AG258",
      "value": "Branch Code 258"
    },
    {
      "key": "AG259",
      "value": "Branch Code 259"
    },
    {
      "key": "AG260",
      "value": "Branch Code 260"
    },
    {
      "key": "AG261",
      "value": "Branch Code 261"
    },
    {
      "key": "AG262",
      "value": "Branch Code 262"
    },
    {
      "key": "AG263",
      "value": "Branch Code 263"
    },
    {
      "key": "AG264",
      "value": "Branch Code 264"
    },
    {
      "key": "AG265",
      "value": "Branch Code 265"
    },
    {
      "key": "AG266",
      "value": "Branch Code 266"
    },
    {
      "key": "AG267",
      "value": "Branch Code 267"
    },
    {
      "key": "AG268",
      "value": "Branch Code 268"
    },
    {
      "key": "AG269",
      "value": "Branch Code 269"
    },
    {
      "key": "AG270",
      "value": "Branch Code 270"
    },
    {
      "key": "AG271",
      "value": "Branch Code 271"
    },
    {
      "key": "AG272",
      "value": "Branch Code 272"
    },
    {
      "key": "AG273",
      "value": "Branch Code 273"
    },
    {
      "key": "AG274",
      "value": "Branch Code 274"
    },
    {
      "key": "AG275",
      "value": "Branch Code 275"
    },
    {
      "key": "AG276",
      "value": "Branch Code 276"
    },
    {
      "key": "AG277",
      "value": "Branch Code 277"
    },
    {
      "key": "AG278",
      "value": "Branch Code 278"
    },
    {
      "key": "AG279",
      "value": "Branch Code 279"
    },
    {
      "key": "AG280",
      "value": "Branch Code 280"
    },
    {
      "key": "AG281",
      "value": "Branch Code 281"
    },
    {
      "key": "AG282",
      "value": "Branch Code 282"
    },
    {
      "key": "AG283",
      "value": "Branch Code 283"
    },
    {
      "key": "AG284",
      "value": "Branch Code 284"
    },
    {
      "key": "AG285",
      "value": "Branch Code 285"
    },
    {
      "key": "AG286",
      "value": "Branch Code 286"
    },
    {
      "key": "AG287",
      "value": "Branch Code 287"
    },
    {
      "key": "AG288",
      "value": "Branch Code 288"
    },
    {
      "key": "AG289",
      "value": "Branch Code 289"
    },
    {
      "key": "AG290",
      "value": "Branch Code 290"
    },
    {
      "key": "AG291",
      "value": "Branch Code 291"
    },
    {
      "key": "AG292",
      "value": "Branch Code 292"
    },
    {
      "key": "AG293",
      "value": "Branch Code 293"
    },
    {
      "key": "AG294",
      "value": "Branch Code 294"
    },
    {
      "key": "AG295",
      "value": "Branch Code 295"
    },
    {
      "key": "AG296",
      "value": "Branch Code 296"
    },
    {
      "key": "AG297",
      "value": "Branch Code 297"
    },
    {
      "key": "AG298",
      "value": "Branch Code 298"
    },
    {
      "key": "AG299",
      "value": "Branch Code 299"
    },
    {
      "key": "AG300",
      "value": "Branch Code 300"
    },
    {
      "key": "AG301",
      "value": "Branch Code 301"
    },
    {
      "key": "AG302",
      "value": "Branch Code 302"
    },
    {
      "key": "AG303",
      "value": "Branch Code 303"
    },
    {
      "key": "AG304",
      "value": "Branch Code 304"
    },
    {
      "key": "AG305",
      "value": "Branch Code 305"
    },
    {
      "key": "AG306",
      "value": "Branch Code 306"
    },
    {
      "key": "AG307",
      "value": "Branch Code 307"
    },
    {
      "key": "AG308",
      "value": "Branch Code 308"
    },
    {
      "key": "AG309",
      "value": "Branch Code 309"
    },
    {
      "key": "AG310",
      "value": "Branch Code 310"
    },
    {
      "key": "AG311",
      "value": "Branch Code 311"
    },
    {
      "key": "AG312",
      "value": "Branch Code 312"
    },
    {
      "key": "AG313",
      "value": "Branch Code 313"
    },
    {
      "key": "AG314",
      "value": "Branch Code 314"
    },
    {
      "key": "AG315",
      "value": "Branch Code 315"
    },
    {
      "key": "AG316",
      "value": "Branch Code 316"
    },
    {
      "key": "AG317",
      "value": "Branch Code 317"
    },
    {
      "key": "AG318",
      "value": "Branch Code 318"
    },
    {
      "key": "AG319",
      "value": "Branch Code 319"
    },
    {
      "key": "AG320",
      "value": "Branch Code 320"
    },
    {
      "key": "AG321",
      "value": "Branch Code 321"
    },
    {
      "key": "AG322",
      "value": "Branch Code 322"
    },
    {
      "key": "AG323",
      "value": "Branch Code 323"
    },
    {
      "key": "AG324",
      "value": "Branch Code 324"
    },
    {
      "key": "AG325",
      "value": "Branch Code 325"
    },
    {
      "key": "AG326",
      "value": "Branch Code 326"
    },
    {
      "key": "AG327",
      "value": "Branch Code 327"
    },
    {
      "key": "AG328",
      "value": "Branch Code 328"
    },
    {
      "key": "AG329",
      "value": "Branch Code 329"
    },
    {
      "key": "AG330",
      "value": "Branch Code 330"
    },
    {
      "key": "AG331",
      "value": "Branch Code 331"
    },
    {
      "key": "AG332",
      "value": "Branch Code 332"
    },
    {
      "key": "AG333",
      "value": "Branch Code 333"
    },
    {
      "key": "AG334",
      "value": "Branch Code 334"
    },
    {
      "key": "AG335",
      "value": "Branch Code 335"
    },
    {
      "key": "AG336",
      "value": "Branch Code 336"
    },
    {
      "key": "AG337",
      "value": "Branch Code 337"
    },
    {
      "key": "AG338",
      "value": "Branch Code 338"
    },
    {
      "key": "AG339",
      "value": "Branch Code 339"
    },
    {
      "key": "AG340",
      "value": "Branch Code 340"
    },
    {
      "key": "AG341",
      "value": "Branch Code 341"
    },
    {
      "key": "AG342",
      "value": "Branch Code 342"
    },
    {
      "key": "AG343",
      "value": "Branch Code 343"
    },
    {
      "key": "AG344",
      "value": "Branch Code 344"
    },
    {
      "key": "AG345",
      "value": "Branch Code 345"
    },
    {
      "key": "AG346",
      "value": "Branch Code 346"
    },
    {
      "key": "AG347",
      "value": "Branch Code 347"
    },
    {
      "key": "AG348",
      "value": "Branch Code 348"
    },
    {
      "key": "AG349",
      "value": "Branch Code 349"
    },
    {
      "key": "AG350",
      "value": "Branch Code 350"
    },
    {
      "key": "AG351",
      "value": "Branch Code 351"
    },
    {
      "key": "AG352",
      "value": "Branch Code 352"
    },
    {
      "key": "AG353",
      "value": "Branch Code 353"
    },
    {
      "key": "AG354",
      "value": "Branch Code 354"
    },
    {
      "key": "AG355",
      "value": "Branch Code 355"
    },
    {
      "key": "AG356",
      "value": "Branch Code 356"
    },
    {
      "key": "AG357",
      "value": "Branch Code 357"
    },
    {
      "key": "AG358",
      "value": "Branch Code 358"
    },
    {
      "key": "AG359",
      "value": "Branch Code 359"
    },
    {
      "key": "AG360",
      "value": "Branch Code 360"
    },
    {
      "key": "AG361",
      "value": "Branch Code 361"
    },
    {
      "key": "AG362",
      "value": "Branch Code 362"
    },
    {
      "key": "AG363",
      "value": "Branch Code 363"
    },
    {
      "key": "AG364",
      "value": "Branch Code 364"
    },
    {
      "key": "AG365",
      "value": "Branch Code 365"
    },
    {
      "key": "AG366",
      "value": "Branch Code 366"
    },
    {
      "key": "AG367",
      "value": "Branch Code 367"
    },
    {
      "key": "AG368",
      "value": "Branch Code 368"
    },
    {
      "key": "AG369",
      "value": "Branch Code 369"
    },
    {
      "key": "AG370",
      "value": "Branch Code 370"
    },
    {
      "key": "AG371",
      "value": "Branch Code 371"
    },
    {
      "key": "AG372",
      "value": "Branch Code 372"
    },
    {
      "key": "AG373",
      "value": "Branch Code 373"
    },
    {
      "key": "AG374",
      "value": "Branch Code 374"
    },
    {
      "key": "AG375",
      "value": "Branch Code 375"
    },
    {
      "key": "AG376",
      "value": "Branch Code 376"
    },
    {
      "key": "AG377",
      "value": "Branch Code 377"
    },
    {
      "key": "AG378",
      "value": "Branch Code 378"
    },
    {
      "key": "AG379",
      "value": "Branch Code 379"
    },
    {
      "key": "AG380",
      "value": "Branch Code 380"
    },
    {
      "key": "AG381",
      "value": "Branch Code 381"
    },
    {
      "key": "AG382",
      "value": "Branch Code 382"
    },
    {
      "key": "AG383",
      "value": "Branch Code 383"
    },
    {
      "key": "AG384",
      "value": "Branch Code 384"
    },
    {
      "key": "AG385",
      "value": "Branch Code 385"
    },
    {
      "key": "AG386",
      "value": "Branch Code 386"
    },
    {
      "key": "AG387",
      "value": "Branch Code 387"
    },
    {
      "key": "AG388",
      "value": "Branch Code 388"
    },
    {
      "key": "AG389",
      "value": "Branch Code 389"
    },
    {
      "key": "AG390",
      "value": "Branch Code 390"
    },
    {
      "key": "AG391",
      "value": "Branch Code 391"
    },
    {
      "key": "AG392",
      "value": "Branch Code 392"
    },
    {
      "key": "AG393",
      "value": "Branch Code 393"
    },
    {
      "key": "AG394",
      "value": "Branch Code 394"
    },
    {
      "key": "AG395",
      "value": "Branch Code 395"
    },
    {
      "key": "AG396",
      "value": "Branch Code 396"
    },
    {
      "key": "AG397",
      "value": "Branch Code 397"
    },
    {
      "key": "AG398",
      "value": "Branch Code 398"
    },
    {
      "key": "AG399",
      "value": "Branch Code 399"
    },
    {
      "key": "AG400",
      "value": "Branch Code 400"
    },
    {
      "key": "AG401",
      "value": "Branch Code 401"
    },
    {
      "key": "AG402",
      "value": "Branch Code 402"
    },
    {
      "key": "AG403",
      "value": "Branch Code 403"
    },
    {
      "key": "AG404",
      "value": "Branch Code 404"
    },
    {
      "key": "AG405",
      "value": "Branch Code 405"
    },
    {
      "key": "AG406",
      "value": "Branch Code 406"
    },
    {
      "key": "AG407",
      "value": "Branch Code 407"
    },
    {
      "key": "AG408",
      "value": "Branch Code 408"
    },
    {
      "key": "AG409",
      "value": "Branch Code 409"
    },
    {
      "key": "AG410",
      "value": "Branch Code 410"
    },
    {
      "key": "AG411",
      "value": "Branch Code 411"
    },
    {
      "key": "AG412",
      "value": "Branch Code 412"
    },
    {
      "key": "AG413",
      "value": "Branch Code 413"
    },
    {
      "key": "AG414",
      "value": "Branch Code 414"
    },
    {
      "key": "AG415",
      "value": "Branch Code 415"
    },
    {
      "key": "AG416",
      "value": "Branch Code 416"
    },
    {
      "key": "AG417",
      "value": "Branch Code 417"
    },
    {
      "key": "AG418",
      "value": "Branch Code 418"
    },
    {
      "key": "AG419",
      "value": "Branch Code 419"
    },
    {
      "key": "AG420",
      "value": "Branch Code 420"
    },
    {
      "key": "AG421",
      "value": "Branch Code 421"
    },
    {
      "key": "AG422",
      "value": "Branch Code 422"
    },
    {
      "key": "AG423",
      "value": "Branch Code 423"
    },
    {
      "key": "AG424",
      "value": "Branch Code 424"
    },
    {
      "key": "AG425",
      "value": "Branch Code 425"
    },
    {
      "key": "AG426",
      "value": "Branch Code 426"
    },
    {
      "key": "AG427",
      "value": "Branch Code 427"
    },
    {
      "key": "AG428",
      "value": "Branch Code 428"
    },
    {
      "key": "AG429",
      "value": "Branch Code 429"
    },
    {
      "key": "AG430",
      "value": "Branch Code 430"
    },
    {
      "key": "AG431",
      "value": "Branch Code 431"
    },
    {
      "key": "AG432",
      "value": "Branch Code 432"
    },
    {
      "key": "AG433",
      "value": "Branch Code 433"
    },
    {
      "key": "AG434",
      "value": "Branch Code 434"
    },
    {
      "key": "AG435",
      "value": "Branch Code 435"
    },
    {
      "key": "AG436",
      "value": "Branch Code 436"
    },
    {
      "key": "AG437",
      "value": "Branch Code 437"
    },
    {
      "key": "AG438",
      "value": "Branch Code 438"
    },
    {
      "key": "AG439",
      "value": "Branch Code 439"
    },
    {
      "key": "AG440",
      "value": "Branch Code 440"
    },
    {
      "key": "AG441",
      "value": "Branch Code 441"
    },
    {
      "key": "AG442",
      "value": "Branch Code 442"
    },
    {
      "key": "AG443",
      "value": "Branch Code 443"
    },
    {
      "key": "AG444",
      "value": "Branch Code 444"
    },
    {
      "key": "AG445",
      "value": "Branch Code 445"
    },
    {
      "key": "AG446",
      "value": "Branch Code 446"
    },
    {
      "key": "AG447",
      "value": "Branch Code 447"
    },
    {
      "key": "AG448",
      "value": "Branch Code 448"
    },
    {
      "key": "AG449",
      "value": "Branch Code 449"
    },
    {
      "key": "AG450",
      "value": "Branch Code 450"
    },
    {
      "key": "AG451",
      "value": "Branch Code 451"
    },
    {
      "key": "AG452",
      "value": "Branch Code 452"
    },
    {
      "key": "AG453",
      "value": "Branch Code 453"
    },
    {
      "key": "AG454",
      "value": "Branch Code 454"
    },
    {
      "key": "AG455",
      "value": "Branch Code 455"
    },
    {
      "key": "AG456",
      "value": "Branch Code 456"
    },
    {
      "key": "AG457",
      "value": "Branch Code 457"
    },
    {
      "key": "AG458",
      "value": "Branch Code 458"
    },
    {
      "key": "AG459",
      "value": "Branch Code 459"
    },
    {
      "key": "AG460",
      "value": "Branch Code 460"
    },
    {
      "key": "AG461",
      "value": "Branch Code 461"
    },
    {
      "key": "AG462",
      "value": "Branch Code 462"
    },
    {
      "key": "AG463",
      "value": "Branch Code 463"
    },
    {
      "key": "AG464",
      "value": "Branch Code 464"
    },
    {
      "key": "AG465",
      "value": "Branch Code 465"
    },
    {
      "key": "AG466",
      "value": "Branch Code 466"
    },
    {
      "key": "AG467",
      "value": "Branch Code 467"
    },
    {
      "key": "AG468",
      "value": "Branch Code 468"
    },
    {
      "key": "AG469",
      "value": "Branch Code 469"
    },
    {
      "key": "AG470",
      "value": "Branch Code 470"
    },
    {
      "key": "AG471",
      "value": "Branch Code 471"
    },
    {
      "key": "AG472",
      "value": "Branch Code 472"
    },
    {
      "key": "AG473",
      "value": "Branch Code 473"
    },
    {
      "key": "AG474",
      "value": "Branch Code 474"
    },
    {
      "key": "AG475",
      "value": "Branch Code 475"
    },
    {
      "key": "AG476",
      "value": "Branch Code 476"
    },
    {
      "key": "AG477",
      "value": "Branch Code 477"
    },
    {
      "key": "AG478",
      "value": "Branch Code 478"
    },
    {
      "key": "AG479",
      "value": "Branch Code 479"
    },
    {
      "key": "AG480",
      "value": "Branch Code 480"
    },
    {
      "key": "AG481",
      "value": "Branch Code 481"
    },
    {
      "key": "AG482",
      "value": "Branch Code 482"
    },
    {
      "key": "AG483",
      "value": "Branch Code 483"
    },
    {
      "key": "AG484",
      "value": "Branch Code 484"
    },
    {
      "key": "AG485",
      "value": "Branch Code 485"
    },
    {
      "key": "AG486",
      "value": "Branch Code 486"
    },
    {
      "key": "AG487",
      "value": "Branch Code 487"
    },
    {
      "key": "AG488",
      "value": "Branch Code 488"
    },
    {
      "key": "AG489",
      "value": "Branch Code 489"
    },
    {
      "key": "AG490",
      "value": "Branch Code 490"
    },
    {
      "key": "AG491",
      "value": "Branch Code 491"
    },
    {
      "key": "AG492",
      "value": "Branch Code 492"
    },
    {
      "key": "AG493",
      "value": "Branch Code 493"
    },
    {
      "key": "AG494",
      "value": "Branch Code 494"
    },
    {
      "key": "AG495",
      "value": "Branch Code 495"
    },
    {
      "key": "AG496",
      "value": "Branch Code 496"
    },
    {
      "key": "AG497",
      "value": "Branch Code 497"
    },
    {
      "key": "AG498",
      "value": "Branch Code 498"
    },
    {
      "key": "AG499",
      "value": "Branch Code 499"
    },
    {
      "key": "AG500",
      "value": "Branch Code 500"
    },
    {
      "key": "AG501",
      "value": "Branch Code 501"
    },
    {
      "key": "AG502",
      "value": "Branch Code 502"
    },
    {
      "key": "AG503",
      "value": "Branch Code 503"
    },
    {
      "key": "AG504",
      "value": "Branch Code 504"
    },
    {
      "key": "AG505",
      "value": "Branch Code 505"
    },
    {
      "key": "AG506",
      "value": "Branch Code 506"
    },
    {
      "key": "AG507",
      "value": "Branch Code 507"
    },
    {
      "key": "AG508",
      "value": "Branch Code 508"
    },
    {
      "key": "AG509",
      "value": "Branch Code 509"
    },
    {
      "key": "AG510",
      "value": "Branch Code 510"
    },
    {
      "key": "AG511",
      "value": "Branch Code 511"
    },
    {
      "key": "AG512",
      "value": "Branch Code 512"
    },
    {
      "key": "AG513",
      "value": "Branch Code 513"
    },
    {
      "key": "AG514",
      "value": "Branch Code 514"
    },
    {
      "key": "AG515",
      "value": "Branch Code 515"
    },
    {
      "key": "AG516",
      "value": "Branch Code 516"
    },
    {
      "key": "AG517",
      "value": "Branch Code 517"
    },
    {
      "key": "AG518",
      "value": "Branch Code 518"
    },
    {
      "key": "AG519",
      "value": "Branch Code 519"
    },
    {
      "key": "AG520",
      "value": "Branch Code 520"
    },
    {
      "key": "AG521",
      "value": "Branch Code 521"
    },
    {
      "key": "AG522",
      "value": "Branch Code 522"
    },
    {
      "key": "AG523",
      "value": "Branch Code 523"
    },
    {
      "key": "AG524",
      "value": "Branch Code 524"
    },
    {
      "key": "AG525",
      "value": "Branch Code 525"
    },
    {
      "key": "AG526",
      "value": "Branch Code 526"
    },
    {
      "key": "AG527",
      "value": "Branch Code 527"
    },
    {
      "key": "AG528",
      "value": "Branch Code 528"
    },
    {
      "key": "AG529",
      "value": "Branch Code 529"
    },
    {
      "key": "AG530",
      "value": "Branch Code 530"
    },
    {
      "key": "AG531",
      "value": "Branch Code 531"
    },
    {
      "key": "AG532",
      "value": "Branch Code 532"
    },
    {
      "key": "AG533",
      "value": "Branch Code 533"
    },
    {
      "key": "AG534",
      "value": "Branch Code 534"
    },
    {
      "key": "AG535",
      "value": "Branch Code 535"
    },
    {
      "key": "AG536",
      "value": "Branch Code 536"
    },
    {
      "key": "AG537",
      "value": "Branch Code 537"
    },
    {
      "key": "AG538",
      "value": "Branch Code 538"
    },
    {
      "key": "AG539",
      "value": "Branch Code 539"
    },
    {
      "key": "AG540",
      "value": "Branch Code 540"
    },
    {
      "key": "AG541",
      "value": "Branch Code 541"
    },
    {
      "key": "AG542",
      "value": "Branch Code 542"
    },
    {
      "key": "AG543",
      "value": "Branch Code 543"
    },
    {
      "key": "AG544",
      "value": "Branch Code 544"
    },
    {
      "key": "AG545",
      "value": "Branch Code 545"
    },
    {
      "key": "AG546",
      "value": "Branch Code 546"
    },
    {
      "key": "AG547",
      "value": "Branch Code 547"
    },
    {
      "key": "AG548",
      "value": "Branch Code 548"
    },
    {
      "key": "AG549",
      "value": "Branch Code 549"
    },
    {
      "key": "AG550",
      "value": "Branch Code 550"
    },
    {
      "key": "AG551",
      "value": "Branch Code 551"
    },
    {
      "key": "AG552",
      "value": "Branch Code 552"
    },
    {
      "key": "AG553",
      "value": "Branch Code 553"
    },
    {
      "key": "AG554",
      "value": "Branch Code 554"
    },
    {
      "key": "AG555",
      "value": "Branch Code 555"
    },
    {
      "key": "AG556",
      "value": "Branch Code 556"
    },
    {
      "key": "AG557",
      "value": "Branch Code 557"
    },
    {
      "key": "AG558",
      "value": "Branch Code 558"
    },
    {
      "key": "AG559",
      "value": "Branch Code 559"
    },
    {
      "key": "AG560",
      "value": "Branch Code 560"
    },
    {
      "key": "AG561",
      "value": "Branch Code 561"
    },
    {
      "key": "AG562",
      "value": "Branch Code 562"
    },
    {
      "key": "AG563",
      "value": "Branch Code 563"
    },
    {
      "key": "AG564",
      "value": "Branch Code 564"
    },
    {
      "key": "AG565",
      "value": "Branch Code 565"
    },
    {
      "key": "AG566",
      "value": "Branch Code 566"
    },
    {
      "key": "AG567",
      "value": "Branch Code 567"
    },
    {
      "key": "AG568",
      "value": "Branch Code 568"
    },
    {
      "key": "AG569",
      "value": "Branch Code 569"
    },
    {
      "key": "AG570",
      "value": "Branch Code 570"
    },
    {
      "key": "AG571",
      "value": "Branch Code 571"
    },
    {
      "key": "AG572",
      "value": "Branch Code 572"
    },
    {
      "key": "AG573",
      "value": "Branch Code 573"
    },
    {
      "key": "AG574",
      "value": "Branch Code 574"
    },
    {
      "key": "AG575",
      "value": "Branch Code 575"
    },
    {
      "key": "AG576",
      "value": "Branch Code 576"
    },
    {
      "key": "AG577",
      "value": "Branch Code 577"
    },
    {
      "key": "AG578",
      "value": "Branch Code 578"
    },
    {
      "key": "AG579",
      "value": "Branch Code 579"
    },
    {
      "key": "AG580",
      "value": "Branch Code 580"
    },
    {
      "key": "AG581",
      "value": "Branch Code 581"
    },
    {
      "key": "AG582",
      "value": "Branch Code 582"
    },
    {
      "key": "AG583",
      "value": "Branch Code 583"
    },
    {
      "key": "AG584",
      "value": "Branch Code 584"
    },
    {
      "key": "AG585",
      "value": "Branch Code 585"
    },
    {
      "key": "AG586",
      "value": "Branch Code 586"
    },
    {
      "key": "AG587",
      "value": "Branch Code 587"
    },
    {
      "key": "AG588",
      "value": "Branch Code 588"
    },
    {
      "key": "AG589",
      "value": "Branch Code 589"
    },
    {
      "key": "AG590",
      "value": "Branch Code 590"
    },
    {
      "key": "AG591",
      "value": "Branch Code 591"
    },
    {
      "key": "AG592",
      "value": "Branch Code 592"
    },
    {
      "key": "AG593",
      "value": "Branch Code 593"
    },
    {
      "key": "AG594",
      "value": "Branch Code 594"
    },
    {
      "key": "AG595",
      "value": "Branch Code 595"
    },
    {
      "key": "AG596",
      "value": "Branch Code 596"
    },
    {
      "key": "AG597",
      "value": "Branch Code 597"
    },
    {
      "key": "AG598",
      "value": "Branch Code 598"
    },
    {
      "key": "AG599",
      "value": "Branch Code 599"
    },
    {
      "key": "AG600",
      "value": "Branch Code 600"
    },
    {
      "key": "AG601",
      "value": "Branch Code 601"
    },
    {
      "key": "AG602",
      "value": "Branch Code 602"
    },
    {
      "key": "AG603",
      "value": "Branch Code 603"
    },
    {
      "key": "AG604",
      "value": "Branch Code 604"
    },
    {
      "key": "AG605",
      "value": "Branch Code 605"
    },
    {
      "key": "AG606",
      "value": "Branch Code 606"
    },
    {
      "key": "AG607",
      "value": "Branch Code 607"
    },
    {
      "key": "AG608",
      "value": "Branch Code 608"
    },
    {
      "key": "AG609",
      "value": "Branch Code 609"
    },
    {
      "key": "AG610",
      "value": "Branch Code 610"
    },
    {
      "key": "AG611",
      "value": "Branch Code 611"
    },
    {
      "key": "AG612",
      "value": "Branch Code 612"
    },
    {
      "key": "AG613",
      "value": "Branch Code 613"
    },
    {
      "key": "AG614",
      "value": "Branch Code 614"
    },
    {
      "key": "AG615",
      "value": "Branch Code 615"
    },
    {
      "key": "AG616",
      "value": "Branch Code 616"
    },
    {
      "key": "AG617",
      "value": "Branch Code 617"
    },
    {
      "key": "AG618",
      "value": "Branch Code 618"
    },
    {
      "key": "AG619",
      "value": "Branch Code 619"
    },
    {
      "key": "AG620",
      "value": "Branch Code 620"
    },
    {
      "key": "AG621",
      "value": "Branch Code 621"
    },
    {
      "key": "AG622",
      "value": "Branch Code 622"
    },
    {
      "key": "AG623",
      "value": "Branch Code 623"
    },
    {
      "key": "AG624",
      "value": "Branch Code 624"
    },
    {
      "key": "AG625",
      "value": "Branch Code 625"
    },
    {
      "key": "AG626",
      "value": "Branch Code 626"
    },
    {
      "key": "AG627",
      "value": "Branch Code 627"
    },
    {
      "key": "AG628",
      "value": "Branch Code 628"
    },
    {
      "key": "AG629",
      "value": "Branch Code 629"
    },
    {
      "key": "AG630",
      "value": "Branch Code 630"
    },
    {
      "key": "AG631",
      "value": "Branch Code 631"
    },
    {
      "key": "AG632",
      "value": "Branch Code 632"
    },
    {
      "key": "AG633",
      "value": "Branch Code 633"
    },
    {
      "key": "AG634",
      "value": "Branch Code 634"
    },
    {
      "key": "AG635",
      "value": "Branch Code 635"
    },
    {
      "key": "AG636",
      "value": "Branch Code 636"
    },
    {
      "key": "AG637",
      "value": "Branch Code 637"
    },
    {
      "key": "AG638",
      "value": "Branch Code 638"
    },
    {
      "key": "AG639",
      "value": "Branch Code 639"
    },
    {
      "key": "AG640",
      "value": "Branch Code 640"
    },
    {
      "key": "AG641",
      "value": "Branch Code 641"
    },
    {
      "key": "AG642",
      "value": "Branch Code 642"
    },
    {
      "key": "AG643",
      "value": "Branch Code 643"
    },
    {
      "key": "AG644",
      "value": "Branch Code 644"
    },
    {
      "key": "AG645",
      "value": "Branch Code 645"
    },
    {
      "key": "AG646",
      "value": "Branch Code 646"
    },
    {
      "key": "AG647",
      "value": "Branch Code 647"
    },
    {
      "key": "AG648",
      "value": "Branch Code 648"
    },
    {
      "key": "AG649",
      "value": "Branch Code 649"
    },
    {
      "key": "AG650",
      "value": "Branch Code 650"
    },
    {
      "key": "AG651",
      "value": "Branch Code 651"
    },
    {
      "key": "AG652",
      "value": "Branch Code 652"
    },
    {
      "key": "AG653",
      "value": "Branch Code 653"
    },
    {
      "key": "AG654",
      "value": "Branch Code 654"
    },
    {
      "key": "AG655",
      "value": "Branch Code 655"
    },
    {
      "key": "AG656",
      "value": "Branch Code 656"
    },
    {
      "key": "AG657",
      "value": "Branch Code 657"
    },
    {
      "key": "AG658",
      "value": "Branch Code 658"
    },
    {
      "key": "AG659",
      "value": "Branch Code 659"
    },
    {
      "key": "AG660",
      "value": "Branch Code 660"
    },
    {
      "key": "AG661",
      "value": "Branch Code 661"
    },
    {
      "key": "AG662",
      "value": "Branch Code 662"
    },
    {
      "key": "AG663",
      "value": "Branch Code 663"
    },
    {
      "key": "AG664",
      "value": "Branch Code 664"
    },
    {
      "key": "AG665",
      "value": "Branch Code 665"
    },
    {
      "key": "AG666",
      "value": "Branch Code 666"
    },
    {
      "key": "AG667",
      "value": "Branch Code 667"
    },
    {
      "key": "AG668",
      "value": "Branch Code 668"
    },
    {
      "key": "AG669",
      "value": "Branch Code 669"
    },
    {
      "key": "AG670",
      "value": "Branch Code 670"
    },
    {
      "key": "AG671",
      "value": "Branch Code 671"
    },
    {
      "key": "AG672",
      "value": "Branch Code 672"
    },
    {
      "key": "AG673",
      "value": "Branch Code 673"
    },
    {
      "key": "AG674",
      "value": "Branch Code 674"
    },
    {
      "key": "AG675",
      "value": "Branch Code 675"
    },
    {
      "key": "AG676",
      "value": "Branch Code 676"
    },
    {
      "key": "AG677",
      "value": "Branch Code 677"
    },
    {
      "key": "AG678",
      "value": "Branch Code 678"
    },
    {
      "key": "AG679",
      "value": "Branch Code 679"
    },
    {
      "key": "AG680",
      "value": "Branch Code 680"
    },
    {
      "key": "AG681",
      "value": "Branch Code 681"
    },
    {
      "key": "AG682",
      "value": "Branch Code 682"
    },
    {
      "key": "AG683",
      "value": "Branch Code 683"
    },
    {
      "key": "AG684",
      "value": "Branch Code 684"
    },
    {
      "key": "AG685",
      "value": "Branch Code 685"
    },
    {
      "key": "AG686",
      "value": "Branch Code 686"
    },
    {
      "key": "AG687",
      "value": "Branch Code 687"
    },
    {
      "key": "AG688",
      "value": "Branch Code 688"
    },
    {
      "key": "AG689",
      "value": "Branch Code 689"
    },
    {
      "key": "AG690",
      "value": "Branch Code 690"
    },
    {
      "key": "AG691",
      "value": "Branch Code 691"
    },
    {
      "key": "AG692",
      "value": "Branch Code 692"
    },
    {
      "key": "AG693",
      "value": "Branch Code 693"
    },
    {
      "key": "AG694",
      "value": "Branch Code 694"
    },
    {
      "key": "AG695",
      "value": "Branch Code 695"
    },
    {
      "key": "AG696",
      "value": "Branch Code 696"
    },
    {
      "key": "AG697",
      "value": "Branch Code 697"
    },
    {
      "key": "AG698",
      "value": "Branch Code 698"
    },
    {
      "key": "AG699",
      "value": "Branch Code 699"
    },
    {
      "key": "AG700",
      "value": "Branch Code 700"
    },
    {
      "key": "AG701",
      "value": "Branch Code 701"
    },
    {
      "key": "AG702",
      "value": "Branch Code 702"
    },
    {
      "key": "AG703",
      "value": "Branch Code 703"
    },
    {
      "key": "AG704",
      "value": "Branch Code 704"
    },
    {
      "key": "AG705",
      "value": "Branch Code 705"
    },
    {
      "key": "AG706",
      "value": "Branch Code 706"
    },
    {
      "key": "AG707",
      "value": "Branch Code 707"
    },
    {
      "key": "AG708",
      "value": "Branch Code 708"
    },
    {
      "key": "AG709",
      "value": "Branch Code 709"
    },
    {
      "key": "AG710",
      "value": "Branch Code 710"
    },
    {
      "key": "AG711",
      "value": "Branch Code 711"
    },
    {
      "key": "AG712",
      "value": "Branch Code 712"
    },
    {
      "key": "AG713",
      "value": "Branch Code 713"
    },
    {
      "key": "AG714",
      "value": "Branch Code 714"
    },
    {
      "key": "AG715",
      "value": "Branch Code 715"
    },
    {
      "key": "AG716",
      "value": "Branch Code 716"
    },
    {
      "key": "AG717",
      "value": "Branch Code 717"
    },
    {
      "key": "AG718",
      "value": "Branch Code 718"
    },
    {
      "key": "AG719",
      "value": "Branch Code 719"
    },
    {
      "key": "AG720",
      "value": "Branch Code 720"
    },
    {
      "key": "AG721",
      "value": "Branch Code 721"
    },
    {
      "key": "AG722",
      "value": "Branch Code 722"
    },
    {
      "key": "AG723",
      "value": "Branch Code 723"
    },
    {
      "key": "AG724",
      "value": "Branch Code 724"
    },
    {
      "key": "AG725",
      "value": "Branch Code 725"
    },
    {
      "key": "AG726",
      "value": "Branch Code 726"
    },
    {
      "key": "AG727",
      "value": "Branch Code 727"
    },
    {
      "key": "AG728",
      "value": "Branch Code 728"
    },
    {
      "key": "AG729",
      "value": "Branch Code 729"
    },
    {
      "key": "AG730",
      "value": "Branch Code 730"
    },
    {
      "key": "AG731",
      "value": "Branch Code 731"
    },
    {
      "key": "AG732",
      "value": "Branch Code 732"
    },
    {
      "key": "AG733",
      "value": "Branch Code 733"
    },
    {
      "key": "AG734",
      "value": "Branch Code 734"
    },
    {
      "key": "AG735",
      "value": "Branch Code 735"
    },
    {
      "key": "AG736",
      "value": "Branch Code 736"
    },
    {
      "key": "AG737",
      "value": "Branch Code 737"
    },
    {
      "key": "AG738",
      "value": "Branch Code 738"
    },
    {
      "key": "AG739",
      "value": "Branch Code 739"
    },
    {
      "key": "AG740",
      "value": "Branch Code 740"
    },
    {
      "key": "AG741",
      "value": "Branch Code 741"
    },
    {
      "key": "AG742",
      "value": "Branch Code 742"
    },
    {
      "key": "AG743",
      "value": "Branch Code 743"
    },
    {
      "key": "AG744",
      "value": "Branch Code 744"
    },
    {
      "key": "AG745",
      "value": "Branch Code 745"
    },
    {
      "key": "AG746",
      "value": "Branch Code 746"
    },
    {
      "key": "AG747",
      "value": "Branch Code 747"
    },
    {
      "key": "AG748",
      "value": "Branch Code 748"
    },
    {
      "key": "AG749",
      "value": "Branch Code 749"
    },
    {
      "key": "AG750",
      "value": "Branch Code 750"
    },
    {
      "key": "AG751",
      "value": "Branch Code 751"
    },
    {
      "key": "AG752",
      "value": "Branch Code 752"
    },
    {
      "key": "AG753",
      "value": "Branch Code 753"
    },
    {
      "key": "AG754",
      "value": "Branch Code 754"
    },
    {
      "key": "AG755",
      "value": "Branch Code 755"
    },
    {
      "key": "AG756",
      "value": "Branch Code 756"
    },
    {
      "key": "AG757",
      "value": "Branch Code 757"
    },
    {
      "key": "AG758",
      "value": "Branch Code 758"
    },
    {
      "key": "AG759",
      "value": "Branch Code 759"
    },
    {
      "key": "AG760",
      "value": "Branch Code 760"
    },
    {
      "key": "AG761",
      "value": "Branch Code 761"
    },
    {
      "key": "AG762",
      "value": "Branch Code 762"
    },
    {
      "key": "AG763",
      "value": "Branch Code 763"
    },
    {
      "key": "AG764",
      "value": "Branch Code 764"
    },
    {
      "key": "AG765",
      "value": "Branch Code 765"
    },
    {
      "key": "AG766",
      "value": "Branch Code 766"
    },
    {
      "key": "AG767",
      "value": "Branch Code 767"
    },
    {
      "key": "AG768",
      "value": "Branch Code 768"
    },
    {
      "key": "AG769",
      "value": "Branch Code 769"
    },
    {
      "key": "AG770",
      "value": "Branch Code 770"
    },
    {
      "key": "AG771",
      "value": "Branch Code 771"
    },
    {
      "key": "AG772",
      "value": "Branch Code 772"
    },
    {
      "key": "AG773",
      "value": "Branch Code 773"
    },
    {
      "key": "AG774",
      "value": "Branch Code 774"
    },
    {
      "key": "AG775",
      "value": "Branch Code 775"
    },
    {
      "key": "AG776",
      "value": "Branch Code 776"
    },
    {
      "key": "AG777",
      "value": "Branch Code 777"
    },
    {
      "key": "AG778",
      "value": "Branch Code 778"
    },
    {
      "key": "AG779",
      "value": "Branch Code 779"
    },
    {
      "key": "AG780",
      "value": "Branch Code 780"
    },
    {
      "key": "AG781",
      "value": "Branch Code 781"
    },
    {
      "key": "AG782",
      "value": "Branch Code 782"
    },
    {
      "key": "AG783",
      "value": "Branch Code 783"
    },
    {
      "key": "AG784",
      "value": "Branch Code 784"
    },
    {
      "key": "AG785",
      "value": "Branch Code 785"
    },
    {
      "key": "AG786",
      "value": "Branch Code 786"
    },
    {
      "key": "AG787",
      "value": "Branch Code 787"
    },
    {
      "key": "AG788",
      "value": "Branch Code 788"
    },
    {
      "key": "AG789",
      "value": "Branch Code 789"
    },
    {
      "key": "AG790",
      "value": "Branch Code 790"
    },
    {
      "key": "AG791",
      "value": "Branch Code 791"
    },
    {
      "key": "AG792",
      "value": "Branch Code 792"
    },
    {
      "key": "AG793",
      "value": "Branch Code 793"
    },
    {
      "key": "AG794",
      "value": "Branch Code 794"
    },
    {
      "key": "AG795",
      "value": "Branch Code 795"
    },
    {
      "key": "AG796",
      "value": "Branch Code 796"
    },
    {
      "key": "AG797",
      "value": "Branch Code 797"
    },
    {
      "key": "AG798",
      "value": "Branch Code 798"
    },
    {
      "key": "AG799",
      "value": "Branch Code 799"
    },
    {
      "key": "AG800",
      "value": "Branch Code 800"
    },
    {
      "key": "AG801",
      "value": "Branch Code 801"
    },
    {
      "key": "AG802",
      "value": "Branch Code 802"
    },
    {
      "key": "AG803",
      "value": "Branch Code 803"
    },
    {
      "key": "AG804",
      "value": "Branch Code 804"
    },
    {
      "key": "AG805",
      "value": "Branch Code 805"
    },
    {
      "key": "AG806",
      "value": "Branch Code 806"
    },
    {
      "key": "AG807",
      "value": "Branch Code 807"
    },
    {
      "key": "AG808",
      "value": "Branch Code 808"
    },
    {
      "key": "AG809",
      "value": "Branch Code 809"
    },
    {
      "key": "AG810",
      "value": "Branch Code 810"
    },
    {
      "key": "AG811",
      "value": "Branch Code 811"
    },
    {
      "key": "AG812",
      "value": "Branch Code 812"
    },
    {
      "key": "AG813",
      "value": "Branch Code 813"
    },
    {
      "key": "AG814",
      "value": "Branch Code 814"
    },
    {
      "key": "AG815",
      "value": "Branch Code 815"
    },
    {
      "key": "AG816",
      "value": "Branch Code 816"
    },
    {
      "key": "AG817",
      "value": "Branch Code 817"
    },
    {
      "key": "AG818",
      "value": "Branch Code 818"
    },
    {
      "key": "AG819",
      "value": "Branch Code 819"
    },
    {
      "key": "AG820",
      "value": "Branch Code 820"
    },
    {
      "key": "AG821",
      "value": "Branch Code 821"
    },
    {
      "key": "AG822",
      "value": "Branch Code 822"
    },
    {
      "key": "AG823",
      "value": "Branch Code 823"
    },
    {
      "key": "AG824",
      "value": "Branch Code 824"
    },
    {
      "key": "AG825",
      "value": "Branch Code 825"
    },
    {
      "key": "AG826",
      "value": "Branch Code 826"
    },
    {
      "key": "AG827",
      "value": "Branch Code 827"
    },
    {
      "key": "AG828",
      "value": "Branch Code 828"
    },
    {
      "key": "AG829",
      "value": "Branch Code 829"
    },
    {
      "key": "AG830",
      "value": "Branch Code 830"
    },
    {
      "key": "AG831",
      "value": "Branch Code 831"
    },
    {
      "key": "AG832",
      "value": "Branch Code 832"
    },
    {
      "key": "AG833",
      "value": "Branch Code 833"
    },
    {
      "key": "AG834",
      "value": "Branch Code 834"
    },
    {
      "key": "AG835",
      "value": "Branch Code 835"
    },
    {
      "key": "AG836",
      "value": "Branch Code 836"
    },
    {
      "key": "AG837",
      "value": "Branch Code 837"
    },
    {
      "key": "AG838",
      "value": "Branch Code 838"
    },
    {
      "key": "AG839",
      "value": "Branch Code 839"
    },
    {
      "key": "AG840",
      "value": "Branch Code 840"
    },
    {
      "key": "AG841",
      "value": "Branch Code 841"
    },
    {
      "key": "AG842",
      "value": "Branch Code 842"
    },
    {
      "key": "AG843",
      "value": "Branch Code 843"
    },
    {
      "key": "AG844",
      "value": "Branch Code 844"
    },
    {
      "key": "AG845",
      "value": "Branch Code 845"
    },
    {
      "key": "AG846",
      "value": "Branch Code 846"
    },
    {
      "key": "AG847",
      "value": "Branch Code 847"
    },
    {
      "key": "AG848",
      "value": "Branch Code 848"
    },
    {
      "key": "AG849",
      "value": "Branch Code 849"
    },
    {
      "key": "AG850",
      "value": "Branch Code 850"
    },
    {
      "key": "AG851",
      "value": "Branch Code 851"
    },
    {
      "key": "AG852",
      "value": "Branch Code 852"
    },
    {
      "key": "AG853",
      "value": "Branch Code 853"
    },
    {
      "key": "AG854",
      "value": "Branch Code 854"
    },
    {
      "key": "AG855",
      "value": "Branch Code 855"
    },
    {
      "key": "AG856",
      "value": "Branch Code 856"
    },
    {
      "key": "AG857",
      "value": "Branch Code 857"
    },
    {
      "key": "AG858",
      "value": "Branch Code 858"
    },
    {
      "key": "AG859",
      "value": "Branch Code 859"
    },
    {
      "key": "AG860",
      "value": "Branch Code 860"
    },
    {
      "key": "AG861",
      "value": "Branch Code 861"
    },
    {
      "key": "AG862",
      "value": "Branch Code 862"
    },
    {
      "key": "AG863",
      "value": "Branch Code 863"
    },
    {
      "key": "AG864",
      "value": "Branch Code 864"
    },
    {
      "key": "AG865",
      "value": "Branch Code 865"
    },
    {
      "key": "AG866",
      "value": "Branch Code 866"
    },
    {
      "key": "AG867",
      "value": "Branch Code 867"
    },
    {
      "key": "AG868",
      "value": "Branch Code 868"
    },
    {
      "key": "AG869",
      "value": "Branch Code 869"
    },
    {
      "key": "AG870",
      "value": "Branch Code 870"
    },
    {
      "key": "AG871",
      "value": "Branch Code 871"
    },
    {
      "key": "AG872",
      "value": "Branch Code 872"
    },
    {
      "key": "AG873",
      "value": "Branch Code 873"
    },
    {
      "key": "AG874",
      "value": "Branch Code 874"
    },
    {
      "key": "AG875",
      "value": "Branch Code 875"
    },
    {
      "key": "AG876",
      "value": "Branch Code 876"
    },
    {
      "key": "AG877",
      "value": "Branch Code 877"
    },
    {
      "key": "AG878",
      "value": "Branch Code 878"
    },
    {
      "key": "AG879",
      "value": "Branch Code 879"
    },
    {
      "key": "AG880",
      "value": "Branch Code 880"
    },
    {
      "key": "AG881",
      "value": "Branch Code 881"
    },
    {
      "key": "AG882",
      "value": "Branch Code 882"
    },
    {
      "key": "AG883",
      "value": "Branch Code 883"
    },
    {
      "key": "AG884",
      "value": "Branch Code 884"
    },
    {
      "key": "AG885",
      "value": "Branch Code 885"
    },
    {
      "key": "AG886",
      "value": "Branch Code 886"
    },
    {
      "key": "AG887",
      "value": "Branch Code 887"
    },
    {
      "key": "AG888",
      "value": "Branch Code 888"
    },
    {
      "key": "AG889",
      "value": "Branch Code 889"
    },
    {
      "key": "AG890",
      "value": "Branch Code 890"
    },
    {
      "key": "AG891",
      "value": "Branch Code 891"
    },
    {
      "key": "AG892",
      "value": "Branch Code 892"
    },
    {
      "key": "AG893",
      "value": "Branch Code 893"
    },
    {
      "key": "AG894",
      "value": "Branch Code 894"
    },
    {
      "key": "AG895",
      "value": "Branch Code 895"
    },
    {
      "key": "AG896",
      "value": "Branch Code 896"
    },
    {
      "key": "AG897",
      "value": "Branch Code 897"
    },
    {
      "key": "AG898",
      "value": "Branch Code 898"
    },
    {
      "key": "AG899",
      "value": "Branch Code 899"
    },
    {
      "key": "AG900",
      "value": "Branch Code 900"
    },
    {
      "key": "AG901",
      "value": "Branch Code 901"
    },
    {
      "key": "AG902",
      "value": "Branch Code 902"
    },
    {
      "key": "AG903",
      "value": "Branch Code 903"
    },
    {
      "key": "AG904",
      "value": "Branch Code 904"
    },
    {
      "key": "AG905",
      "value": "Branch Code 905"
    },
    {
      "key": "AG906",
      "value": "Branch Code 906"
    },
    {
      "key": "AG907",
      "value": "Branch Code 907"
    },
    {
      "key": "AG908",
      "value": "Branch Code 908"
    },
    {
      "key": "AG909",
      "value": "Branch Code 909"
    },
    {
      "key": "AG910",
      "value": "Branch Code 910"
    },
    {
      "key": "AG911",
      "value": "Branch Code 911"
    },
    {
      "key": "AG912",
      "value": "Branch Code 912"
    },
    {
      "key": "AG913",
      "value": "Branch Code 913"
    },
    {
      "key": "AG914",
      "value": "Branch Code 914"
    },
    {
      "key": "AG915",
      "value": "Branch Code 915"
    },
    {
      "key": "AG916",
      "value": "Branch Code 916"
    },
    {
      "key": "AG917",
      "value": "Branch Code 917"
    },
    {
      "key": "AG918",
      "value": "Branch Code 918"
    },
    {
      "key": "AG919",
      "value": "Branch Code 919"
    },
    {
      "key": "AG920",
      "value": "Branch Code 920"
    },
    {
      "key": "AG921",
      "value": "Branch Code 921"
    },
    {
      "key": "AG922",
      "value": "Branch Code 922"
    },
    {
      "key": "AG923",
      "value": "Branch Code 923"
    },
    {
      "key": "AG924",
      "value": "Branch Code 924"
    },
    {
      "key": "AG925",
      "value": "Branch Code 925"
    },
    {
      "key": "AG926",
      "value": "Branch Code 926"
    },
    {
      "key": "AG927",
      "value": "Branch Code 927"
    },
    {
      "key": "AG928",
      "value": "Branch Code 928"
    },
    {
      "key": "AG929",
      "value": "Branch Code 929"
    },
    {
      "key": "AG930",
      "value": "Branch Code 930"
    },
    {
      "key": "AG931",
      "value": "Branch Code 931"
    },
    {
      "key": "AG932",
      "value": "Branch Code 932"
    },
    {
      "key": "AG933",
      "value": "Branch Code 933"
    },
    {
      "key": "AG934",
      "value": "Branch Code 934"
    },
    {
      "key": "AG935",
      "value": "Branch Code 935"
    },
    {
      "key": "AG936",
      "value": "Branch Code 936"
    },
    {
      "key": "AG937",
      "value": "Branch Code 937"
    },
    {
      "key": "AG938",
      "value": "Branch Code 938"
    },
    {
      "key": "AG939",
      "value": "Branch Code 939"
    },
    {
      "key": "AG940",
      "value": "Branch Code 940"
    },
    {
      "key": "AG941",
      "value": "Branch Code 941"
    },
    {
      "key": "AG942",
      "value": "Branch Code 942"
    },
    {
      "key": "AG943",
      "value": "Branch Code 943"
    },
    {
      "key": "AG944",
      "value": "Branch Code 944"
    },
    {
      "key": "AG945",
      "value": "Branch Code 945"
    },
    {
      "key": "AG946",
      "value": "Branch Code 946"
    },
    {
      "key": "AG947",
      "value": "Branch Code 947"
    },
    {
      "key": "AG948",
      "value": "Branch Code 948"
    },
    {
      "key": "AG949",
      "value": "Branch Code 949"
    },
    {
      "key": "AG950",
      "value": "Branch Code 950"
    },
    {
      "key": "AG951",
      "value": "Branch Code 951"
    },
    {
      "key": "AG952",
      "value": "Branch Code 952"
    },
    {
      "key": "AG953",
      "value": "Branch Code 953"
    },
    {
      "key": "AG954",
      "value": "Branch Code 954"
    },
    {
      "key": "AG955",
      "value": "Branch Code 955"
    },
    {
      "key": "AG956",
      "value": "Branch Code 956"
    },
    {
      "key": "AG957",
      "value": "Branch Code 957"
    },
    {
      "key": "AG958",
      "value": "Branch Code 958"
    },
    {
      "key": "AG959",
      "value": "Branch Code 959"
    },
    {
      "key": "AG960",
      "value": "Branch Code 960"
    },
    {
      "key": "AG961",
      "value": "Branch Code 961"
    },
    {
      "key": "AG962",
      "value": "Branch Code 962"
    },
    {
      "key": "AG963",
      "value": "Branch Code 963"
    },
    {
      "key": "AG964",
      "value": "Branch Code 964"
    },
    {
      "key": "AG965",
      "value": "Branch Code 965"
    },
    {
      "key": "AG966",
      "value": "Branch Code 966"
    },
    {
      "key": "AG967",
      "value": "Branch Code 967"
    },
    {
      "key": "AG968",
      "value": "Branch Code 968"
    },
    {
      "key": "AG969",
      "value": "Branch Code 969"
    },
    {
      "key": "AG970",
      "value": "Branch Code 970"
    },
    {
      "key": "AG971",
      "value": "Branch Code 971"
    },
    {
      "key": "AG972",
      "value": "Branch Code 972"
    },
    {
      "key": "AG973",
      "value": "Branch Code 973"
    },
    {
      "key": "AG974",
      "value": "Branch Code 974"
    },
    {
      "key": "AG975",
      "value": "Branch Code 975"
    },
    {
      "key": "AG976",
      "value": "Branch Code 976"
    },
    {
      "key": "AG977",
      "value": "Branch Code 977"
    },
    {
      "key": "AG978",
      "value": "Branch Code 978"
    },
    {
      "key": "AG979",
      "value": "Branch Code 979"
    },
    {
      "key": "AG980",
      "value": "Branch Code 980"
    },
    {
      "key": "AG981",
      "value": "Branch Code 981"
    },
    {
      "key": "AG982",
      "value": "Branch Code 982"
    },
    {
      "key": "AG983",
      "value": "Branch Code 983"
    },
    {
      "key": "AG984",
      "value": "Branch Code 984"
    },
    {
      "key": "AG985",
      "value": "Branch Code 985"
    },
    {
      "key": "AG986",
      "value": "Branch Code 986"
    },
    {
      "key": "AG987",
      "value": "Branch Code 987"
    },
    {
      "key": "AG988",
      "value": "Branch Code 988"
    },
    {
      "key": "AG989",
      "value": "Branch Code 989"
    },
    {
      "key": "AG990",
      "value": "Branch Code 990"
    },
    {
      "key": "AG991",
      "value": "Branch Code 991"
    },
    {
      "key": "AG992",
      "value": "Branch Code 992"
    },
    {
      "key": "AG993",
      "value": "Branch Code 993"
    },
    {
      "key": "AG994",
      "value": "Branch Code 994"
    },
    {
      "key": "AG995",
      "value": "Branch Code 995"
    },
    {
      "key": "AG996",
      "value": "Branch Code 996"
    },
    {
      "key": "AG997",
      "value": "Branch Code 997"
    },
    {
      "key": "AG998",
      "value": "Branch Code 998"
    },
    {
      "key": "AG999",
      "value": "Branch Code 999"
    },
    {
      "key": "AUC01",
      "value": "AUC01"
    },
    {
      "key": "BR001",
      "value": "Branch Code 001"
    },
    {
      "key": "BR002",
      "value": "Branch Code 002"
    },
    {
      "key": "BR003",
      "value": "Branch Code 003"
    },
    {
      "key": "BR004",
      "value": "Branch Code 004"
    },
    {
      "key": "BR005",
      "value": "Branch Code 005"
    },
    {
      "key": "BR006",
      "value": "Branch Code 006"
    },
    {
      "key": "BR007",
      "value": "Branch Code 007"
    },
    {
      "key": "BR008",
      "value": "Branch Code 008"
    },
    {
      "key": "BR009",
      "value": "Branch Code 009"
    },
    {
      "key": "BR010",
      "value": "Branch Code 010"
    },
    {
      "key": "BR011",
      "value": "Branch Code 011"
    },
    {
      "key": "BR012",
      "value": "Branch Code 012"
    },
    {
      "key": "BR013",
      "value": "Branch Code 013"
    },
    {
      "key": "BR014",
      "value": "Branch Code 014"
    },
    {
      "key": "BR015",
      "value": "Branch Code 015"
    },
    {
      "key": "BR016",
      "value": "Branch Code 016"
    },
    {
      "key": "BR017",
      "value": "Branch Code 017"
    },
    {
      "key": "BR018",
      "value": "Branch Code 018"
    },
    {
      "key": "BR019",
      "value": "Branch Code 019"
    },
    {
      "key": "BR020",
      "value": "Branch Code 020"
    },
    {
      "key": "BR021",
      "value": "Branch Code 021"
    },
    {
      "key": "BR022",
      "value": "Branch Code 022"
    },
    {
      "key": "BR023",
      "value": "Branch Code 023"
    },
    {
      "key": "BR024",
      "value": "Branch Code 024"
    },
    {
      "key": "BR025",
      "value": "Branch Code 025"
    },
    {
      "key": "BR026",
      "value": "Branch Code 026"
    },
    {
      "key": "BR027",
      "value": "Branch Code 027"
    },
    {
      "key": "BR028",
      "value": "Branch Code 028"
    },
    {
      "key": "BR029",
      "value": "Branch Code 029"
    },
    {
      "key": "BR030",
      "value": "Branch Code 030"
    },
    {
      "key": "BR031",
      "value": "Branch Code 031"
    },
    {
      "key": "BR032",
      "value": "Branch Code 032"
    },
    {
      "key": "BR033",
      "value": "Branch Code 033"
    },
    {
      "key": "BR034",
      "value": "Branch Code 034"
    },
    {
      "key": "BR035",
      "value": "Branch Code 035"
    },
    {
      "key": "BR036",
      "value": "Branch Code 036"
    },
    {
      "key": "BR037",
      "value": "Branch Code 037"
    },
    {
      "key": "BR038",
      "value": "Branch Code 038"
    },
    {
      "key": "BR039",
      "value": "Branch Code 039"
    },
    {
      "key": "BR040",
      "value": "Branch Code 040"
    },
    {
      "key": "BR041",
      "value": "Branch Code 041"
    },
    {
      "key": "BR042",
      "value": "Branch Code 042"
    },
    {
      "key": "BR043",
      "value": "Branch Code 043"
    },
    {
      "key": "BR044",
      "value": "Branch Code 044"
    },
    {
      "key": "BR045",
      "value": "Branch Code 045"
    },
    {
      "key": "BR046",
      "value": "Branch Code 046"
    },
    {
      "key": "BR047",
      "value": "Branch Code 047"
    },
    {
      "key": "BR048",
      "value": "Branch Code 048"
    },
    {
      "key": "BR049",
      "value": "Branch Code 049"
    },
    {
      "key": "BR050",
      "value": "Branch Code 050"
    },
    {
      "key": "BR051",
      "value": "Branch Code 051"
    },
    {
      "key": "BR052",
      "value": "Branch Code 052"
    },
    {
      "key": "BR053",
      "value": "Branch Code 053"
    },
    {
      "key": "BR054",
      "value": "Branch Code 054"
    },
    {
      "key": "BR055",
      "value": "Branch Code 055"
    },
    {
      "key": "BR056",
      "value": "Branch Code 056"
    },
    {
      "key": "BR057",
      "value": "Branch Code 057"
    },
    {
      "key": "BR058",
      "value": "Branch Code 058"
    },
    {
      "key": "BR059",
      "value": "Branch Code 059"
    },
    {
      "key": "BR060",
      "value": "Branch Code 060"
    },
    {
      "key": "BR061",
      "value": "Branch Code 061"
    },
    {
      "key": "BR062",
      "value": "Branch Code 062"
    },
    {
      "key": "BR063",
      "value": "Branch Code 063"
    },
    {
      "key": "BR064",
      "value": "Branch Code 064"
    },
    {
      "key": "BR065",
      "value": "Branch Code 065"
    },
    {
      "key": "BR066",
      "value": "Branch Code 066"
    },
    {
      "key": "BR067",
      "value": "Branch Code 067"
    },
    {
      "key": "BR068",
      "value": "Branch Code 068"
    },
    {
      "key": "BR069",
      "value": "Branch Code 069"
    },
    {
      "key": "BR070",
      "value": "Branch Code 070"
    },
    {
      "key": "BR071",
      "value": "Branch Code 071"
    },
    {
      "key": "BR072",
      "value": "Branch Code 072"
    },
    {
      "key": "BR073",
      "value": "Branch Code 073"
    },
    {
      "key": "BR074",
      "value": "Branch Code 074"
    },
    {
      "key": "BR075",
      "value": "Branch Code 075"
    },
    {
      "key": "BR076",
      "value": "Branch Code 076"
    },
    {
      "key": "BR077",
      "value": "Branch Code 077"
    },
    {
      "key": "BR078",
      "value": "Branch Code 078"
    },
    {
      "key": "BR079",
      "value": "Branch Code 079"
    },
    {
      "key": "BR080",
      "value": "Branch Code 080"
    },
    {
      "key": "BR081",
      "value": "Branch Code 081"
    },
    {
      "key": "BR082",
      "value": "Branch Code 082"
    },
    {
      "key": "BR083",
      "value": "Branch Code 083"
    },
    {
      "key": "BR084",
      "value": "Branch Code 084"
    },
    {
      "key": "BR085",
      "value": "Branch Code 085"
    },
    {
      "key": "BR086",
      "value": "Branch Code 086"
    },
    {
      "key": "BR087",
      "value": "Branch Code 087"
    },
    {
      "key": "BR088",
      "value": "Branch Code 088"
    },
    {
      "key": "BR089",
      "value": "Branch Code 089"
    },
    {
      "key": "BR090",
      "value": "Branch Code 090"
    },
    {
      "key": "BR091",
      "value": "Branch Code 091"
    },
    {
      "key": "BR092",
      "value": "Branch Code 092"
    },
    {
      "key": "BR093",
      "value": "Branch Code 093"
    },
    {
      "key": "BR094",
      "value": "Branch Code 094"
    },
    {
      "key": "BR095",
      "value": "Branch Code 095"
    },
    {
      "key": "BR096",
      "value": "Branch Code 096"
    },
    {
      "key": "BR097",
      "value": "Branch Code 097"
    },
    {
      "key": "BR098",
      "value": "Branch Code 098"
    },
    {
      "key": "BR099",
      "value": "Branch Code 099"
    },
    {
      "key": "BR100",
      "value": "Branch Code 100"
    },
    {
      "key": "BR101",
      "value": "Branch Code 101"
    },
    {
      "key": "BR102",
      "value": "Branch Code 102"
    },
    {
      "key": "BR103",
      "value": "Branch Code 103"
    },
    {
      "key": "BR104",
      "value": "Branch Code 104"
    },
    {
      "key": "BR105",
      "value": "Branch Code 105"
    },
    {
      "key": "BR106",
      "value": "Branch Code 106"
    },
    {
      "key": "BR107",
      "value": "Branch Code 107"
    },
    {
      "key": "BR108",
      "value": "Branch Code 108"
    },
    {
      "key": "BR109",
      "value": "Branch Code 109"
    },
    {
      "key": "BR110",
      "value": "Branch Code 110"
    },
    {
      "key": "BR111",
      "value": "Branch Code 111"
    },
    {
      "key": "BR112",
      "value": "Branch Code 112"
    },
    {
      "key": "BR113",
      "value": "Branch Code 113"
    },
    {
      "key": "BR114",
      "value": "Branch Code 114"
    },
    {
      "key": "BR115",
      "value": "Branch Code 115"
    },
    {
      "key": "BR116",
      "value": "Branch Code 116"
    },
    {
      "key": "BR117",
      "value": "Branch Code 117"
    },
    {
      "key": "BR118",
      "value": "Branch Code 118"
    },
    {
      "key": "BR119",
      "value": "Branch Code 119"
    },
    {
      "key": "BR120",
      "value": "Branch Code 120"
    },
    {
      "key": "BR121",
      "value": "Branch Code 121"
    },
    {
      "key": "BR122",
      "value": "Branch Code 122"
    },
    {
      "key": "BR123",
      "value": "Branch Code 123"
    },
    {
      "key": "BR124",
      "value": "Branch Code 124"
    },
    {
      "key": "BR125",
      "value": "Branch Code 125"
    },
    {
      "key": "BR126",
      "value": "Branch Code 126"
    },
    {
      "key": "BR127",
      "value": "Branch Code 127"
    },
    {
      "key": "BR128",
      "value": "Branch Code 128"
    },
    {
      "key": "BR129",
      "value": "Branch Code 129"
    },
    {
      "key": "BR130",
      "value": "Branch Code 130"
    },
    {
      "key": "BR131",
      "value": "Branch Code 131"
    },
    {
      "key": "BR132",
      "value": "Branch Code 132"
    },
    {
      "key": "BR133",
      "value": "Branch Code 133"
    },
    {
      "key": "BR134",
      "value": "Branch Code 134"
    },
    {
      "key": "BR135",
      "value": "Branch Code 135"
    },
    {
      "key": "BR136",
      "value": "Branch Code 136"
    },
    {
      "key": "BR137",
      "value": "Branch Code 137"
    },
    {
      "key": "BR138",
      "value": "Branch Code 138"
    },
    {
      "key": "BR139",
      "value": "Branch Code 139"
    },
    {
      "key": "BR140",
      "value": "Branch Code 140"
    },
    {
      "key": "BR141",
      "value": "Branch Code 141"
    },
    {
      "key": "BR142",
      "value": "Branch Code 142"
    },
    {
      "key": "BR143",
      "value": "Branch Code 143"
    },
    {
      "key": "BR144",
      "value": "Branch Code 144"
    },
    {
      "key": "BR145",
      "value": "Branch Code 145"
    },
    {
      "key": "BR146",
      "value": "Branch Code 146"
    },
    {
      "key": "BR147",
      "value": "Branch Code 147"
    },
    {
      "key": "BR148",
      "value": "Branch Code 148"
    },
    {
      "key": "BR149",
      "value": "Branch Code 149"
    },
    {
      "key": "BR150",
      "value": "Branch Code 150"
    },
    {
      "key": "BR151",
      "value": "Branch Code 151"
    },
    {
      "key": "BR152",
      "value": "Branch Code 152"
    },
    {
      "key": "BR153",
      "value": "Branch Code 153"
    },
    {
      "key": "BR154",
      "value": "Branch Code 154"
    },
    {
      "key": "BR155",
      "value": "Branch Code 155"
    },
    {
      "key": "BR156",
      "value": "Branch Code 156"
    },
    {
      "key": "BR157",
      "value": "Branch Code 157"
    },
    {
      "key": "BR158",
      "value": "Branch Code 158"
    },
    {
      "key": "BR159",
      "value": "Branch Code 159"
    },
    {
      "key": "BR160",
      "value": "Branch Code 160"
    },
    {
      "key": "BR161",
      "value": "Branch Code 161"
    },
    {
      "key": "BR162",
      "value": "Branch Code 162"
    },
    {
      "key": "BR163",
      "value": "Branch Code 163"
    },
    {
      "key": "BR164",
      "value": "Branch Code 164"
    },
    {
      "key": "BR165",
      "value": "Branch Code 165"
    },
    {
      "key": "BR166",
      "value": "Branch Code 166"
    },
    {
      "key": "BR167",
      "value": "Branch Code 167"
    },
    {
      "key": "BR168",
      "value": "Branch Code 168"
    },
    {
      "key": "BR169",
      "value": "Branch Code 169"
    },
    {
      "key": "BR170",
      "value": "Branch Code 170"
    },
    {
      "key": "BR171",
      "value": "Branch Code 171"
    },
    {
      "key": "BR172",
      "value": "Branch Code 172"
    },
    {
      "key": "BR173",
      "value": "Branch Code 173"
    },
    {
      "key": "BR174",
      "value": "Branch Code 174"
    },
    {
      "key": "BR175",
      "value": "Branch Code 175"
    },
    {
      "key": "BR176",
      "value": "Branch Code 176"
    },
    {
      "key": "BR177",
      "value": "Branch Code 177"
    },
    {
      "key": "BR178",
      "value": "Branch Code 178"
    },
    {
      "key": "BR179",
      "value": "Branch Code 179"
    },
    {
      "key": "BR180",
      "value": "Branch Code 180"
    },
    {
      "key": "BR181",
      "value": "Branch Code 181"
    },
    {
      "key": "BR182",
      "value": "Branch Code 182"
    },
    {
      "key": "BR183",
      "value": "Branch Code 183"
    },
    {
      "key": "BR184",
      "value": "Branch Code 184"
    },
    {
      "key": "BR185",
      "value": "Branch Code 185"
    },
    {
      "key": "BR186",
      "value": "Branch Code 186"
    },
    {
      "key": "BR187",
      "value": "Branch Code 187"
    },
    {
      "key": "BR188",
      "value": "Branch Code 188"
    },
    {
      "key": "BR189",
      "value": "Branch Code 189"
    },
    {
      "key": "BR190",
      "value": "Branch Code 190"
    },
    {
      "key": "BR191",
      "value": "Branch Code 191"
    },
    {
      "key": "BR192",
      "value": "Branch Code 192"
    },
    {
      "key": "BR193",
      "value": "Branch Code 193"
    },
    {
      "key": "BR194",
      "value": "Branch Code 194"
    },
    {
      "key": "BR195",
      "value": "Branch Code 195"
    },
    {
      "key": "BR196",
      "value": "Branch Code 196"
    },
    {
      "key": "BR197",
      "value": "Branch Code 197"
    },
    {
      "key": "BR198",
      "value": "Branch Code 198"
    },
    {
      "key": "BR199",
      "value": "Branch Code 199"
    },
    {
      "key": "BR200",
      "value": "Branch Code 200"
    },
    {
      "key": "BR201",
      "value": "Branch Code 201"
    },
    {
      "key": "BR202",
      "value": "Branch Code 202"
    },
    {
      "key": "BR203",
      "value": "Branch Code 203"
    },
    {
      "key": "BR204",
      "value": "Branch Code 204"
    },
    {
      "key": "BR205",
      "value": "Branch Code 205"
    },
    {
      "key": "BR206",
      "value": "Branch Code 206"
    },
    {
      "key": "BR207",
      "value": "Branch Code 207"
    },
    {
      "key": "BR208",
      "value": "Branch Code 208"
    },
    {
      "key": "BR209",
      "value": "Branch Code 209"
    },
    {
      "key": "BR210",
      "value": "Branch Code 210"
    },
    {
      "key": "BR211",
      "value": "Branch Code 211"
    },
    {
      "key": "BR212",
      "value": "Branch Code 212"
    },
    {
      "key": "BR213",
      "value": "Branch Code 213"
    },
    {
      "key": "BR214",
      "value": "Branch Code 214"
    },
    {
      "key": "BR215",
      "value": "Branch Code 215"
    },
    {
      "key": "BR216",
      "value": "Branch Code 216"
    },
    {
      "key": "BR217",
      "value": "Branch Code 217"
    },
    {
      "key": "BR218",
      "value": "Branch Code 218"
    },
    {
      "key": "BR219",
      "value": "Branch Code 219"
    },
    {
      "key": "BR220",
      "value": "Branch Code 220"
    },
    {
      "key": "BR221",
      "value": "Branch Code 221"
    },
    {
      "key": "BR222",
      "value": "Branch Code 222"
    },
    {
      "key": "BR223",
      "value": "Branch Code 223"
    },
    {
      "key": "BR224",
      "value": "Branch Code 224"
    },
    {
      "key": "BR225",
      "value": "Branch Code 225"
    },
    {
      "key": "BR226",
      "value": "Branch Code 226"
    },
    {
      "key": "BR227",
      "value": "Branch Code 227"
    },
    {
      "key": "BR228",
      "value": "Branch Code 228"
    },
    {
      "key": "BR229",
      "value": "Branch Code 229"
    },
    {
      "key": "BR230",
      "value": "Branch Code 230"
    },
    {
      "key": "BR231",
      "value": "Branch Code 231"
    },
    {
      "key": "BR232",
      "value": "Branch Code 232"
    },
    {
      "key": "BR233",
      "value": "Branch Code 233"
    },
    {
      "key": "BR234",
      "value": "Branch Code 234"
    },
    {
      "key": "BR235",
      "value": "Branch Code 235"
    },
    {
      "key": "BR236",
      "value": "Branch Code 236"
    },
    {
      "key": "BR237",
      "value": "Branch Code 237"
    },
    {
      "key": "BR238",
      "value": "Branch Code 238"
    },
    {
      "key": "BR239",
      "value": "Branch Code 239"
    },
    {
      "key": "BR240",
      "value": "Branch Code 240"
    },
    {
      "key": "BR241",
      "value": "Branch Code 241"
    },
    {
      "key": "BR242",
      "value": "Branch Code 242"
    },
    {
      "key": "BR243",
      "value": "Branch Code 243"
    },
    {
      "key": "BR244",
      "value": "Branch Code 244"
    },
    {
      "key": "BR245",
      "value": "Branch Code 245"
    },
    {
      "key": "BR246",
      "value": "Branch Code 246"
    },
    {
      "key": "BR247",
      "value": "Branch Code 247"
    },
    {
      "key": "BR248",
      "value": "Branch Code 248"
    },
    {
      "key": "BR249",
      "value": "Branch Code 249"
    },
    {
      "key": "BR250",
      "value": "Branch Code 250"
    },
    {
      "key": "BR251",
      "value": "Branch Code 251"
    },
    {
      "key": "BR252",
      "value": "Branch Code 252"
    },
    {
      "key": "BR253",
      "value": "Branch Code 253"
    },
    {
      "key": "BR254",
      "value": "Branch Code 254"
    },
    {
      "key": "BR255",
      "value": "Branch Code 255"
    },
    {
      "key": "BR256",
      "value": "Branch Code 256"
    },
    {
      "key": "BR257",
      "value": "Branch Code 257"
    },
    {
      "key": "BR258",
      "value": "Branch Code 258"
    },
    {
      "key": "BR259",
      "value": "Branch Code 259"
    },
    {
      "key": "BR260",
      "value": "Branch Code 260"
    },
    {
      "key": "BR261",
      "value": "Branch Code 261"
    },
    {
      "key": "BR262",
      "value": "Branch Code 262"
    },
    {
      "key": "BR263",
      "value": "Branch Code 263"
    },
    {
      "key": "BR264",
      "value": "Branch Code 264"
    },
    {
      "key": "BR265",
      "value": "Branch Code 265"
    },
    {
      "key": "BR266",
      "value": "Branch Code 266"
    },
    {
      "key": "BR267",
      "value": "Branch Code 267"
    },
    {
      "key": "BR268",
      "value": "Branch Code 268"
    },
    {
      "key": "BR269",
      "value": "Branch Code 269"
    },
    {
      "key": "BR270",
      "value": "Branch Code 270"
    },
    {
      "key": "BR271",
      "value": "Branch Code 271"
    },
    {
      "key": "BR272",
      "value": "Branch Code 272"
    },
    {
      "key": "BR273",
      "value": "Branch Code 273"
    },
    {
      "key": "BR274",
      "value": "Branch Code 274"
    },
    {
      "key": "BR275",
      "value": "Branch Code 275"
    },
    {
      "key": "BR276",
      "value": "Branch Code 276"
    },
    {
      "key": "BR277",
      "value": "Branch Code 277"
    },
    {
      "key": "BR278",
      "value": "Branch Code 278"
    },
    {
      "key": "BR279",
      "value": "Branch Code 279"
    },
    {
      "key": "BR280",
      "value": "Branch Code 280"
    },
    {
      "key": "BR281",
      "value": "Branch Code 281"
    },
    {
      "key": "BR282",
      "value": "Branch Code 282"
    },
    {
      "key": "BR283",
      "value": "Branch Code 283"
    },
    {
      "key": "BR284",
      "value": "Branch Code 284"
    },
    {
      "key": "BR285",
      "value": "Branch Code 285"
    },
    {
      "key": "BR286",
      "value": "Branch Code 286"
    },
    {
      "key": "BR287",
      "value": "Branch Code 287"
    },
    {
      "key": "BR288",
      "value": "Branch Code 288"
    },
    {
      "key": "BR289",
      "value": "Branch Code 289"
    },
    {
      "key": "BR290",
      "value": "Branch Code 290"
    },
    {
      "key": "BR291",
      "value": "Branch Code 291"
    },
    {
      "key": "BR292",
      "value": "Branch Code 292"
    },
    {
      "key": "BR293",
      "value": "Branch Code 293"
    },
    {
      "key": "BR294",
      "value": "Branch Code 294"
    },
    {
      "key": "BR295",
      "value": "Branch Code 295"
    },
    {
      "key": "BR296",
      "value": "Branch Code 296"
    },
    {
      "key": "BR297",
      "value": "Branch Code 297"
    },
    {
      "key": "BR298",
      "value": "Branch Code 298"
    },
    {
      "key": "BR299",
      "value": "Branch Code 299"
    },
    {
      "key": "BR300",
      "value": "Branch Code 300"
    },
    {
      "key": "BR301",
      "value": "Branch Code 301"
    },
    {
      "key": "BR302",
      "value": "Branch Code 302"
    },
    {
      "key": "BR303",
      "value": "Branch Code 303"
    },
    {
      "key": "BR304",
      "value": "Branch Code 304"
    },
    {
      "key": "BR305",
      "value": "Branch Code 305"
    },
    {
      "key": "BR306",
      "value": "Branch Code 306"
    },
    {
      "key": "BR307",
      "value": "Branch Code 307"
    },
    {
      "key": "BR308",
      "value": "Branch Code 308"
    },
    {
      "key": "BR309",
      "value": "Branch Code 309"
    },
    {
      "key": "BR310",
      "value": "Branch Code 310"
    },
    {
      "key": "BR311",
      "value": "Branch Code 311"
    },
    {
      "key": "BR312",
      "value": "Branch Code 312"
    },
    {
      "key": "BR313",
      "value": "Branch Code 313"
    },
    {
      "key": "BR314",
      "value": "Branch Code 314"
    },
    {
      "key": "BR315",
      "value": "Branch Code 315"
    },
    {
      "key": "BR316",
      "value": "Branch Code 316"
    },
    {
      "key": "BR317",
      "value": "Branch Code 317"
    },
    {
      "key": "BR318",
      "value": "Branch Code 318"
    },
    {
      "key": "BR319",
      "value": "Branch Code 319"
    },
    {
      "key": "BR320",
      "value": "Branch Code 320"
    },
    {
      "key": "BR321",
      "value": "Branch Code 321"
    },
    {
      "key": "BR322",
      "value": "Branch Code 322"
    },
    {
      "key": "BR323",
      "value": "Branch Code 323"
    },
    {
      "key": "BR324",
      "value": "Branch Code 324"
    },
    {
      "key": "BR325",
      "value": "Branch Code 325"
    },
    {
      "key": "BR326",
      "value": "Branch Code 326"
    },
    {
      "key": "BR327",
      "value": "Branch Code 327"
    },
    {
      "key": "BR328",
      "value": "Branch Code 328"
    },
    {
      "key": "BR329",
      "value": "Branch Code 329"
    },
    {
      "key": "BR330",
      "value": "Branch Code 330"
    },
    {
      "key": "BR331",
      "value": "Branch Code 331"
    },
    {
      "key": "BR332",
      "value": "Branch Code 332"
    },
    {
      "key": "BR333",
      "value": "Branch Code 333"
    },
    {
      "key": "BR334",
      "value": "Branch Code 334"
    },
    {
      "key": "BR335",
      "value": "Branch Code 335"
    },
    {
      "key": "BR336",
      "value": "Branch Code 336"
    },
    {
      "key": "BR337",
      "value": "Branch Code 337"
    },
    {
      "key": "BR338",
      "value": "Branch Code 338"
    },
    {
      "key": "BR339",
      "value": "Branch Code 339"
    },
    {
      "key": "BR340",
      "value": "Branch Code 340"
    },
    {
      "key": "BR341",
      "value": "Branch Code 341"
    },
    {
      "key": "BR342",
      "value": "Branch Code 342"
    },
    {
      "key": "BR343",
      "value": "Branch Code 343"
    },
    {
      "key": "BR344",
      "value": "Branch Code 344"
    },
    {
      "key": "BR345",
      "value": "Branch Code 345"
    },
    {
      "key": "BR346",
      "value": "Branch Code 346"
    },
    {
      "key": "BR347",
      "value": "Branch Code 347"
    },
    {
      "key": "BR348",
      "value": "Branch Code 348"
    },
    {
      "key": "BR349",
      "value": "Branch Code 349"
    },
    {
      "key": "BR350",
      "value": "Branch Code 350"
    },
    {
      "key": "BR351",
      "value": "Branch Code 351"
    },
    {
      "key": "BR352",
      "value": "Branch Code 352"
    },
    {
      "key": "BR353",
      "value": "Branch Code 353"
    },
    {
      "key": "BR354",
      "value": "Branch Code 354"
    },
    {
      "key": "BR355",
      "value": "Branch Code 355"
    },
    {
      "key": "BR356",
      "value": "Branch Code 356"
    },
    {
      "key": "BR357",
      "value": "Branch Code 357"
    },
    {
      "key": "BR358",
      "value": "Branch Code 358"
    },
    {
      "key": "BR359",
      "value": "Branch Code 359"
    },
    {
      "key": "BR360",
      "value": "Branch Code 360"
    },
    {
      "key": "BR361",
      "value": "Branch Code 361"
    },
    {
      "key": "BR362",
      "value": "Branch Code 362"
    },
    {
      "key": "BR363",
      "value": "Branch Code 363"
    },
    {
      "key": "BR364",
      "value": "Branch Code 364"
    },
    {
      "key": "BR365",
      "value": "Branch Code 365"
    },
    {
      "key": "BR366",
      "value": "Branch Code 366"
    },
    {
      "key": "BR367",
      "value": "Branch Code 367"
    },
    {
      "key": "BR368",
      "value": "Branch Code 368"
    },
    {
      "key": "BR369",
      "value": "Branch Code 369"
    },
    {
      "key": "BR370",
      "value": "Branch Code 370"
    },
    {
      "key": "BR371",
      "value": "Branch Code 371"
    },
    {
      "key": "BR372",
      "value": "Branch Code 372"
    },
    {
      "key": "BR373",
      "value": "Branch Code 373"
    },
    {
      "key": "BR374",
      "value": "Branch Code 374"
    },
    {
      "key": "BR375",
      "value": "Branch Code 375"
    },
    {
      "key": "BR376",
      "value": "Branch Code 376"
    },
    {
      "key": "BR377",
      "value": "Branch Code 377"
    },
    {
      "key": "BR378",
      "value": "Branch Code 378"
    },
    {
      "key": "BR379",
      "value": "Branch Code 379"
    },
    {
      "key": "BR380",
      "value": "Branch Code 380"
    },
    {
      "key": "BR381",
      "value": "Branch Code 381"
    },
    {
      "key": "BR382",
      "value": "Branch Code 382"
    },
    {
      "key": "BR383",
      "value": "Branch Code 383"
    },
    {
      "key": "BR384",
      "value": "Branch Code 384"
    },
    {
      "key": "BR385",
      "value": "Branch Code 385"
    },
    {
      "key": "BR386",
      "value": "Branch Code 386"
    },
    {
      "key": "BR387",
      "value": "Branch Code 387"
    },
    {
      "key": "BR388",
      "value": "Branch Code 388"
    },
    {
      "key": "BR389",
      "value": "Branch Code 389"
    },
    {
      "key": "BR390",
      "value": "Branch Code 390"
    },
    {
      "key": "BR391",
      "value": "Branch Code 391"
    },
    {
      "key": "BR392",
      "value": "Branch Code 392"
    },
    {
      "key": "BR393",
      "value": "Branch Code 393"
    },
    {
      "key": "BR394",
      "value": "Branch Code 394"
    },
    {
      "key": "BR395",
      "value": "Branch Code 395"
    },
    {
      "key": "BR396",
      "value": "Branch Code 396"
    },
    {
      "key": "BR397",
      "value": "Branch Code 397"
    },
    {
      "key": "BR398",
      "value": "Branch Code 398"
    },
    {
      "key": "BR399",
      "value": "Branch Code 399"
    },
    {
      "key": "BR400",
      "value": "Branch Code 400"
    },
    {
      "key": "BR401",
      "value": "Branch Code 401"
    },
    {
      "key": "BR402",
      "value": "Branch Code 402"
    },
    {
      "key": "BR403",
      "value": "Branch Code 403"
    },
    {
      "key": "BR404",
      "value": "Branch Code 404"
    },
    {
      "key": "BR405",
      "value": "Branch Code 405"
    },
    {
      "key": "BR406",
      "value": "Branch Code 406"
    },
    {
      "key": "BR407",
      "value": "Branch Code 407"
    },
    {
      "key": "BR408",
      "value": "Branch Code 408"
    },
    {
      "key": "BR409",
      "value": "Branch Code 409"
    },
    {
      "key": "BR410",
      "value": "Branch Code 410"
    },
    {
      "key": "BR411",
      "value": "Branch Code 411"
    },
    {
      "key": "BR412",
      "value": "Branch Code 412"
    },
    {
      "key": "BR413",
      "value": "Branch Code 413"
    },
    {
      "key": "BR414",
      "value": "Branch Code 414"
    },
    {
      "key": "BR415",
      "value": "Branch Code 415"
    },
    {
      "key": "BR416",
      "value": "Branch Code 416"
    },
    {
      "key": "BR417",
      "value": "Branch Code 417"
    },
    {
      "key": "BR418",
      "value": "Branch Code 418"
    },
    {
      "key": "BR419",
      "value": "Branch Code 419"
    },
    {
      "key": "BR420",
      "value": "Branch Code 420"
    },
    {
      "key": "BR421",
      "value": "Branch Code 421"
    },
    {
      "key": "BR422",
      "value": "Branch Code 422"
    },
    {
      "key": "BR423",
      "value": "Branch Code 423"
    },
    {
      "key": "BR424",
      "value": "Branch Code 424"
    },
    {
      "key": "BR425",
      "value": "Branch Code 425"
    },
    {
      "key": "BR426",
      "value": "Branch Code 426"
    },
    {
      "key": "BR427",
      "value": "Branch Code 427"
    },
    {
      "key": "BR428",
      "value": "Branch Code 428"
    },
    {
      "key": "BR429",
      "value": "Branch Code 429"
    },
    {
      "key": "BR430",
      "value": "Branch Code 430"
    },
    {
      "key": "BR431",
      "value": "Branch Code 431"
    },
    {
      "key": "BR432",
      "value": "Branch Code 432"
    },
    {
      "key": "BR433",
      "value": "Branch Code 433"
    },
    {
      "key": "BR434",
      "value": "Branch Code 434"
    },
    {
      "key": "BR435",
      "value": "Branch Code 435"
    },
    {
      "key": "BR436",
      "value": "Branch Code 436"
    },
    {
      "key": "BR437",
      "value": "Branch Code 437"
    },
    {
      "key": "BR438",
      "value": "Branch Code 438"
    },
    {
      "key": "BR439",
      "value": "Branch Code 439"
    },
    {
      "key": "BR440",
      "value": "Branch Code 440"
    },
    {
      "key": "BR441",
      "value": "Branch Code 441"
    },
    {
      "key": "BR442",
      "value": "Branch Code 442"
    },
    {
      "key": "BR443",
      "value": "Branch Code 443"
    },
    {
      "key": "BR444",
      "value": "Branch Code 444"
    },
    {
      "key": "BR445",
      "value": "Branch Code 445"
    },
    {
      "key": "BR446",
      "value": "Branch Code 446"
    },
    {
      "key": "BR447",
      "value": "Branch Code 447"
    },
    {
      "key": "BR448",
      "value": "Branch Code 448"
    },
    {
      "key": "BR449",
      "value": "Branch Code 449"
    },
    {
      "key": "BR450",
      "value": "Branch Code 450"
    },
    {
      "key": "BR451",
      "value": "Branch Code 451"
    },
    {
      "key": "BR452",
      "value": "Branch Code 452"
    },
    {
      "key": "BR453",
      "value": "Branch Code 453"
    },
    {
      "key": "BR454",
      "value": "Branch Code 454"
    },
    {
      "key": "BR455",
      "value": "Branch Code 455"
    },
    {
      "key": "BR456",
      "value": "Branch Code 456"
    },
    {
      "key": "BR457",
      "value": "Branch Code 457"
    },
    {
      "key": "BR458",
      "value": "Branch Code 458"
    },
    {
      "key": "BR459",
      "value": "Branch Code 459"
    },
    {
      "key": "BR460",
      "value": "Branch Code 460"
    },
    {
      "key": "BR461",
      "value": "Branch Code 461"
    },
    {
      "key": "BR462",
      "value": "Branch Code 462"
    },
    {
      "key": "BR463",
      "value": "Branch Code 463"
    },
    {
      "key": "BR464",
      "value": "Branch Code 464"
    },
    {
      "key": "BR465",
      "value": "Branch Code 465"
    },
    {
      "key": "BR466",
      "value": "Branch Code 466"
    },
    {
      "key": "BR467",
      "value": "Branch Code 467"
    },
    {
      "key": "BR468",
      "value": "Branch Code 468"
    },
    {
      "key": "BR469",
      "value": "Branch Code 469"
    },
    {
      "key": "BR470",
      "value": "Branch Code 470"
    },
    {
      "key": "BR471",
      "value": "Branch Code 471"
    },
    {
      "key": "BR472",
      "value": "Branch Code 472"
    },
    {
      "key": "BR473",
      "value": "Branch Code 473"
    },
    {
      "key": "BR474",
      "value": "Branch Code 474"
    },
    {
      "key": "BR475",
      "value": "Branch Code 475"
    },
    {
      "key": "BR476",
      "value": "Branch Code 476"
    },
    {
      "key": "BR477",
      "value": "Branch Code 477"
    },
    {
      "key": "BR478",
      "value": "Branch Code 478"
    },
    {
      "key": "BR479",
      "value": "Branch Code 479"
    },
    {
      "key": "BR480",
      "value": "Branch Code 480"
    },
    {
      "key": "BR481",
      "value": "Branch Code 481"
    },
    {
      "key": "BR482",
      "value": "Branch Code 482"
    },
    {
      "key": "BR483",
      "value": "Branch Code 483"
    },
    {
      "key": "BR484",
      "value": "Branch Code 484"
    },
    {
      "key": "BR485",
      "value": "Branch Code 485"
    },
    {
      "key": "BR486",
      "value": "Branch Code 486"
    },
    {
      "key": "BR487",
      "value": "Branch Code 487"
    },
    {
      "key": "BR488",
      "value": "Branch Code 488"
    },
    {
      "key": "BR489",
      "value": "Branch Code 489"
    },
    {
      "key": "BR490",
      "value": "Branch Code 490"
    },
    {
      "key": "BR491",
      "value": "Branch Code 491"
    },
    {
      "key": "BR492",
      "value": "Branch Code 492"
    },
    {
      "key": "BR493",
      "value": "Branch Code 493"
    },
    {
      "key": "BR494",
      "value": "Branch Code 494"
    },
    {
      "key": "BR495",
      "value": "Branch Code 495"
    },
    {
      "key": "BR496",
      "value": "Branch Code 496"
    },
    {
      "key": "BR497",
      "value": "Branch Code 497"
    },
    {
      "key": "BR498",
      "value": "Branch Code 498"
    },
    {
      "key": "BR499",
      "value": "Branch Code 499"
    },
    {
      "key": "BR500",
      "value": "Branch Code 500"
    },
    {
      "key": "BR501",
      "value": "Branch Code 501"
    },
    {
      "key": "BR502",
      "value": "Branch Code 502"
    },
    {
      "key": "BR503",
      "value": "Branch Code 503"
    },
    {
      "key": "BR504",
      "value": "Branch Code 504"
    },
    {
      "key": "BR505",
      "value": "Branch Code 505"
    },
    {
      "key": "BR506",
      "value": "Branch Code 506"
    },
    {
      "key": "BR507",
      "value": "Branch Code 507"
    },
    {
      "key": "BR508",
      "value": "Branch Code 508"
    },
    {
      "key": "BR509",
      "value": "Branch Code 509"
    },
    {
      "key": "BR510",
      "value": "Branch Code 510"
    },
    {
      "key": "BR511",
      "value": "Branch Code 511"
    },
    {
      "key": "BR512",
      "value": "Branch Code 512"
    },
    {
      "key": "BR513",
      "value": "Branch Code 513"
    },
    {
      "key": "BR514",
      "value": "Branch Code 514"
    },
    {
      "key": "BR515",
      "value": "Branch Code 515"
    },
    {
      "key": "BR516",
      "value": "Branch Code 516"
    },
    {
      "key": "BR517",
      "value": "Branch Code 517"
    },
    {
      "key": "BR518",
      "value": "Branch Code 518"
    },
    {
      "key": "BR519",
      "value": "Branch Code 519"
    },
    {
      "key": "BR520",
      "value": "Branch Code 520"
    },
    {
      "key": "BR521",
      "value": "Branch Code 521"
    },
    {
      "key": "BR522",
      "value": "Branch Code 522"
    },
    {
      "key": "BR523",
      "value": "Branch Code 523"
    },
    {
      "key": "BR524",
      "value": "Branch Code 524"
    },
    {
      "key": "BR525",
      "value": "Branch Code 525"
    },
    {
      "key": "BR526",
      "value": "Branch Code 526"
    },
    {
      "key": "BR527",
      "value": "Branch Code 527"
    },
    {
      "key": "BR528",
      "value": "Branch Code 528"
    },
    {
      "key": "BR529",
      "value": "Branch Code 529"
    },
    {
      "key": "BR530",
      "value": "Branch Code 530"
    },
    {
      "key": "BR531",
      "value": "Branch Code 531"
    },
    {
      "key": "BR532",
      "value": "Branch Code 532"
    },
    {
      "key": "BR533",
      "value": "Branch Code 533"
    },
    {
      "key": "BR534",
      "value": "Branch Code 534"
    },
    {
      "key": "BR535",
      "value": "Branch Code 535"
    },
    {
      "key": "BR536",
      "value": "Branch Code 536"
    },
    {
      "key": "BR537",
      "value": "Branch Code 537"
    },
    {
      "key": "BR538",
      "value": "Branch Code 538"
    },
    {
      "key": "BR539",
      "value": "Branch Code 539"
    },
    {
      "key": "BR540",
      "value": "Branch Code 540"
    },
    {
      "key": "BR541",
      "value": "Branch Code 541"
    },
    {
      "key": "BR542",
      "value": "Branch Code 542"
    },
    {
      "key": "BR543",
      "value": "Branch Code 543"
    },
    {
      "key": "BR544",
      "value": "Branch Code 544"
    },
    {
      "key": "BR545",
      "value": "Branch Code 545"
    },
    {
      "key": "BR546",
      "value": "Branch Code 546"
    },
    {
      "key": "BR547",
      "value": "Branch Code 547"
    },
    {
      "key": "BR548",
      "value": "Branch Code 548"
    },
    {
      "key": "BR549",
      "value": "Branch Code 549"
    },
    {
      "key": "BR550",
      "value": "Branch Code 550"
    },
    {
      "key": "BR551",
      "value": "Branch Code 551"
    },
    {
      "key": "BR552",
      "value": "Branch Code 552"
    },
    {
      "key": "BR553",
      "value": "Branch Code 553"
    },
    {
      "key": "BR554",
      "value": "Branch Code 554"
    },
    {
      "key": "BR555",
      "value": "Branch Code 555"
    },
    {
      "key": "BR556",
      "value": "Branch Code 556"
    },
    {
      "key": "BR557",
      "value": "Branch Code 557"
    },
    {
      "key": "BR558",
      "value": "Branch Code 558"
    },
    {
      "key": "BR559",
      "value": "Branch Code 559"
    },
    {
      "key": "BR560",
      "value": "Branch Code 560"
    },
    {
      "key": "BR561",
      "value": "Branch Code 561"
    },
    {
      "key": "BR562",
      "value": "Branch Code 562"
    },
    {
      "key": "BR563",
      "value": "Branch Code 563"
    },
    {
      "key": "BR564",
      "value": "Branch Code 564"
    },
    {
      "key": "BR565",
      "value": "Branch Code 565"
    },
    {
      "key": "BR566",
      "value": "Branch Code 566"
    },
    {
      "key": "BR567",
      "value": "Branch Code 567"
    },
    {
      "key": "BR568",
      "value": "Branch Code 568"
    },
    {
      "key": "BR569",
      "value": "Branch Code 569"
    },
    {
      "key": "BR570",
      "value": "Branch Code 570"
    },
    {
      "key": "BR571",
      "value": "Branch Code 571"
    },
    {
      "key": "BR572",
      "value": "Branch Code 572"
    },
    {
      "key": "BR573",
      "value": "Branch Code 573"
    },
    {
      "key": "BR574",
      "value": "Branch Code 574"
    },
    {
      "key": "BR575",
      "value": "Branch Code 575"
    },
    {
      "key": "BR576",
      "value": "Branch Code 576"
    },
    {
      "key": "BR577",
      "value": "Branch Code 577"
    },
    {
      "key": "BR578",
      "value": "Branch Code 578"
    },
    {
      "key": "BR579",
      "value": "Branch Code 579"
    },
    {
      "key": "BR580",
      "value": "Branch Code 580"
    },
    {
      "key": "BR581",
      "value": "Branch Code 581"
    },
    {
      "key": "BR582",
      "value": "Branch Code 582"
    },
    {
      "key": "BR583",
      "value": "Branch Code 583"
    },
    {
      "key": "BR584",
      "value": "Branch Code 584"
    },
    {
      "key": "BR585",
      "value": "Branch Code 585"
    },
    {
      "key": "BR586",
      "value": "Branch Code 586"
    },
    {
      "key": "BR587",
      "value": "Branch Code 587"
    },
    {
      "key": "BR588",
      "value": "Branch Code 588"
    },
    {
      "key": "BR589",
      "value": "Branch Code 589"
    },
    {
      "key": "BR590",
      "value": "Branch Code 590"
    },
    {
      "key": "BR591",
      "value": "Branch Code 591"
    },
    {
      "key": "BR592",
      "value": "Branch Code 592"
    },
    {
      "key": "BR593",
      "value": "Branch Code 593"
    },
    {
      "key": "BR594",
      "value": "Branch Code 594"
    },
    {
      "key": "BR595",
      "value": "Branch Code 595"
    },
    {
      "key": "BR596",
      "value": "Branch Code 596"
    },
    {
      "key": "BR597",
      "value": "Branch Code 597"
    },
    {
      "key": "BR598",
      "value": "Branch Code 598"
    },
    {
      "key": "BR599",
      "value": "Branch Code 599"
    },
    {
      "key": "BR600",
      "value": "Branch Code 600"
    },
    {
      "key": "BR601",
      "value": "Branch Code 601"
    },
    {
      "key": "BR602",
      "value": "Branch Code 602"
    },
    {
      "key": "BR603",
      "value": "Branch Code 603"
    },
    {
      "key": "BR604",
      "value": "Branch Code 604"
    },
    {
      "key": "BR605",
      "value": "Branch Code 605"
    },
    {
      "key": "BR606",
      "value": "Branch Code 606"
    },
    {
      "key": "BR607",
      "value": "Branch Code 607"
    },
    {
      "key": "BR608",
      "value": "Branch Code 608"
    },
    {
      "key": "BR609",
      "value": "Branch Code 609"
    },
    {
      "key": "BR610",
      "value": "Branch Code 610"
    },
    {
      "key": "BR611",
      "value": "Branch Code 611"
    },
    {
      "key": "BR612",
      "value": "Branch Code 612"
    },
    {
      "key": "BR613",
      "value": "Branch Code 613"
    },
    {
      "key": "BR614",
      "value": "Branch Code 614"
    },
    {
      "key": "BR615",
      "value": "Branch Code 615"
    },
    {
      "key": "BR616",
      "value": "Branch Code 616"
    },
    {
      "key": "BR617",
      "value": "Branch Code 617"
    },
    {
      "key": "BR618",
      "value": "Branch Code 618"
    },
    {
      "key": "BR619",
      "value": "Branch Code 619"
    },
    {
      "key": "BR620",
      "value": "Branch Code 620"
    },
    {
      "key": "BR621",
      "value": "Branch Code 621"
    },
    {
      "key": "BR622",
      "value": "Branch Code 622"
    },
    {
      "key": "BR623",
      "value": "Branch Code 623"
    },
    {
      "key": "BR624",
      "value": "Branch Code 624"
    },
    {
      "key": "BR625",
      "value": "Branch Code 625"
    },
    {
      "key": "BR626",
      "value": "Branch Code 626"
    },
    {
      "key": "BR627",
      "value": "Branch Code 627"
    },
    {
      "key": "BR628",
      "value": "Branch Code 628"
    },
    {
      "key": "BR629",
      "value": "Branch Code 629"
    },
    {
      "key": "BR630",
      "value": "Branch Code 630"
    },
    {
      "key": "BR631",
      "value": "Branch Code 631"
    },
    {
      "key": "BR632",
      "value": "Branch Code 632"
    },
    {
      "key": "BR633",
      "value": "Branch Code 633"
    },
    {
      "key": "BR634",
      "value": "Branch Code 634"
    },
    {
      "key": "BR635",
      "value": "Branch Code 635"
    },
    {
      "key": "BR636",
      "value": "Branch Code 636"
    },
    {
      "key": "BR637",
      "value": "Branch Code 637"
    },
    {
      "key": "BR638",
      "value": "Branch Code 638"
    },
    {
      "key": "BR639",
      "value": "Branch Code 639"
    },
    {
      "key": "BR640",
      "value": "Branch Code 640"
    },
    {
      "key": "BR641",
      "value": "Branch Code 641"
    },
    {
      "key": "BR642",
      "value": "Branch Code 642"
    },
    {
      "key": "BR643",
      "value": "Branch Code 643"
    },
    {
      "key": "BR644",
      "value": "Branch Code 644"
    },
    {
      "key": "BR645",
      "value": "Branch Code 645"
    },
    {
      "key": "BR646",
      "value": "Branch Code 646"
    },
    {
      "key": "BR647",
      "value": "Branch Code 647"
    },
    {
      "key": "BR648",
      "value": "Branch Code 648"
    },
    {
      "key": "BR649",
      "value": "Branch Code 649"
    },
    {
      "key": "BR650",
      "value": "Branch Code 650"
    },
    {
      "key": "BR651",
      "value": "Branch Code 651"
    },
    {
      "key": "BR652",
      "value": "Branch Code 652"
    },
    {
      "key": "BR653",
      "value": "Branch Code 653"
    },
    {
      "key": "BR654",
      "value": "Branch Code 654"
    },
    {
      "key": "BR655",
      "value": "Branch Code 655"
    },
    {
      "key": "BR656",
      "value": "Branch Code 656"
    },
    {
      "key": "BR657",
      "value": "Branch Code 657"
    },
    {
      "key": "BR658",
      "value": "Branch Code 658"
    },
    {
      "key": "BR659",
      "value": "Branch Code 659"
    },
    {
      "key": "BR660",
      "value": "Branch Code 660"
    },
    {
      "key": "BR661",
      "value": "Branch Code 661"
    },
    {
      "key": "BR662",
      "value": "Branch Code 662"
    },
    {
      "key": "BR663",
      "value": "Branch Code 663"
    },
    {
      "key": "BR664",
      "value": "Branch Code 664"
    },
    {
      "key": "BR665",
      "value": "Branch Code 665"
    },
    {
      "key": "BR666",
      "value": "Branch Code 666"
    },
    {
      "key": "BR667",
      "value": "Branch Code 667"
    },
    {
      "key": "BR668",
      "value": "Branch Code 668"
    },
    {
      "key": "BR669",
      "value": "Branch Code 669"
    },
    {
      "key": "BR670",
      "value": "Branch Code 670"
    },
    {
      "key": "BR671",
      "value": "Branch Code 671"
    },
    {
      "key": "BR672",
      "value": "Branch Code 672"
    },
    {
      "key": "BR673",
      "value": "Branch Code 673"
    },
    {
      "key": "BR674",
      "value": "Branch Code 674"
    },
    {
      "key": "BR675",
      "value": "Branch Code 675"
    },
    {
      "key": "BR676",
      "value": "Branch Code 676"
    },
    {
      "key": "BR677",
      "value": "Branch Code 677"
    },
    {
      "key": "BR678",
      "value": "Branch Code 678"
    },
    {
      "key": "BR679",
      "value": "Branch Code 679"
    },
    {
      "key": "BR680",
      "value": "Branch Code 680"
    },
    {
      "key": "BR681",
      "value": "Branch Code 681"
    },
    {
      "key": "BR682",
      "value": "Branch Code 682"
    },
    {
      "key": "BR683",
      "value": "Branch Code 683"
    },
    {
      "key": "BR684",
      "value": "Branch Code 684"
    },
    {
      "key": "BR685",
      "value": "Branch Code 685"
    },
    {
      "key": "BR686",
      "value": "Branch Code 686"
    },
    {
      "key": "BR687",
      "value": "Branch Code 687"
    },
    {
      "key": "BR688",
      "value": "Branch Code 688"
    },
    {
      "key": "BR689",
      "value": "Branch Code 689"
    },
    {
      "key": "BR690",
      "value": "Branch Code 690"
    },
    {
      "key": "BR691",
      "value": "Branch Code 691"
    },
    {
      "key": "BR692",
      "value": "Branch Code 692"
    },
    {
      "key": "BR693",
      "value": "Branch Code 693"
    },
    {
      "key": "BR694",
      "value": "Branch Code 694"
    },
    {
      "key": "BR695",
      "value": "Branch Code 695"
    },
    {
      "key": "BR696",
      "value": "Branch Code 696"
    },
    {
      "key": "BR697",
      "value": "Branch Code 697"
    },
    {
      "key": "BR698",
      "value": "Branch Code 698"
    },
    {
      "key": "BR699",
      "value": "Branch Code 699"
    },
    {
      "key": "BR700",
      "value": "Branch Code 700"
    },
    {
      "key": "BR701",
      "value": "Branch Code 701"
    },
    {
      "key": "BR702",
      "value": "Branch Code 702"
    },
    {
      "key": "BR703",
      "value": "Branch Code 703"
    },
    {
      "key": "BR704",
      "value": "Branch Code 704"
    },
    {
      "key": "BR705",
      "value": "Branch Code 705"
    },
    {
      "key": "BR706",
      "value": "Branch Code 706"
    },
    {
      "key": "BR707",
      "value": "Branch Code 707"
    },
    {
      "key": "BR708",
      "value": "Branch Code 708"
    },
    {
      "key": "BR709",
      "value": "Branch Code 709"
    },
    {
      "key": "BR710",
      "value": "Branch Code 710"
    },
    {
      "key": "BR711",
      "value": "Branch Code 711"
    },
    {
      "key": "BR712",
      "value": "Branch Code 712"
    },
    {
      "key": "BR713",
      "value": "Branch Code 713"
    },
    {
      "key": "BR714",
      "value": "Branch Code 714"
    },
    {
      "key": "BR715",
      "value": "Branch Code 715"
    },
    {
      "key": "BR716",
      "value": "Branch Code 716"
    },
    {
      "key": "BR717",
      "value": "Branch Code 717"
    },
    {
      "key": "BR718",
      "value": "Branch Code 718"
    },
    {
      "key": "BR719",
      "value": "Branch Code 719"
    },
    {
      "key": "BR720",
      "value": "Branch Code 720"
    },
    {
      "key": "BR721",
      "value": "Branch Code 721"
    },
    {
      "key": "BR722",
      "value": "Branch Code 722"
    },
    {
      "key": "BR723",
      "value": "Branch Code 723"
    },
    {
      "key": "BR724",
      "value": "Branch Code 724"
    },
    {
      "key": "BR725",
      "value": "Branch Code 725"
    },
    {
      "key": "BR726",
      "value": "Branch Code 726"
    },
    {
      "key": "BR727",
      "value": "Branch Code 727"
    },
    {
      "key": "BR728",
      "value": "Branch Code 728"
    },
    {
      "key": "BR729",
      "value": "Branch Code 729"
    },
    {
      "key": "BR730",
      "value": "Branch Code 730"
    },
    {
      "key": "BR731",
      "value": "Branch Code 731"
    },
    {
      "key": "BR732",
      "value": "Branch Code 732"
    },
    {
      "key": "BR733",
      "value": "Branch Code 733"
    },
    {
      "key": "BR734",
      "value": "Branch Code 734"
    },
    {
      "key": "BR735",
      "value": "Branch Code 735"
    },
    {
      "key": "BR736",
      "value": "Branch Code 736"
    },
    {
      "key": "BR737",
      "value": "Branch Code 737"
    },
    {
      "key": "BR738",
      "value": "Branch Code 738"
    },
    {
      "key": "BR739",
      "value": "Branch Code 739"
    },
    {
      "key": "BR740",
      "value": "Branch Code 740"
    },
    {
      "key": "BR741",
      "value": "Branch Code 741"
    },
    {
      "key": "BR742",
      "value": "Branch Code 742"
    },
    {
      "key": "BR743",
      "value": "Branch Code 743"
    },
    {
      "key": "BR744",
      "value": "Branch Code 744"
    },
    {
      "key": "BR745",
      "value": "Branch Code 745"
    },
    {
      "key": "BR746",
      "value": "Branch Code 746"
    },
    {
      "key": "BR747",
      "value": "Branch Code 747"
    },
    {
      "key": "BR748",
      "value": "Branch Code 748"
    },
    {
      "key": "BR749",
      "value": "Branch Code 749"
    },
    {
      "key": "BR750",
      "value": "Branch Code 750"
    },
    {
      "key": "BR751",
      "value": "Branch Code 751"
    },
    {
      "key": "BR752",
      "value": "Branch Code 752"
    },
    {
      "key": "BR753",
      "value": "Branch Code 753"
    },
    {
      "key": "BR754",
      "value": "Branch Code 754"
    },
    {
      "key": "BR755",
      "value": "Branch Code 755"
    },
    {
      "key": "BR756",
      "value": "Branch Code 756"
    },
    {
      "key": "BR757",
      "value": "Branch Code 757"
    },
    {
      "key": "BR758",
      "value": "Branch Code 758"
    },
    {
      "key": "BR759",
      "value": "Branch Code 759"
    },
    {
      "key": "BR760",
      "value": "Branch Code 760"
    },
    {
      "key": "BR761",
      "value": "Branch Code 761"
    },
    {
      "key": "BR762",
      "value": "Branch Code 762"
    },
    {
      "key": "BR763",
      "value": "Branch Code 763"
    },
    {
      "key": "BR764",
      "value": "Branch Code 764"
    },
    {
      "key": "BR765",
      "value": "Branch Code 765"
    },
    {
      "key": "BR766",
      "value": "Branch Code 766"
    },
    {
      "key": "BR767",
      "value": "Branch Code 767"
    },
    {
      "key": "BR768",
      "value": "Branch Code 768"
    },
    {
      "key": "BR769",
      "value": "Branch Code 769"
    },
    {
      "key": "BR770",
      "value": "Branch Code 770"
    },
    {
      "key": "BR771",
      "value": "Branch Code 771"
    },
    {
      "key": "BR772",
      "value": "Branch Code 772"
    },
    {
      "key": "BR773",
      "value": "Branch Code 773"
    },
    {
      "key": "BR774",
      "value": "Branch Code 774"
    },
    {
      "key": "BR775",
      "value": "Branch Code 775"
    },
    {
      "key": "BR776",
      "value": "Branch Code 776"
    },
    {
      "key": "BR777",
      "value": "Branch Code 777"
    },
    {
      "key": "BR778",
      "value": "Branch Code 778"
    },
    {
      "key": "BR779",
      "value": "Branch Code 779"
    },
    {
      "key": "BR780",
      "value": "Branch Code 780"
    },
    {
      "key": "BR781",
      "value": "Branch Code 781"
    },
    {
      "key": "BR782",
      "value": "Branch Code 782"
    },
    {
      "key": "BR783",
      "value": "Branch Code 783"
    },
    {
      "key": "BR784",
      "value": "Branch Code 784"
    },
    {
      "key": "BR785",
      "value": "Branch Code 785"
    },
    {
      "key": "BR786",
      "value": "Branch Code 786"
    },
    {
      "key": "BR787",
      "value": "Branch Code 787"
    },
    {
      "key": "BR788",
      "value": "Branch Code 788"
    },
    {
      "key": "BR789",
      "value": "Branch Code 789"
    },
    {
      "key": "BR790",
      "value": "Branch Code 790"
    },
    {
      "key": "BR791",
      "value": "Branch Code 791"
    },
    {
      "key": "BR792",
      "value": "Branch Code 792"
    },
    {
      "key": "BR793",
      "value": "Branch Code 793"
    },
    {
      "key": "BR794",
      "value": "Branch Code 794"
    },
    {
      "key": "BR795",
      "value": "Branch Code 795"
    },
    {
      "key": "BR796",
      "value": "Branch Code 796"
    },
    {
      "key": "BR797",
      "value": "Branch Code 797"
    },
    {
      "key": "BR798",
      "value": "Branch Code 798"
    },
    {
      "key": "BR799",
      "value": "Branch Code 799"
    },
    {
      "key": "BR800",
      "value": "Branch Code 800"
    },
    {
      "key": "BR801",
      "value": "Branch Code 801"
    },
    {
      "key": "BR802",
      "value": "Branch Code 802"
    },
    {
      "key": "BR803",
      "value": "Branch Code 803"
    },
    {
      "key": "BR804",
      "value": "Branch Code 804"
    },
    {
      "key": "BR805",
      "value": "Branch Code 805"
    },
    {
      "key": "BR806",
      "value": "Branch Code 806"
    },
    {
      "key": "BR807",
      "value": "Branch Code 807"
    },
    {
      "key": "BR808",
      "value": "Branch Code 808"
    },
    {
      "key": "BR809",
      "value": "Branch Code 809"
    },
    {
      "key": "BR810",
      "value": "Branch Code 810"
    },
    {
      "key": "BR811",
      "value": "Branch Code 811"
    },
    {
      "key": "BR812",
      "value": "Branch Code 812"
    },
    {
      "key": "BR813",
      "value": "Branch Code 813"
    },
    {
      "key": "BR814",
      "value": "Branch Code 814"
    },
    {
      "key": "BR815",
      "value": "Branch Code 815"
    },
    {
      "key": "BR816",
      "value": "Branch Code 816"
    },
    {
      "key": "BR817",
      "value": "Branch Code 817"
    },
    {
      "key": "BR818",
      "value": "Branch Code 818"
    },
    {
      "key": "BR819",
      "value": "Branch Code 819"
    },
    {
      "key": "BR820",
      "value": "Branch Code 820"
    },
    {
      "key": "BR821",
      "value": "Branch Code 821"
    },
    {
      "key": "BR822",
      "value": "Branch Code 822"
    },
    {
      "key": "BR823",
      "value": "Branch Code 823"
    },
    {
      "key": "BR824",
      "value": "Branch Code 824"
    },
    {
      "key": "BR825",
      "value": "Branch Code 825"
    },
    {
      "key": "BR826",
      "value": "Branch Code 826"
    },
    {
      "key": "BR827",
      "value": "Branch Code 827"
    },
    {
      "key": "BR828",
      "value": "Branch Code 828"
    },
    {
      "key": "BR829",
      "value": "Branch Code 829"
    },
    {
      "key": "BR830",
      "value": "Branch Code 830"
    },
    {
      "key": "BR831",
      "value": "Branch Code 831"
    },
    {
      "key": "BR832",
      "value": "Branch Code 832"
    },
    {
      "key": "BR833",
      "value": "Branch Code 833"
    },
    {
      "key": "BR834",
      "value": "Branch Code 834"
    },
    {
      "key": "BR835",
      "value": "Branch Code 835"
    },
    {
      "key": "BR836",
      "value": "Branch Code 836"
    },
    {
      "key": "BR837",
      "value": "Branch Code 837"
    },
    {
      "key": "BR838",
      "value": "Branch Code 838"
    },
    {
      "key": "BR839",
      "value": "Branch Code 839"
    },
    {
      "key": "BR840",
      "value": "Branch Code 840"
    },
    {
      "key": "BR841",
      "value": "Branch Code 841"
    },
    {
      "key": "BR842",
      "value": "Branch Code 842"
    },
    {
      "key": "BR843",
      "value": "Branch Code 843"
    },
    {
      "key": "BR844",
      "value": "Branch Code 844"
    },
    {
      "key": "BR845",
      "value": "Branch Code 845"
    },
    {
      "key": "BR846",
      "value": "Branch Code 846"
    },
    {
      "key": "BR847",
      "value": "Branch Code 847"
    },
    {
      "key": "BR848",
      "value": "Branch Code 848"
    },
    {
      "key": "BR849",
      "value": "Branch Code 849"
    },
    {
      "key": "BR850",
      "value": "Branch Code 850"
    },
    {
      "key": "BR851",
      "value": "Branch Code 851"
    },
    {
      "key": "BR852",
      "value": "Branch Code 852"
    },
    {
      "key": "BR853",
      "value": "Branch Code 853"
    },
    {
      "key": "BR854",
      "value": "Branch Code 854"
    },
    {
      "key": "BR855",
      "value": "Branch Code 855"
    },
    {
      "key": "BR856",
      "value": "Branch Code 856"
    },
    {
      "key": "BR857",
      "value": "Branch Code 857"
    },
    {
      "key": "BR858",
      "value": "Branch Code 858"
    },
    {
      "key": "BR859",
      "value": "Branch Code 859"
    },
    {
      "key": "BR860",
      "value": "Branch Code 860"
    },
    {
      "key": "BR861",
      "value": "Branch Code 861"
    },
    {
      "key": "BR862",
      "value": "Branch Code 862"
    },
    {
      "key": "BR863",
      "value": "Branch Code 863"
    },
    {
      "key": "BR864",
      "value": "Branch Code 864"
    },
    {
      "key": "BR865",
      "value": "Branch Code 865"
    },
    {
      "key": "BR866",
      "value": "Branch Code 866"
    },
    {
      "key": "BR867",
      "value": "Branch Code 867"
    },
    {
      "key": "BR868",
      "value": "Branch Code 868"
    },
    {
      "key": "BR869",
      "value": "Branch Code 869"
    },
    {
      "key": "BR870",
      "value": "Branch Code 870"
    },
    {
      "key": "BR871",
      "value": "Branch Code 871"
    },
    {
      "key": "BR872",
      "value": "Branch Code 872"
    },
    {
      "key": "BR873",
      "value": "Branch Code 873"
    },
    {
      "key": "BR874",
      "value": "Branch Code 874"
    },
    {
      "key": "BR875",
      "value": "Branch Code 875"
    },
    {
      "key": "BR876",
      "value": "Branch Code 876"
    },
    {
      "key": "BR877",
      "value": "Branch Code 877"
    },
    {
      "key": "BR878",
      "value": "Branch Code 878"
    },
    {
      "key": "BR879",
      "value": "Branch Code 879"
    },
    {
      "key": "BR880",
      "value": "Branch Code 880"
    },
    {
      "key": "BR881",
      "value": "Branch Code 881"
    },
    {
      "key": "BR882",
      "value": "Branch Code 882"
    },
    {
      "key": "BR883",
      "value": "Branch Code 883"
    },
    {
      "key": "BR884",
      "value": "Branch Code 884"
    },
    {
      "key": "BR885",
      "value": "Branch Code 885"
    },
    {
      "key": "BR886",
      "value": "Branch Code 886"
    },
    {
      "key": "BR887",
      "value": "Branch Code 887"
    },
    {
      "key": "BR888",
      "value": "Branch Code 888"
    },
    {
      "key": "BR889",
      "value": "Branch Code 889"
    },
    {
      "key": "BR890",
      "value": "Branch Code 890"
    },
    {
      "key": "BR891",
      "value": "Branch Code 891"
    },
    {
      "key": "BR892",
      "value": "Branch Code 892"
    },
    {
      "key": "BR893",
      "value": "Branch Code 893"
    },
    {
      "key": "BR894",
      "value": "Branch Code 894"
    },
    {
      "key": "BR895",
      "value": "Branch Code 895"
    },
    {
      "key": "BR896",
      "value": "Branch Code 896"
    },
    {
      "key": "BR897",
      "value": "Branch Code 897"
    },
    {
      "key": "BR898",
      "value": "Branch Code 898"
    },
    {
      "key": "BR899",
      "value": "Branch Code 899"
    },
    {
      "key": "BR900",
      "value": "Branch Code 900"
    },
    {
      "key": "BR901",
      "value": "Branch Code 901"
    },
    {
      "key": "BR902",
      "value": "Branch Code 902"
    },
    {
      "key": "BR903",
      "value": "Branch Code 903"
    },
    {
      "key": "BR904",
      "value": "Branch Code 904"
    },
    {
      "key": "BR905",
      "value": "Branch Code 905"
    },
    {
      "key": "BR906",
      "value": "Branch Code 906"
    },
    {
      "key": "BR907",
      "value": "Branch Code 907"
    },
    {
      "key": "BR908",
      "value": "Branch Code 908"
    },
    {
      "key": "BR909",
      "value": "Branch Code 909"
    },
    {
      "key": "BR910",
      "value": "Branch Code 910"
    },
    {
      "key": "BR911",
      "value": "Branch Code 911"
    },
    {
      "key": "BR912",
      "value": "Branch Code 912"
    },
    {
      "key": "BR913",
      "value": "Branch Code 913"
    },
    {
      "key": "BR914",
      "value": "Branch Code 914"
    },
    {
      "key": "BR915",
      "value": "Branch Code 915"
    },
    {
      "key": "BR916",
      "value": "Branch Code 916"
    },
    {
      "key": "BR917",
      "value": "Branch Code 917"
    },
    {
      "key": "BR918",
      "value": "Branch Code 918"
    },
    {
      "key": "BR919",
      "value": "Branch Code 919"
    },
    {
      "key": "BR920",
      "value": "Branch Code 920"
    },
    {
      "key": "BR921",
      "value": "Branch Code 921"
    },
    {
      "key": "BR922",
      "value": "Branch Code 922"
    },
    {
      "key": "BR923",
      "value": "Branch Code 923"
    },
    {
      "key": "BR924",
      "value": "Branch Code 924"
    },
    {
      "key": "BR925",
      "value": "Branch Code 925"
    },
    {
      "key": "BR926",
      "value": "Branch Code 926"
    },
    {
      "key": "BR927",
      "value": "Branch Code 927"
    },
    {
      "key": "BR928",
      "value": "Branch Code 928"
    },
    {
      "key": "BR929",
      "value": "Branch Code 929"
    },
    {
      "key": "BR930",
      "value": "Branch Code 930"
    },
    {
      "key": "BR931",
      "value": "Branch Code 931"
    },
    {
      "key": "BR932",
      "value": "Branch Code 932"
    },
    {
      "key": "BR933",
      "value": "Branch Code 933"
    },
    {
      "key": "BR934",
      "value": "Branch Code 934"
    },
    {
      "key": "BR935",
      "value": "Branch Code 935"
    },
    {
      "key": "BR936",
      "value": "Branch Code 936"
    },
    {
      "key": "BR937",
      "value": "Branch Code 937"
    },
    {
      "key": "BR938",
      "value": "Branch Code 938"
    },
    {
      "key": "BR939",
      "value": "Branch Code 939"
    },
    {
      "key": "BR940",
      "value": "Branch Code 940"
    },
    {
      "key": "BR941",
      "value": "Branch Code 941"
    },
    {
      "key": "BR942",
      "value": "Branch Code 942"
    },
    {
      "key": "BR943",
      "value": "Branch Code 943"
    },
    {
      "key": "BR944",
      "value": "Branch Code 944"
    },
    {
      "key": "BR945",
      "value": "Branch Code 945"
    },
    {
      "key": "BR946",
      "value": "Branch Code 946"
    },
    {
      "key": "BR947",
      "value": "Branch Code 947"
    },
    {
      "key": "BR948",
      "value": "Branch Code 948"
    },
    {
      "key": "BR949",
      "value": "Branch Code 949"
    },
    {
      "key": "BR950",
      "value": "Branch Code 950"
    },
    {
      "key": "BR951",
      "value": "Branch Code 951"
    },
    {
      "key": "BR952",
      "value": "Branch Code 952"
    },
    {
      "key": "BR953",
      "value": "Branch Code 953"
    },
    {
      "key": "BR954",
      "value": "Branch Code 954"
    },
    {
      "key": "BR955",
      "value": "Branch Code 955"
    },
    {
      "key": "BR956",
      "value": "Branch Code 956"
    },
    {
      "key": "BR957",
      "value": "Branch Code 957"
    },
    {
      "key": "BR958",
      "value": "Branch Code 958"
    },
    {
      "key": "BR959",
      "value": "Branch Code 959"
    },
    {
      "key": "BR960",
      "value": "Branch Code 960"
    },
    {
      "key": "BR961",
      "value": "Branch Code 961"
    },
    {
      "key": "BR962",
      "value": "Branch Code 962"
    },
    {
      "key": "BR963",
      "value": "Branch Code 963"
    },
    {
      "key": "BR964",
      "value": "Branch Code 964"
    },
    {
      "key": "BR965",
      "value": "Branch Code 965"
    },
    {
      "key": "BR966",
      "value": "Branch Code 966"
    },
    {
      "key": "BR967",
      "value": "Branch Code 967"
    },
    {
      "key": "BR968",
      "value": "Branch Code 968"
    },
    {
      "key": "BR969",
      "value": "Branch Code 969"
    },
    {
      "key": "BR970",
      "value": "Branch Code 970"
    },
    {
      "key": "BR971",
      "value": "Branch Code 971"
    },
    {
      "key": "BR972",
      "value": "Branch Code 972"
    },
    {
      "key": "BR973",
      "value": "Branch Code 973"
    },
    {
      "key": "BR974",
      "value": "Branch Code 974"
    },
    {
      "key": "BR975",
      "value": "Branch Code 975"
    },
    {
      "key": "BR976",
      "value": "Branch Code 976"
    },
    {
      "key": "BR977",
      "value": "Branch Code 977"
    },
    {
      "key": "BR978",
      "value": "Branch Code 978"
    },
    {
      "key": "BR979",
      "value": "Branch Code 979"
    },
    {
      "key": "BR980",
      "value": "Branch Code 980"
    },
    {
      "key": "BR981",
      "value": "Branch Code 981"
    },
    {
      "key": "BR982",
      "value": "Branch Code 982"
    },
    {
      "key": "BR983",
      "value": "Branch Code 983"
    },
    {
      "key": "BR984",
      "value": "Branch Code 984"
    },
    {
      "key": "BR985",
      "value": "Branch Code 985"
    },
    {
      "key": "BR986",
      "value": "Branch Code 986"
    },
    {
      "key": "BR987",
      "value": "Branch Code 987"
    },
    {
      "key": "BR988",
      "value": "Branch Code 988"
    },
    {
      "key": "BR989",
      "value": "Branch Code 989"
    },
    {
      "key": "BR990",
      "value": "Branch Code 990"
    },
    {
      "key": "BR991",
      "value": "Branch Code 991"
    },
    {
      "key": "BR992",
      "value": "Branch Code 992"
    },
    {
      "key": "BR993",
      "value": "Branch Code 993"
    },
    {
      "key": "BR994",
      "value": "Branch Code 994"
    },
    {
      "key": "BR995",
      "value": "Branch Code 995"
    },
    {
      "key": "BR996",
      "value": "Branch Code 996"
    },
    {
      "key": "BR997",
      "value": "Branch Code 997"
    },
    {
      "key": "BR998",
      "value": "Branch Code 998"
    },
    {
      "key": "BR999",
      "value": "Branch Code 999"
    },
    {
      "key": "BULKD",
      "value": "BULKD"
    },
    {
      "key": "CAP01",
      "value": "CAP01"
    },
    {
      "key": "CAP02",
      "value": "CAP02"
    },
    {
      "key": "CAP03",
      "value": "CAP03"
    },
    {
      "key": "CAP04",
      "value": "CAP04"
    },
    {
      "key": "CAP05",
      "value": "CAP05"
    },
    {
      "key": "CAP06",
      "value": "CAP06"
    },
    {
      "key": "CAP09",
      "value": "CAP09"
    },
    {
      "key": "CAP10",
      "value": "CAP10"
    },
    {
      "key": "CAP11",
      "value": "CAP11"
    },
    {
      "key": "CAP29",
      "value": "CAP29"
    },
    {
      "key": "CCL14",
      "value": "CCL14"
    },
    {
      "key": "CCLFC",
      "value": "CCLFC"
    },
    {
      "key": "CCLFL",
      "value": "CCLFL"
    },
    {
      "key": "CCLPR",
      "value": "CCLPR"
    },
    {
      "key": "CCR01",
      "value": "CCR01"
    },
    {
      "key": "ECO01",
      "value": "ECO01"
    },
    {
      "key": "ECO02",
      "value": "ECO02"
    },
    {
      "key": "ECO03",
      "value": "ECO03"
    },
    {
      "key": "ECO04",
      "value": "ECO04"
    },
    {
      "key": "ECO05",
      "value": "ECO05"
    },
    {
      "key": "ECO06",
      "value": "ECO06"
    },
    {
      "key": "ECO07",
      "value": "ECO07"
    },
    {
      "key": "ECO08",
      "value": "ECO08"
    },
    {
      "key": "ECO09",
      "value": "ECO09"
    },
    {
      "key": "ECO10",
      "value": "ECO10"
    },
    {
      "key": "ECO11",
      "value": "ECO11"
    },
    {
      "key": "ECO12",
      "value": "ECO12"
    },
    {
      "key": "ECO13",
      "value": "ECO13"
    },
    {
      "key": "ECO14",
      "value": "ECO14"
    },
    {
      "key": "ECO15",
      "value": "ECO15"
    },
    {
      "key": "ECO16",
      "value": "ECO16"
    },
    {
      "key": "ECO17",
      "value": "ECO17"
    },
    {
      "key": "ECO18",
      "value": "ECO18"
    },
    {
      "key": "ECO19",
      "value": "ECO19"
    },
    {
      "key": "ECO20",
      "value": "ECO20"
    },
    {
      "key": "ECO21",
      "value": "ECO21"
    },
    {
      "key": "ECO22",
      "value": "ECO22"
    },
    {
      "key": "ECONE",
      "value": "ECONE"
    },
    {
      "key": "ECONR",
      "value": "ECONR"
    },
    {
      "key": "GCONT",
      "value": "GCONT"
    },
    {
      "key": "GEN01",
      "value": "GEN01"
    },
    {
      "key": "GEN02",
      "value": "GEN02"
    },
    {
      "key": "GEN03",
      "value": "GEN03"
    },
    {
      "key": "GEN13",
      "value": "GEN13"
    },
    {
      "key": "GEN16",
      "value": "GEN16"
    },
    {
      "key": "GEN18",
      "value": "GEN18"
    },
    {
      "key": "GEN19",
      "value": "GEN19"
    },
    {
      "key": "GEN21",
      "value": "GEN21"
    },
    {
      "key": "GEN24",
      "value": "GEN24"
    },
    {
      "key": "GEN27",
      "value": "GEN27"
    },
    {
      "key": "GEN28",
      "value": "GEN28"
    },
    {
      "key": "GEN29",
      "value": "GEN29"
    },
    {
      "key": "GEN30",
      "value": "GEN30"
    },
    {
      "key": "GEN31",
      "value": "GEN31"
    },
    {
      "key": "GEN32",
      "value": "GEN32"
    },
    {
      "key": "GEN33",
      "value": "GEN33"
    },
    {
      "key": "GEN34",
      "value": "GEN34"
    },
    {
      "key": "GEN35",
      "value": "GEN35"
    },
    {
      "key": "GEN36",
      "value": "GEN36"
    },
    {
      "key": "GEN3C",
      "value": "GEN3C"
    },
    {
      "key": "GEN43",
      "value": "GEN43"
    },
    {
      "key": "GEN44",
      "value": "GEN44"
    },
    {
      "key": "GEN45",
      "value": "GEN45"
    },
    {
      "key": "GEN47",
      "value": "GEN47"
    },
    {
      "key": "GEN48",
      "value": "GEN48"
    },
    {
      "key": "GEN49",
      "value": "GEN49"
    },
    {
      "key": "GEN52",
      "value": "GEN52"
    },
    {
      "key": "GEN86",
      "value": "GEN86"
    },
    {
      "key": "GMSPR",
      "value": "GMSPR"
    },
    {
      "key": "IRQ0B",
      "value": "IRQ0B"
    },
    {
      "key": "IRQ0C",
      "value": "IRQ0C"
    },
    {
      "key": "MIB01",
      "value": "MIB01"
    },
    {
      "key": "MORC1",
      "value": "MORC1"
    },
    {
      "key": "NCGDS",
      "value": "NCGDS"
    },
    {
      "key": "NCSMA",
      "value": "NCSMA"
    },
    {
      "key": "OSD01",
      "value": "OSD01"
    },
    {
      "key": "OVR01",
      "value": "OVR01"
    },
    {
      "key": "PRO01",
      "value": "PRO01"
    },
    {
      "key": "PRO02",
      "value": "PRO02"
    },
    {
      "key": "PRO03",
      "value": "PRO03"
    },
    {
      "key": "PRO04",
      "value": "PRO04"
    },
    {
      "key": "PRO05",
      "value": "PRO05"
    },
    {
      "key": "PRO06",
      "value": "PRO06"
    },
    {
      "key": "PRO07",
      "value": "PRO07"
    },
    {
      "key": "PRO08",
      "value": "PRO08"
    },
    {
      "key": "PRO09",
      "value": "PRO09"
    },
    {
      "key": "PRO10",
      "value": "PRO10"
    },
    {
      "key": "PRO11",
      "value": "PRO11"
    },
    {
      "key": "RCD01",
      "value": "RCD01"
    },
    {
      "key": "RCONE",
      "value": "RCONE"
    },
    {
      "key": "RED01",
      "value": "RED01"
    },
    {
      "key": "ROY01",
      "value": "ROY01"
    },
    {
      "key": "ROY02",
      "value": "ROY02"
    },
    {
      "key": "RPTID",
      "value": "RPTID"
    },
    {
      "key": "RVAT1",
      "value": "RVAT1"
    },
    {
      "key": "SHNMO",
      "value": "SHNMO"
    },
    {
      "key": "TAPAR",
      "value": "TAPAR"
    },
    {
      "key": "TOR01",
      "value": "TOR01"
    },
    {
      "key": "WHSRP",
      "value": "WHSRP"
    },
    {
      "key": "MOVE2",
      "value": "MOVE2"
    },
    {
      "key": "MOVE3",
      "value": "MOVE3"
    },
    {
      "key": "MOVE4",
      "value": "MOVE4"
    },
    {
      "key": "MOVE1",
      "value": "MOVE1"
    },
    {
      "key": "TSPER",
      "value": "TSPER"
    },
    {
      "key": "VAT03",
      "value": "VAT03"
    },
    {
      "key": "VAT05",
      "value": "VAT05"
    },
    {
      "key": "REV01",
      "value": "REV01"
    },
    {
      "key": "REV02",
      "value": "REV02"
    },
    {
      "key": "REV03",
      "value": "REV03"
    },
    {
      "key": "REV04",
      "value": "REV04"
    },
    {
      "key": "REV05",
      "value": "REV05"
    },
    {
      "key": "REV06",
      "value": "REV06"
    },
    {
      "key": "REV08",
      "value": "REV08"
    },
    {
      "key": "REV09",
      "value": "REV09"
    },
    {
      "key": "REV10",
      "value": "REV10"
    },
    {
      "key": "PRO12",
      "value": "PRO12"
    },
    {
      "key": "PRO13",
      "value": "PRO13"
    },
    {
      "key": "PAR01",
      "value": "PAR01"
    },
    {
      "key": "PAR03",
      "value": "PAR03"
    },
    {
      "key": "POD07",
      "value": "POD07"
    },
    {
      "key": "POD08",
      "value": "POD08"
    },
    {
      "key": "POD09",
      "value": "POD09"
    },
    {
      "key": "POD10",
      "value": "POD10"
    },
    {
      "key": "POD11",
      "value": "POD11"
    },
    {
      "key": "POD12",
      "value": "POD12"
    },
    {
      "key": "POD13",
      "value": "POD13"
    },
    {
      "key": "POD14",
      "value": "POD14"
    },
    {
      "key": "POD15",
      "value": "POD15"
    },
    {
      "key": "POD16",
      "value": "POD16"
    },
    {
      "key": "POD17",
      "value": "POD17"
    },
    {
      "key": "POD18",
      "value": "POD18"
    },
    {
      "key": "POD19",
      "value": "POD19"
    },
    {
      "key": "POD20",
      "value": "POD20"
    },
    {
      "key": "POD21",
      "value": "POD21"
    },
    {
      "key": "POD22",
      "value": "POD22"
    },
    {
      "key": "POD23",
      "value": "POD23"
    },
    {
      "key": "POD24",
      "value": "POD24"
    },
    {
      "key": "GEN06",
      "value": "GEN06"
    },
    {
      "key": "GEN08",
      "value": "GEN08"
    },
    {
      "key": "GEN09",
      "value": "GEN09"
    },
    {
      "key": "GEN11",
      "value": "GEN11"
    },
    {
      "key": "GBILB",
      "value": "GBILB"
    },
    {
      "key": "NIDOM",
      "value": "NIDOM"
    },
    {
      "key": "NIIMP",
      "value": "NIIMP"
    },
    {
      "key": "NIAID",
      "value": "NIAID"
    },
    {
      "key": "NIREM",
      "value": "NIREM"
    },
    {
      "key": "OSR42",
      "value": "OSR42"
    },
    {
      "key": "UVC01",
      "value": "UVC01"
    },
    {
      "key": "OVR02",
      "value": "OVR02"
    },
    {
      "key": "OVR03",
      "value": "OVR03"
    },
    {
      "key": "CPAPP",
      "value": "CPAPP"
    },
    {
      "key": "BFAPP",
      "value": "BFAPP"
    },
    {
      "key": "NCHAP",
      "value": "NCHAP"
    },
  ];

  export default additionalInformationCodes