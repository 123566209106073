import { useTranslation } from "react-i18next";
import elements from "../../../../constants/validation";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";

const TableHead = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const { arrayItems } = props;
	const { t } = useTranslation();

	const retrieveColumnNames = () => {
		return arrayItems.map(
			(item) =>
				elements.filter((tmpElement) => tmpElement.elementName === item.elementName)[0]
					?.textDescription
		);
	};

	return (
		<thead className={withTheme("table-head")}>
			<tr>
				<th scope="col">#</th>
				{retrieveColumnNames().map((item, index) => (
					<th scope="col" key={index}>
						{item}
					</th>
				))}
				<th scope="col">{t("actions")}</th>
			</tr>
		</thead>
	);
};

export default TableHead;
