import currencies from "./validation-pulldown-options/currencies";

const settings = [
    {
        elementName: "settingsNoOfSearchRecords",
        type: 'pulldown',
        pullDownMenu: [
            {
                "key": "10",
                "value": "10"
            },
            {
                "key": "25",
                "value": "25"
            },
            {
                "key": "50",
                "value": "50"
            },
            {
                "key": "100",
                "value": "100"
            }
        ],
        "validation": false,
        "validationLogic": {},
        textDescription: "No. of search records",
    },
    {
        elementName: "language",
        type: 'pulldown',
        pullDownMenu: [
            {
                "key": "en",
                "value": "English"
            },
            {
                "key": "fr",
                "value": "French"
            }
        ],
        "validation": false,
        "validationLogic": {},
        textDescription: "Display Language",
    },
    {
        elementName: "notifications",
        type: 'pulldown',
        pullDownMenu: [
            {
                "key": "App",
                "value": "Navigation Bar"
            },
            {
                "key": "Email",
                "value": "Email"
            }
        ],
        "validation": false,
        "validationLogic": {},
        textDescription: "Notification Options",
    },
    {
        elementName: "settingsTheme",
        type: 'pulldown',
        pullDownMenu: [
            {
                "key": "Theme1",
                "value": "Theme 1"
            },
            {
                "key": "Theme2",
                "value": "Theme 2"
            }
        ],
        "validation": false,
        "validationLogic": {},
        textDescription: "Theme options",
    },
    {
        elementName: "settingsSecureCallNo",
        validation: false,
        validationLogic: {},
        textDescription: "Secure Call Number",
        readOnly: true,
        type: 'text',
    },
    {
        elementName: "settingsUserEORI",
        validation: false,
        validationLogic: {},
        textDescription: "Your EORI number",
        readOnly: true,
        type: 'text',
    },
    {
        type: 'text',
        elementName: "settingsUserEmail",
        validation: false,
        validationLogic: {},
        textDescription: "Your Email address",
    },
    {
        type: 'text',
        elementName: "settingsUserName",
        validation: false,
        validationLogic: {},
        textDescription: "Your First Name",
    },
    {
        type: 'text',
        elementName: "settingsUserSurname",
        validation: false,
        validationLogic: {},
        textDescription: "Your Last Name",
    },
    {
        type: 'text',
        elementName: "settingsUserAddress1",
        validation: false,
        validationLogic: {},
        textDescription: "Address Line 1",
    },
    {
        type: 'text',
        elementName: "settingsUserAddress2",
        validation: false,
        validationLogic: {},
        textDescription: "Address Line 2",
    },
    {
        type: 'text',
        elementName: "settingsUserAddress3",
        validation: false,
        validationLogic: {},
        textDescription: "Address Line 3",
    },
    {
        type: 'text',
        elementName: "settingsUserAddress4",
        validation: false,
        validationLogic: {},
        textDescription: "Address Line 4",
    },
    {
        type: 'text',
        elementName: "settingsUserCompany",
        validation: false,
        validationLogic: {},
        textDescription: "Your company details",
    },
    {
        type: 'text',
        elementName: "settingsUserCountry",
        validation: false,
        validationLogic: {},
        textDescription: "Country",
    },
    {
        type: 'text',
        elementName: "settingsUserPostcode",
        validation: false,
        validationLogic: {},
        textDescription: "Postcode of your address",
    },
    {
        type: 'phoneNumber',
        elementName: "settingsUserPhoneNo",
        validation: false,
        validationLogic: {},
        textDescription: "Your Phone number",
    },
    {
        type: 'phoneNumber',
        elementName: "settingsUserMobileNo",
        validation: false,
        validationLogic: {},
        textDescription: "Your Mobile number",
    },
    {
        elementName: "settingsUserSubscription",
        type: 'pulldown',
        pullDownMenu: [
            {
                "key": "Sub1",
                "value": "Subscription 1"
            },
            {
                "key": "Sub2",
                "value": "Subscription 2"
            }
        ],
        textDescription: "Subscription options",
    },
    {
        type: 'text',
        elementName: "settingsUserDeferredPaymentAccount",
        validation: false,
        validationLogic: {},
        textDescription: "Your Deferred Payment Account (if any)",
        isMandatory: false,
        readOnly: true,
    },
    {
        elementName: "settingsUserCurrency",
        type: 'pulldown',
        pullDownMenu: currencies,
        validation: false,
        validationLogic: {},
        textDescription: "Default Currency",
    }
]

export default settings;