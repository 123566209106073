import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { PiPencilSimple, PiPlus } from "react-icons/pi";

const InputsActions = (props) => {
	const { t } = useTranslation();
	const {
		items,
		limit,
		selectedRowIndex,
		setSelectedRowIndex,
		inputValues,
		setInputValues,
		updateItems,
		setSelectedPage,
		disabled,
		ActionsComponent,
	} = props;

	const handleCancelClick = () => {
		setInputValues({});
		setSelectedRowIndex(null);
	};

	const handleSaveRowChangesClick = () => {
		items.forEach((item, index) => {
			if (index === parseInt(selectedRowIndex)) {
				items[index] = { ...item[index], ...inputValues };
			}
		});

		updateItems(items);
		setInputValues({});
		setSelectedRowIndex(null);
	};

	const handleResetClick = () => {
		setInputValues({});
	};

	const handleAddClick = () => {
		updateItems([...items, inputValues]);
		setInputValues({});

		// Select the page to which this new item has been added
		setSelectedPage(Math.floor(items.length / 10));
	};

	return (
		<div
			style={{
				marginTop: 20,
				paddingBottom: 20,
				display: "flex",
				alignItems: "center",
				justifyContent: !ActionsComponent ? "center" : null,
				borderBottom: "1px solid #D7DAE0",
			}}
		>
			{ActionsComponent}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					width: "fit-content",
					gap: 12,
					marginLeft: ActionsComponent ? "auto" : null,
				}}
			>
				{selectedRowIndex !== null && (
					<>
						<Button
							type="secondary"
							disabled={disabled}
							onClick={handleCancelClick}
						>
							{t("cancel")}
						</Button>

						<Button LeftIcon={PiPencilSimple} onClick={handleSaveRowChangesClick}>
							{t("modify")}
						</Button>
					</>
				)}

				{/* Action Buttons */}
				{selectedRowIndex === null && (
					<>
						<Button type="secondary" disabled={disabled} onClick={handleResetClick}>
							{t("reset")}
						</Button>

						<Button
							disabled={items.length === limit || disabled}
							LeftIcon={PiPlus}
							onClick={handleAddClick}
						>
							{t("add")}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default InputsActions;
