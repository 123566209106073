import {  useState } from "react";
import SearchPage from "../components/layouts/declarations/SectionsSearch/SearchPage/SearchPage";
import PageContainer from "../components/layouts/PageContainer";
import { useTranslation } from "react-i18next";
import { SEARCH_TYPES, SEARCH_TYPE_ELEMENTS } from "../constants/GlobalConstants";

const GoodsLocations = () => {
	const [searchValue, setSearchValue] = useState("");
	const { t } = useTranslation();

	return (
		<PageContainer title={t("goodsLocations")}>
			<SearchPage
				standalone={true}
				searchType={SEARCH_TYPES.GOODS_LOCATIONS}
				searchResultElements={SEARCH_TYPE_ELEMENTS.GOODS_LOCATIONS}
				initialSearchValue={searchValue}
				onSearchInputChange={(e) => setSearchValue(e.target.value)}
			/>
		</PageContainer>
	);
};

export default GoodsLocations;
