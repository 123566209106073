import { useContext, useMemo } from "react";
import ThemeContext from "../../../../../../../../context/theme/ThemeContext";
import validationElements from "../../../../../../../../constants/validation";
import ConstantsBasedPullDownMenu from "../../../../../../../common/ConstantsBasedPulDownMenu/ConstantsBasedPullDownMenu";
import ConstantsBasedTextInput from "../../../../../../../common/ConstantsBasedTextInput/ConstantsBasedTextInput";

const SearchItemElement = ({ elementName, value, error, onInputChange, isEditModeEnabled }) => {
	const { withTheme } = useContext(ThemeContext);

	const element = useMemo(
		() => validationElements.find((element) => element.elementName === elementName),
		[elementName]
	);

	if (!element) {
		return null;
	}

	const isPullDownElement = element.pullDownMenu?.length;

	if (isEditModeEnabled) {
		if (isPullDownElement) {
			return (
				<div className={withTheme("item-element-container")}>
					<ConstantsBasedPullDownMenu
						value={value || ""}
						onChange={onInputChange}
						element={element}
						error={error}
					/>
				</div>
			);
		} else {
			return (
				<div className={withTheme("item-element-container")}>
					<ConstantsBasedTextInput
						value={value || ""}
						onChange={onInputChange}
						element={element}
						error={error}
					/>
				</div>
			);
		}
	} else {
		return (
			<div className={withTheme("item-element-container")}>
				<div className={withTheme("item-element-name")}>{element.textDescription}</div>
				<div className={withTheme("item-element-value")}>{value || "none"}</div>
			</div>
		);
	}
};

export default SearchItemElement;
