import { isArray, isNumber, isObject, isString } from "lodash";
import moment from "moment";

/** A date object always contains a proeprty called DateTimeString */
export const isDateObject = (object) => Boolean(object.DateTimeString);

/** Retrieves the unix date value from the #text property in a date object and formats it */
export const getDateObjectValue = (object) => {
	return moment(object.DateTimeString["#text"], "YYYYMMDDHHmmssZ").format("DD MMM YY, hh:mm A");
};

/** Identifies a currency object if it contains a currency property */
export const isCurrencyObject = (object) => Boolean(object["@_currencyID"]);

/** Merges the text and currency properties in a single string */
export const getCurrencyObjectValue = (object) => {
	return `${object["#text"]} ${object["@_currencyID"]}`;
};

/** Sorts a data object to be in the following order
 * 1- String/Number properties
 * 2- Arrays
 * 3- Nested Objects
 */
export const sortData = (data) => {
	let result = {};

	const sortedKeys = Object.keys(data).sort((first, second) => {
		const isFirstNotAnObject = !isObjectDataEntry(data[first]) || !objectContainsNestedObjects(data[first]);
		const isSecondNotAnObject =
			!isObjectDataEntry(data[second]) || !objectContainsNestedObjects(data[second]);

		if (isFirstNotAnObject) {
			if (isSecondNotAnObject) {
				if (!isArray(data[first])) {
					return -1;
				} else {
					return 1;
				}
			}
			return -1;
		}

		return 2;
	});

	sortedKeys.forEach((key) => {
		result[key] = data[key];
	});

	return result;
};

export const isObjectDataEntry = (entry) => {
	return isObject(entry) && !isArray(entry) && !isDateObject(entry) && !isCurrencyObject(entry);
};

export const objectContainsNestedObjects = (object) => {
	if (!isObject(object) || isArray(object)) {
		return false;
	}

	return Object.values(object).find((entry) => isObjectDataEntry(entry));
};

export const getElementValue = (data) => {
	if (isDateObject(data)) {
		return getDateObjectValue(data);
	} else if (isCurrencyObject(data)) {
		return getCurrencyObjectValue(data);
	} else if (isObject(data) && data["#text"]) {
		return data["#text"];
	} else if (isString(data) || isNumber(data)) {
		return data;
	}

	return "";
};
