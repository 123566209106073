import React, { useEffect, useState, useContext, useRef } from "react";
import TabsContainer from "./Tabs/TabsContainer";
import DeclarationsList from "../../components/layouts/declarations/DeclarationsList/index";
import DeclarationsContext from "../../context/declarations/DeclarationsContext";
import ThemeContext from "../../context/theme/ThemeContext";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getQueryParam, removeQueryParam, updateQueryParam } from "../../router/utils";
import DeclarationPageHeader from "../../components/layouts/declarations/PageHeader/Header";
import { useBeforeunload } from "react-beforeunload";
import PageContainer from "../../components/layouts/PageContainer";
import { DECLARATION_ID_QUERY_PARAM, ELEMENT_QUERY_PARAM } from "../../constants/GlobalConstants";
import UnsavedChangesModal from "../../components/modals/UnsavedChangesModal";
import EmptyPageMessage from "../../components/common/EmptyPageMessage";
import declarationsErrorIllust from "../../assets/images/declarations-error-illust.png";
import notFoundIllust from "../../assets/images/not-found-illust.png";
import NewDeclarationModal from "../../components/layouts/declarations/NewDeclarationModal/NewDeclarationModal";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const DeclarationLayout = () => {
	const {
		state,
		setHasUnsavedChanges,
		setSelectedDeclaration,
		listDeclarations,
		clearSelectedDeclaration,
		getDeclarationById,
	} = useContext(DeclarationsContext);
	const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);
	const [isNewDeclarationModalOpen, setIsNewDeclarationModalOpen] = useState(false);
	const [isDeclarationNotFound, setIsDeclarationNotFound] = useState(false);
	const [loadingAction, setLoadingAction] = useState();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const location = useLocation();
	const history = useHistory();
	const { declarations, declarationsLoading, loadingDeclarationId } = state;
	const { withTheme } = useContext(ThemeContext);
	const loadedDeclarationIdQuery = useRef(null);
	const { t } = useTranslation();

	useBeforeunload((event) => {
		if (state.hasUnsavedChanges) {
			event.preventDefault();
		}
	});

	const findDeclarationAndSelect = async (_id) => {
		removeQueryParam(ELEMENT_QUERY_PARAM);

		if (state.loadingDeclarationId) {
			return;
		}
		setIsDeclarationNotFound(false);

		// TODO: Handle request cancelling
		try {
			const declaration = await getDeclarationById(_id);

			clearSelectedDeclaration();
			setSelectedDeclaration({ ...declaration });
			setHasUnsavedChanges(false);
		} catch (e) {
			console.log(e);

			setIsDeclarationNotFound(true);
		}
	};

	const updateStateFromQueryParams = () => {
		const declarationIdQuery = getQueryParam(DECLARATION_ID_QUERY_PARAM);

		if (loadedDeclarationIdQuery.current !== declarationIdQuery) {
			loadedDeclarationIdQuery.current = declarationIdQuery;

			if (declarationIdQuery) {
				findDeclarationAndSelect(declarationIdQuery);
			} else {
				clearSelectedDeclaration();
			}
		}
	};

	// Listen to URL changes and update the selected declaration from the _id parameter
	useEffect(() => {
		const eventHandler = () => {
			updateStateFromQueryParams();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, [state.declaration, state.loadingDeclarationId, declarations]);

	// useEffect(() => {
	// 	const declarationId = getQueryParam(DECLARATION_ID_QUERY_PARAM);

	// 	if (declarationId && declarations.length) {
	// 		findDeclarationAndSelect(declarationId);
	// 	}
	// }, []);

	useEffect(() => {
		if (!declarationsLoading && !state.declarations.length) {
			listDeclarations();
		}

		return function cleanup() {
			setHasUnsavedChanges(false);
		};
	}, []);

	useEffect(() => {
		updateStateFromQueryParams();
	}, [location]);

	return (
		<PageContainer title={t("declarations")} style={{ padding: 0 }}>
			<NewDeclarationModal
				isOpen={isNewDeclarationModalOpen}
				onSubmit={({ _id }) => updateQueryParam(DECLARATION_ID_QUERY_PARAM, _id)}
				onClose={() => setIsNewDeclarationModalOpen(false)}
			/>

			<UnsavedChangesModal hasUnsavedChanges={state.hasUnsavedChanges} />

			<div style={{ display: "flex", width: "100%" }}>
				<DeclarationsList
					declarationsLoading={declarationsLoading}
					isExpanded={isDrawerExpanded}
					onAddClick={() => setIsNewDeclarationModalOpen(true)}
					setIsExpanded={setIsDrawerExpanded}
				/>
				<div
					style={{
						transition: "0.3s",
						width: `calc(100% - ${isDrawerExpanded ? 320 : 64}px)`,
						paddingLeft: 40,
						paddingRight: 40,
					}}
				>
					<DeclarationPageHeader
						selectedTabIndex={selectedTabIndex}
						loadingAction={loadingAction}
						setLoadingAction={setLoadingAction}
					/>

					{!declarationsLoading && !loadingDeclarationId && (
						<>
							{isDeclarationNotFound ? (
								<EmptyPageMessage
									image={notFoundIllust}
									text={t("declarationNotFound")}
									actionButtonLabel={t("backToPreviousPage")}
									onActionButtonClick={() => history.push(-1)}
								/>
							) : !state.declarations.length ? (
								<EmptyPageMessage
									image={declarationsErrorIllust}
									text={t("noDeclarationsYet")}
									actionButtonLabel={t("addNewDeclaration")}
									onActionButtonClick={() =>
										setIsNewDeclarationModalOpen(true)
									}
								/>
							) : state.declaration ? (
								<TabsContainer
									selectedTabIndex={selectedTabIndex}
									setSelectedTabIndex={setSelectedTabIndex}
								/>
							) : (
								<div
									style={{ paddingTop: 92 }}
									className={withTheme("empty-page-text")}
								>
									{t("selectDeclaration")}
								</div>
							)}
						</>
					)}

					{(declarationsLoading || Boolean(loadingDeclarationId)) && (
						<div className={withTheme("declarations-loading-container")}>
							<Spinner animation="border" role="status" />
						</div>
					)}
				</div>
			</div>
		</PageContainer>
	);
};

export default DeclarationLayout;
