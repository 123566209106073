import { PiUploadSimple } from "react-icons/pi";
import Drawer from "../../navigation/Drawer";
import { useContext, useMemo, useState } from "react";
import SearchInput from "../../common/SearchInput";
import FilesContext from "../../../context/files/FilesContext";
import ListItem, { ListItemSubTitle, ListItemTitle } from "../../common/ListItem";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { updateQueryParam } from "../../../router/utils";
import { FILE_ID_QUERY_PARAM } from "../../../constants/GlobalConstants";

const FilesDrawer = ({ isExpanded, isLoading, selectedFile, setIsNewFileModalOpen, setIsExpanded }) => {
	const { files } = useContext(FilesContext);
	const [searchValue, setSearchValue] = useState("");
	const [sort, setSort] = useState("oldestFirst");

	const filteredFiles = useMemo(() => {
		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			return files?.filter((file) => file.Key.toLowerCase().includes(lowerCaseSearchValue)) || [];
		} else {
			return files || [];
		}
	}, [searchValue, files]);

	return (
		<Drawer
			isExpanded={isExpanded}
			setIsExpanded={setIsExpanded}
			CollapsedIcon={PiUploadSimple}
			sort={sort}
			onSortChange={setSort}
			onAddClick={() => setIsNewFileModalOpen(true)}
		>
			<div
				style={{
					paddingRight: 20,
					paddingLeft: 20,
					display: "flex",
					alignItems: "center",
				}}
			>
				<SearchInput
					containerStyle={{ width: "100%" }}
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
				/>
			</div>

			<div style={{ marginTop: 16 }}>
				{isLoading ? (
					<Spinner style={{ marginTop: 16 }} animation="border" />
				) : (
					filteredFiles.map((file, index) => (
						<ListItem
							key={index}
							isSelected={selectedFile?._id === file._id}
							onClick={() => updateQueryParam(FILE_ID_QUERY_PARAM, file._id)}
						>
							<ListItemTitle style={{ fontWeight: 600 }}>
								{file.label}
							</ListItemTitle>

							<ListItemSubTitle style={{ fontSize: 14 }}>
								{moment(file.createdAt).format("DD MMM YYYY")}
							</ListItemSubTitle>
						</ListItem>
					))
				)}
			</div>
		</Drawer>
	);
};

export default FilesDrawer;
