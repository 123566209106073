import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";

const Input = ({ label, error, containerStyle, ...rest }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={{ textAlign: "left", ...containerStyle }}>
			{label && (
				<div style={{ marginBottom: 4 }} className={withTheme("input-label")}>
					{label}
				</div>
			)}
			<input {...rest} className={withTheme("input")} />

			<div
				style={{
					minHeight: error ? 10 : 0,
					maxHeight: error ? 10 : 0,
				}}
				className={withTheme("error-text")}
			>
				{error}
			</div>
		</div>
	);
};

export default Input;
