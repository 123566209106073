import { startCase } from 'lodash';
import { useMemo } from 'react';
import Table from 'react-bootstrap/Table'

const TableDisplayElement = ({ element }) => {
    const tableProperties = useMemo(() => {
        let properties = [];
        element.forEach(entry => {
            Object.keys(entry).forEach(key => {
                if (!properties.includes(key)) {
                    properties.push(key)
                }
            })
        })

        return properties
    }, []);

    return (
        <Table striped bordered>
            <thead>
                {/* Retrieve the header row titles from the first object in the array element */}
                <tr>
                    {tableProperties.map((property, index) => (
                        <th style={{ fontSize: 12 }} key={index}>
                            {startCase(property)}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {element.map((entry, entryIndex) => (
                    <tr style={{ fontSize: 12 }} key={entryIndex}>
                        {tableProperties.map((property, valueIndex) => (
                            <td key={valueIndex}>{entry[property]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table >
    )
};

export default TableDisplayElement;