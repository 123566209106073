import { LS_SELECTED_WORKSPACE_KEY } from "../../constants/GlobalConstants";
import { SET_SELECTED_WORKSPACE } from "../types";

const workspacesReducer = (state, action) => {
	switch (action.type) {
		// case SET_USER_INFO: {
		// 	return {
		// 		...state,
		// 		user: action.payload,
		// 	};
		// }
		// case SET_USER_SETTINGS: {
		// 	return {
		// 		...state,
		// 		settings: action.payload,
		// 	};
		// }

		case SET_SELECTED_WORKSPACE: {
         localStorage.setItem(LS_SELECTED_WORKSPACE_KEY, action.payload);
         
			return {
				...state,
				selectedWorkspaceId: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default workspacesReducer;
