const airports = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "AAE",
        "value": "Rabah Bitat Airport, Annaba, Algeria"
    },
    {
        "key": "AAL",
        "value": "Aalborg Airport, Aalborg, Denmark"
    },
    {
        "key": "AAR",
        "value": "Aarhus Airport, Aarhus, Denmark"
    },
    {
        "key": "ABJ",
        "value": "Port Bouet Airport, Abidjan, Cote d'Ivoire"
    },
    {
        "key": "ABV",
        "value": "Nnamdi Azikiwe International Airport, Abuja, Nigeria"
    },
    {
        "key": "ACC",
        "value": "Kotoka International Airport, Accra, Ghana"
    },
    {
        "key": "ACE",
        "value": "Lanzarote Airport, Arrecife, Spain"
    },
    {
        "key": "AES",
        "value": "Ålesund Airport, Alesund, Norway"
    },
    {
        "key": "AGA",
        "value": "Al Massira Airport, Agadir, Morocco"
    },
    {
        "key": "AHU",
        "value": "Cherif Al Idrissi Airport, Al Hociema, Morocco"
    },
    {
        "key": "AJY",
        "value": "Mano Dayak International Airport, Agadez, Niger"
    },
    {
        "key": "ALF",
        "value": "Alta Airport, Alta, Norway"
    },
    {
        "key": "ALG",
        "value": "Houari Boumediene Airport, Algier, Algeria"
    },
    {
        "key": "AMS",
        "value": "Amsterdam Airport Schiphol, Amsterdam, Netherlands"
    },
    {
        "key": "ANR",
        "value": "Antwerp International Airport (Deurne), Antwerp, Belgium"
    },
    {
        "key": "ARN",
        "value": "Stockholm-Arlanda Airport, Stockholm, Sweden"
    },
    {
        "key": "ASI",
        "value": "RAF Ascension Island, Wide Awake, Saint Helena"
    },
    {
        "key": "BDU",
        "value": "Bardufoss Airport, Bardufoss, Norway"
    },
    {
        "key": "BFN",
        "value": "Bram Fischer International Airport, Bloemfontein, South Africa"
    },
    {
        "key": "BGF",
        "value": "Bangui M'Poko International Airport, Bangui, Central African Republic"
    },
    {
        "key": "BGO",
        "value": "Bergen Airport Flesland, Bergen, Norway"
    },
    {
        "key": "BJL",
        "value": "Banjul International Airport, Banjul, Gambia"
    },
    {
        "key": "BKO",
        "value": "Modibo Keita International Airport, Bamako, Mali"
    },
    {
        "key": "BLL",
        "value": "Billund Airport, Billund, Denmark"
    },
    {
        "key": "BLZ",
        "value": "Chileka International Airport, Blantyre, Malawi"
    },
    {
        "key": "BMA",
        "value": "Stockholm-Bromma Airport, Stockholm, Sweden"
    },
    {
        "key": "BNN",
        "value": "Brønnøysund Airport, Bronnoysund, Norway"
    },
    {
        "key": "BOO",
        "value": "Bodø Airport, Bodo, Norway"
    },
    {
        "key": "BRE",
        "value": "Bremen Airport, Bremen, Germany"
    },
    {
        "key": "BRU",
        "value": "Brussels Airport, Brussels, Belgium"
    },
    {
        "key": "BUQ",
        "value": "Joshua Mqabuko Nkomo International Airport, Bulawayo, Zimbabwe"
    },
    {
        "key": "BZV",
        "value": "Maya-Maya Airport, Brazzaville, Congo (Brazzaville)"
    },
    {
        "key": "CAB",
        "value": "Cabinda Airport, Cabinda, Angola"
    },
    {
        "key": "CGN",
        "value": "Cologne Bonn Airport, Cologne, Germany"
    },
    {
        "key": "CMN",
        "value": "Mohammed V International Airport, Casablanca, Morocco"
    },
    {
        "key": "COO",
        "value": "Cadjehoun Airport, Cotonou, Benin"
    },
    {
        "key": "CPH",
        "value": "Copenhagen Kastrup Airport, Copenhagen, Denmark"
    },
    {
        "key": "CPT",
        "value": "Cape Town International Airport, Cape Town, South Africa"
    },
    {
        "key": "CRL",
        "value": "Brussels South Charleroi Airport, Charleroi, Belgium"
    },
    {
        "key": "CZL",
        "value": "Mohamed Boudiaf International Airport, Constantine, Algeria"
    },
    {
        "key": "DJE",
        "value": "Djerba Zarzis International Airport, Djerba, Tunisia"
    },
    {
        "key": "DKR",
        "value": "Léopold Sédar Senghor International Airport, Dakar, Senegal"
    },
    {
        "key": "DLA",
        "value": "Douala International Airport, Douala, Cameroon"
    },
    {
        "key": "DTM",
        "value": "Dortmund Airport, Dortmund, Germany"
    },
    {
        "key": "DUB",
        "value": "Dublin Airport, Dublin, Ireland"
    },
    {
        "key": "DUR",
        "value": "King Shaka International Airport, Durban, South Africa"
    },
    {
        "key": "DUS",
        "value": "Düsseldorf Airport, Duesseldorf, Germany"
    },
    {
        "key": "DZA",
        "value": "Dzaoudzi Pamandzi International Airport, Dzaoudzi, Mayotte"
    },
    {
        "key": "EGS",
        "value": "Egilsstaðir Airport, Egilsstadir, Iceland"
    },
    {
        "key": "EIN",
        "value": "Eindhoven Airport, Eindhoven, Netherlands"
    },
    {
        "key": "ELS",
        "value": "Ben Schoeman Airport, East London, South Africa"
    },
    {
        "key": "EVE",
        "value": "Harstad/Narvik Airport, Evenes, Harstad/Narvik, Norway"
    },
    {
        "key": "FAE",
        "value": "Vagar Airport, Vagar, Faroe Islands"
    },
    {
        "key": "FBM",
        "value": "Lubumbashi International Airport, Lubumashi, Congo (Kinshasa)"
    },
    {
        "key": "FDH",
        "value": "Friedrichshafen Airport, Friedrichshafen, Germany"
    },
    {
        "key": "FEZ",
        "value": "Saïss Airport, Fes, Morocco"
    },
    {
        "key": "FIH",
        "value": "Ndjili International Airport, Kinshasa, Congo (Kinshasa)"
    },
    {
        "key": "FKI",
        "value": "Bangoka International Airport, Kisangani, Congo (Kinshasa)"
    },
    {
        "key": "FNA",
        "value": "Lungi International Airport, Freetown, Sierra Leone"
    },
    {
        "key": "FRA",
        "value": "Frankfurt am Main Airport, Frankfurt, Germany"
    },
    {
        "key": "FRO",
        "value": "Florø Airport, Floro, Norway"
    },
    {
        "key": "FUE",
        "value": "Fuerteventura Airport, Fuerteventura, Spain"
    },
    {
        "key": "GBE",
        "value": "Sir Seretse Khama International Airport, Gaberone, Botswana"
    },
    {
        "key": "GDN",
        "value": "Gdańsk Lech Wałęsa Airport, Gdansk, Poland"
    },
    {
        "key": "GOT",
        "value": "Gothenburg-Landvetter Airport, Gothenborg, Sweden"
    },
    {
        "key": "GOU",
        "value": "Garoua International Airport, Garoua, Cameroon"
    },
    {
        "key": "GRQ",
        "value": "Eelde Airport, Groningen, Netherlands"
    },
    {
        "key": "GSE",
        "value": "Gothenburg City Airport, Gothenborg, Sweden"
    },
    {
        "key": "HAH",
        "value": "Prince Said Ibrahim International Airport, Moroni, Comoros"
    },
    {
        "key": "HAJ",
        "value": "Hannover Airport, Hannover, Germany"
    },
    {
        "key": "HAM",
        "value": "Hamburg Airport, Hamburg, Germany"
    },
    {
        "key": "HAU",
        "value": "Haugesund Airport, Haugesund, Norway"
    },
    {
        "key": "HEL",
        "value": "Helsinki Vantaa Airport, Helsinki, Finland"
    },
    {
        "key": "HHN",
        "value": "Frankfurt-Hahn Airport, Hahn, Germany"
    },
    {
        "key": "HLA",
        "value": "Lanseria Airport, Johannesburg, South Africa"
    },
    {
        "key": "HRE",
        "value": "Robert Gabriel Mugabe International Airport, Harare, Zimbabwe"
    },
    {
        "key": "HVA",
        "value": "Analalava Airport, Analalava, Madagascar"
    },
    {
        "key": "JNB",
        "value": "OR Tambo International Airport, Johannesburg, South Africa"
    },
    {
        "key": "JOS",
        "value": "Yakubu Gowon Airport, Jos, Nigeria"
    },
    {
        "key": "KAN",
        "value": "Mallam Aminu International Airport, Kano, Nigeria"
    },
    {
        "key": "KAO",
        "value": "Kuusamo Airport, Kuusamo, Finland"
    },
    {
        "key": "KEF",
        "value": "Keflavik International Airport, Keflavik, Iceland"
    },
    {
        "key": "KIM",
        "value": "Kimberley Airport, Kimberley, South Africa"
    },
    {
        "key": "KIR",
        "value": "Kerry Airport, Kerry, Ireland"
    },
    {
        "key": "KIW",
        "value": "Southdowns Airport, Southdowns, Zambia"
    },
    {
        "key": "KKN",
        "value": "Kirkenes Airport (Høybuktmoen), Kirkenes, Norway"
    },
    {
        "key": "KRK",
        "value": "Kraków John Paul II International Airport, Krakow, Poland"
    },
    {
        "key": "KRS",
        "value": "Kristiansand Airport, Kristiansand, Norway"
    },
    {
        "key": "KSU",
        "value": "Kristiansund Airport (Kvernberget), Kristiansund, Norway"
    },
    {
        "key": "KTT",
        "value": "Kittilä Airport, Kittila, Finland"
    },
    {
        "key": "KTW",
        "value": "Katowice International Airport, Katowice, Poland"
    },
    {
        "key": "KUO",
        "value": "Kuopio Airport, Kuopio, Finland"
    },
    {
        "key": "LAD",
        "value": "Quatro de Fevereiro Airport, Luanda, Angola"
    },
    {
        "key": "LBC",
        "value": "Lübeck Blankensee Airport, Luebeck, Germany"
    },
    {
        "key": "LBV",
        "value": "Libreville Leon M'ba International Airport, Libreville, Gabon"
    },
    {
        "key": "LEJ",
        "value": "Leipzig/Halle Airport, Leipzig, Germany"
    },
    {
        "key": "LFW",
        "value": "Lomé-Tokoin Airport, Lome, Togo"
    },
    {
        "key": "LGG",
        "value": "Liège Airport, Liege, Belgium"
    },
    {
        "key": "LKL",
        "value": "Banak Airport, Lakselv, Norway"
    },
    {
        "key": "LLA",
        "value": "Luleå Airport, Lulea, Sweden"
    },
    {
        "key": "LLW",
        "value": "Lilongwe International Airport, Lilongwe, Malawi"
    },
    {
        "key": "LOS",
        "value": "Murtala Muhammed International Airport, Lagos, Nigeria"
    },
    {
        "key": "LPA",
        "value": "Gran Canaria Airport, Gran Canaria, Spain"
    },
    {
        "key": "LPP",
        "value": "Lappeenranta Airport, Lappeenranta, Finland"
    },
    {
        "key": "LUN",
        "value": "Kenneth Kaunda International Airport Lusaka, Lusaka, Zambia"
    },
    {
        "key": "LUX",
        "value": "Luxembourg-Findel International Airport, Luxemburg, Luxembourg"
    },
    {
        "key": "LYR",
        "value": "Svalbard Airport, Longyear, Svalbard, Norway"
    },
    {
        "key": "MHQ",
        "value": "Mariehamn Airport, Mariehamn, Finland"
    },
    {
        "key": "MIR",
        "value": "Monastir Habib Bourguiba International Airport, Monastir, Tunisia"
    },
    {
        "key": "MLW",
        "value": "Spriggs Payne Airport, Monrovia, Liberia"
    },
    {
        "key": "MMX",
        "value": "Malmö Sturup Airport, Malmoe, Sweden"
    },
    {
        "key": "MOL",
        "value": "Molde Airport, Molde, Norway"
    },
    {
        "key": "MPM",
        "value": "Maputo Airport, Maputo, Mozambique"
    },
    {
        "key": "MRU",
        "value": "Sir Seewoosagur Ramgoolam International Airport, Plaisance, Mauritius"
    },
    {
        "key": "MST",
        "value": "Maastricht Aachen Airport, Maastricht, Netherlands"
    },
    {
        "key": "MUB",
        "value": "Maun Airport, Maun, Botswana"
    },
    {
        "key": "MUC",
        "value": "Munich Airport, Munich, Germany"
    },
    {
        "key": "MVR",
        "value": "Salak Airport, Maroua, Cameroon"
    },
    {
        "key": "NDJ",
        "value": "N'Djamena International Airport, N'djamena, Chad"
    },
    {
        "key": "NIM",
        "value": "Diori Hamani International Airport, Niamey, Niger"
    },
    {
        "key": "NKC",
        "value": "Nouakchott–Oumtounsy International Airport, Nouakschott, Mauritania"
    },
    {
        "key": "NLA",
        "value": "Simon Mwansa Kapwepwe International Airport, Ndola, Zambia"
    },
    {
        "key": "NOC",
        "value": "Ireland West Knock Airport, Connaught, Ireland"
    },
    {
        "key": "NRK",
        "value": "Norrköping Airport, Norrkoeping, Sweden"
    },
    {
        "key": "NUE",
        "value": "Nuremberg Airport, Nuernberg, Germany"
    },
    {
        "key": "NYO",
        "value": "Stockholm Skavsta Airport, Stockholm, Sweden"
    },
    {
        "key": "ORK",
        "value": "Cork Airport, Cork, Ireland"
    },
    {
        "key": "ORN",
        "value": "Es Senia Airport, Oran, Algeria"
    },
    {
        "key": "OSL",
        "value": "Oslo Lufthavn, Oslo, Norway"
    },
    {
        "key": "OST",
        "value": "Ostend-Bruges International Airport, Ostend, Belgium"
    },
    {
        "key": "OUA",
        "value": "Ouagadougou Airport, Ouagadougou, Burkina Faso"
    },
    {
        "key": "OUD",
        "value": "Angads Airport, Oujda, Morocco"
    },
    {
        "key": "OUL",
        "value": "Oulu Airport, Oulu, Finland"
    },
    {
        "key": "OZZ",
        "value": "Ouarzazate Airport, Ouarzazate, Morocco"
    },
    {
        "key": "PHC",
        "value": "Port Harcourt International Airport, Port Hartcourt, Nigeria"
    },
    {
        "key": "PLZ",
        "value": "Port Elizabeth Airport, Port Elizabeth, South Africa"
    },
    {
        "key": "PNR",
        "value": "Pointe Noire Airport, Pointe-noire, Congo (Brazzaville)"
    },
    {
        "key": "POG",
        "value": "Port Gentil Airport, Port Gentil, Gabon"
    },
    {
        "key": "POL",
        "value": "Pemba Airport, Pemba, Mozambique"
    },
    {
        "key": "POM",
        "value": "Port Moresby Jacksons International Airport, Port Moresby, Papua New Guinea"
    },
    {
        "key": "POZ",
        "value": "Poznań-Ławica Airport, Poznan, Poland"
    },
    {
        "key": "PRI",
        "value": "Praslin Airport, Praslin, Seychelles"
    },
    {
        "key": "PRY",
        "value": "Wonderboom Airport, Pretoria, South Africa"
    },
    {
        "key": "PTG",
        "value": "Polokwane International Airport, Potgietersrus, South Africa"
    },
    {
        "key": "RAK",
        "value": "Menara Airport, Marrakech, Morocco"
    },
    {
        "key": "RBA",
        "value": "Rabat-Salé Airport, Rabat, Morocco"
    },
    {
        "key": "ROB",
        "value": "Roberts International Airport, Monrovia, Liberia"
    },
    {
        "key": "RTM",
        "value": "Rotterdam The Hague Airport, Rotterdam, Netherlands"
    },
    {
        "key": "RUN",
        "value": "Roland Garros Airport, St.-denis, Reunion"
    },
    {
        "key": "RVN",
        "value": "Rovaniemi Airport, Rovaniemi, Finland"
    },
    {
        "key": "RZE",
        "value": "Rzeszów-Jasionka Airport, Rzeszow, Poland"
    },
    {
        "key": "SEZ",
        "value": "Seychelles International Airport, Mahe, Seychelles"
    },
    {
        "key": "SFA",
        "value": "Sfax Thyna International Airport, Sfax, Tunisia"
    },
    {
        "key": "SFJ",
        "value": "Kangerlussuaq Airport, Sondrestrom, Greenland"
    },
    {
        "key": "SNN",
        "value": "Shannon Airport, Shannon, Ireland"
    },
    {
        "key": "SPC",
        "value": "La Palma Airport, Santa Cruz De La Palma, Spain"
    },
    {
        "key": "SSG",
        "value": "Malabo Airport, Malabo, Equatorial Guinea"
    },
    {
        "key": "STR",
        "value": "Stuttgart Airport, Stuttgart, Germany"
    },
    {
        "key": "SVG",
        "value": "Stavanger Airport Sola, Stavanger, Norway"
    },
    {
        "key": "SXF",
        "value": "Berlin-Schönefeld Airport, Berlin, Germany"
    },
    {
        "key": "SZZ",
        "value": "Szczecin-Goleniów - Solidarność Airport, Szczecin, Poland"
    },
    {
        "key": "TAY",
        "value": "Tartu Airport, Tartu, Estonia"
    },
    {
        "key": "TFN",
        "value": "Tenerife Norte Airport, Tenerife, Spain"
    },
    {
        "key": "TFS",
        "value": "Tenerife South Airport, Tenerife, Spain"
    },
    {
        "key": "TKU",
        "value": "Turku Airport, Turku, Finland"
    },
    {
        "key": "TLL",
        "value": "Lennart Meri Tallinn Airport, Tallinn-ulemiste International, Estonia"
    },
    {
        "key": "TMP",
        "value": "Tampere-Pirkkala Airport, Tampere, Finland"
    },
    {
        "key": "TMS",
        "value": "São Tomé International Airport, Sao Tome, Sao Tome and Principe"
    },
    {
        "key": "TNG",
        "value": "Ibn Batouta Airport, Tanger, Morocco"
    },
    {
        "key": "TNR",
        "value": "Ivato Airport, Antananarivo, Madagascar"
    },
    {
        "key": "TOS",
        "value": "Tromsø Airport,, Tromso, Norway"
    },
    {
        "key": "TRD",
        "value": "Trondheim Airport Værnes, Trondheim, Norway"
    },
    {
        "key": "TRF",
        "value": "Sandefjord Airport, Torp, Sandefjord, Norway"
    },
    {
        "key": "TUN",
        "value": "Tunis Carthage International Airport, Tunis, Tunisia"
    },
    {
        "key": "TXL",
        "value": "Berlin-Tegel Airport, Berlin, Germany"
    },
    {
        "key": "UAK",
        "value": "Narsarsuaq Airport, Narssarssuaq, Greenland"
    },
    {
        "key": "UME",
        "value": "Umeå Airport, Umea, Sweden"
    },
    {
        "key": "VAA",
        "value": "Vaasa Airport, Vaasa, Finland"
    },
    {
        "key": "VBY",
        "value": "Visby Airport, Visby, Sweden"
    },
    {
        "key": "VST",
        "value": "Stockholm Västerås Airport, Vasteras, Sweden"
    },
    {
        "key": "VXO",
        "value": "Växjö Kronoberg Airport, Vaxjo, Sweden"
    },
    {
        "key": "WAW",
        "value": "Warsaw Chopin Airport, Warsaw, Poland"
    },
    {
        "key": "WRO",
        "value": "Copernicus Wrocław Airport, Wroclaw, Poland"
    },
    {
        "key": "YAO",
        "value": "Yaoundé Airport, Yaounde, Cameroon"
    },
    {
        "key": "YEG",
        "value": "Edmonton International Airport, Edmonton, Canada"
    },
    {
        "key": "YFC",
        "value": "Fredericton Airport, Fredericton, Canada"
    },
    {
        "key": "YHM",
        "value": "John C. Munro Hamilton International Airport, Hamilton, Canada"
    },
    {
        "key": "YHZ",
        "value": "Halifax / Stanfield International Airport, Halifax, Canada"
    },
    {
        "key": "YLW",
        "value": "Kelowna International Airport, Kelowna, Canada"
    },
    {
        "key": "YMM",
        "value": "Fort McMurray Airport, Fort Mcmurray, Canada"
    },
    {
        "key": "YMX",
        "value": "Montreal International (Mirabel) Airport, Montreal, Canada"
    },
    {
        "key": "YOW",
        "value": "Ottawa Macdonald-Cartier International Airport, Ottawa, Canada"
    },
    {
        "key": "YQB",
        "value": "Quebec Jean Lesage International Airport, Quebec, Canada"
    },
    {
        "key": "YQG",
        "value": "Windsor Airport, Windsor, Canada"
    },
    {
        "key": "YQM",
        "value": "Greater Moncton International Airport, Moncton, Canada"
    },
    {
        "key": "YQR",
        "value": "Regina International Airport, Regina, Canada"
    },
    {
        "key": "YQT",
        "value": "Thunder Bay Airport, Thunder Bay, Canada"
    },
    {
        "key": "YQX",
        "value": "Gander International Airport, Gander, Canada"
    },
    {
        "key": "YTZ",
        "value": "Billy Bishop Toronto City Centre Airport, Toronto, Canada"
    },
    {
        "key": "YUL",
        "value": "Montreal / Pierre Elliott Trudeau International Airport, Montreal, Canada"
    },
    {
        "key": "YVR",
        "value": "Vancouver International Airport, Vancouver, Canada"
    },
    {
        "key": "YWG",
        "value": "Winnipeg / James Armstrong Richardson International Airport, Winnipeg, Canada"
    },
    {
        "key": "YXE",
        "value": "Saskatoon John G. Diefenbaker International Airport, Saskatoon, Canada"
    },
    {
        "key": "YXU",
        "value": "London Airport, London, Canada"
    },
    {
        "key": "YXY",
        "value": "Whitehorse / Erik Nielsen International Airport, Whitehorse, Canada"
    },
    {
        "key": "YYC",
        "value": "Calgary International Airport, Calgary, Canada"
    },
    {
        "key": "YYJ",
        "value": "Victoria International Airport, Victoria, Canada"
    },
    {
        "key": "YYT",
        "value": "St. John's International Airport, St. John's, Canada"
    },
    {
        "key": "YYZ",
        "value": "Lester B. Pearson International Airport, Toronto, Canada"
    },
    {
        "key": "ZAR",
        "value": "Zaria Airport, Zaria, Nigeria"
    },
    {
        "key": "NDB",
        "value": "Nouadhibou International Airport, Nouadhibou, Mauritania"
    },
    {
        "key": "LEK",
        "value": "Tata Airport, Labe, Guinea"
    },
    {
        "key": "SID",
        "value": "Amílcar Cabral International Airport, Amilcar Cabral, Cape Verde"
    },
    {
        "key": "ADD",
        "value": "Addis Ababa Bole International Airport, Addis Ababa, Ethiopia"
    },
    {
        "key": "BJM",
        "value": "Bujumbura International Airport, Bujumbura, Burundi"
    },
    {
        "key": "ALY",
        "value": "El Nouzha Airport, Alexandria, Egypt"
    },
    {
        "key": "CAI",
        "value": "Cairo International Airport, Cairo, Egypt"
    },
    {
        "key": "HRG",
        "value": "Hurghada International Airport, Hurghada, Egypt"
    },
    {
        "key": "LXR",
        "value": "Luxor International Airport, Luxor, Egypt"
    },
    {
        "key": "MUH",
        "value": "Mersa Matruh Airport, Mersa-matruh, Egypt"
    },
    {
        "key": "ASW",
        "value": "Aswan International Airport, Aswan, Egypt"
    },
    {
        "key": "KIS",
        "value": "Kisumu Airport, Kisumu, Kenya"
    },
    {
        "key": "MBA",
        "value": "Mombasa Moi International Airport, Mombasa, Kenya"
    },
    {
        "key": "WIL",
        "value": "Nairobi Wilson Airport, Nairobi, Kenya"
    },
    {
        "key": "BEN",
        "value": "Benina International Airport, Benghazi, Libya"
    },
    {
        "key": "SEB",
        "value": "Sabha Airport, Sebha, Libya"
    },
    {
        "key": "TIP",
        "value": "Tripoli International Airport, Tripoli, Libya"
    },
    {
        "key": "KGL",
        "value": "Kigali International Airport, Kigali, Rwanda"
    },
    {
        "key": "JUB",
        "value": "Juba International Airport, Juba, South Sudan"
    },
    {
        "key": "KRT",
        "value": "Khartoum International Airport, Khartoum, Sudan"
    },
    {
        "key": "DAR",
        "value": "Julius Nyerere International Airport, Dar Es Salaam, Tanzania"
    },
    {
        "key": "JRO",
        "value": "Kilimanjaro International Airport, Kilimanjaro, Tanzania"
    },
    {
        "key": "EBB",
        "value": "Entebbe International Airport, Entebbe, Uganda"
    },
    {
        "key": "TIA",
        "value": "Tirana International Airport Mother Teresa, Tirana, Albania"
    },
    {
        "key": "BOJ",
        "value": "Burgas Airport, Bourgas, Bulgaria"
    },
    {
        "key": "PDV",
        "value": "Plovdiv International Airport, Plovdiv, Bulgaria"
    },
    {
        "key": "SOF",
        "value": "Sofia Airport, Sofia, Bulgaria"
    },
    {
        "key": "VAR",
        "value": "Varna Airport, Varna, Bulgaria"
    },
    {
        "key": "LCA",
        "value": "Larnaca International Airport, Larnaca, Cyprus"
    },
    {
        "key": "PFO",
        "value": "Paphos International Airport, Paphos, Cyprus"
    },
    {
        "key": "DBV",
        "value": "Dubrovnik Airport, Dubrovnik, Croatia"
    },
    {
        "key": "OSI",
        "value": "Osijek Airport, Osijek, Croatia"
    },
    {
        "key": "PUY",
        "value": "Pula Airport, Pula, Croatia"
    },
    {
        "key": "RJK",
        "value": "Rijeka Airport, Rijeka, Croatia"
    },
    {
        "key": "SPU",
        "value": "Split Airport, Split, Croatia"
    },
    {
        "key": "ALC",
        "value": "Alicante International Airport, Alicante, Spain"
    },
    {
        "key": "LEI",
        "value": "Almería International Airport, Almeria, Spain"
    },
    {
        "key": "OVD",
        "value": "Asturias Airport, Aviles, Spain"
    },
    {
        "key": "BCN",
        "value": "Barcelona International Airport, Barcelona, Spain"
    },
    {
        "key": "LCG",
        "value": "A Coruña Airport, La Coruna, Spain"
    },
    {
        "key": "GRO",
        "value": "Girona Airport, Gerona, Spain"
    },
    {
        "key": "GRX",
        "value": "Federico Garcia Lorca Airport, Granada, Spain"
    },
    {
        "key": "IBZ",
        "value": "Ibiza Airport, Ibiza, Spain"
    },
    {
        "key": "XRY",
        "value": "Jerez Airport, Jerez, Spain"
    },
    {
        "key": "MAD",
        "value": "Adolfo Suárez Madrid–Barajas Airport, Madrid, Spain"
    },
    {
        "key": "AGP",
        "value": "Málaga Airport, Malaga, Spain"
    },
    {
        "key": "MAH",
        "value": "Menorca Airport, Menorca, Spain"
    },
    {
        "key": "REU",
        "value": "Reus Air Base, Reus, Spain"
    },
    {
        "key": "SCQ",
        "value": "Santiago de Compostela Airport, Santiago, Spain"
    },
    {
        "key": "VLC",
        "value": "Valencia Airport, Valencia, Spain"
    },
    {
        "key": "VLL",
        "value": "Valladolid Airport, Valladolid, Spain"
    },
    {
        "key": "SDR",
        "value": "Santander Airport, Santander, Spain"
    },
    {
        "key": "SVQ",
        "value": "Sevilla Airport, Sevilla, Spain"
    },
    {
        "key": "BOD",
        "value": "Bordeaux-Mérignac Airport, Bordeaux, France"
    },
    {
        "key": "EGC",
        "value": "Bergerac-Roumanière Airport, Bergerac, France"
    },
    {
        "key": "PIS",
        "value": "Poitiers-Biard Airport, Poitiers, France"
    },
    {
        "key": "LIG",
        "value": "Limoges Airport, Limoges, France"
    },
    {
        "key": "TLS",
        "value": "Toulouse-Blagnac Airport, Toulouse, France"
    },
    {
        "key": "PUF",
        "value": "Pau Pyrénées Airport, Pau, France"
    },
    {
        "key": "BIQ",
        "value": "Biarritz-Anglet-Bayonne Airport, Biarritz-bayonne, France"
    },
    {
        "key": "RDZ",
        "value": "Rodez-Marcillac Airport, Rodez, France"
    },
    {
        "key": "BIA",
        "value": "Bastia-Poretta Airport, Bastia, France"
    },
    {
        "key": "FSC",
        "value": "Figari Sud-Corse Airport, Figari, France"
    },
    {
        "key": "AJA",
        "value": "Ajaccio-Napoléon Bonaparte Airport, Ajaccio, France"
    },
    {
        "key": "CMF",
        "value": "Chambéry-Savoie Airport, Chambery, France"
    },
    {
        "key": "LYS",
        "value": "Lyon Saint-Exupéry Airport, Lyon, France"
    },
    {
        "key": "GNB",
        "value": "Grenoble-Isère Airport, Grenoble, France"
    },
    {
        "key": "EBU",
        "value": "Saint-Étienne-Bouthéon Airport, St-Etienne, France"
    },
    {
        "key": "CCF",
        "value": "Carcassonne Airport, Carcassonne, France"
    },
    {
        "key": "MRS",
        "value": "Marseille Provence Airport, Marseille, France"
    },
    {
        "key": "NCE",
        "value": "Nice-Côte d'Azur Airport, Nice, France"
    },
    {
        "key": "PGF",
        "value": "Perpignan-Rivesaltes (Llabanère) Airport, Perpignan, France"
    },
    {
        "key": "BZR",
        "value": "Béziers-Vias Airport, Beziers, France"
    },
    {
        "key": "BVA",
        "value": "Paris Beauvais Tillé Airport, Beauvais, France"
    },
    {
        "key": "XCR",
        "value": "Châlons-Vatry Airport, Chalons, France"
    },
    {
        "key": "TUF",
        "value": "Tours-Val-de-Loire Airport, Tours, France"
    },
    {
        "key": "CDG",
        "value": "Charles de Gaulle International Airport, Paris, France"
    },
    {
        "key": "ORY",
        "value": "Paris-Orly Airport, Paris, France"
    },
    {
        "key": "LIL",
        "value": "Lille-Lesquin Airport, Lille, France"
    },
    {
        "key": "BES",
        "value": "Brest Bretagne Airport, Brest, France"
    },
    {
        "key": "DNR",
        "value": "Dinard-Pleurtuit-Saint-Malo Airport, Dinard, France"
    },
    {
        "key": "NTE",
        "value": "Nantes Atlantique Airport, Nantes, France"
    },
    {
        "key": "BSL",
        "value": "EuroAirport Basel-Mulhouse-Freiburg Airport, Mulhouse, France"
    },
    {
        "key": "SXB",
        "value": "Strasbourg Airport, Strasbourg, France"
    },
    {
        "key": "TLN",
        "value": "Toulon-Hyères Airport, Hyeres, France"
    },
    {
        "key": "FNI",
        "value": "Nîmes-Arles-Camargue Airport, Nimes, France"
    },
    {
        "key": "FSP",
        "value": "St Pierre Airport, St.-pierre, Saint Pierre and Miquelon"
    },
    {
        "key": "VOL",
        "value": "Nea Anchialos Airport, Nea Anghialos, Greece"
    },
    {
        "key": "JKH",
        "value": "Chios Island National Airport, Chios, Greece"
    },
    {
        "key": "HER",
        "value": "Heraklion International Nikos Kazantzakis Airport, Heraklion, Greece"
    },
    {
        "key": "EFL",
        "value": "Kefallinia Airport, Keffallinia, Greece"
    },
    {
        "key": "KLX",
        "value": "Kalamata Airport, Kalamata, Greece"
    },
    {
        "key": "KGS",
        "value": "Kos Airport, Kos, Greece"
    },
    {
        "key": "AOK",
        "value": "Karpathos Airport, Karpathos, Greece"
    },
    {
        "key": "CFU",
        "value": "Ioannis Kapodistrias International Airport, Kerkyra/corfu, Greece"
    },
    {
        "key": "KVA",
        "value": "Alexander the Great International Airport, Kavala, Greece"
    },
    {
        "key": "JMK",
        "value": "Mikonos Airport, Mykonos, Greece"
    },
    {
        "key": "MJT",
        "value": "Mytilene International Airport, Mytilini, Greece"
    },
    {
        "key": "PVK",
        "value": "Aktion National Airport, Preveza, Greece"
    },
    {
        "key": "RHO",
        "value": "Diagoras Airport, Rhodos, Greece"
    },
    {
        "key": "CHQ",
        "value": "Chania International Airport, Chania, Greece"
    },
    {
        "key": "JSI",
        "value": "Skiathos Island National Airport, Skiathos, Greece"
    },
    {
        "key": "SMI",
        "value": "Samos Airport, Samos, Greece"
    },
    {
        "key": "JTR",
        "value": "Santorini Airport, Thira, Greece"
    },
    {
        "key": "SKU",
        "value": "Skiros Airport, Skiros, Greece"
    },
    {
        "key": "SKG",
        "value": "Thessaloniki Macedonia International Airport, Thessaloniki, Greece"
    },
    {
        "key": "BUD",
        "value": "Budapest Liszt Ferenc International Airport, Budapest, Hungary"
    },
    {
        "key": "DEB",
        "value": "Debrecen International Airport, Debrecen, Hungary"
    },
    {
        "key": "BRI",
        "value": "Bari Karol Wojtyła Airport, Bari, Italy"
    },
    {
        "key": "PSR",
        "value": "Pescara International Airport, Pescara, Italy"
    },
    {
        "key": "BDS",
        "value": "Brindisi – Salento Airport, Brindisi, Italy"
    },
    {
        "key": "SUF",
        "value": "Lamezia Terme Airport, Lamezia, Italy"
    },
    {
        "key": "CTA",
        "value": "Catania-Fontanarossa Airport, Catania, Italy"
    },
    {
        "key": "PMO",
        "value": "Falcone–Borsellino Airport, Palermo, Italy"
    },
    {
        "key": "TPS",
        "value": "Vincenzo Florio Airport Trapani-Birgi, Trapani, Italy"
    },
    {
        "key": "AHO",
        "value": "Alghero-Fertilia Airport, Alghero, Italy"
    },
    {
        "key": "CAG",
        "value": "Cagliari Elmas Airport, Cagliari, Italy"
    },
    {
        "key": "OLB",
        "value": "Olbia Costa Smeralda Airport, Olbia, Italy"
    },
    {
        "key": "MXP",
        "value": "Malpensa International Airport, Milano, Italy"
    },
    {
        "key": "BGY",
        "value": "Il Caravaggio International Airport, Bergamo, Italy"
    },
    {
        "key": "TRN",
        "value": "Turin Airport, Torino, Italy"
    },
    {
        "key": "GOA",
        "value": "Genoa Cristoforo Colombo Airport, Genoa, Italy"
    },
    {
        "key": "LIN",
        "value": "Milano Linate Airport, Milan, Italy"
    },
    {
        "key": "CUF",
        "value": "Cuneo International Airport, Cuneo, Italy"
    },
    {
        "key": "BLQ",
        "value": "Bologna Guglielmo Marconi Airport, Bologna, Italy"
    },
    {
        "key": "VBS",
        "value": "Brescia Airport, Brescia, Italy"
    },
    {
        "key": "TRS",
        "value": "Trieste–Friuli Venezia Giulia Airport, Ronchi De Legionari, Italy"
    },
    {
        "key": "RMI",
        "value": "Federico Fellini International Airport, Rimini, Italy"
    },
    {
        "key": "VRN",
        "value": "Verona Villafranca Airport, Villafranca, Italy"
    },
    {
        "key": "VCE",
        "value": "Venice Marco Polo Airport, Venice, Italy"
    },
    {
        "key": "CIA",
        "value": "Ciampino–G. B. Pastine International Airport, Rome, Italy"
    },
    {
        "key": "FCO",
        "value": "Leonardo da Vinci–Fiumicino Airport, Rome, Italy"
    },
    {
        "key": "NAP",
        "value": "Naples International Airport, Naples, Italy"
    },
    {
        "key": "PSA",
        "value": "Pisa International Airport, Pisa, Italy"
    },
    {
        "key": "FLR",
        "value": "Peretola Airport, Florence, Italy"
    },
    {
        "key": "PEG",
        "value": "Perugia San Francesco d'Assisi – Umbria International Airport, Perugia, Italy"
    },
    {
        "key": "LJU",
        "value": "Ljubljana Jože Pučnik Airport, Ljubljana, Slovenia"
    },
    {
        "key": "MBX",
        "value": "Maribor Airport, Maribor, Slovenia"
    },
    {
        "key": "POW",
        "value": "Portoroz Airport, Portoroz, Slovenia"
    },
    {
        "key": "KLV",
        "value": "Karlovy Vary International Airport, Karlovy Vary, Czech Republic"
    },
    {
        "key": "OSR",
        "value": "Ostrava Leos Janáček Airport, Ostrava, Czech Republic"
    },
    {
        "key": "PED",
        "value": "Pardubice Airport, Pardubice, Czech Republic"
    },
    {
        "key": "PRG",
        "value": "Václav Havel Airport Prague, Prague, Czech Republic"
    },
    {
        "key": "BRQ",
        "value": "Brno-Tuřany Airport, Brno, Czech Republic"
    },
    {
        "key": "TLV",
        "value": "Ben Gurion International Airport, Tel-aviv, Israel"
    },
    {
        "key": "ETH",
        "value": "Eilat Airport, Elat, Israel"
    },
    {
        "key": "HFA",
        "value": "Haifa International Airport, Haifa, Israel"
    },
    {
        "key": "VDA",
        "value": "Ovda International Airport, Ovda, Israel"
    },
    {
        "key": "SDV",
        "value": "Sde Dov Airport, Tel-aviv, Israel"
    },
    {
        "key": "MLA",
        "value": "Malta International Airport, Malta, Malta"
    },
    {
        "key": "GRZ",
        "value": "Graz Airport, Graz, Austria"
    },
    {
        "key": "INN",
        "value": "Innsbruck Airport, Innsbruck, Austria"
    },
    {
        "key": "LNZ",
        "value": "Linz Hörsching Airport, Linz, Austria"
    },
    {
        "key": "SZG",
        "value": "Salzburg Airport, Salzburg, Austria"
    },
    {
        "key": "VIE",
        "value": "Vienna International Airport, Vienna, Austria"
    },
    {
        "key": "BYJ",
        "value": "Beja Airport / Airbase, Beja (madeira), Portugal"
    },
    {
        "key": "FAO",
        "value": "Faro Airport, Faro, Portugal"
    },
    {
        "key": "TER",
        "value": "Lajes Airport, Lajes (terceira Island), Portugal"
    },
    {
        "key": "PDL",
        "value": "João Paulo II Airport, Ponta Delgada, Portugal"
    },
    {
        "key": "OPO",
        "value": "Francisco de Sá Carneiro Airport, Porto, Portugal"
    },
    {
        "key": "PXO",
        "value": "Porto Santo Airport, Porto Santo, Portugal"
    },
    {
        "key": "LIS",
        "value": "Humberto Delgado Airport (Lisbon Portela Airport), Lisbon, Portugal"
    },
    {
        "key": "OMO",
        "value": "Mostar International Airport, Mostar, Bosnia and Herzegovina"
    },
    {
        "key": "SJJ",
        "value": "Sarajevo International Airport, Sarajevo, Bosnia and Herzegovina"
    },
    {
        "key": "CND",
        "value": "Mihail Kogălniceanu International Airport, Constanta, Romania"
    },
    {
        "key": "CLJ",
        "value": "Cluj-Napoca International Airport, Cluj-napoca, Romania"
    },
    {
        "key": "IAS",
        "value": "Iaşi Airport, Iasi, Romania"
    },
    {
        "key": "OMR",
        "value": "Oradea International Airport, Oradea, Romania"
    },
    {
        "key": "OTP",
        "value": "Henri Coandă International Airport, Bucharest, Romania"
    },
    {
        "key": "SBZ",
        "value": "Sibiu International Airport, Sibiu, Romania"
    },
    {
        "key": "TSR",
        "value": "Timişoara Traian Vuia Airport, Timisoara, Romania"
    },
    {
        "key": "GVA",
        "value": "Geneva Cointrin International Airport, Geneva, Switzerland"
    },
    {
        "key": "BRN",
        "value": "Bern Belp Airport, Bern, Switzerland"
    },
    {
        "key": "ZRH",
        "value": "Zürich Airport, Zurich, Switzerland"
    },
    {
        "key": "ACH",
        "value": "St Gallen Altenrhein Airport, Altenrhein, Switzerland"
    },
    {
        "key": "ESB",
        "value": "Esenboğa International Airport, Ankara, Turkey"
    },
    {
        "key": "ANK",
        "value": "Etimesgut Air Base, Ankara, Turkey"
    },
    {
        "key": "ADA",
        "value": "Adana Airport, Adana, Turkey"
    },
    {
        "key": "AYT",
        "value": "Antalya International Airport, Antalya, Turkey"
    },
    {
        "key": "GZT",
        "value": "Gaziantep International Airport, Gaziantep, Turkey"
    },
    {
        "key": "ASR",
        "value": "Kayseri Erkilet Airport, Kayseri, Turkey"
    },
    {
        "key": "DNZ",
        "value": "Çardak Airport, Denizli, Turkey"
    },
    {
        "key": "ADB",
        "value": "Adnan Menderes International Airport, Izmir, Turkey"
    },
    {
        "key": "DLM",
        "value": "Dalaman International Airport, Dalaman, Turkey"
    },
    {
        "key": "ERC",
        "value": "Erzincan Airport, Erzincan, Turkey"
    },
    {
        "key": "ERZ",
        "value": "Erzurum International Airport, Erzurum, Turkey"
    },
    {
        "key": "TZX",
        "value": "Trabzon International Airport, Trabzon, Turkey"
    },
    {
        "key": "BZY",
        "value": "Bălți International Airport, Saltsy, Moldova"
    },
    {
        "key": "KIV",
        "value": "Chişinău International Airport, Chisinau, Moldova"
    },
    {
        "key": "OHD",
        "value": "Ohrid St. Paul the Apostle Airport, Ohrid, Macedonia"
    },
    {
        "key": "SKP",
        "value": "Skopje Alexander the Great Airport, Skopje, Macedonia"
    },
    {
        "key": "BEG",
        "value": "Belgrade Nikola Tesla Airport, Belgrade, Serbia"
    },
    {
        "key": "INI",
        "value": "Nis Airport, Nis, Serbia"
    },
    {
        "key": "TGD",
        "value": "Podgorica Airport, Podgorica, Montenegro"
    },
    {
        "key": "PRN",
        "value": "Priština International Airport, Pristina, Serbia"
    },
    {
        "key": "TIV",
        "value": "Tivat Airport, Tivat, Montenegro"
    },
    {
        "key": "BTS",
        "value": "M. R. Štefánik Airport, Bratislava, Slovakia"
    },
    {
        "key": "KSC",
        "value": "Košice Airport, Kosice, Slovakia"
    },
    {
        "key": "PZY",
        "value": "Piešťany Airport, Piestany, Slovakia"
    },
    {
        "key": "SLD",
        "value": "Sliač Airport, Sliac, Slovakia"
    },
    {
        "key": "TAT",
        "value": "Poprad-Tatry Airport, Poprad, Slovakia"
    },
    {
        "key": "LRM",
        "value": "Casa De Campo International Airport, La Romana, Dominican Republic"
    },
    {
        "key": "PUJ",
        "value": "Punta Cana International Airport, Punta Cana, Dominican Republic"
    },
    {
        "key": "POP",
        "value": "Gregorio Luperon International Airport, Puerto Plata, Dominican Republic"
    },
    {
        "key": "SDQ",
        "value": "Las Américas International Airport, Santo Domingo, Dominican Republic"
    },
    {
        "key": "GUA",
        "value": "La Aurora Airport, Guatemala City, Guatemala"
    },
    {
        "key": "SAP",
        "value": "Ramón Villeda Morales International Airport, San Pedro Sula, Honduras"
    },
    {
        "key": "RTB",
        "value": "Juan Manuel Galvez International Airport, Roatan, Honduras"
    },
    {
        "key": "TGU",
        "value": "Toncontín International Airport, Tegucigalpa, Honduras"
    },
    {
        "key": "KIN",
        "value": "Norman Manley International Airport, Kingston, Jamaica"
    },
    {
        "key": "MBJ",
        "value": "Sangster International Airport, Montego Bay, Jamaica"
    },
    {
        "key": "ACA",
        "value": "General Juan N Alvarez International Airport, Acapulco, Mexico"
    },
    {
        "key": "AGU",
        "value": "Jesús Terán Paredo International Airport, Aguascalientes, Mexico"
    },
    {
        "key": "HUX",
        "value": "Bahías de Huatulco International Airport, Huatulco, Mexico"
    },
    {
        "key": "CME",
        "value": "Ciudad del Carmen International Airport, Ciudad Del Carmen, Mexico"
    },
    {
        "key": "CUL",
        "value": "Bachigualato Federal International Airport, Culiacan, Mexico"
    },
    {
        "key": "CEN",
        "value": "Ciudad Obregón International Airport, Ciudad Obregon, Mexico"
    },
    {
        "key": "CJS",
        "value": "Abraham González International Airport, Ciudad Juarez, Mexico"
    },
    {
        "key": "CUU",
        "value": "General Roberto Fierro Villalobos International Airport, Chihuahua, Mexico"
    },
    {
        "key": "CVM",
        "value": "General Pedro Jose Mendez International Airport, Ciudad Victoria, Mexico"
    },
    {
        "key": "CZM",
        "value": "Cozumel International Airport, Cozumel, Mexico"
    },
    {
        "key": "GDL",
        "value": "Don Miguel Hidalgo Y Costilla International Airport, Guadalajara, Mexico"
    },
    {
        "key": "HMO",
        "value": "General Ignacio P. Garcia International Airport, Hermosillo, Mexico"
    },
    {
        "key": "LMM",
        "value": "Valle del Fuerte International Airport, Los Mochis, Mexico"
    },
    {
        "key": "BJX",
        "value": "Del Bajío International Airport, Del Bajio, Mexico"
    },
    {
        "key": "LAP",
        "value": "Manuel Márquez de León International Airport, La Paz, Mexico"
    },
    {
        "key": "MID",
        "value": "Licenciado Manuel Crescencio Rejon Int Airport, Merida, Mexico"
    },
    {
        "key": "MEX",
        "value": "Licenciado Benito Juarez International Airport, Mexico City, Mexico"
    },
    {
        "key": "MTY",
        "value": "General Mariano Escobedo International Airport, Monterrey, Mexico"
    },
    {
        "key": "NOG",
        "value": "Nogales International Airport, Nogales, Mexico"
    },
    {
        "key": "PVR",
        "value": "Licenciado Gustavo Díaz Ordaz International Airport, Puerto Vallarta, Mexico"
    },
    {
        "key": "PXM",
        "value": "Puerto Escondido International Airport, Puerto Escondido, Mexico"
    },
    {
        "key": "SJD",
        "value": "Los Cabos International Airport, San Jose Del Cabo, Mexico"
    },
    {
        "key": "SLP",
        "value": "Ponciano Arriaga International Airport, San Luis Potosi, Mexico"
    },
    {
        "key": "TIJ",
        "value": "General Abelardo L. Rodríguez International Airport, Tijuana, Mexico"
    },
    {
        "key": "TAM",
        "value": "General Francisco Javier Mina International Airport, Tampico, Mexico"
    },
    {
        "key": "CUN",
        "value": "Cancún International Airport, Cancun, Mexico"
    },
    {
        "key": "VSA",
        "value": "Carlos Rovirosa Pérez International Airport, Villahermosa, Mexico"
    },
    {
        "key": "VER",
        "value": "General Heriberto Jara International Airport, Vera Cruz, Mexico"
    },
    {
        "key": "ZCL",
        "value": "General Leobardo C. Ruiz International Airport, Zacatecas, Mexico"
    },
    {
        "key": "ZIH",
        "value": "Ixtapa Zihuatanejo International Airport, Zihuatanejo, Mexico"
    },
    {
        "key": "ZLO",
        "value": "Playa De Oro International Airport, Manzanillo, Mexico"
    },
    {
        "key": "MGA",
        "value": "Augusto C. Sandino (Managua) International Airport, Managua, Nicaragua"
    },
    {
        "key": "PTY",
        "value": "Tocumen International Airport, Panama City, Panama"
    },
    {
        "key": "SJO",
        "value": "Juan Santamaria International Airport, San Jose, Costa Rica"
    },
    {
        "key": "SAL",
        "value": "Monseñor Óscar Arnulfo Romero International Airport, San Salvador, El Salvador"
    },
    {
        "key": "CAP",
        "value": "Cap Haitien International Airport, Cap Haitien, Haiti"
    },
    {
        "key": "JAK",
        "value": "Jacmel Airport, Jacmel, Haiti"
    },
    {
        "key": "PAP",
        "value": "Toussaint Louverture International Airport, Port-au-prince, Haiti"
    },
    {
        "key": "CFG",
        "value": "Jaime Gonzalez Airport, Cienfuegos, Cuba"
    },
    {
        "key": "SCU",
        "value": "Antonio Maceo International Airport, Santiago De Cuba, Cuba"
    },
    {
        "key": "HAV",
        "value": "José Martí International Airport, Havana, Cuba"
    },
    {
        "key": "HOG",
        "value": "Frank Pais International Airport, Holguin, Cuba"
    },
    {
        "key": "VRA",
        "value": "Juan Gualberto Gomez International Airport, Varadero, Cuba"
    },
    {
        "key": "GCM",
        "value": "Owen Roberts International Airport, Georgetown, Cayman Islands"
    },
    {
        "key": "CCZ",
        "value": "Chub Cay Airport, Chub Cay, Bahamas"
    },
    {
        "key": "FPO",
        "value": "Grand Bahama International Airport, Freeport, Bahamas"
    },
    {
        "key": "NAS",
        "value": "Lynden Pindling International Airport, Nassau, Bahamas"
    },
    {
        "key": "ZSA",
        "value": "San Salvador Airport, Cockburn Town, Bahamas"
    },
    {
        "key": "BZE",
        "value": "Philip S. W. Goldson International Airport, Belize City, Belize"
    },
    {
        "key": "RAR",
        "value": "Rarotonga International Airport, Avarua, Cook Islands"
    },
    {
        "key": "NAN",
        "value": "Nadi International Airport, Nandi, Fiji"
    },
    {
        "key": "TRW",
        "value": "Bonriki International Airport, Tarawa, Kiribati"
    },
    {
        "key": "APW",
        "value": "Faleolo International Airport, Faleolo, Samoa"
    },
    {
        "key": "VLI",
        "value": "Bauerfield International Airport, Port-vila, Vanuatu"
    },
    {
        "key": "NOU",
        "value": "La Tontouta International Airport, Noumea, New Caledonia"
    },
    {
        "key": "AKL",
        "value": "Auckland International Airport, Auckland, New Zealand"
    },
    {
        "key": "CHC",
        "value": "Christchurch International Airport, Christchurch, New Zealand"
    },
    {
        "key": "DUD",
        "value": "Dunedin Airport, Dunedin, New Zealand"
    },
    {
        "key": "HKK",
        "value": "Hokitika Airfield, Hokitika, New Zealand"
    },
    {
        "key": "HLZ",
        "value": "Hamilton International Airport, Hamilton, New Zealand"
    },
    {
        "key": "MON",
        "value": "Mount Cook Airport, Mount Cook, New Zealand"
    },
    {
        "key": "NPL",
        "value": "New Plymouth Airport, New Plymouth, New Zealand"
    },
    {
        "key": "PMR",
        "value": "Palmerston North Airport, Palmerston North, New Zealand"
    },
    {
        "key": "WLG",
        "value": "Wellington International Airport, Wellington, New Zealand"
    },
    {
        "key": "JAA",
        "value": "Jalalabad Airport, Jalalabad, Afghanistan"
    },
    {
        "key": "KBL",
        "value": "Hamid Karzai International Airport, Kabul, Afghanistan"
    },
    {
        "key": "BAH",
        "value": "Bahrain International Airport, Bahrain, Bahrain"
    },
    {
        "key": "AHB",
        "value": "Abha Regional Airport, Abha, Saudi Arabia"
    },
    {
        "key": "DMM",
        "value": "King Fahd International Airport, Dammam, Saudi Arabia"
    },
    {
        "key": "DHA",
        "value": "King Abdulaziz Air Base, Dhahran, Saudi Arabia"
    },
    {
        "key": "JED",
        "value": "King Abdulaziz International Airport, Jeddah, Saudi Arabia"
    },
    {
        "key": "MED",
        "value": "Prince Mohammad Bin Abdulaziz Airport, Madinah, Saudi Arabia"
    },
    {
        "key": "RUH",
        "value": "King Khaled International Airport, Riyadh, Saudi Arabia"
    },
    {
        "key": "TUU",
        "value": "Tabuk Airport, Tabuk, Saudi Arabia"
    },
    {
        "key": "TIF",
        "value": "Ta’if Regional Airport, Taif, Saudi Arabia"
    },
    {
        "key": "YNB",
        "value": "Prince Abdulmohsin Bin Abdulaziz Airport, Yenbo, Saudi Arabia"
    },
    {
        "key": "ABD",
        "value": "Abadan Airport, Abadan, Iran"
    },
    {
        "key": "THR",
        "value": "Mehrabad International Airport, Teheran, Iran"
    },
    {
        "key": "SYZ",
        "value": "Shiraz Shahid Dastghaib International Airport, Shiraz, Iran"
    },
    {
        "key": "AMM",
        "value": "Queen Alia International Airport, Amman, Jordan"
    },
    {
        "key": "ADJ",
        "value": "Amman-Marka International Airport, Amman, Jordan"
    },
    {
        "key": "AQJ",
        "value": "Aqaba King Hussein International Airport, Aqaba, Jordan"
    },
    {
        "key": "KWI",
        "value": "Kuwait International Airport, Kuwait, Kuwait"
    },
    {
        "key": "BEY",
        "value": "Beirut Rafic Hariri International Airport, Beirut, Lebanon"
    },
    {
        "key": "AUH",
        "value": "Abu Dhabi International Airport, Abu Dhabi, United Arab Emirates"
    },
    {
        "key": "DXB",
        "value": "Dubai International Airport, Dubai, United Arab Emirates"
    },
    {
        "key": "FJR",
        "value": "Fujairah International Airport, Fujeirah, United Arab Emirates"
    },
    {
        "key": "RKT",
        "value": "Ras Al Khaimah International Airport, Ras Al Khaimah, United Arab Emirates"
    },
    {
        "key": "SHJ",
        "value": "Sharjah International Airport, Sharjah, United Arab Emirates"
    },
    {
        "key": "MCT",
        "value": "Muscat International Airport, Muscat, Oman"
    },
    {
        "key": "SLL",
        "value": "Salalah Airport, Salalah, Oman"
    },
    {
        "key": "LYP",
        "value": "Faisalabad International Airport, Faisalabad, Pakistan"
    },
    {
        "key": "GWD",
        "value": "Gwadar International Airport, Gwadar, Pakistan"
    },
    {
        "key": "KHI",
        "value": "Jinnah International Airport, Karachi, Pakistan"
    },
    {
        "key": "LHE",
        "value": "Alama Iqbal International Airport, Lahore, Pakistan"
    },
    {
        "key": "MUX",
        "value": "Multan International Airport, Multan, Pakistan"
    },
    {
        "key": "PEW",
        "value": "Peshawar International Airport, Peshawar, Pakistan"
    },
    {
        "key": "RYK",
        "value": "Shaikh Zaid Airport, Rahim Yar Khan, Pakistan"
    },
    {
        "key": "SKZ",
        "value": "Sukkur Airport, Sukkur, Pakistan"
    },
    {
        "key": "BSR",
        "value": "Basrah International Airport, Basrah, Iraq"
    },
    {
        "key": "ALP",
        "value": "Aleppo International Airport, Aleppo, Syria"
    },
    {
        "key": "DAM",
        "value": "Damascus International Airport, Damascus, Syria"
    },
    {
        "key": "SPN",
        "value": "Saipan International Airport, Saipan, Northern Mariana Islands"
    },
    {
        "key": "GUM",
        "value": "Antonio B. Won Pat International Airport, Agana, Guam"
    },
    {
        "key": "CXI",
        "value": "Cassidy International Airport, Kiritimati, Kiribati"
    },
    {
        "key": "KHH",
        "value": "Kaohsiung International Airport, Kaohsiung, Taiwan"
    },
    {
        "key": "TPE",
        "value": "Taiwan Taoyuan International Airport, Taipei, Taiwan"
    },
    {
        "key": "NRT",
        "value": "Narita International Airport, Tokyo, Japan"
    },
    {
        "key": "MUS",
        "value": "Minami Torishima Airport, Minami Tori Shima, Japan"
    },
    {
        "key": "CTS",
        "value": "New Chitose Airport, Sapporo, Japan"
    },
    {
        "key": "HKD",
        "value": "Hakodate Airport, Hakodate, Japan"
    },
    {
        "key": "FUK",
        "value": "Fukuoka Airport, Fukuoka, Japan"
    },
    {
        "key": "KOJ",
        "value": "Kagoshima Airport, Kagoshima, Japan"
    },
    {
        "key": "KMI",
        "value": "Miyazaki Airport, Miyazaki, Japan"
    },
    {
        "key": "OIT",
        "value": "Oita Airport, Oita, Japan"
    },
    {
        "key": "KMJ",
        "value": "Kumamoto Airport, Kumamoto, Japan"
    },
    {
        "key": "KMQ",
        "value": "Komatsu Airport, Kanazawa, Japan"
    },
    {
        "key": "HIJ",
        "value": "Hiroshima Airport, Hiroshima, Japan"
    },
    {
        "key": "ITM",
        "value": "Osaka International Airport, Osaka, Japan"
    },
    {
        "key": "TAK",
        "value": "Takamatsu Airport, Takamatsu, Japan"
    },
    {
        "key": "AOJ",
        "value": "Aomori Airport, Aomori, Japan"
    },
    {
        "key": "SDJ",
        "value": "Sendai Airport, Sendai, Japan"
    },
    {
        "key": "HND",
        "value": "Tokyo Haneda International Airport, Tokyo, Japan"
    },
    {
        "key": "CJU",
        "value": "Jeju International Airport, Cheju, South Korea"
    },
    {
        "key": "PUS",
        "value": "Gimhae International Airport, Busan, South Korea"
    },
    {
        "key": "OKA",
        "value": "Naha Airport, Okinawa, Japan"
    },
    {
        "key": "MNL",
        "value": "Ninoy Aquino International Airport, Manila, Philippines"
    },
    {
        "key": "COC",
        "value": "Comodoro Pierrestegui Airport, Concordia, Argentina"
    },
    {
        "key": "PRA",
        "value": "General Urquiza Airport, Parana, Argentina"
    },
    {
        "key": "ROS",
        "value": "Islas Malvinas Airport, Rosario, Argentina"
    },
    {
        "key": "SFN",
        "value": "Sauce Viejo Airport, Santa Fe, Argentina"
    },
    {
        "key": "AEP",
        "value": "Jorge Newbery Airpark, Buenos Aires, Argentina"
    },
    {
        "key": "COR",
        "value": "Ingeniero Ambrosio Taravella Airport, Cordoba, Argentina"
    },
    {
        "key": "MDZ",
        "value": "El Plumerillo Airport, Mendoza, Argentina"
    },
    {
        "key": "TUC",
        "value": "Teniente Benjamin Matienzo Airport, Tucuman, Argentina"
    },
    {
        "key": "IGR",
        "value": "Cataratas Del Iguazú International Airport, Iguazu Falls, Argentina"
    },
    {
        "key": "SLA",
        "value": "Martin Miguel De Guemes International Airport, Salta, Argentina"
    },
    {
        "key": "JUJ",
        "value": "Gobernador Horacio Guzman International Airport, Jujuy, Argentina"
    },
    {
        "key": "USH",
        "value": "Malvinas Argentinas Airport, Ushuaia, Argentina"
    },
    {
        "key": "MDQ",
        "value": "Ástor Piazzola International Airport, Mar Del Plata, Argentina"
    },
    {
        "key": "BRC",
        "value": "San Carlos De Bariloche Airport, San Carlos De Bariloche, Argentina"
    },
    {
        "key": "BEL",
        "value": "Val de Cans/Júlio Cezar Ribeiro International Airport, Belem, Brazil"
    },
    {
        "key": "BSB",
        "value": "Presidente Juscelino Kubistschek International Airport, Brasilia, Brazil"
    },
    {
        "key": "BVB",
        "value": "Atlas Brasil Cantanhede Airport, Boa Vista, Brazil"
    },
    {
        "key": "CNF",
        "value": "Tancredo Neves International Airport, Belo Horizonte, Brazil"
    },
    {
        "key": "CGR",
        "value": "Campo Grande Airport, Campo Grande, Brazil"
    },
    {
        "key": "CWB",
        "value": "Afonso Pena Airport, Curitiba, Brazil"
    },
    {
        "key": "CGB",
        "value": "Marechal Rondon Airport, Cuiaba, Brazil"
    },
    {
        "key": "MAO",
        "value": "Eduardo Gomes International Airport, Manaus, Brazil"
    },
    {
        "key": "IGU",
        "value": "Cataratas International Airport, Foz Do Iguacu, Brazil"
    },
    {
        "key": "FLN",
        "value": "Hercílio Luz International Airport, Florianopolis, Brazil"
    },
    {
        "key": "FOR",
        "value": "Pinto Martins International Airport, Fortaleza, Brazil"
    },
    {
        "key": "GIG",
        "value": "Rio Galeão – Tom Jobim International Airport, Rio De Janeiro, Brazil"
    },
    {
        "key": "GRU",
        "value": "Guarulhos - Governador André Franco Montoro International Airport, Sao Paulo, Brazil"
    },
    {
        "key": "JPA",
        "value": "Presidente Castro Pinto International Airport, Joao Pessoa, Brazil"
    },
    {
        "key": "VCP",
        "value": "Viracopos International Airport, Campinas, Brazil"
    },
    {
        "key": "MCP",
        "value": "Alberto Alcolumbre Airport, Macapa, Brazil"
    },
    {
        "key": "NAT",
        "value": "Governador Aluízio Alves International Airport, Natal, Brazil"
    },
    {
        "key": "POA",
        "value": "Salgado Filho Airport, Porto Alegre, Brazil"
    },
    {
        "key": "PET",
        "value": "João Simões Lopes Neto International Airport, Pelotas, Brazil"
    },
    {
        "key": "REC",
        "value": "Guararapes - Gilberto Freyre International Airport, Recife, Brazil"
    },
    {
        "key": "SLZ",
        "value": "Marechal Cunha Machado International Airport, Sao Luis, Brazil"
    },
    {
        "key": "CGH",
        "value": "Congonhas Airport, Sao Paulo, Brazil"
    },
    {
        "key": "SSA",
        "value": "Deputado Luiz Eduardo Magalhães International Airport, Salvador, Brazil"
    },
    {
        "key": "THE",
        "value": "Senador Petrônio Portela Airport, Teresina, Brazil"
    },
    {
        "key": "TUR",
        "value": "Tucuruí Airport, Tucurui, Brazil"
    },
    {
        "key": "PUQ",
        "value": "Pdte. Carlos Ibañez del Campo Airport, Punta Arenas, Chile"
    },
    {
        "key": "SCL",
        "value": "Comodoro Arturo Merino Benítez International Airport, Santiago, Chile"
    },
    {
        "key": "ANF",
        "value": "Andrés Sabella Gálvez International Airport, Antofagasta, Chile"
    },
    {
        "key": "CCP",
        "value": "Carriel Sur Airport, Concepcion, Chile"
    },
    {
        "key": "IPC",
        "value": "Mataveri Airport, Easter Island, Chile"
    },
    {
        "key": "GYE",
        "value": "José Joaquín de Olmedo International Airport, Guayaquil, Ecuador"
    },
    {
        "key": "UIO",
        "value": "Mariscal Sucre International Airport, Quito, Ecuador"
    },
    {
        "key": "ASU",
        "value": "Silvio Pettirossi International Airport, Asuncion, Paraguay"
    },
    {
        "key": "BGA",
        "value": "Palonegro Airport, Bucaramanga, Colombia"
    },
    {
        "key": "BOG",
        "value": "El Dorado International Airport, Bogota, Colombia"
    },
    {
        "key": "BAQ",
        "value": "Ernesto Cortissoz International Airport, Barranquilla, Colombia"
    },
    {
        "key": "CTG",
        "value": "Rafael Nuñez International Airport, Cartagena, Colombia"
    },
    {
        "key": "CLO",
        "value": "Alfonso Bonilla Aragon International Airport, Cali, Colombia"
    },
    {
        "key": "MDE",
        "value": "Jose Maria Córdova International Airport, Rio Negro, Colombia"
    },
    {
        "key": "ADZ",
        "value": "Gustavo Rojas Pinilla International Airport, San Andres Island, Colombia"
    },
    {
        "key": "CBB",
        "value": "Jorge Wilsterman International Airport, Cochabamba, Bolivia"
    },
    {
        "key": "LPB",
        "value": "El Alto International Airport, La Paz, Bolivia"
    },
    {
        "key": "PBM",
        "value": "Johan Adolf Pengel International Airport, Zandery, Suriname"
    },
    {
        "key": "CAY",
        "value": "Cayenne-Rochambeau Airport, Cayenne, French Guiana"
    },
    {
        "key": "LIM",
        "value": "Jorge Chávez International Airport, Lima, Peru"
    },
    {
        "key": "JUL",
        "value": "Inca Manco Capac International Airport, Juliaca, Peru"
    },
    {
        "key": "IQT",
        "value": "Coronel FAP Francisco Secada Vignetta International Airport, Iquitos, Peru"
    },
    {
        "key": "PIU",
        "value": "Capitán FAP Guillermo Concha Iberico International Airport, Piura, Peru"
    },
    {
        "key": "MVD",
        "value": "Carrasco International /General C L Berisso Airport, Montevideo, Uruguay"
    },
    {
        "key": "BLA",
        "value": "General José Antonio Anzoategui International Airport, Barcelona, Venezuela"
    },
    {
        "key": "MAR",
        "value": "La Chinita International Airport, Maracaibo, Venezuela"
    },
    {
        "key": "CCS",
        "value": "Simón Bolívar International Airport, Caracas, Venezuela"
    },
    {
        "key": "SFD",
        "value": "San Fernando De Apure Airport, San Fernando De Apure, Venezuela"
    },
    {
        "key": "ANU",
        "value": "V.C. Bird International Airport, Antigua, Antigua and Barbuda"
    },
    {
        "key": "BGI",
        "value": "Sir Grantley Adams International Airport, Bridgetown, Barbados"
    },
    {
        "key": "DOM",
        "value": "Douglas-Charles Airport, Dominica, Dominica"
    },
    {
        "key": "FDF",
        "value": "Martinique Aimé Césaire International Airport, Fort-de-france, Martinique"
    },
    {
        "key": "PTP",
        "value": "Pointe-à-Pitre Le Raizet, Pointe-a-pitre, Guadeloupe"
    },
    {
        "key": "GND",
        "value": "Point Salines International Airport, Point Salines, Grenada"
    },
    {
        "key": "STT",
        "value": "Cyril E. King Airport, St. Thomas, Virgin Islands"
    },
    {
        "key": "BQN",
        "value": "Rafael Hernandez Airport, Aguadilla, Puerto Rico"
    },
    {
        "key": "SIG",
        "value": "Fernando Luis Ribas Dominicci Airport, San Juan, Puerto Rico"
    },
    {
        "key": "PSE",
        "value": "Mercedita Airport, Ponce, Puerto Rico"
    },
    {
        "key": "SJU",
        "value": "Luis Munoz Marin International Airport, San Juan, Puerto Rico"
    },
    {
        "key": "SKB",
        "value": "Robert L. Bradshaw International Airport, Basse Terre, Saint Kitts and Nevis"
    },
    {
        "key": "SLU",
        "value": "George F. L. Charles Airport, Castries, Saint Lucia"
    },
    {
        "key": "UVF",
        "value": "Hewanorra International Airport, Hewandorra, Saint Lucia"
    },
    {
        "key": "AUA",
        "value": "Queen Beatrix International Airport, Oranjestad, Aruba"
    },
    {
        "key": "BON",
        "value": "Flamingo International Airport, Kralendijk, Netherlands Antilles"
    },
    {
        "key": "CUR",
        "value": "Hato International Airport, Willemstad, Netherlands Antilles"
    },
    {
        "key": "SXM",
        "value": "Princess Juliana International Airport, Philipsburg, Netherlands Antilles"
    },
    {
        "key": "AXA",
        "value": "Clayton J Lloyd International Airport, The Valley, Anguilla"
    },
    {
        "key": "TAB",
        "value": "Tobago-Crown Point Airport, Scarborough, Trinidad and Tobago"
    },
    {
        "key": "POS",
        "value": "Piarco International Airport, Port-of-spain, Trinidad and Tobago"
    },
    {
        "key": "EIS",
        "value": "Terrance B. Lettsome International Airport, Tortola, British Virgin Islands"
    },
    {
        "key": "SVD",
        "value": "Argyle International Airport, Kingstown, Saint Vincent and the Grenadines"
    },
    {
        "key": "ALA",
        "value": "Almaty Airport, Alma-ata, Kazakhstan"
    },
    {
        "key": "TSE",
        "value": "Astana International Airport, Tselinograd, Kazakhstan"
    },
    {
        "key": "FRU",
        "value": "Manas International Airport, Bishkek, Kyrgyzstan"
    },
    {
        "key": "GYD",
        "value": "Heydar Aliyev International Airport, Baku, Azerbaijan"
    },
    {
        "key": "KHV",
        "value": "Khabarovsk-Novy Airport, Khabarovsk, Russia"
    },
    {
        "key": "IKT",
        "value": "Irkutsk Airport, Irkutsk, Russia"
    },
    {
        "key": "KBP",
        "value": "Boryspil International Airport, Kiev, Ukraine"
    },
    {
        "key": "SIP",
        "value": "Simferopol International Airport, Simferopol, Ukraine"
    },
    {
        "key": "IEV",
        "value": "Kiev Zhuliany International Airport, Kiev, Ukraine"
    },
    {
        "key": "LWO",
        "value": "Lviv International Airport, Lvov, Ukraine"
    },
    {
        "key": "ODS",
        "value": "Odessa International Airport, Odessa, Ukraine"
    },
    {
        "key": "LED",
        "value": "Pulkovo Airport, St. Petersburg, Russia"
    },
    {
        "key": "MSQ",
        "value": "Minsk National Airport, Minsk 2, Belarus"
    },
    {
        "key": "MRV",
        "value": "Mineralnyye Vody Airport, Mineralnye Vody, Russia"
    },
    {
        "key": "AER",
        "value": "Sochi International Airport, Sochi, Russia"
    },
    {
        "key": "ASB",
        "value": "Ashgabat International Airport, Ashkhabad, Turkmenistan"
    },
    {
        "key": "DYU",
        "value": "Dushanbe Airport, Dushanbe, Tajikistan"
    },
    {
        "key": "SKD",
        "value": "Samarkand Airport, Samarkand, Uzbekistan"
    },
    {
        "key": "TAS",
        "value": "Tashkent International Airport, Tashkent, Uzbekistan"
    },
    {
        "key": "SVO",
        "value": "Sheremetyevo International Airport, Moscow, Russia"
    },
    {
        "key": "VKO",
        "value": "Vnukovo International Airport, Moscow, Russia"
    },
    {
        "key": "AMD",
        "value": "Sardar Vallabhbhai Patel International Airport, Ahmedabad, India"
    },
    {
        "key": "BOM",
        "value": "Chhatrapati Shivaji International Airport, Mumbai, India"
    },
    {
        "key": "GOI",
        "value": "Dabolim Airport, Goa, India"
    },
    {
        "key": "NAG",
        "value": "Dr. Babasaheb Ambedkar International Airport, Nagpur, India"
    },
    {
        "key": "CMB",
        "value": "Bandaranaike International Colombo Airport, Colombo, Sri Lanka"
    },
    {
        "key": "PNH",
        "value": "Phnom Penh International Airport, Phnom-penh, Cambodia"
    },
    {
        "key": "BBI",
        "value": "Biju Patnaik Airport, Bhubaneswar, India"
    },
    {
        "key": "CCU",
        "value": "Netaji Subhash Chandra Bose International Airport, Kolkata, India"
    },
    {
        "key": "CGP",
        "value": "Shah Amanat International Airport, Chittagong, Bangladesh"
    },
    {
        "key": "ZYL",
        "value": "Osmany International Airport, Sylhet Osmani, Bangladesh"
    },
    {
        "key": "DAC",
        "value": "Hazrat Shahjalal International Airport, Dhaka, Bangladesh"
    },
    {
        "key": "HKG",
        "value": "Hong Kong International Airport, Hong Kong, Hong Kong"
    },
    {
        "key": "ATQ",
        "value": "Sri Guru Ram Dass Jee International Airport, Amritsar, India"
    },
    {
        "key": "VNS",
        "value": "Lal Bahadur Shastri Airport, Varanasi, India"
    },
    {
        "key": "IXC",
        "value": "Chandigarh Airport, Chandigarh, India"
    },
    {
        "key": "DEL",
        "value": "Indira Gandhi International Airport, Delhi, India"
    },
    {
        "key": "JAI",
        "value": "Jaipur International Airport, Jaipur, India"
    },
    {
        "key": "LKO",
        "value": "Chaudhary Charan Singh International Airport, Lucknow, India"
    },
    {
        "key": "MFM",
        "value": "Macau International Airport, Macau, Macau"
    },
    {
        "key": "KTM",
        "value": "Tribhuvan International Airport, Kathmandu, Nepal"
    },
    {
        "key": "PKR",
        "value": "Pokhara Airport, Pokhara, Nepal"
    },
    {
        "key": "BLR",
        "value": "Kempegowda International Airport, Bangalore, India"
    },
    {
        "key": "CJB",
        "value": "Coimbatore International Airport, Coimbatore, India"
    },
    {
        "key": "COK",
        "value": "Cochin International Airport, Kochi, India"
    },
    {
        "key": "CCJ",
        "value": "Calicut International Airport, Calicut, India"
    },
    {
        "key": "MAA",
        "value": "Chennai International Airport, Madras, India"
    },
    {
        "key": "TRZ",
        "value": "Tiruchirapally Civil Airport Airport, Tiruchirappalli, India"
    },
    {
        "key": "TRV",
        "value": "Trivandrum International Airport, Trivandrum, India"
    },
    {
        "key": "PBH",
        "value": "Paro Airport, Thimphu, Bhutan"
    },
    {
        "key": "MLE",
        "value": "Malé International Airport, Male, Maldives"
    },
    {
        "key": "UTP",
        "value": "U-Tapao International Airport, Pattaya, Thailand"
    },
    {
        "key": "HKT",
        "value": "Phuket International Airport, Phuket, Thailand"
    },
    {
        "key": "HDY",
        "value": "Hat Yai International Airport, Hat Yai, Thailand"
    },
    {
        "key": "DAD",
        "value": "Da Nang International Airport, Danang, Vietnam"
    },
    {
        "key": "HAN",
        "value": "Noi Bai International Airport, Hanoi, Vietnam"
    },
    {
        "key": "HUI",
        "value": "Phu Bai Airport, Hue, Vietnam"
    },
    {
        "key": "SGN",
        "value": "Tan Son Nhat International Airport, Ho Chi Minh City, Vietnam"
    },
    {
        "key": "MDL",
        "value": "Mandalay International Airport, Mandalay, Burma"
    },
    {
        "key": "RGN",
        "value": "Yangon International Airport, Yangon, Burma"
    },
    {
        "key": "UPG",
        "value": "Hasanuddin International Airport, Ujung Pandang, Indonesia"
    },
    {
        "key": "MDC",
        "value": "Sam Ratulangi Airport, Manado, Indonesia"
    },
    {
        "key": "KCH",
        "value": "Kuching International Airport, Kuching, Malaysia"
    },
    {
        "key": "MUR",
        "value": "Marudi Airport, Marudi, Malaysia"
    },
    {
        "key": "MYY",
        "value": "Miri Airport, Miri, Malaysia"
    },
    {
        "key": "BKI",
        "value": "Kota Kinabalu International Airport, Kota Kinabalu, Malaysia"
    },
    {
        "key": "LBU",
        "value": "Labuan Airport, Labuan, Malaysia"
    },
    {
        "key": "TWU",
        "value": "Tawau Airport, Tawau, Malaysia"
    },
    {
        "key": "BWN",
        "value": "Brunei International Airport, Bandar Seri Begawan, Brunei"
    },
    {
        "key": "CGK",
        "value": "Soekarno-Hatta International Airport, Jakarta, Indonesia"
    },
    {
        "key": "PDG",
        "value": "Minangkabau International Airport, Padang, Indonesia"
    },
    {
        "key": "JHB",
        "value": "Senai International Airport, Johor Bahru, Malaysia"
    },
    {
        "key": "KUL",
        "value": "Kuala Lumpur International Airport, Kuala Lumpur, Malaysia"
    },
    {
        "key": "LGK",
        "value": "Langkawi International Airport, Langkawi, Malaysia"
    },
    {
        "key": "PEN",
        "value": "Penang International Airport, Penang, Malaysia"
    },
    {
        "key": "SIN",
        "value": "Singapore Changi Airport, Singapore, Singapore"
    },
    {
        "key": "ASP",
        "value": "Alice Springs Airport, Alice Springs, Australia"
    },
    {
        "key": "BNE",
        "value": "Brisbane International Airport, Brisbane, Australia"
    },
    {
        "key": "OOL",
        "value": "Gold Coast Airport, Coolangatta, Australia"
    },
    {
        "key": "CNS",
        "value": "Cairns International Airport, Cairns, Australia"
    },
    {
        "key": "MEB",
        "value": "Melbourne Essendon Airport, Melbourne, Australia"
    },
    {
        "key": "HBA",
        "value": "Hobart International Airport, Hobart, Australia"
    },
    {
        "key": "MEL",
        "value": "Melbourne International Airport, Melbourne, Australia"
    },
    {
        "key": "ADL",
        "value": "Adelaide International Airport, Adelaide, Australia"
    },
    {
        "key": "KTA",
        "value": "Karratha Airport, Karratha, Australia"
    },
    {
        "key": "PHE",
        "value": "Port Hedland International Airport, Port Hedland, Australia"
    },
    {
        "key": "PER",
        "value": "Perth International Airport, Perth, Australia"
    },
    {
        "key": "CBR",
        "value": "Canberra International Airport, Canberra, Australia"
    },
    {
        "key": "SYD",
        "value": "Sydney Kingsford Smith International Airport, Sydney, Australia"
    },
    {
        "key": "PEK",
        "value": "Beijing Capital International Airport, Beijing, China"
    },
    {
        "key": "TSN",
        "value": "Tianjin Binhai International Airport, Tianjin, China"
    },
    {
        "key": "TYN",
        "value": "Taiyuan Wusu Airport, Taiyuan, China"
    },
    {
        "key": "CAN",
        "value": "Guangzhou Baiyun International Airport, Guangzhou, China"
    },
    {
        "key": "KWL",
        "value": "Guilin Liangjiang International Airport, Guilin, China"
    },
    {
        "key": "SZX",
        "value": "Shenzhen Bao'an International Airport, Shenzhen, China"
    },
    {
        "key": "CGO",
        "value": "Zhengzhou Xinzheng International Airport, Zhengzhou, China"
    },
    {
        "key": "WUH",
        "value": "Wuhan Tianhe International Airport, Wuhan, China"
    },
    {
        "key": "FNJ",
        "value": "Pyongyang Sunan International Airport, Pyongyang, North Korea"
    },
    {
        "key": "XIY",
        "value": "Xi'an Xianyang International Airport, Xi'an, China"
    },
    {
        "key": "ULN",
        "value": "Chinggis Khaan International Airport, Ulan Bator, Mongolia"
    },
    {
        "key": "JHG",
        "value": "Xishuangbanna Gasa Airport, Jinghonggasa, China"
    },
    {
        "key": "KMG",
        "value": "Kunming Changshui International Airport, Kunming, China"
    },
    {
        "key": "XMN",
        "value": "Xiamen Gaoqi International Airport, Xiamen, China"
    },
    {
        "key": "FOC",
        "value": "Fuzhou Changle International Airport, Fuzhou, China"
    },
    {
        "key": "HGH",
        "value": "Hangzhou Xiaoshan International Airport, Hangzhou, China"
    },
    {
        "key": "NKG",
        "value": "Nanjing Lukou Airport, Nanjing, China"
    },
    {
        "key": "TAO",
        "value": "Liuting Airport, Qingdao, China"
    },
    {
        "key": "SHA",
        "value": "Shanghai Hongqiao International Airport, Shanghai, China"
    },
    {
        "key": "CKG",
        "value": "Chongqing Jiangbei International Airport, Chongqing, China"
    },
    {
        "key": "CTU",
        "value": "Chengdu Shuangliu International Airport, Chengdu, China"
    },
    {
        "key": "URC",
        "value": "Ürümqi Diwopu International Airport, Urumqi, China"
    },
    {
        "key": "HRB",
        "value": "Taiping Airport, Harbin, China"
    },
    {
        "key": "DLC",
        "value": "Zhoushuizi Airport, Dalian, China"
    },
    {
        "key": "PVG",
        "value": "Shanghai Pudong International Airport, Shanghai, China"
    },
    {
        "key": "SZB",
        "value": "Sultan Abdul Aziz Shah International Airport, Kuala Lumpur, Malaysia"
    },
    {
        "key": "ITO",
        "value": "Hilo International Airport, Hilo, United States"
    },
    {
        "key": "BOS",
        "value": "General Edward Lawrence Logan International Airport, Boston, United States"
    },
    {
        "key": "OAK",
        "value": "Metropolitan Oakland International Airport, Oakland, United States"
    },
    {
        "key": "MCI",
        "value": "Kansas City International Airport, Kansas City, United States"
    },
    {
        "key": "PHX",
        "value": "Phoenix Sky Harbor International Airport, Phoenix, United States"
    },
    {
        "key": "BGR",
        "value": "Bangor International Airport, Bangor, United States"
    },
    {
        "key": "GEG",
        "value": "Spokane International Airport, Spokane, United States"
    },
    {
        "key": "SFO",
        "value": "San Francisco International Airport, San Francisco, United States"
    },
    {
        "key": "MEM",
        "value": "Memphis International Airport, Memphis, United States"
    },
    {
        "key": "LAX",
        "value": "Los Angeles International Airport, Los Angeles, United States"
    },
    {
        "key": "CLE",
        "value": "Cleveland Hopkins International Airport, Cleveland, United States"
    },
    {
        "key": "CVG",
        "value": "Cincinnati Northern Kentucky International Airport, Cincinnati, United States"
    },
    {
        "key": "JNU",
        "value": "Juneau International Airport, Juneau, United States"
    },
    {
        "key": "EWR",
        "value": "Newark Liberty International Airport, Newark, United States"
    },
    {
        "key": "DAL",
        "value": "Dallas Love Field, Dallas, United States"
    },
    {
        "key": "KOA",
        "value": "Ellison Onizuka Kona International At Keahole Airport, Kona, United States"
    },
    {
        "key": "DCA",
        "value": "Ronald Reagan Washington National Airport, Washington, United States"
    },
    {
        "key": "ACY",
        "value": "Atlantic City International Airport, Atlantic City, United States"
    },
    {
        "key": "FLL",
        "value": "Fort Lauderdale Hollywood International Airport, Fort Lauderdale, United States"
    },
    {
        "key": "SLC",
        "value": "Salt Lake City International Airport, Salt Lake City, United States"
    },
    {
        "key": "MDT",
        "value": "Harrisburg International Airport, Harrisburg, United States"
    },
    {
        "key": "LAN",
        "value": "Capital City Airport, Lansing, United States"
    },
    {
        "key": "IAH",
        "value": "George Bush Intercontinental Houston Airport, Houston, United States"
    },
    {
        "key": "ELP",
        "value": "El Paso International Airport, El Paso, United States"
    },
    {
        "key": "HRL",
        "value": "Valley International Airport, Harlingen, United States"
    },
    {
        "key": "HOU",
        "value": "William P Hobby Airport, Houston, United States"
    },
    {
        "key": "PIT",
        "value": "Pittsburgh International Airport, Pittsburgh, United States"
    },
    {
        "key": "EFD",
        "value": "Ellington Airport, Houston, United States"
    },
    {
        "key": "MIA",
        "value": "Miami International Airport, Miami, United States"
    },
    {
        "key": "SEA",
        "value": "Seattle Tacoma International Airport, Seattle, United States"
    },
    {
        "key": "JAN",
        "value": "Jackson-Medgar Wiley Evers International Airport, Jackson, United States"
    },
    {
        "key": "IND",
        "value": "Indianapolis International Airport, Indianapolis, United States"
    },
    {
        "key": "YUM",
        "value": "Yuma MCAS/Yuma International Airport, Yuma, United States"
    },
    {
        "key": "DLH",
        "value": "Duluth International Airport, Duluth, United States"
    },
    {
        "key": "LOU",
        "value": "Bowman Field, Louisville, United States"
    },
    {
        "key": "HUF",
        "value": "Terre Haute Regional Airport, Hulman Field, Terre Haute, United States"
    },
    {
        "key": "RIC",
        "value": "Richmond International Airport, Richmond, United States"
    },
    {
        "key": "ORF",
        "value": "Norfolk International Airport, Norfolk, United States"
    },
    {
        "key": "SAV",
        "value": "Savannah Hilton Head International Airport, Savannah, United States"
    },
    {
        "key": "SAT",
        "value": "San Antonio International Airport, San Antonio, United States"
    },
    {
        "key": "ROC",
        "value": "Greater Rochester International Airport, Rochester, United States"
    },
    {
        "key": "RDU",
        "value": "Raleigh Durham International Airport, Raleigh-durham, United States"
    },
    {
        "key": "DAY",
        "value": "James M Cox Dayton International Airport, Dayton, United States"
    },
    {
        "key": "IAG",
        "value": "Niagara Falls International Airport, Niagara Falls, United States"
    },
    {
        "key": "TUS",
        "value": "Tucson International Airport, Tucson, United States"
    },
    {
        "key": "PVD",
        "value": "Theodore Francis Green State Airport, Providence, United States"
    },
    {
        "key": "SBY",
        "value": "Salisbury Ocean City Wicomico Regional Airport, Salisbury, United States"
    },
    {
        "key": "TPA",
        "value": "Tampa International Airport, Tampa, United States"
    },
    {
        "key": "MAF",
        "value": "Midland International Airport, Midland, United States"
    },
    {
        "key": "GRB",
        "value": "Austin Straubel International Airport, Green Bay, United States"
    },
    {
        "key": "SWF",
        "value": "Stewart International Airport, Newburgh, United States"
    },
    {
        "key": "DFW",
        "value": "Dallas Fort Worth International Airport, Dallas-Fort Worth, United States"
    },
    {
        "key": "AUS",
        "value": "Austin Bergstrom International Airport, Austin, United States"
    },
    {
        "key": "STL",
        "value": "St Louis Lambert International Airport, St. Louis, United States"
    },
    {
        "key": "ATL",
        "value": "Hartsfield Jackson Atlanta International Airport, Atlanta, United States"
    },
    {
        "key": "GRR",
        "value": "Gerald R. Ford International Airport, Grand Rapids, United States"
    },
    {
        "key": "FAT",
        "value": "Fresno Yosemite International Airport, Fresno, United States"
    },
    {
        "key": "BNA",
        "value": "Nashville International Airport, Nashville, United States"
    },
    {
        "key": "LGA",
        "value": "La Guardia Airport, New York, United States"
    },
    {
        "key": "TLH",
        "value": "Tallahassee Regional Airport, Tallahassee, United States"
    },
    {
        "key": "BTV",
        "value": "Burlington International Airport, Burlington, United States"
    },
    {
        "key": "JAX",
        "value": "Jacksonville International Airport, Jacksonville, United States"
    },
    {
        "key": "IAD",
        "value": "Washington Dulles International Airport, Washington, United States"
    },
    {
        "key": "MKE",
        "value": "General Mitchell International Airport, Milwaukee, United States"
    },
    {
        "key": "PDX",
        "value": "Portland International Airport, Portland, United States"
    },
    {
        "key": "HNL",
        "value": "Daniel K Inouye International Airport, Honolulu, United States"
    },
    {
        "key": "DSM",
        "value": "Des Moines International Airport, Des Moines, United States"
    },
    {
        "key": "SAN",
        "value": "San Diego International Airport, San Diego, United States"
    },
    {
        "key": "ONT",
        "value": "Ontario International Airport, Ontario, United States"
    },
    {
        "key": "DET",
        "value": "Coleman A. Young Municipal Airport, Detroit, United States"
    },
    {
        "key": "CRP",
        "value": "Corpus Christi International Airport, Corpus Christi, United States"
    },
    {
        "key": "SYR",
        "value": "Syracuse Hancock International Airport, Syracuse, United States"
    },
    {
        "key": "MDW",
        "value": "Chicago Midway International Airport, Chicago, United States"
    },
    {
        "key": "SJC",
        "value": "Norman Y. Mineta San Jose International Airport, San Jose, United States"
    },
    {
        "key": "PNE",
        "value": "Northeast Philadelphia Airport, Philadelphia, United States"
    },
    {
        "key": "DEN",
        "value": "Denver International Airport, Denver, United States"
    },
    {
        "key": "PHL",
        "value": "Philadelphia International Airport, Philadelphia, United States"
    },
    {
        "key": "CMH",
        "value": "John Glenn Columbus International Airport, Columbus, United States"
    },
    {
        "key": "ANC",
        "value": "Ted Stevens Anchorage International Airport, Anchorage, United States"
    },
    {
        "key": "BLI",
        "value": "Bellingham International Airport, Bellingham, United States"
    },
    {
        "key": "OPF",
        "value": "Opa-locka Executive Airport, Miami, United States"
    },
    {
        "key": "RSW",
        "value": "Southwest Florida International Airport, Fort Myers, United States"
    },
    {
        "key": "JFK",
        "value": "John F Kennedy International Airport, New York, United States"
    },
    {
        "key": "CHS",
        "value": "Charleston Air Force Base-International Airport, Charleston, United States"
    },
    {
        "key": "RNO",
        "value": "Reno Tahoe International Airport, Reno, United States"
    },
    {
        "key": "KTN",
        "value": "Ketchikan International Airport, Ketchikan, United States"
    },
    {
        "key": "BHM",
        "value": "Birmingham-Shuttlesworth International Airport, Birmingham, United States"
    },
    {
        "key": "SMF",
        "value": "Sacramento International Airport, Sacramento, United States"
    },
    {
        "key": "BUF",
        "value": "Buffalo Niagara International Airport, Buffalo, United States"
    },
    {
        "key": "BDL",
        "value": "Bradley International Airport, Windsor Locks, United States"
    },
    {
        "key": "LBB",
        "value": "Lubbock Preston Smith International Airport, Lubbock, United States"
    },
    {
        "key": "ORD",
        "value": "Chicago O'Hare International Airport, Chicago, United States"
    },
    {
        "key": "FAI",
        "value": "Fairbanks International Airport, Fairbanks, United States"
    },
    {
        "key": "AMA",
        "value": "Rick Husband Amarillo International Airport, Amarillo, United States"
    },
    {
        "key": "ILM",
        "value": "Wilmington International Airport, Wilmington, United States"
    },
    {
        "key": "BWI",
        "value": "Baltimore/Washington International Thurgood Marshall Airport, Baltimore, United States"
    },
    {
        "key": "TUL",
        "value": "Tulsa International Airport, Tulsa, United States"
    },
    {
        "key": "MSP",
        "value": "Minneapolis-St Paul International/Wold-Chamberlain Airport, Minneapolis, United States"
    },
    {
        "key": "MSY",
        "value": "Louis Armstrong New Orleans International Airport, New Orleans, United States"
    },
    {
        "key": "PWM",
        "value": "Portland International Jetport Airport, Portland, United States"
    },
    {
        "key": "OKC",
        "value": "Will Rogers World Airport, Oklahoma City, United States"
    },
    {
        "key": "ALB",
        "value": "Albany International Airport, Albany, United States"
    },
    {
        "key": "EYW",
        "value": "Key West International Airport, Key West, United States"
    },
    {
        "key": "CLT",
        "value": "Charlotte Douglas International Airport, Charlotte, United States"
    },
    {
        "key": "LAS",
        "value": "McCarran International Airport, Las Vegas, United States"
    },
    {
        "key": "MCO",
        "value": "Orlando International Airport, Orlando, United States"
    },
    {
        "key": "GTF",
        "value": "Great Falls International Airport, Great Falls, United States"
    },
    {
        "key": "BKK",
        "value": "Suvarnabhumi Airport, Bangkok, Thailand"
    },
    {
        "key": "BDO",
        "value": "Husein Sastranegara International Airport, Bandung, Indonesia"
    },
    {
        "key": "SUB",
        "value": "Juanda International Airport, Surabaya, Indonesia"
    },
    {
        "key": "SOC",
        "value": "Adi Sumarmo Wiryokusumo Airport, Solo City, Indonesia"
    },
    {
        "key": "ICN",
        "value": "Incheon International Airport, Seoul, South Korea"
    },
    {
        "key": "CNX",
        "value": "Chiang Mai International Airport, Chiang Mai, Thailand"
    },
    {
        "key": "DPS",
        "value": "Ngurah Rai (Bali) International Airport, Denpasar, Indonesia"
    },
    {
        "key": "ATH",
        "value": "Eleftherios Venizelos International Airport, Athens, Greece"
    },
    {
        "key": "NGO",
        "value": "Chubu Centrair International Airport, Nagoya, Japan"
    },
    {
        "key": "UKB",
        "value": "Kobe Airport, Kobe, Japan"
    },
    {
        "key": "DAB",
        "value": "Daytona Beach International Airport, Daytona Beach, United States"
    },
    {
        "key": "RIX",
        "value": "Riga International Airport, Riga, Latvia"
    },
    {
        "key": "SQQ",
        "value": "Šiauliai International Airport, Siauliai, Lithuania"
    },
    {
        "key": "KUN",
        "value": "Kaunas International Airport, Kaunas, Lithuania"
    },
    {
        "key": "PLQ",
        "value": "Palanga International Airport, Palanga, Lithuania"
    },
    {
        "key": "VNO",
        "value": "Vilnius International Airport, Vilnius, Lithuania"
    },
    {
        "key": "EVN",
        "value": "Zvartnots International Airport, Yerevan, Armenia"
    },
    {
        "key": "ASM",
        "value": "Asmara International Airport, Asmara, Eritrea"
    },
    {
        "key": "GZA",
        "value": "Yasser Arafat International Airport, Gaza, Palestine"
    },
    {
        "key": "TBS",
        "value": "Tbilisi International Airport, Tbilisi, Georgia"
    },
    {
        "key": "RIY",
        "value": "Mukalla International Airport, Mukalla, Yemen"
    },
    {
        "key": "TAI",
        "value": "Ta'izz International Airport, Taiz, Yemen"
    },
    {
        "key": "ADE",
        "value": "Aden International Airport, Aden, Yemen"
    },
    {
        "key": "SAH",
        "value": "Sana'a International Airport, Sanaa, Yemen"
    },
    {
        "key": "FMM",
        "value": "Memmingen Allgau Airport, Memmingen, Germany"
    },
    {
        "key": "EZE",
        "value": "Ministro Pistarini International Airport, Buenos Aires, Argentina"
    },
    {
        "key": "KIX",
        "value": "Kansai International Airport, Osaka, Japan"
    },
    {
        "key": "PMI",
        "value": "Palma De Mallorca Airport, Palma de Mallorca, Spain"
    },
    {
        "key": "DRW",
        "value": "Darwin International Airport, Darwin, Australia"
    },
    {
        "key": "GSO",
        "value": "Piedmont Triad International Airport, Greensboro, United States"
    },
    {
        "key": "SDF",
        "value": "Louisville International Standiford Field, Louisville, United States"
    },
    {
        "key": "LEX",
        "value": "Blue Grass Airport, Lexington KY, United States"
    },
    {
        "key": "ABQ",
        "value": "Albuquerque International Sunport, Albuquerque, United States"
    },
    {
        "key": "BZN",
        "value": "Gallatin Field, Bozeman, United States"
    },
    {
        "key": "BIL",
        "value": "Billings Logan International Airport, Billings, United States"
    },
    {
        "key": "RFD",
        "value": "Chicago Rockford International Airport, Rockford, United States"
    },
    {
        "key": "DME",
        "value": "Domodedovo International Airport, Moscow, Russia"
    },
    {
        "key": "GSP",
        "value": "Greenville Spartanburg International Airport, Greenville, United States"
    },
    {
        "key": "GPT",
        "value": "Gulfport Biloxi International Airport, Gulfport, United States"
    },
    {
        "key": "AZO",
        "value": "Kalamazoo Battle Creek International Airport, Kalamazoo, United States"
    },
    {
        "key": "FWA",
        "value": "Fort Wayne International Airport, Fort Wayne, United States"
    },
    {
        "key": "ATW",
        "value": "Appleton International Airport, Appleton, United States"
    },
    {
        "key": "KGC",
        "value": "Kingscote Airport, Kingscote, Australia"
    },
    {
        "key": "SSH",
        "value": "Sharm El Sheikh International Airport, Sharm El Sheikh, Egypt"
    },
    {
        "key": "NBO",
        "value": "Jomo Kenyatta International Airport, Nairobi, Kenya"
    },
    {
        "key": "BDA",
        "value": "L.F. Wade International International Airport, Bermuda, Bermuda"
    },
    {
        "key": "VNY",
        "value": "Van Nuys Airport, Van Nuys, United States"
    },
    {
        "key": "MLI",
        "value": "Quad City International Airport, Moline, United States"
    },
    {
        "key": "HIR",
        "value": "Honiara International Airport, Honiara, Solomon Islands"
    },
    {
        "key": "PPT",
        "value": "Faa'a International Airport, Papeete, French Polynesia"
    },
    {
        "key": "OVB",
        "value": "Tolmachevo Airport, Novosibirsk, Russia"
    },
    {
        "key": "FNT",
        "value": "Bishop International Airport, Flint, United States"
    },
    {
        "key": "FNC",
        "value": "Madeira Airport, Funchal, Portugal"
    },
    {
        "key": "WDH",
        "value": "Hosea Kutako International Airport, Windhoek, Namibia"
    },
    {
        "key": "TNA",
        "value": "Yaoqiang Airport, Jinan, China"
    },
    {
        "key": "CZX",
        "value": "Changzhou Benniu Airport, Changzhou, China"
    },
    {
        "key": "CAK",
        "value": "Akron Canton Regional Airport, Akron, United States"
    },
    {
        "key": "HSV",
        "value": "Huntsville International Carl T Jones Field, Huntsville, United States"
    },
    {
        "key": "JIB",
        "value": "Djibouti-Ambouli Airport, Djibouti, Djibouti"
    },
    {
        "key": "FCA",
        "value": "Glacier Park International Airport, Kalispell, United States"
    },
    {
        "key": "MBS",
        "value": "MBS International Airport, Saginaw, United States"
    },
    {
        "key": "BGW",
        "value": "Baghdad International Airport, Baghdad, Iraq"
    },
    {
        "key": "CRK",
        "value": "Diosdado Macapagal International Airport, Angeles City, Philippines"
    },
    {
        "key": "SHE",
        "value": "Taoxian Airport, Shenyang, China"
    },
    {
        "key": "CKY",
        "value": "Conakry International Airport, Conakry, Guinea"
    },
    {
        "key": "FKB",
        "value": "Karlsruhe Baden-Baden Airport, Karlsruhe/Baden-Baden, Germany"
    },
    {
        "key": "RMF",
        "value": "Marsa Alam International Airport, Marsa Alam, Egypt"
    },
    {
        "key": "NRN",
        "value": "Weeze Airport, Weeze, Germany"
    },
    {
        "key": "CEB",
        "value": "Mactan Cebu International Airport, Cebu, Philippines"
    },
    {
        "key": "SRZ",
        "value": "El Trompillo Airport, Santa Cruz, Bolivia"
    },
    {
        "key": "ERI",
        "value": "Erie International Tom Ridge Field, Erie, United States"
    },
    {
        "key": "AVP",
        "value": "Wilkes Barre Scranton International Airport, Scranton, United States"
    },
    {
        "key": "MKC",
        "value": "Charles B. Wheeler Downtown Airport, Kansas City, United States"
    },
    {
        "key": "GEO",
        "value": "Cheddi Jagan International Airport, Georgetown, Guyana"
    },
    {
        "key": "AOI",
        "value": "Ancona Falconara Airport, Ancona, Italy"
    },
    {
        "key": "BJV",
        "value": "Milas Bodrum International Airport, Bodrum, Turkey"
    },
    {
        "key": "SAW",
        "value": "Sabiha Gökçen International Airport, Istanbul, Turkey"
    },
    {
        "key": "BME",
        "value": "Broome International Airport, Broome, Australia"
    },
    {
        "key": "KLU",
        "value": "Klagenfurt Airport, Klagenfurt, Austria"
    },
    {
        "key": "HFT",
        "value": "Hammerfest Airport, Hammerfest, Norway"
    },
    {
        "key": "MHD",
        "value": "Mashhad International Airport, Mashhad, Iran"
    },
    {
        "key": "SGF",
        "value": "Springfield Branson National Airport, Springfield, United States"
    },
    {
        "key": "ABE",
        "value": "Lehigh Valley International Airport, Allentown, United States"
    },
    {
        "key": "SBN",
        "value": "South Bend Regional Airport, South Bend, United States"
    },
    {
        "key": "ARH",
        "value": "Talagi Airport, Arkhangelsk, Russia"
    },
    {
        "key": "CGQ",
        "value": "Longjia Airport, Changchun, China"
    },
    {
        "key": "KIJ",
        "value": "Niigata Airport, Niigata, Japan"
    },
    {
        "key": "SCZ",
        "value": "Santa Cruz/Graciosa Bay/Luova Airport, Santa Cruz/Graciosa Bay/Luova, Solomon Islands"
    },
    {
        "key": "THO",
        "value": "Thorshofn Airport, Thorshofn, Iceland"
    },
    {
        "key": "KMS",
        "value": "Kumasi Airport, Kumasi, Ghana"
    },
    {
        "key": "BZG",
        "value": "Bydgoszcz Ignacy Jan Paderewski Airport, Bydgoszcz, Poland"
    },
    {
        "key": "LCJ",
        "value": "Łódź Władysław Reymont Airport, Lodz, Poland"
    },
    {
        "key": "OSD",
        "value": "Åre Östersund Airport, Östersund, Sweden"
    },
    {
        "key": "VNT",
        "value": "Ventspils International Airport, Ventspils, Latvia"
    },
    {
        "key": "VIL",
        "value": "Dakhla Airport, Dakhla, Western Sahara"
    },
    {
        "key": "RAI",
        "value": "Praia International Airport, Praia, Santiago Island, Cape Verde"
    },
    {
        "key": "BEI",
        "value": "Beica Airport, Beica, Ethiopia"
    },
    {
        "key": "MGQ",
        "value": "Aden Adde International Airport, Mogadishu, Somalia"
    },
    {
        "key": "AAC",
        "value": "El Arish International Airport, El Arish, Egypt"
    },
    {
        "key": "ATZ",
        "value": "Assiut International Airport, Asyut, Egypt"
    },
    {
        "key": "ABR",
        "value": "Aberdeen Regional Airport, Aberdeen, United States"
    },
    {
        "key": "CLM",
        "value": "William R Fairchild International Airport, Port Angeles, United States"
    },
    {
        "key": "EAU",
        "value": "Chippewa Valley Regional Airport, Eau Claire, United States"
    },
    {
        "key": "SOB",
        "value": "Sármellék International Airport, Sármellék, Hungary"
    },
    {
        "key": "KCM",
        "value": "Kahramanmaraş Airport, Kahramanmaras, Turkey"
    },
    {
        "key": "ADF",
        "value": "Adıyaman Airport, Adiyaman, Turkey"
    },
    {
        "key": "SZF",
        "value": "Samsun Çarşamba Airport, Samsun, Turkey"
    },
    {
        "key": "ILZ",
        "value": "Žilina Airport, Žilina, Slovakia"
    },
    {
        "key": "JEE",
        "value": "Jérémie Airport, Jeremie, Haiti"
    },
    {
        "key": "AAN",
        "value": "Al Ain International Airport, Al Ain, United Arab Emirates"
    },
    {
        "key": "ENT",
        "value": "Eniwetok Airport, Eniwetok Atoll, Marshall Islands"
    },
    {
        "key": "KUH",
        "value": "Kushiro Airport, Kushiro, Japan"
    },
    {
        "key": "FKS",
        "value": "Fukushima Airport, Fukushima, Japan"
    },
    {
        "key": "ORG",
        "value": "Zorg en Hoop Airport, Paramaribo, Suriname"
    },
    {
        "key": "HRK",
        "value": "Kharkiv International Airport, Kharkov, Ukraine"
    },
    {
        "key": "UGC",
        "value": "Urgench Airport, Urgench, Uzbekistan"
    },
    {
        "key": "GAU",
        "value": "Lokpriya Gopinath Bordoloi International Airport, Guwahati, India"
    },
    {
        "key": "HLP",
        "value": "Halim Perdanakusuma International Airport, Jakarta, Indonesia"
    },
    {
        "key": "BUC",
        "value": "Burketown Airport, Burketown, Australia"
    },
    {
        "key": "BLT",
        "value": "Blackwater Airport, Blackwater, Australia"
    },
    {
        "key": "PLO",
        "value": "Port Lincoln Airport, Port Lincoln, Australia"
    },
    {
        "key": "AKA",
        "value": "Ankang Wulipu Airport, Ankang, China"
    },
    {
        "key": "JJN",
        "value": "Quanzhou Jinjiang International Airport, Quanzhou, China"
    },
    {
        "key": "JMU",
        "value": "Jiamusi Airport, Jiamusi, China"
    },
    {
        "key": "LRH",
        "value": "La Rochelle-Île de Ré Airport, La Rochelle, France"
    },
    {
        "key": "SKT",
        "value": "Sialkot Airport, Sialkot, Pakistan"
    },
    {
        "key": "WVB",
        "value": "Walvis Bay Airport, Walvis Bay, Namibia"
    },
    {
        "key": "QRW",
        "value": "Warri Airport, Osubi, Nigeria"
    },
    {
        "key": "SIA",
        "value": "Xi'an Xiguan Airport, Xi'AN, China"
    },
    {
        "key": "BRA",
        "value": "Barreiras Airport, Barreiras, Brazil"
    },
    {
        "key": "AAX",
        "value": "Romeu Zema Airport, Araxa, Brazil"
    },
    {
        "key": "JIJ",
        "value": "Wilwal International Airport, Jijiga, Ethiopia"
    },
    {
        "key": "ILD",
        "value": "Lleida-Alguaire Airport, Lleida, Spain"
    },
    {
        "key": "HSK",
        "value": "Huesca/Pirineos Airport, Huesca, Spain"
    },
    {
        "key": "CKS",
        "value": "Carajás Airport, Parauapebas, Brazil"
    },
    {
        "key": "DWC",
        "value": "Al Maktoum International Airport, Dubai, United Arab Emirates"
    },
    {
        "key": "BNU",
        "value": "Blumenau Airport, BLUMENAU, Brazil"
    },
    {
        "key": "WMI",
        "value": "Modlin Airport, Warsaw, Poland"
    },
    {
        "key": "HOA",
        "value": "Hola Airport, Hola, Kenya"
    },
    {
        "key": "BST",
        "value": "Bost Airport, Lashkar Gah, Afghanistan"
    },
    {
        "key": "LUZ",
        "value": "Lublin Airport, Lublin, Poland"
    },
    {
        "key": "INCOMPLETE",
        "value": "Lakefront Airport, New Orleans, United States"
    },
    {
        "key": "FCM",
        "value": "Flying Cloud Airport, Eden Prairie, United States"
    },
    {
        "key": "BTZ",
        "value": "Bursa Airport, Bursa, Turkey"
    },
    {
        "key": "WAR",
        "value": "Waris Airport, Waris-Papua Island, Indonesia"
    },
    {
        "key": "CZA",
        "value": "Chichen Itza International Airport, Chichen Itza, Mexico"
    },
    {
        "key": "DOH",
        "value": "Hamad International Airport, Doha, Qatar"
    },
    {
        "key": "MAI",
        "value": "Mangochi Airport, Mangochi, Malawi"
    },
    {
        "key": "HYD",
        "value": "Rajiv Gandhi International Airport, Hyderabad, India"
    },
    {
        "key": "BGL",
        "value": "Baglung Airport, Baglung, Nepal"
    },
    {
        "key": "IST",
        "value": "Istanbul Airport, Istanbul, Turkey"
    },
    {
        "key": "ISB",
        "value": "New Islamabad International Airport, Islamabad, Pakistan"
    },
    {
        "key": "RMU",
        "value": "Región de Murcia International Airport, Murcia, Spain"
    },
    {
        "key": "DTT",
        "value": "Detroit (MI) , Metropolitan Area, Detroit, USA"
    },
    {
        "key": "MOW",
        "value": "Moscow - Metropolitan Area, Moscow, Russia"
    },
    {
        "key": "NYC",
        "value": "New York (NY) , New York, USA"
    },
    {
        "key": "YTO",
        "value": "Toronto, Toronto, Canada"
    },
    {
        "key": "CAS",
        "value": "Casablanca, Casablanca, Morocco"
    },
    {
        "key": "BAK",
        "value": "Heydar Aliyev International Airport, Baku, Azerbaijan"
    },
    {
        "key": "BAN",
        "value": "Berlin, Berlin, Germany"
    },
    {
        "key": "BUE",
        "value": "Buenos Aires, Buenos Aires, Argentina"
    },
    {
        "key": "CHI",
        "value": "Chicago (IL), Chicago, USA"
    },
    {
        "key": "IZM",
        "value": "Izmir, Izmir, Turkey"
    },
    {
        "key": "JKT",
        "value": "Jakarta - Metropolitan Area, Jakarta, Indonesia"
    },
    {
        "key": "MLH",
        "value": "Mulhouse, Mulhouse, France"
    },
    {
        "key": "OSA",
        "value": "Osaka, Metropolitan Area, Osaka, Japan"
    },
    {
        "key": "REK",
        "value": "Reykjavik - Metropolitan Area, Reykjavik, Iceland"
    },
    {
        "key": "RIO",
        "value": "Rio Branco - Plácido de Castro International Airport, Rio Branco, Brazil"
    },
    {
        "key": "SAO",
        "value": "Sao Paulo, Sao Paulo, Brazil"
    },
    {
        "key": "SEL",
        "value": "Kimpo, Seoul, South Korea"
    },
    {
        "key": "WAS",
        "value": "Washington DC, Washington DC, USA"
    },
    {
        "key": "WEL",
        "value": "Welkom, Welkom, South Africa"
    }
];

export default airports;