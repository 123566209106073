import { useContext, useEffect, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { getQueryParam } from "../../router/utils";
import HelpButton from "./HelpButton";
import ThemeContext from "../../context/theme/ThemeContext";
import { ELEMENT_QUERY_PARAM } from "../../constants/GlobalConstants";

const ElementLabel = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const [isHighlighted, setIsHighlighted] = useState(false);

	const label = useMemo(
		() => props.label || `${props.element.parenthesisCode || ""} ${props.element.textDescription}`,
		[props.label, props.element]
	);

	const helpText = useMemo(() => props.helpText || props.element?.description, [props.helpText, props.element]);

	useEffect(() => {
		const checkIsHighlighted = () => {
			return props.isHighlighted || getQueryParam(ELEMENT_QUERY_PARAM) === props.element?.elementName;
		};

		setIsHighlighted(checkIsHighlighted());

		const eventHandler = () => {
			setIsHighlighted(checkIsHighlighted());
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, []);

	return (
		<div className="d-flex align-items-center" style={{ textAlign: "left", marginBottom: 4 }}>
			<Highlighter
				className={withTheme("input-label")}
				highlightStyle={{ backgroundColor: "yellow" }}
				searchWords={isHighlighted ? [label] : []}
				autoEscape={true}
				textToHighlight={label}
			/>

			{helpText && <HelpButton helpText={helpText} style={{ marginLeft: 8, height: 16 }} />}
		</div>
	);
};

export default ElementLabel;
