import { useMemo, useState } from "react";
import LoadingSpinner from "../../../../../../common/LoadingSpinner";
import DrawerItem from "./DrawerItem/DrawerItem";
import Drawer from "../../../../../../navigation/Drawer";
import { PiBarcodeFill, PiMapPinLineFill, PiUserSquareFill } from "react-icons/pi";
import SearchInput from "../../../../../../common/SearchInput";
import moment from "moment";
import { SEARCH_TYPES } from "../../../../../../../constants/GlobalConstants";

const SearchPageDrawer = (props) => {
	const [sort, setSort] = useState("oldestFirst");
	const [searchValue, setSearchValue] = useState("");
	const {
		items,
		searchType,
		setIsNewItemModalOpen,
		isLoading,
		selectedItem,
		setSelectedItem,
		isExpanded,
		setIsExpanded,
		isInModal,
	} = props;

	const filteredItems = useMemo(() => {
		let filteredItems = [];

		if (searchValue) {
			const lowerCaseSearchValue = String(searchValue).toLowerCase();

			items?.forEach((item) => {
				if (item) {
					for (const value of Object.values(item.data).values()) {
						if (String(value).toLowerCase().includes(lowerCaseSearchValue)) {
							filteredItems.push(item);
							break;
						}
					}
				}
			});
		} else {
			filteredItems = items;
		}

		return filteredItems.sort((a, b) => {
			const isAfter = moment(a.createdAt).isAfter(moment(b.createdAt));

			return sort === "oldestFirst" ? (isAfter ? 1 : -1) : isAfter ? -1 : 1;
		});
	}, [items, searchValue, sort]);

	const CollapsedIcon = useMemo(() => {
		if (searchType === SEARCH_TYPES.CONTACTS) {
			return PiUserSquareFill;
		} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
			return PiMapPinLineFill;
		} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
			return PiBarcodeFill;
		}
	}, [searchType]);

	return (
		<Drawer
			hideHeader={isInModal}
			hideExpandButton={isInModal}
			style={isInModal ? { height: "100%", minHeight: 0 } : {}}
			isExpanded={isExpanded}
			setIsExpanded={setIsExpanded}
			CollapsedIcon={CollapsedIcon}
			sort={sort}
			onSortChange={setSort}
			onAddClick={() => setIsNewItemModalOpen(true)}
		>
			<div
				style={{
					paddingRight: 20,
					paddingLeft: !isInModal ? 20 : 0,
					display: "flex",
					alignItems: "center",
				}}
			>
				<SearchInput
					containerStyle={{ width: "100%" }}
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
				/>
			</div>

			<div
				style={{
					marginTop: 20,
					height: isInModal ? "calc(100% - 70px)" : null,
					overflowY: isInModal ? "auto" : null,
				}}
			>
				{isLoading ? (
					<div style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto" }}>
						<LoadingSpinner style={{ marginTop: 16 }} />
					</div>
				) : (
					filteredItems.map((item, index) => (
						<DrawerItem
							onClick={() => setSelectedItem(item)}
							searchType={searchType}
							isSelected={selectedItem?._id === item._id}
							item={item}
							key={index}
						/>
					))
				)}
			</div>
		</Drawer>
	);
};

export default SearchPageDrawer;
