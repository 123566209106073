import { BrowserRouter, useLocation } from "react-router-dom";
import DeclarationsState from "./context/declarations/DeclarationsState";
import FilesState from "./context/files/FilesState";
import UserState from "./context/user/UserState";
import ThemeState from "./context/theme/ThemeState";
import "./themes";
import Router from "./router/Router";
// import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import WorkspacesState from "./context/workspaces/WorkspacesState";
import NotificationsState from "./context/notifications/NotificationsState";

export function useQuery() {
	return new URLSearchParams(useLocation().search);
}

// const matomoInstance = createInstance({
// 	urlBase: "https://testing.matomo.cloud",
// 	siteId: 1,
// 	// userId: 'UID76903202', // optional, default value: `undefined`.
// });

const App = () => {
	return (
		// <MatomoProvider value={monacoInstance}>
		<I18nextProvider i18n={i18n} defaultNS="common">
			<BrowserRouter>
				<WorkspacesState>
					<UserState>
						<DeclarationsState>
							<NotificationsState>
								<ThemeState>
									<FilesState>
										<Router />
									</FilesState>
								</ThemeState>
							</NotificationsState>
						</DeclarationsState>
					</UserState>
				</WorkspacesState>
			</BrowserRouter>
		</I18nextProvider>
		// </MatomoProvider>
	);
};

export default App;
