import { useContext, useEffect, useState } from "react";
import Modal from "../../../modals/Modal";
import { useTranslation } from "react-i18next";
import Button from "../../../common/Button";
import FilesContext from "../../../../context/files/FilesContext";
import SelectFileInput from "./SelectFileInput";
import FileMetadataInputs from "./FileMetadataInputs";

const NewFileModal = (props) => {
	const { t } = useTranslation();
	const { uploadFile } = useContext(FilesContext);
	const [inputValues, setInputValues] = useState({});
	const [errors, setErrors] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const { isOpen, onSubmit, onClose } = props;

	const handleSubmit = async () => {
		let errors = {};

		if (!inputValues.label) {
			errors.label = t("required");
		}

		if (!inputValues.description) {
			errors.description = t("required");
		}

		setErrors(errors);

		if (Object.keys(errors).length) {
			return null;
		}

		setIsSaveLoading(true);

		const file = await uploadFile(inputValues);

		setIsSaveLoading(false);
		onSubmit(file)
		onClose();
	};

	const handleFileChange = async (event) => {
		const { files } = event.target;

		if (!files?.[0]) {
			return;
		}

		setInputValues({ ...inputValues, file: files[0], label: files[0].name });
		setErrors({});
	};

	const handleInputChange = (name, value) => {
		setInputValues({ ...inputValues, [name]: value });
		
		if (errors[name]) {
			delete errors[name];

			setErrors({ ...errors });
		}
	};

	useEffect(() => {
		if (isOpen) {
			setInputValues({});
			setErrors({});
		}
	}, [isOpen]);

	return (
		<Modal
			style={{ width: 800 }}
			isOpen={isOpen}
			onClose={onClose}
			label={t("fileUpload")}
			Footer={
				<>
					<Button style={{ minWidth: 100 }} type="secondary" onClick={onClose}>
						{t("cancel")}
					</Button>
					<Button
						isLoading={isSaveLoading}
						isDisabled={!inputValues.file}
						style={{ minWidth: 132 }}
						onClick={handleSubmit}
					>
						{t("uploadFile")}
					</Button>
				</>
			}
		>
			<SelectFileInput selectedFile={inputValues.file} onFileChange={handleFileChange} />
			<FileMetadataInputs
				selectedFile={inputValues.file}
				inputValues={inputValues}
				errors={errors}
				onInputChange={handleInputChange}
				onRemoveClick={() => {
					setInputValues({ ...inputValues, file: null, label: "" });
					setErrors({});
				}}
			/>
		</Modal>
	);
};

export default NewFileModal;
