import SearchPage from "./SearchPage/SearchPage";
import Button from "../../../common/Button";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../modals/Modal";
import { SEARCH_TYPES } from "../../../../constants/GlobalConstants";

// Search is located at the top of the LocationOfGoods, Contacts and CommodityCodes
// Contains the search input/button and accesses the search modal which contains the results
const SectionSearch = (props) => {
	const { t } = useTranslation();
	const [selectedItem, setSelectedItem] = useState(null);
	const { setIsSearchModalOpen, isSearchModalOpen, searchResultElements, onAddClick, searchType } = props;

	const label = useMemo(() => {
		if (searchType === SEARCH_TYPES.CONTACTS) {
			return t("addFromContacts");
		} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
			return t("addFromLocations");
		} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
			return t("addFromCommodityCodes");
		}
	}, [searchType]);

	const handleAddClick = () => {
		if (!selectedItem) {
			return null;
		}

		onAddClick(selectedItem.data);
	};

	return (
		<div style={{ width: "fit-content", marginLeft: "auto" }}>
			<Modal
				isOpen={isSearchModalOpen}
				label={label}
				style={{ width: 800, height: '90vh' }}
				onClose={() => setIsSearchModalOpen(false)}
			>
				<div style={{ height: "calc(100% - 70px)" }}>
					{isSearchModalOpen && (
						<SearchPage
							isInModal
							onSelect={onAddClick}
							searchType={searchType}
							searchResultElements={searchResultElements}
							onSelectedItemChange={setSelectedItem}
						/>
					)}
				</div>

				<div
					style={{
						width: "fit-content",
						marginLeft: "auto",
						display: "flex",
						alignItems: "center",
						gap: 8,
						marginTop: 16,
					}}
				>
					<Button type="secondary" onClick={() => setIsSearchModalOpen(false)}>
						{t("cancel")}
					</Button>

					<Button
						isDisabled={!selectedItem}
						onClick={handleAddClick}
						style={{ minWidth: 150 }}
					>
						{t("add")}
					</Button>
				</div>
			</Modal>

			<div className="d-flex align-items-center justify-content-center">
				<Button onClick={() => setIsSearchModalOpen(true)} type="button">
					{label}
				</Button>
			</div>
		</div>
	);
};

export default memo(SectionSearch);
