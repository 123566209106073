import { useTranslation } from "react-i18next";
import Modal from "../../../../components/modals/Modal";
import { useContext, useEffect, useState } from "react";
import SelectFileInput from "../../../../components/layouts/files/NewFileModal/SelectFileInput";
import FileMetadataInputs from "../../../../components/layouts/files/NewFileModal/FileMetadataInputs";
import UploadedFilesList from "../../../../components/layouts/files/NewFileModal/UploadedFilesList";
import Button from "../../../../components/common/Button";
import FilesContext from "../../../../context/files/FilesContext";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";

const AttachFileModal = ({ isOpen, onClose }) => {
	const { bulkUpdateFiles, uploadFile } = useContext(FilesContext);
	const { state, updateDeclarationInState } = useContext(DeclarationsContext);
	const [selectedUploadedFiles, setSelectedUploadedFiles] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [inputValues, setInputValues] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const { t } = useTranslation();

	const handleFileChange = async (event) => {
		const { files } = event.target;

		if (!files?.[0]) {
			return;
		}

		setSelectedFile(files[0]);
		setInputValues({ ...inputValues, label: files[0].name });
		setErrors({});
	};

	const handleUploadedFileClick = (file) => {
		const fileIndex = selectedUploadedFiles.find(({ _id }) => _id === file._id);

		if (fileIndex > -1) {
			selectedUploadedFiles.splice(fileIndex, 1);

			setSelectedUploadedFiles([...selectedUploadedFiles]);
		} else {
			setSelectedUploadedFiles([...selectedUploadedFiles, file]);
		}
	};

	const handleInputChange = (name, value) => {
		setInputValues({ ...inputValues, [name]: value });

		if (errors[name]) {
			delete errors[name];

			setErrors({ ...errors });
		}
	};

	const handleSubmit = async () => {
		let errors = {};

		if (selectedFile) {
			if (!inputValues.description) {
				errors.description = t("required");
			}

			if (!inputValues.label) {
				errors.label = t("required");
			}
		}

		setErrors({ ...errors });

		if (Object.keys(errors).length) {
			return;
		}
		setIsSaveLoading(true);

		let addedFiles = [];

		if (selectedFile) {
			const uploadedFile = await uploadFile({
				file: selectedFile,
				declarations: [state.declaration._id],
				...inputValues,
			});

			addedFiles.push(uploadedFile);
		}

		if (selectedUploadedFiles?.length) {
			const updatedFiles = await bulkUpdateFiles(
				selectedUploadedFiles.map((file) => ({
					_id: file._id,
					updates: { declarations: [...file.declarations, state.declaration._id] },
				}))
			);

			addedFiles.unshift(...updatedFiles);
		}

		updateDeclarationInState(state.declaration._id, { files: [...state.declaration.files, ...addedFiles] });

		setIsSaveLoading(false);
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			setErrors({});
			setSelectedFile(null);
			setSelectedUploadedFiles([]);
			setInputValues({});
		}
	}, [isOpen]);

	return (
		<Modal
			style={{ width: 800 }}
			isOpen={isOpen}
			onClose={onClose}
			label={t("attachFile(s)")}
			Footer={
				<>
					<Button style={{ minWidth: 100 }} type="secondary" onClick={onClose}>
						{t("cancel")}
					</Button>
					<Button
						isLoading={isSaveLoading}
						isDisabled={!selectedFile && !selectedUploadedFiles.length}
						style={{ minWidth: 132 }}
						onClick={handleSubmit}
					>
						{t("attachFile(s)")}
					</Button>
				</>
			}
		>
			<SelectFileInput selectedFile={selectedFile} onFileChange={handleFileChange} />

			{selectedFile && (
				<FileMetadataInputs
					selectedFile={selectedFile}
					inputValues={inputValues}
					errors={errors}
					onInputChange={handleInputChange}
					onRemoveClick={() => setSelectedFile(null)}
				/>
			)}

			{isOpen && (
				<UploadedFilesList
					selectedFileIds={selectedUploadedFiles.map((file) => file._id)}
					onFileClick={handleUploadedFileClick}
				/>
			)}
		</Modal>
	);
};

export default AttachFileModal;
