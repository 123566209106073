import { useContext, useReducer } from "react";
import notificationsReducer from "./NotificationsReducer";
import WorkspacesContext from "../workspaces/WorkspacesContext";
import NotificationsContext from "./NotificationsContext";
import { SET_NOTIFICATIONS, SET_UNREAD_NOTIFICATIONS_COUNT } from "../types";
import {
	getNotifications as getNotificationsApiReq,
	getUnreadCount as getUnreadCountApiReq,
} from "../../api/notifications";
import { parseNotifications } from "../declarations/validations";

const initialState = {
	notifications: null,
	unreadCount: 0,
};

const NotificationsState = (props) => {
	const [state, dispatch] = useReducer(notificationsReducer, initialState);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);

	const setNotifications = (notifications) => {
		dispatch({
			type: SET_NOTIFICATIONS,
			payload: notifications,
		});
	};

	const setUnreadCount = (count) => {
		dispatch({
			type: SET_UNREAD_NOTIFICATIONS_COUNT,
			payload: count,
		});
	};

	const getNotifications = async () => {
		const notifications = await getNotificationsApiReq({
			workspaceId: selectedWorkspaceId,
			markAsRead: true,
		});

		setNotifications(parseNotifications(notifications));
	};

	const getUnreadCount = async () => {
		const count = await getUnreadCountApiReq(selectedWorkspaceId);

		setUnreadCount(count);
	};

	return (
		<NotificationsContext.Provider
			value={{
				...state,
				getNotifications,
				setNotifications,
				setUnreadCount,
				getUnreadCount,
			}}
		>
			{props.children}
		</NotificationsContext.Provider>
	);
};

export default NotificationsState;
