import { startCase } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TableDisplayElement from "./TableDisplayElement";
import Modal from "../../../modals/Modal";

const DisplayElementsModal = (props) => {
	const { open, elements, onClose } = props;
	const { t } = useTranslation();

	return (
		<Modal isOpen={open} onClose={onClose} label={t("notificationElements")} style={{ width: 560 }}>
			<div>
				{Object.keys(elements).map((key) => {
					const element = elements[key];

					return (
						<>
							<div style={{ fontSize: 14, fontWeight: 700 }}>
								{startCase(key)}
							</div>
							{Array.isArray(element) ? (
								<TableDisplayElement element={element} />
							) : (
								<div style={{ fontSize: 14 }}>
									{moment(element).isValid()
										? moment(element).format(
												"DD/MM/YY - hh:mm A"
										  )
										: element}
								</div>
							)}
						</>
					);
				})}
			</div>
		</Modal>
	);
};

export default DisplayElementsModal;
