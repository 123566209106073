const commodityCodeAdditionalNationalCode = [
    {
      "key": "DEFAULT",
      "value": "Please Select"
    },
    {
      "key": "VATE",
      "value": "VAT Exempt duty rate"
    },
    {
      "key": "VATR",
      "value": "VAT 5% duty rate"
    },
    {
      "key": "VATZ",
      "value": "VAT 0% duty rate"
    },
    {
      "key": "X407",
      "value": "UK tax type 407, Beer made in UK (standard rate, that is, annual production more than 60,000 hectolitres)"
    },
    {
      "key": "X411",
      "value": "UK tax type 411, Wine [sparkling], of fresh grape, exceeding 8.5% vol, but less than 15% vol."
    },
    {
      "key": "X412",
      "value": "UK tax type 412, Wine [sparkling], of fresh grape, exceeding 5.5% vol. but less than 8.5% vol."
    },
    {
      "key": "X413",
      "value": "UK tax type 413, Wine [still], exceeding 5.5% vol. but not exceeding 15% vol."
    },
    {
      "key": "X415",
      "value": "UK tax type 415, Wine [still or sparkling], exceeding 15% vol. but not exceeding 22% vol."
    },
    {
      "key": "X419",
      "value": "UK tax type 419, Wine [still or sparkling], exceeding 22% vol."
    },
    {
      "key": "X421",
      "value": "UK tax type 421, Made-wine [sparkling], exceeding 8.5% vol. but not exceeding 15% vol."
    },
    {
      "key": "X422",
      "value": "UK tax type 422, Made-wine [sparkling], exceeding 5.5% vol. but less than 8.5% vol."
    },
    {
      "key": "X423",
      "value": "UK tax type 423, Made-wine [still], exceeding 5.5% vol. but not exceeding 15% vol."
    },
    {
      "key": "X425",
      "value": "UK tax type 425, Made-wine [still or sparkling], exceeding 15% vol. but not exceeding 22% vol."
    },
    {
      "key": "X429",
      "value": "UK tax type 429, Made-wine [still or sparkling], exceeding 22% vol."
    },
    {
      "key": "X431",
      "value": "UK tax type 431, Low alcohol beverage of less than 1.2% vol."
    },
    {
      "key": "X433",
      "value": "UK tax type 433, Wine, spirit-based beverage exceeding 1.2% vol. but not exceeding 4% vol."
    },
    {
      "key": "X435",
      "value": "UK tax type 435, Wine, spirit-based beverage exceeding 4% vol. but not exceeding 5.5% vol."
    },
    {
      "key": "X438",
      "value": "UK tax type 438, Spirit-based beverages exceeding, 1.2% vol. but not exceeding 8% vol."
    },
    {
      "key": "X440",
      "value": "UK tax type 440, Beer made in UK - small brewery beer eligible to reduced rates (variable rate, that is, annual production more than 5,000 hectolitres but not exceeding 60,000 hectolitres)"
    },
    {
      "key": "X441",
      "value": "UK tax type 441, Imported beer - small brewery beer eligible to reduced rates (variable rate, that is, annual production more than 5,000 hectolitres but not exceeding 60,000 hectolitres)"
    },
    {
      "key": "X442",
      "value": "UK tax type 442, Beer made in UK - small brewery beer eligible to reduced rates (variable rate, that is, annual production no more than 5,000 hectolitres)"
    },
    {
      "key": "X443",
      "value": "UK tax type 443, Imported beer - small brewery beer eligible to reduced rates (variable rate, that is, annual production no more than 5,000 hectolitres)"
    },
    {
      "key": "X444",
      "value": "UK tax type 444, Beer made in UK - reduced rate of general beer duty (applies to beer exceeding 1.2% abv but not exceeding 2.8% abv)"
    },
    {
      "key": "X445",
      "value": "UK tax type 445, Beer made in UK - high strength beer duty (applies to beer exceeding 7.5% abv - general beer duty is also due on all UK produced high strength beer)"
    },
    {
      "key": "X446",
      "value": "UK tax type 446, Imported beer - reduced rate of general beer duty (applies to beer exceeding 1.2% abv but not exceeding 2.8% abv)"
    },
    {
      "key": "X447",
      "value": "UK tax type 447, Imported beer - high strength beer duty (applies to beer exceeding 7.5% abv - general beer duty is also due on all imported high strength beer)"
    },
    {
      "key": "X451",
      "value": "UK tax type 451, Spirits other than UK produced whisky"
    },
    {
      "key": "X461",
      "value": "UK tax type 461, UK produced Whisky - Wholly Malt"
    },
    {
      "key": "X462",
      "value": "UK tax type 462, UK produced Whisky - Wholly Grain"
    },
    {
      "key": "X463",
      "value": "UK tax type 463, UK produced Whisky - Blended"
    },
    {
      "key": "X473",
      "value": "UK tax type 473, Beer based beverage exceeding 1.2% vol."
    },
    {
      "key": "X481",
      "value": "UK tax type 481, Cider and Perry [sparkling], made in the UK or imported, exceeding 1.2% but not exceeding 5.5%, Cider and Perry [still], made in the UK or imported, exceeding 1.2% but not exceeding 6.9%"
    },
    {
      "key": "X483",
      "value": "UK tax type 483, Cider and Perry [still], made in the UK or imported exceeding 7.5% but not exceeding 8.5%"
    },
    {
      "key": "X485",
      "value": "UK tax type 485, Cider and Perry [sparkling], made in the UK or imported exceeding 5.5% but not exceeding 8.5%"
    },
    {
      "key": "X487",
      "value": "UK tax type 487, Cider and Perry [still], made in the UK or imported exceeding 6.9% but not exceeding 7.5%"
    },
    {
      "key": "X511",
      "value": "UK tax type 511, Light oil (unmarked) - aviation gasoline (including light oil aviation turbine fuel)"
    },
    {
      "key": "X520",
      "value": "UK tax type 520, Light oil (unmarked) - other unrebated light oil"
    },
    {
      "key": "X521",
      "value": "UK tax type 521, Light oil, furnace fuel"
    },
    {
      "key": "X522",
      "value": "UK tax type 522, Light oil, unleaded fuel"
    },
    {
      "key": "X540",
      "value": "UK tax type 540, Heavy oil - other (unmarked) heavy oil (other than kerosene) intended for use as heating fuel, or as fuel for an engine; which would otherwise be eligible for a full rebate"
    },
    {
      "key": "X541",
      "value": "UK tax type 541, Heavy oil (unmarked; including Diesel Engine Road Vehicle (DERV)) or road fuel extender and unmarked kerosene or unmarked gas oil for which no marking waiver has been granted"
    },
    {
      "key": "X542",
      "value": "UK tax type 542, Heavy oil kerosene to be used as motor fuel off road or in an excepted vehicle"
    },
    {
      "key": "X551",
      "value": "UK tax type 551, Heavy oil kerosene (marked/unmarked under marking waiver, including heavy oil aviation turbine fuel) to be used other than as motor fuel off-road or in an excepted vehicle"
    },
    {
      "key": "X556",
      "value": "UK tax type 556, Heavy gas oil (marked/unmarked under marking waiver)"
    },
    {
      "key": "X561",
      "value": "UK tax type 561, Heavy oil, fuel oil (unmarked)"
    },
    {
      "key": "X570",
      "value": "UK tax type 570, Heavy oil, other (unmarked)"
    },
    {
      "key": "X571",
      "value": "UK tax type 571, Biodiesel to be used as motor fuel off road or in an excepted vehicle"
    },
    {
      "key": "X572",
      "value": "UK tax type 572, Biodiesel blended with kerosene for use as heating fuel"
    },
    {
      "key": "X589",
      "value": "UK tax type 589, Biodiesel"
    },
    {
      "key": "X591",
      "value": "UK tax type 591, Road fuel gases - natural gas including biogas"
    },
    {
      "key": "X592",
      "value": "UK tax type 592, Road fuel gases - other than natural gas, e.g. LPG"
    },
    {
      "key": "X595",
      "value": "UK tax type 595, Bioethanol"
    },
    {
      "key": "X611",
      "value": "UK tax type 611, Cigarettes"
    },
    {
      "key": "X615",
      "value": "UK tax type 615, Cigars"
    },
    {
      "key": "X619",
      "value": "UK tax type 619, Hand rolling tobacco"
    },
    {
      "key": "X623",
      "value": "UK tax type 623, Smoking tobacco - other"
    },
    {
      "key": "X627",
      "value": "UK tax type 627, Chewing tobacco"
    },
    {
      "key": "X633",
      "value": "UK tax type 633, Tobacco for heating"
    },
    {
      "key": "X99A",
      "value": "Solid fuels i.e. coal and lignite, coke and semi-coke of coal or lignite, and petroleum coke"
    },
    {
      "key": "X99B",
      "value": "Any petroleum gas, or other gaseous hydrocarbon supplied in a liquid state"
    },
    {
      "key": "X99C",
      "value": "Gas supplied by a gas utility or any gas supplied in a gaseous state that is of a kind supplied by a gas utility Great Britain"
    },
    {
      "key": "X99D",
      "value": "Electricity"
    }
  ]

  export default commodityCodeAdditionalNationalCode