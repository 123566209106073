import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import LoadingSpinner from "./LoadingSpinner";
import Tooltip from "./Tooltip";

const Button = ({ children, type, LeftIcon, iconStyle, tooltip, ...props }) => {
	const { withTheme } = useContext(ThemeContext);

	props.disabled = props.disabled || props.isDisabled || props.isLoading;

	const Content = () => {
		return (
			<>
				{props.isLoading && <LoadingSpinnerOverlay />}

				<span
					style={{
						display: "flex",
						alignItems: "center",
						opacity: props.isLoading ? 0.1 : 1,
					}}
				>
					{LeftIcon && (
						<LeftIcon style={{ marginRight: 8, fontSize: 16, ...iconStyle }} />
					)}
					{children}
				</span>
			</>
		);
	};

	let Button = (
		<button {...props} className={withTheme("primary-button")}>
			<Content />
		</button>
	);

	if (type === "text") {
		Button = (
			<div onClick={props.onClick} className={withTheme("text-button")}>
				<Content />
			</div>
		);
	} else if (type === "secondary" || type === "ghost") {
		Button = (
			<button
				{...props}
				className={`${withTheme("secondary-button")} ${
					type === "ghost" ? withTheme("text-button") : ""
				}`}
			>
				<Content />
			</button>
		);
	} else if (type === "icon") {
		Button = (
			<button {...props} className={withTheme("icon-button")}>
				<Content />
			</button>
		);
	} else if (type === "secondary-icon") {
		Button = (
			<button {...props} className={withTheme("icon-button-secondary")}>
				<Content />
			</button>
		);
	}

	if (tooltip) {
		return (
			<Tooltip placement="top" isCompact openOnHover text={tooltip}>
				{Button}
			</Tooltip>
		);
	} else {
		return Button;
	}
};

const LoadingSpinnerOverlay = () => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("button-loading-spinner-overlay")}>
			<LoadingSpinner size="sm" />
		</div>
	);
};

export default Button;
