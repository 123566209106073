import { useContext, useRef, useState } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { PiFunnel } from "react-icons/pi";
import { Overlay } from "react-bootstrap";
import FilterSection from "./Section";
import Badge from "../Badge";

const FilterButton = ({ selectedFilters, filterSections, style, onSelectedFiltersChange }) => {
	const overlayTarget = useRef(null);
	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);

	const handleFilterClick = (filter) => {
		if (selectedFilters.includes(filter)) {
			let updatedFilters = [...selectedFilters];

			updatedFilters.splice(updatedFilters.indexOf(filter), 1);
			onSelectedFiltersChange(updatedFilters);
		} else {
			onSelectedFiltersChange([...selectedFilters, filter]);
		}
	};

	return (
		<>
			<Overlay
				placement="bottom"
				rootClose={true}
				target={overlayTarget}
				onHide={() => setIsOverlayOpen(false)}
				show={isOverlayOpen}
			>
				{({ placement, arrowProps, show: _show, popper, ...props }) => (
					<div {...props} className={withTheme("overlay-body")}>
						{filterSections.map((section) => (
							<FilterSection
								section={section}
								selectedFilters={selectedFilters}
								onFilterClick={handleFilterClick}
							/>
						))}
					</div>
				)}
			</Overlay>

			<Badge count={selectedFilters.length}>
				<div
					ref={overlayTarget}
					style={style}
					className={withTheme("filter-button")}
					onClick={() => setIsOverlayOpen(true)}
				>
					<PiFunnel fontSize={20} />
				</div>
			</Badge>
		</>
	);
};

export default FilterButton;
