import { useTranslation } from "react-i18next";
import Button from "../../../../common/Button";
import { PiPlus } from "react-icons/pi";

// The section actions are located at the bottom of the LocationOfGoods, Contacts and CommodityCodes sections
// Used to Clear, Save and Load the element values from the database
const SectionActions = (props) => {
	const { t } = useTranslation();
	const {
		isUpdateLoading,
		isSaveLoading,
		onUpdateExistingClick,
		hideClearButton,
		onClearClick,
		onSaveClick,
		hideUpdateExisting,
		inputValues,
	} = props;

	return (
		<div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
			{!hideClearButton && (
				<Button type="secondary" onClick={onClearClick} disabled={isSaveLoading || isUpdateLoading}>
					{t("clear")}
				</Button>
			)}

			<div
				style={{
					width: "fit-content",
					marginLeft: "auto",
					display: "flex",
					alignItems: "center",
					gap: 12,
				}}
			>
				{!hideUpdateExisting && (
					<Button
						type="secondary"
						isLoading={isUpdateLoading}
						disabled={isSaveLoading || !inputValues.id}
						onClick={onUpdateExistingClick}
					>
						{t("updateExisting")}
					</Button>
				)}

				{/* TODO: Requires validation */}
				<Button
					LeftIcon={PiPlus}
					isLoading={isSaveLoading}
					disabled={isUpdateLoading}
					onClick={onSaveClick}
				>
					{t("saveAsNew")}
				</Button>
			</div>
		</div>
	);
};

export default SectionActions;
