import { useTranslation } from "react-i18next";
import PageContainer from "../components/layouts/PageContainer";
import FilesDrawer from "../components/layouts/files/Drawer";
import { useContext, useEffect, useState } from "react";
import FilesContext from "../context/files/FilesContext";
import SelectedFile from "../components/layouts/files/SelectedFile/SelectedFile";
import NewFileModal from "../components/layouts/files/NewFileModal/NewFileModal";
import filesErrorIllust from "../assets/images/files-error-illust.png";
import EmptyPageMessage from "../components/common/EmptyPageMessage";
import { getQueryParam, removeQueryParam, updateQueryParam } from "../router/utils";
import { FILE_ID_QUERY_PARAM } from "../constants/GlobalConstants";

const Files = () => {
	const { files, loadFiles, isFilesLoading } = useContext(FilesContext);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isNewFileModalOpen, setIsNewFileModalOpen] = useState(false);
	const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		if (!files) {
			loadFiles();
		}
	}, []);

	const setSelectedFileFromQuery = () => {
		const fileId = getQueryParam(FILE_ID_QUERY_PARAM);

		if (fileId) {
			const file = files?.find((file) => file._id === fileId);

			if (file) {
				setSelectedFile(file);
			} else if (files?.length) {
				setSelectedFile(files[0]);
			}
		} else {
			setSelectedFile(null);
		}
	};

	useEffect(() => {
		setSelectedFileFromQuery();

		const eventHandler = () => {
			setSelectedFileFromQuery();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, [files]);

	return (
		<PageContainer title={t("files")}>
			<NewFileModal
				isOpen={isNewFileModalOpen}
				onClose={() => setIsNewFileModalOpen(false)}
				onSubmit={(file) => {
					updateQueryParam(FILE_ID_QUERY_PARAM, file._id);
				}}
			/>
			<div style={{ display: "flex", width: "100%", height: "100%" }}>
				<FilesDrawer
					isLoading={isFilesLoading}
					isExpanded={isDrawerExpanded}
					selectedFile={selectedFile}
					setIsExpanded={setIsDrawerExpanded}
					setIsNewFileModalOpen={setIsNewFileModalOpen}
				/>

				{!files?.length && !isFilesLoading ? (
					<div
						style={{
							width: "100%",
						}}
					>
						<EmptyPageMessage
							image={filesErrorIllust}
							text={t("noFilesUploaded")}
							actionButtonLabel={t("addNewFile")}
							onActionButtonClick={() => setIsNewFileModalOpen(true)}
						/>
					</div>
				) : (
					<SelectedFile
						isDrawerExpanded={isDrawerExpanded}
						file={selectedFile}
						onBackClick={() => removeQueryParam(FILE_ID_QUERY_PARAM)}
					/>
				)}
			</div>
		</PageContainer>
	);
};

export default Files;
