import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const getSettings = async () => {
	console.trace();
	const result = await axiosInstance.get(`${apiUrl}/settings`, { headers: getHeaders() });

	return result.data;
};

export const createSettings = async (payload) => {
	const result = await axiosInstance.post(
		`${apiUrl}/settings`,
		{ meta: { ...payload } },
		{ headers: getHeaders() }
	);

	return result.data;
};

export const updateSettings = async (payload) => {
	const result = await axiosInstance.put(`${apiUrl}/settings`, { settings: payload }, { headers: getHeaders() });

	return result.data;
};
