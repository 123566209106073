import { useContext } from "react";
import { ListGroup, Overlay } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";

const UserOverlay = (props) => {
	const { target, open, handleClose } = props;
	const history = useHistory();
	const userContext = useContext(UserContext);

	const handleLogoutClick = () => {
		userContext.signOut();
		history.push("/login");
	};

	return (
		<Overlay
			placement="bottom"
			rootClose={true}
			target={target}
			onHide={handleClose}
			show={open}
		>
			{({ placement, arrowProps, show: _show, popper, ...props }) => (
				<div {...props}>
					<div style={{ marginTop: 16, marginLeft: 38 }}>
						<ListGroup>
							<ListGroup.Item
								action
								onClick={() =>
									history.push("/settings")
								}
							>
								Settings
							</ListGroup.Item>
							<ListGroup.Item action>
								Subscription Plans
							</ListGroup.Item>
							<ListGroup.Item
								action
								onClick={handleLogoutClick}
							>
								Log Out
							</ListGroup.Item>
						</ListGroup>
					</div>
				</div>
			)}
		</Overlay>
	);
};

export default UserOverlay;
