import React, { useContext, useState, useEffect, useMemo } from "react";
import elements from "../../../../constants/validation";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import { createContact, updateContact } from "../../../../api/contacts";
import NotificationToast from "../../../common/NotificationToast";
import ThemeContext from "../../../../context/theme/ThemeContext";
import SectionActions from "./components/SectionActions";
import SectionSearch from "../SectionsSearch/SectionsSearch";
import { SEARCH_TYPES, SEARCH_TYPE_ELEMENTS } from "../../../../constants/GlobalConstants";
import ValidationInput from "../../../common/InputsSection/ValidationInput";
import WorkspacesContext from "../../../../context/workspaces/WorkspacesContext";

const ContactForm = (props) => {
	const {
		setItemElementValueInSection,
		setElementValueInSection,
		setHasUnsavedChanges,
		isDeclarationReadOnly,
		state,
	} = useContext(DeclarationsContext);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const { declaration } = state;
	const [saveConfirmation, setSaveConfirmation] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const { formName, formRole, itemIndex } = props;
	const { withTheme } = useContext(ThemeContext);

	const readOnly = useMemo(() => isDeclarationReadOnly(), [state.declaration]);

	const contactElements = useMemo(() => {
		if (formRole === "representative") {
			return [...SEARCH_TYPE_ELEMENTS.CONTACTS, { name: "representativeStatusCode", type: 'text' }];
		} else {
			return [...SEARCH_TYPE_ELEMENTS.CONTACTS];
		}
	}, [formRole]);

	/**
	 * update current contact of a specific user with current role
	 */
	const handleUpdateExistingClick = async () => {
		if (!inputValues._id) {
			return;
		}
		setIsUpdateLoading(true);

		const { _id, ...data } = inputValues;

		await updateContact(_id, data);
		setIsUpdateLoading(false);
	};

	/**
	 * adding new contact to a specific user and with a specific role
	 */
	const handleSaveClick = async () => {
		setIsSaveLoading(true);
		const { _id, ...data } = inputValues;

		const contact = await createContact({ ...data, workspace: selectedWorkspaceId });

		setInputValues({
			...contact.data,
			_id: contact._id,
		});

		setSaveConfirmation(true);
		setIsSaveLoading(false);
	};
	/**
	 * (delete) clearing current contact & role of user
	 */
	const handleClearClick = () => {
		let updatedState = {};

		contactElements.forEach((element) => {
			updatedState[element] = "";
			if (typeof itemIndex === "number") {
				setItemElementValueInSection(itemIndex, formRole, element, "");
			} else {
				setElementValueInSection(formRole, element, "");
			}
		});

		setInputValues(updatedState);
	};

	const handleChange = (name, value) => {
		const updatedState = {
			...inputValues,
			[name]: value,
		};

		setHasUnsavedChanges(true);

		if (typeof itemIndex === "number") {
			setItemElementValueInSection(itemIndex, formRole, name, value);
		} else {
			setElementValueInSection(formRole, name, value);
		}

		setInputValues(updatedState);
	};

	const handleSearchSelection = (data) => {
		setIsSearchModalOpen(false);
		if (data) {
			Object.keys(data).forEach((key) => {
				if (typeof itemIndex === "number") {
					setItemElementValueInSection(itemIndex, formRole, key, data[key]);
				} else {
					setElementValueInSection(formRole, key, data[key]);
				}
			});
		}
	};

	useEffect(() => {
		if (typeof itemIndex === "number") {
			setInputValues(declaration?.data.declarationItems?.[itemIndex]?.[formRole] || {});
		} else {
			setInputValues(declaration.data[formRole] || {});
		}
	}, [state.declaration, itemIndex]);

	return (
		<div className={`${withTheme("peach")} ${withTheme("inputs-section")}`}>
			<NotificationToast
				open={saveConfirmation}
				handleClose={() => setSaveConfirmation(false)}
				text="Contact saved successfully"
			/>

			<div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
				<div className={withTheme("inputs-section-title")}>Contact Info: {formName}</div>

				{!readOnly && (
					<SectionSearch
						setIsSearchModalOpen={setIsSearchModalOpen}
						isSearchModalOpen={isSearchModalOpen}
						searchResultElements={contactElements}
						onAddClick={handleSearchSelection}
						searchType={SEARCH_TYPES.CONTACTS}
					/>
				)}
			</div>

			<div style={{ display: "flex", flexWrap: "wrap", columnGap: 40, rowGap: 20 }}>
				{contactElements.map((element, index) => (
					<Element
						readOnly={readOnly}
						element={element}
						inputValues={inputValues}
						onChange={handleChange}
						key={index}
					/>
				))}
			</div>

			{!readOnly && (
				<SectionActions
					isUpdateLoading={isUpdateLoading}
					isSaveLoading={isSaveLoading}
					onUpdateExistingClick={handleUpdateExistingClick}
					onClearClick={handleClearClick}
					onSaveClick={handleSaveClick}
					inputValues={inputValues}
				/>
			)}
		</div>
	);
};

const Element = ({ element, readOnly, inputValues, onChange }) => {
	const validationElement = useMemo(
		() => elements.find((tmpElement) => tmpElement.elementName === element.name),
		[element]
	);

	return (
		<div style={{ width: "calc(50% - 40px)", flexGrow: 1 }}>
			<ValidationInput
				onChange={onChange}
				groupingElement={element}
				value={inputValues[element.name]}
				element={validationElement}
				inputStyle={{ width: "100%" }}
				readOnly={readOnly}
			/>
		</div>
	);
};

export default ContactForm;
