import { useContext, useEffect } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { PiX } from "react-icons/pi";
import Button from "../common/Button";
import { createPortal } from "react-dom";

const Modal = ({ isOpen, onClose, children, label, style, Footer }) => {
	const { withTheme } = useContext(ThemeContext);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}

		return () => {
			document.body.style.overflow = "unset";
		};
	}, [isOpen]);

	return createPortal(
		<div
			style={{ opacity: isOpen ? 1 : 0, zIndex: isOpen ? 10 : -1 }}
			className={withTheme("custom-modal-backdrop")}
		>
			<div className={withTheme("custom-modal")} style={style}>
				<div style={{ width: "100%", height: "100%" }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							width: "100%",
						}}
					>
						{label && <div className={withTheme("modal-label")}>{label}</div>}

						<div style={{ width: "fit-content", marginLeft: "auto" }}>
							<Button type="secondary-icon" onClick={onClose}>
								<PiX />
							</Button>
						</div>
					</div>

					<div style={{ paddingTop: 18, width: "100%", height: "100%" }}>
						{children}

						{Footer && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 8,
									marginTop: 32,
									width: "fit-content",
									marginLeft: "auto",
								}}
							>
								{Footer}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>,
		document.body
	);
};

export default Modal;
