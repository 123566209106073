import React from 'react';
import { Toast } from 'react-bootstrap';

const NotificationToast = ({ open, handleClose, text }) => {
    return (
        <Toast
            autohide
            style={{
                position: 'fixed', top: 16,
                right: 16, backgroundColor: '#ffffff', zIndex: 999
            }}
            onClose={handleClose}
            show={open} delay={3000}>
            <Toast.Header closeButton={false}>
                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                <strong className='mr-auto'>Notification</strong>
            </Toast.Header>
            <Toast.Body>
                {text}
            </Toast.Body>
        </Toast>
    )
};

export default NotificationToast;