import { useMemo } from "react";
import elements from "../../../../constants/validation";
import Button from "../../Button";
import { PiPencilSimpleLine, PiTrash } from "react-icons/pi";

const TableBody = (props) => {
	const {
		arrayItems,
		items,
		selectedPage,
		updateItems,
		setSelectedRowIndex,
		setInputValues,
		disabled,
		readOnlyItems,
	} = props;

	const retrieveElementNames = () => {
		return arrayItems.map(
			(item) =>
				elements.filter((tmpElement) => tmpElement.elementName === item.elementName)[0]
					.elementName
		);
	};

	const mapTableElements = (item) => {
		let tableItems = [];

		retrieveElementNames().forEach((tmpItem, index) => {
			const validationElement = elements.filter(
				(tmpElement) => tmpElement.elementName === tmpItem
			)[0];

			tableItems.push(
				<td
					key={index}
					style={{
						maxWidth: 200,
						minWidth: validationElement.tableColMinWidth,
						whiteSpace: "initial",
						wordWrap: "break-word",
					}}
				>
					{/* If element is of type pulldown, show the value of the option instead of key */}
					{validationElement?.pullDownMenu
						? validationElement.pullDownMenu.find(
								(option) => option.key === item[tmpItem]
						  )?.value || item[tmpItem]
						: item[tmpItem]}
				</td>
			);
		});

		return tableItems;
	};

	// Remove a row from the ITEMS array
	const handleDeleteRowClick = (selectedIndex) => {
		items.splice(selectedIndex, 1);
		updateItems([...items]);
	};

	const handleEditRowClick = (index) => {
		setSelectedRowIndex(index);
		setInputValues({ ...items[index] });
	};

	const filteredItems = useMemo(
		() => items.filter((item, index) => index >= selectedPage * 10 && index + 1 <= (selectedPage + 1) * 10),
		[items, selectedPage]
	);

	return (
		<tbody>
			{/* Show only table items within the selected page (a maximum of 10 records) */}
			{filteredItems.map((item, index) => {
				const isDeclarationReadOnly = readOnlyItems?.find(
					(readOnlyItem) =>
						Object.values(readOnlyItem).join(",") === Object.values(item).join(",")
				);

				return (
					<tr key={index}>
						<th scope="row">{index + 1}</th>
						{mapTableElements(item)}
						<td>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									gap: 8,
									width: 80,
								}}
							>
								<Button
									disabled={isDeclarationReadOnly || disabled}
									onClick={() => handleEditRowClick(index)}
									type="secondary-icon"
									style={{ outline: "none", padding: 5 }}
								>
									<PiPencilSimpleLine fontSize="20px" />
								</Button>
								<Button
									disabled={isDeclarationReadOnly || disabled}
									onClick={() => handleDeleteRowClick(index)}
									type="secondary-icon"
									style={{ outline: "none", padding: 5 }}
								>
									<PiTrash fontSize="20px" />
								</Button>
							</div>
						</td>
					</tr>
				);
			})}
		</tbody>
	);
};

export default TableBody;
