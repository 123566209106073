import { useContext } from "react";
import ValidationInput from "../../components/common/InputsSection/ValidationInput";
import settingsElements from "../../constants/settings";
import ThemeContext from "../../context/theme/ThemeContext";

const TabInputs = ({ elements, onChange, inputValues, errors }) => {
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				alignItems: "center",
				flexWrap: "wrap",
				rowGap: 20,
				columnGap: 40,
				marginTop: 40,
			}}
		>
			{elements.map((elementName, index) => (
				<SettingsElement
					elementName={elementName}
					onChange={onChange}
					inputValues={inputValues}
					errors={errors}
					key={index}
				/>
			))}
		</div>
	);
};

const SettingsElement = ({ elementName, onChange, inputValues, errors }) => {
	const { withTheme } = useContext(ThemeContext);
	const element = settingsElements.find((element) => element.elementName === elementName);

	const error = errors[elementName];

	if (!element) {
		return null;
	}

	return (
		<div style={{ width: "calc(50% - 20px)", flexGrow: 1 }}>
			<ValidationInput
				onChange={onChange}
				value={inputValues[element.elementName]}
				element={element}
			/>

			<div
				style={{
					minHeight: error ? 10 : 0,
					maxHeight: error ? 10 : 0,
				}}
				className={withTheme("error-text")}
			>
				{error}
			</div>
		</div>
	);
};

export default TabInputs;
