import { useContext } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import FilterOption from "./Option";

const FilterSection = ({ section, selectedFilters, onFilterClick }) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<div style={{ marginBottom: 12 }}>
			<div style={{ marginBottom: 12 }} className={withTheme("overlay-label")}>
				{t(section.name)}
			</div>
			<div
				style={{
					paddingLeft: 4,
					paddingRight: 4,
					display: "flex",
					flexDirection: "column",
					gap: 8,
					marginTop: 8,
				}}
			>
				{section.options.map((option, index) => (
					<FilterOption
						option={option}
						isSelected={selectedFilters.includes(option)}
						onFilterClick={onFilterClick}
						key={index}
					/>
				))}
			</div>
		</div>
	);
};

export default FilterSection;
