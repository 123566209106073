import { useContext } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import { PiMagnifyingGlass } from "react-icons/pi";

const SearchInput = (props) => {
	const { withTheme } = useContext(ThemeContext);

	const { containerStyle, ...rest } = props;

	return (
		<div style={{ ...props.containerStyle }} className={withTheme("search-input-container")}>
			<PiMagnifyingGlass style={{ color: "#B0B5BD", fontSize: 16 }} />

			<input
				placeholder={props.placeholder || "Search"}
				className={withTheme("search-input")}
				{...rest}
			/>
		</div>
	);
};

export default SearchInput;
