import { useState, useEffect, useContext, useMemo } from "react";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import journey from "../../../constants/journey.json";
import Select from "react-select";
import ThemeContext from "../../../context/theme/ThemeContext";
import ElementLabel from "../ElementLabel";
import { getQueryParam } from "../../../router/utils";
import { PiX } from "react-icons/pi";
import Button from "../Button";
import { ITEM_QUERY_PARAM } from "../../../constants/GlobalConstants";
import OptionLabel from "./OptionLabel";

const ConstantsBasedPullDownMenu = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const declarationContext = useContext(DeclarationsContext);
	const [listItems, setListItems] = useState([]);
	const [value, setValue] = useState("");
	const { pullDownMenu, elementName } = props.element || {};
	const { findValueInContext, state, setElementValue, isElementReadOnly } = declarationContext;
	const { mandatoryElements, disabledElements, requiredPulldownOptions } = state || {};

	const requiredKeys = requiredPulldownOptions ? requiredPulldownOptions[elementName]?.keys || [] : [];
	const isMandatory = useMemo(
		() => mandatoryElements?.find((element) => element.elementName === elementName),
		[mandatoryElements, elementName]
	);

	const isDisabled = useMemo(
		() =>
			disabledElements?.find(
				(element) =>
					element.elementName === elementName &&
					element.itemIndex === parseInt(getQueryParam(ITEM_QUERY_PARAM))
			) || props.disabled,
		[elementName, disabledElements, props.disabled]
	);

	const isDeclarationReadOnly = useMemo(() => {
		if (props.element?.readOnly || isElementReadOnly(elementName)) {
			return true;
		} else {
			return false;
		}
	}, [props.element]);

	const handleElementChange = (e) => {
		props.onChange?.(elementName, e.value);
	};

	/**
	 * Extract pulldown menu items from the validation element, and if the element is found and matches
	 * ones of the conditions in journey JSON, filter the menu items accordingly.
	 */
	useEffect(() => {
		if (!pullDownMenu) {
			return;
		}
		let filteredMenu = [...pullDownMenu];

		for (let element of journey) {
			const journeyValue = element.value;

			if (element.elementName === elementName) {
				journeyValue.forEach((condition) => {
					let matchedConditions = 0; // number of conditions that in within the condition.if array

					condition.if?.forEach((object) => {
						const key = Object.keys(object)[0];
						/*
							If the conditions has a key of type "service", check if the currently selected declaration's
						   service matches the key
					   */
						if (
							key === "service" &&
							object[key].includes(state.declaration.service)
						) {
							matchedConditions = matchedConditions + 1;
							// console.log(key, object[key])

							// find the value of the condition key in context and check if it's included in the journey array
						} else if (object[key].includes(findValueInContext(key))) {
							matchedConditions = matchedConditions + 1;
						}
					});

					// if all conditions match, set the filteredMenu to be the values in condition.
					if (matchedConditions === condition.if?.length) {
						filteredMenu = condition.then;
					}
				});
				break;
			}
		}

		const updatedListItems = filteredMenu
			? filteredMenu
					.filter((option) => !props.excludedOptions?.includes(option.key))
					.map((option) => ({
						label: option.value,
						value: option.key,
						disabled: option.key === "DEFAULT",
					}))
			: [];

		updatedListItems.sort((option) => {
			const matches = requiredKeys.includes(option.value);
			return matches ? -1 : 1;
		});

		if (JSON.stringify(updatedListItems) !== JSON.stringify(listItems)) {
			// Sort to keep required values first
			setListItems([...updatedListItems]);
		}
	}, [declarationContext, props.element, requiredKeys]);

	// Get the list item value from the value prop
	useEffect(() => {
		let value = listItems?.find((item) => {
			return (
				item.value ===
				(typeof props.value === "number" || props.value ? props.value : "DEFAULT")
			);
		});

		
		if (state.declaration) {
			// Set the element value to DEFAULT if:
			//   - It doesn't have a value at all
			//   - If it's disabled but has a value other than DEFAULT
			if (
				((props.value && !value) || (isDisabled && props.value !== "DEFAULT")) &&
				elementName !== "selectedDeclaration"
			) {
				setElementValue(elementName, "DEFAULT");
			}
		}
		setValue(value || "DEFAULT");
	}, [listItems, isDisabled, props.value]);

	return (
		<div className="mb-2">
			<ElementLabel element={props.element} />

			<div className="d-flex align-items-center" style={{ width: "100%", gap: 4 }}>
				<div
					className={`${withTheme(elementName)}`}
					style={{
						width: "100%",
						...props.inputStyle,
					}}
				>
					<Select
						id={elementName}
						value={value}
						isDisabled={isDeclarationReadOnly || isDisabled}
						onChange={handleElementChange}
						options={listItems}
						sort
						formatOptionLabel={(props) => (
							<OptionLabel {...props} requiredKeys={requiredKeys} />
						)}
						isOptionDisabled={(option) => option.disabled}
						styles={{
							indicatorSeparator: (baseStyles) => ({
								...baseStyles,
								display: "none",
							}),
							control: (baseStyles) => ({
								...baseStyles,
								height: 36,
								fontSize: 14,
								borderRadius: 4,
								border: isMandatory
									? "1px solid #dc3545"
									: "1px solid #D7DAE0",
								cursor: "pointer",
							}),
						}}
					/>
				</div>

				{props.showResetButton && props.value && props.value !== "DEFAULT" && (
					<Button
						type="secondary-icon"
						onClick={() =>
							handleElementChange({
								target: { name: elementName, value: "DEFAULT" },
							})
						}
					>
						<PiX fontSize={20} />
					</Button>
				)}
			</div>

			<div
				className="text-danger"
				style={{
					textAlign: "left",
					transition: "0.3s",
					overflow: "hidden",
					minHeight: props.error ? 18 : 0,
					maxHeight: props.error ? 18 : 0,
				}}
			>
				{props.error}
			</div>
		</div>
	);
};

export default ConstantsBasedPullDownMenu;
