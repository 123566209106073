import { useContext } from "react";
import Card from "../../common/Card";
import ThemeContext from "../../../context/theme/ThemeContext";
import Track from "../Track";
import { useTranslation } from "react-i18next";

// TODO: Data to be populated once there's a subscription system
const SubscriptionUsageCard = () => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<Card style={{ height: 264, width: "50%" }}>
			<div className={withTheme("dashboard-card-label")}>{t("subscriptionUsage")}</div>

			<div style={{ paddingLeft: 16, paddingRight: 16 }}>
				<Track label={t("totalSubmitted")} value={0} total={0} />
				<Track label={t("imports")} value={0} total={0} style={{ marginTop: 22 }} />
				<Track label={t("exports")} value={0} total={0} style={{ marginTop: 22 }} />
			</div>
		</Card>
	);
};

export default SubscriptionUsageCard;
