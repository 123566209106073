import { useContext, useState } from "react";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import EmptyPageMessage from "../../../../components/common/EmptyPageMessage";
import { useTranslation } from "react-i18next";
import { PiUploadSimple } from "react-icons/pi";
import AttachFileModal from "./AttachFileModal";
import Button from "../../../../components/common/Button";
import FileListItem from "../../../../components/layouts/files/FileListItem/FileListItem";

const Files = () => {
	const { t } = useTranslation();
	const [isAttachFileModalOpen, setIsAttachFileModalOpen] = useState(false);
	const { state } = useContext(DeclarationsContext);

	return (
		<>
			<AttachFileModal
				isOpen={isAttachFileModalOpen}
				onClose={() => setIsAttachFileModalOpen(false)}
			/>

			{!state.declaration?.files?.length ? (
				<EmptyPageMessage
					text={t("noFilesAttached")}
					actionButtonLabel={t("attachFile")}
					actionButtonProps={{
						LeftIcon: PiUploadSimple,
						type: "secondary",
					}}
					onActionButtonClick={() => {
						setIsAttachFileModalOpen(true);
					}}
				/>
			) : (
				<div style={{ marginTop: 12 }}>
					<Button
						type="secondary"
						LeftIcon={PiUploadSimple}
						onClick={() => setIsAttachFileModalOpen(true)}
					>
						{t("attachFile")}
					</Button>

					<div
						style={{
							marginTop: 20,
							display: "flex",
							flexDirection: "column",
							gap: 20,
						}}
					>
						{state.declaration.files.map((file, index) => (
							<FileListItem
								key={index}
								file={file}
								showOptionsButton
								isAssociatedWithDeclaration
							/>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default Files;
