import { useTranslation } from "react-i18next";
import EmptyPageMessage from "../../../../../common/EmptyPageMessage";
import contactsErrorIllust from "../../../../../../assets/images/contacts-error-illust.png";
import goodsLocationsErrorIllust from "../../../../../../assets/images/locations-error-illust.png";
import commodityCodesErrorIllust from "../../../../../../assets/images/commodity-codes-error-illust.png";
import { useMemo } from "react";
import { SEARCH_TYPES } from "../../../../../../constants/GlobalConstants";

const EmptySearchPageMessage = ({ searchType, onActionButtonClick }) => {
	const { t } = useTranslation();

	const { image, text, actionButtonLabel } = useMemo(() => {
		if (searchType === SEARCH_TYPES.CONTACTS) {
			return {
				image: contactsErrorIllust,
				text: t("noContactsYet"),
				actionButtonLabel: t("addContact"),
			};
		} else if (searchType === SEARCH_TYPES.GOODS_LOCATIONS) {
			return {
				image: goodsLocationsErrorIllust,
				text: t("noGoodsLocationsYet"),
				actionButtonLabel: t("addLocation"),
			};
		} else if (searchType === SEARCH_TYPES.COMMODITY_CODES) {
			return {
				image: commodityCodesErrorIllust,
				text: t("noCommodityCodesYet"),
				actionButtonLabel: t("addCode"),
			};
		}
	}, []);

	return (
		<EmptyPageMessage
			image={image}
			text={text}
			actionButtonLabel={actionButtonLabel}
			onActionButtonClick={onActionButtonClick}
		/>
	);
};

export default EmptySearchPageMessage;
