import React, { useContext, useReducer } from "react";
import FilesContext from "./FilesContext";
import filesReducer from "./FilesReducer";
import { SET_FILES, SET_FILES_LOADING } from "../types";
import {
	fetchFiles as fetchFilesApiReq,
	uploadFile as uploadFileApiReq,
	updateFile as updateFileApiReq,
	deleteFile as deleteFileApiReq,
	bulkUpdateFiles as bulkUpdateFilesApiReq,
} from "../../api/files";
import DeclarationsContext from "../declarations/DeclarationsContext";
import { isObject, isString } from "lodash";
import WorkspacesContext from "../workspaces/WorkspacesContext";

const initialState = {
	isFilesLoading: false,
	uploadFilesLoading: false,
	uploadFilesResult: null,
	uploadFilesError: null,
	files: null,
};

const FilesState = (props) => {
	const [state, dispatch] = useReducer(filesReducer, initialState);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const declarationsContext = useContext(DeclarationsContext);

	const loadFiles = async () => {
		dispatch({
			type: SET_FILES_LOADING,
			payload: true,
		});

		const files = await fetchFilesApiReq(selectedWorkspaceId);

		dispatch({
			type: SET_FILES,
			payload: files,
		});
	};

	const deleteFile = async (file) => {
		await deleteFileApiReq(file._id);

		updateFileInDeclarationState(file, null, { delete: true });

		const fileIndex = state.files.findIndex(({ _id }) => _id === file._id);

		if (fileIndex > -1) {
			state.files.splice(fileIndex, 1);
		}

		dispatch({
			type: SET_FILES,
			payload: [...state.files],
		});
	};

	const uploadFile = async (file, options) => {
		const uploadedFile = await uploadFileApiReq({ ...file, workspace: selectedWorkspaceId }, options);

		dispatch({
			type: SET_FILES,
			payload: [uploadedFile, ...state.files],
		});

		return uploadedFile;
	};

	const updateFile = async (file, updates) => {
		const updatedFile = await updateFileApiReq(file._id, updates);

		const fileIndex = state.files.findIndex(({ _id }) => _id === file._id);

		updateFileInDeclarationState(file, updatedFile);

		// TODO: Test
		if (fileIndex > -1) {
			/** Make sure that state.files always includes popualated declarations.
			 * This is ensured to work as uploadFile is only used to disassociated declarations from a file, and not the opposite
			 */
			const populatedDeclarations = state.files[fileIndex].declarations.filter((dec) =>
				updatedFile.declarations.includes(dec._id)
			);

			state.files[fileIndex] = { ...updatedFile, declarations: populatedDeclarations };

			dispatch({
				type: SET_FILES,
				payload: [...state.files],
			});
		}
	};

	const bulkUpdateFiles = async (files) => {
		await bulkUpdateFilesApiReq({ files, workspace: selectedWorkspaceId });
		const { declarations } = declarationsContext.state;

		let updatedFiles = [];

		files.forEach((file) => {
			const fileIndex = state.files.findIndex(({ _id }) => _id === file._id);

			if (fileIndex > -1) {
				const updatedFile = { ...state.files[fileIndex], ...file.updates };

				state.files[fileIndex] = updatedFile;

				/** Replace unpopulated declaration IDs with the actual declaration object if it already exists in state */
				/** This is ensured to work for now as bulkUpdateFiles is only being used to associated files with a declaration that's already in state */
				state.files[fileIndex].declarations.forEach((declaration, index) => {
					if (isString(declaration)) {
						state.files[fileIndex].declarations[index] =
							declarations.find(({ _id }) => _id === declaration) ||
							declaration;
					}
				});

				updatedFiles.push(updatedFile);
			}
		});

		dispatch({
			type: SET_FILES,
			payload: [...state.files],
		});

		return updatedFiles;
	};

	/** Updates the "files" array in each declaration with the corresponding updates made to the files state.
	 * This ensures that files that were previously fetched when loading declarations are up to date in state
	 */
	const updateFileInDeclarationState = (file, updatedFile, options) => {
		const { state, setSelectedDeclaration, setDeclarations } = declarationsContext;
		const declarations = state.declarations;

		file.declarations?.forEach((dec) => {
			const declarationId = isObject(dec) ? dec._id : dec;

			const declaration =
				state.declaration?._id === declarationId
					? state.declaration
					: declarations.find(({ _id }) => _id === declarationId);

			if (declaration) {
				const fileIndex = declaration.files?.findIndex(({ _id }) => file._id === _id);

				if (fileIndex > -1) {
					const isDeleted =
						options?.delete ||
						!updatedFile.declarations.find(
							(dec) => declarationId === (isObject(dec) ? dec._id : dec)
						);

					if (isDeleted) {
						declaration.files.splice(fileIndex, 1);
					} else {
						declaration.files[fileIndex] = updatedFile;
					}
				}

				if (declaration._id === state.declaration._id) {
					setSelectedDeclaration({ ...declaration });
				}
			}
		});

		setDeclarations([...declarations]);
	};

	return (
		<FilesContext.Provider
			value={{
				...state,
				loadFiles,
				deleteFile,
				uploadFile,
				updateFile,
				bulkUpdateFiles,
			}}
		>
			{props.children}
		</FilesContext.Provider>
	);
};

export default FilesState;
