import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { updateQueryParam } from "../../../../router/utils";
import NotificationToast from "../../../common/NotificationToast";
import CancelDeclarationModal from "../../../modals/CancelDeclarationModal";
import Button from "../../../common/Button";
import { PiArrowFatUp, PiPencilSimple, PiX } from "react-icons/pi";
import { DECLARATION_ID_QUERY_PARAM, DECLARATION_STATUSES } from "../../../../constants/GlobalConstants";
import DeclarationActionsPopover from "./ActionsPopover";

const DeclarationActions = (props) => {
	const { state, submitDeclaration, getValidationErrors, createAmendment } = useContext(DeclarationsContext);
	const [isCancelConfirmModalOpen, setIsCancelConfirmModalOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [toast, setToast] = useState("");
	const { setLoadingAction, loadingAction } = props;

	const handleAmendClick = async () => {
		const existingAmendment = state.declaration.relatedDeclarations.find(
			({ isAmendment, status }) => isAmendment && status === DECLARATION_STATUSES.INCOMPLETE
		);

		if (existingAmendment) {
			updateQueryParam(DECLARATION_ID_QUERY_PARAM, existingAmendment._id);
		} else {
			setLoadingAction("amend");

			const amendment = await createAmendment(state.declaration);

			setLoadingAction(null);
			updateQueryParam(DECLARATION_ID_QUERY_PARAM, amendment._id);
		}

		// TODO: Show toast explaining what's happened
	};

	const handleSubmitClick = async () => {
		if (loadingAction) {
			return;
		}

		const errors = getValidationErrors();

		if (errors.length > 0) {
			return setToast(t("validationErrorToast"));
		}

		setLoadingAction("submit");

		try {
			await submitDeclaration(state.declaration);
			setToast(t("submitSuccessToast"));
		} catch (e) {
			const message = e.response.data?.message;
			console.log(message);

			let toastText = t("submitErrorToast");
			if (message === "ParentDeclarationCleared") {
				toastText = t("ParentDeclarationClearedError");
			} else {
			}
			setToast(toastText);
		}

		setLoadingAction(null);
	};

	if (!state.declaration) {
		return null;
	}

	return (
		<div className={withTheme("declaration-action-buttons-container")}>
			<NotificationToast open={Boolean(toast)} handleClose={() => setToast("")} text={toast} />

			<CancelDeclarationModal
				isOpen={isCancelConfirmModalOpen}
				onClose={() => setIsCancelConfirmModalOpen(false)}
			/>

			{state.declaration.status === DECLARATION_STATUSES.ACCEPTED && (
				<>
					<>
						<Button
							type="secondary"
							onClick={() => setIsCancelConfirmModalOpen(true)}
							LeftIcon={PiX}
						>
							{t("cancelDeclaration")}
						</Button>

						<Button
							isLoading={loadingAction === "amend"}
							onClick={handleAmendClick}
							LeftIcon={PiPencilSimple}
						>
							{t("amend")}
						</Button>
					</>
				</>
			)}

			{(!state.declaration.status ||
				state.declaration.status === DECLARATION_STATUSES.INCOMPLETE ||
				state.declaration.status === DECLARATION_STATUSES.REJECTED) && (
				<Button
					onClick={handleSubmitClick}
					isLoading={loadingAction === "submit"}
					LeftIcon={PiArrowFatUp}
				>
					{t("submit")}
				</Button>
			)}

			<DeclarationActionsPopover />
		</div>
	);
};

export default DeclarationActions;
