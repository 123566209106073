import ConstantsBasedPullDownMenu from "../../ConstantsBasedPulDownMenu/ConstantsBasedPullDownMenu";
import ConstantsBasedTextInput from "../../ConstantsBasedTextInput/ConstantsBasedTextInput";
import elements from "../../../../constants/validation";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";

const TableInputs = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const { arrayItems, inputValues, setInputValues, disabled, readOnlyItems } = props;

	const handleChange = (name, value) => {
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	return (
		<div className={withTheme("table-inputs-container")}>
			{/* Table Elements */}
			{arrayItems.map((input, index) => {
				const validationElement = elements.find(
					(tmpElement) => tmpElement.elementName === input.elementName
				);

				if (!validationElement) {
					return <></>;
				}

				return (
					<div key={index} style={{ width: "calc(50% - 20px)" }}>
						{!validationElement.pullDownMenu ? (
							<ConstantsBasedTextInput
								inputStyle={{ width: "100%" }}
								element={validationElement}
								value={inputValues[input.elementName] || ""}
								onChange={handleChange}
								disabled={disabled}
							/>
						) : (
							<ConstantsBasedPullDownMenu
								inputStyle={{ width: "100%" }}
								element={validationElement}
								value={inputValues[input.elementName] || ""}
								onChange={handleChange}
								excludedOptions={
									readOnlyItems?.map(
										(readOnlyItem) =>
											readOnlyItem[input.elementName]
									) || []
								}
								disabled={disabled}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default TableInputs;
