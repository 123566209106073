import { useContext, useRef, useState, useEffect } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import { PiBell } from "react-icons/pi";
import Badge from "../../common/Badge";
import NotificationsContext from "../../../context/notifications/NotificationsContext";
import Popover from "../../common/Popover/Popover";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useTranslation } from "react-i18next";
import NotificationItem from "../../common/NotificationItem/NotificationItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NotificationsButton = () => {
	const { withTheme } = useContext(ThemeContext);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const targetRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const { notifications, getNotifications, getUnreadCount, setUnreadCount, unreadCount } =
		useContext(NotificationsContext);
	const history = useHistory();

	const handleNotificationClick = (notification) => {
		const { declaration } = notification;

		if (declaration) {
			history.push(`/declarations?declarationId=${declaration._id}`);
		}

		setIsPopoverOpen(false);
	};

	useEffect(() => {
		getUnreadCount();
	}, []);

	useEffect(() => {
		if (isPopoverOpen && !notifications) {
			(async () => {
				setIsLoading(true);

				await getNotifications();

				setIsLoading(false);

				setUnreadCount(0);
			})();
		}
	}, [isPopoverOpen]);

	return (
		<>
			<Popover
				style={{ width: 500, paddingBottom: 0 }}
				isOpen={isPopoverOpen}
				targetRef={targetRef}
				onClose={() => setIsPopoverOpen(false)}
			>
				<div style={{ fontWeight: 500, fontSize: 18, marginBottom: 24 }}>
					{t("notifications")}
				</div>

				{isLoading ? (
					<LoadingSpinner
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							paddingBottom: 32,
						}}
					/>
				) : (
					notifications?.map((notification, index) => (
						<NotificationItem
							showDeclarationLabel
							key={index}
							notification={notification}
							style={{ marginTop: 12, marginBottom: 12 }}
							onClick={handleNotificationClick}
						/>
					))
				)}
			</Popover>
			<div onClick={() => setIsPopoverOpen(true)} className={withTheme("notification-button")}>
				<div>
					<Badge count={unreadCount}>
						<div ref={targetRef} style={{ display: "flex" }}>
							<PiBell fontSize={24} />
						</div>
					</Badge>
				</div>
			</div>
		</>
	);
};

export default NotificationsButton;
