import { useContext, useRef } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import Button from "../../../common/Button";
import { PiPaperclip } from "react-icons/pi";
import { humanFileSize } from "../helpers";
import { FILE_SIZE_LIMIT } from "../../../../constants/GlobalConstants";
import { useTranslation } from "react-i18next";

const SelectFileInput = ({ selectedFile, onFileChange }) => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);
	const fileInputRef = useRef(null);

	const handleFileInputClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	return (
		<div style={{ display: "flex", alignItems: "center", marginTop: 32 }}>
			<div style={{ fontWeight: 500 }} className={withTheme("secondary-text")}>
				{selectedFile ? humanFileSize(selectedFile.size, true, 0) : `0 MB`} / {FILE_SIZE_LIMIT}{" "}
				MB
			</div>

			<div style={{ width: "fit-content", marginLeft: "auto" }}>
				<Button
					type="secondary"
					LeftIcon={PiPaperclip}
					onClick={() => {
						fileInputRef.current.click();
					}}
				>
					{t("selectFile")}
				</Button>

				<input
					ref={fileInputRef}
					type="file"
					multiple={false}
					style={{ display: "none" }}
					onChange={onFileChange}
					onClick={handleFileInputClick}
				/>
			</div>
		</div>
	);
};

export default SelectFileInput;
