import { useContext, useMemo } from "react";
import ConstantsBasedPullDownMenu from "./ConstantsBasedPulDownMenu/ConstantsBasedPullDownMenu";
import DeclarationsContext from "../../context/declarations/DeclarationsContext";
import { getDeclarationLabel } from "../../helpers";

export const declarationsBaseElement = {
	elementName: "selectedDeclaration",
	textDescription: "Select Declaration",
	pullDownMenu: [],
};

const SelectDeclarationInput = ({ onChange, value }) => {
	const { state } = useContext(DeclarationsContext);

	const options = useMemo(() => {
		let options = [];

		state.declarations.forEach((dec) => {
			if (dec.data.service !== "import" || !dec.mrn) {
				return;
			}

			options.push({
				key: dec.mrn,
				value: getDeclarationLabel(dec),
			});
		});

		return options;
	}, [state.declarations]);

	return (
		<ConstantsBasedPullDownMenu
			controlled
			onChange={onChange}
			value={value}
			element={{
				...declarationsBaseElement,
				pullDownMenu: [{ key: "DEFAULT", value: "Please Select" }, ...options],
			}}
		/>
	);
};

export default SelectDeclarationInput;
