const commodityCodeTypes = [
    {
        "key": "DEFAULT",
        "value": "Please Select"
    },
    {
        "key": "CV",
        "value": "Customs article number"
    },
    {
        "key": "GN",
        "value": "National Additional code"
    },
    {
        "key": "SSO",
        "value": "UN Dangerous Goods Code"
    },
    {
        "key": "TRA",
        "value": "TARIC Additional code"
    },
    {
        "key": "TRC",
        "value": "TARIC code"
    },
    {
        "key": "TSP",
        "value": "Combined Nomenclature code "
    }
]
export default commodityCodeTypes;