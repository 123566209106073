import { useContext, useState } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import ImageErrorIllust from "../../../../assets/images/image-error-illust.png";
import moment from "moment";
import { PiCheckCircleFill } from "react-icons/pi";
import FileOptionsPopover from "./OptionsPopover";
import FileListItemEditMode from "./EditMode";

const FileListItem = ({
	file,
	isSelected,
	isDisabled,
	style,
	isAssociatedWithDeclaration,
	showOptionsButton,
	onClick,
}) => {
	const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
	const { withTheme } = useContext(ThemeContext);

	return (
		<div
			className={`${withTheme("file-list-item")} ${
				isSelected ? withTheme("file-list-item-selected") : ""
			} ${isDisabled ? withTheme("file-list-item-disabled") : ""}`}
			style={{ ...style, cursor: onClick ? "pointer" : "default", userSelect: onClick && "none" }}
			onClick={() => onClick?.(file)}
		>
			<img className={withTheme("file-list-item-image")} alt="File Preview" src={ImageErrorIllust} />

			{isEditModeEnabled ? (
				<FileListItemEditMode
					file={file}
					onSave={() => setIsEditModeEnabled(false)}
					onCancel={() => setIsEditModeEnabled(false)}
				/>
			) : (
				<div className={withTheme("file-list-item-text")}>
					<div title={file.label} className={withTheme("file-list-item-title")}>
						{file.label}
					</div>
					<div
						title={file.description}
						className={withTheme("file-list-item-description")}
					>
						{file.description}
					</div>
					<div>{moment(file.createdAt).format("DD MMM YYYY")}</div>
				</div>
			)}

			{showOptionsButton && !isEditModeEnabled && (
				<FileOptionsPopover
					file={file}
					isAssociatedWithDeclaration={isAssociatedWithDeclaration}
					onRenameClick={() => setIsEditModeEnabled(true)}
				/>
			)}

			{isSelected && (
				<div className={withTheme("file-list-item-check-circle")}>
					<PiCheckCircleFill fontSize="32px" />
				</div>
			)}
		</div>
	);
};

export default FileListItem;
