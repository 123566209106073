import { useContext, useRef, useState } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import UserContext from "../../../context/user/UserContext";
import NotificationsButton from "../components/NotificationsButton";
import UserInfo from "../components/UserInfo";
import UserOverlay from "../components/UserOverlay";

const MainNavBar = ({ title, isSideNavBarExpanded }) => {
	const [userOverlay, setUserOverlay] = useState(false);
	const { withTheme } = useContext(ThemeContext);
	const { user } = useContext(UserContext);
	const userOverlayTarget = useRef(null);

	return (
		<>
			<UserOverlay
				target={userOverlayTarget}
				open={userOverlay}
				handleClose={() => setUserOverlay(false)}
			/>

			<div
				className={`${withTheme("main-nav")} ${
					!isSideNavBarExpanded ? withTheme("main-nav-collapsed-side") : ""
				}`}
			>
				<div className={withTheme("main-nav-inner")}>
					<div className={withTheme("main-nav-title")}>{title}</div>

					{user && (
						<div className={withTheme("main-nav-right")} style={{ fontSize: 20 }}>
							<NotificationsButton />
							{/* User Info */}
							<div ref={userOverlayTarget}>
								<UserInfo
									user={user}
									onClick={() => setUserOverlay(true)}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MainNavBar;
