import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PageContainer from "../../components/layouts/PageContainer";
import Tabs from "../../components/common/Tabs/Tabs";
import TabInputs from "./TabInputs";
import ActionButtons from "./ActionButtons";
import UserContext from "../../context/user/UserContext";
import { useTranslation } from "react-i18next";

const tabs = [
	{
		label: "Application Settings",
		elementNames: ["language", "notifications"],
	},
	// {
	// 	label: "User Settings",
	// 	elementNames: [
	// 		"settingsUserName",
	// 		"settingsUserSurname",
	// 		"settingsUserAddress1",
	// 		"settingsUserAddress2",
	// 		"settingsUserAddress3",
	// 		"settingsUserAddress4",
	// 		"settingsUserPostcode",
	// 		"settingsUserCompany",
	// 		"settingsUserCountry",
	// 		"settingsUserPhoneNo",
	// 		"settingsUserMobileNo",
	// 	],
	// },
];

const requiredElements = [];

const SettingsLayout = () => {
	const { t } = useTranslation();
	const { getSettings, updateSettings, settings } = useContext(UserContext);
	const [inputValues, setInputValues] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const handleChange = (name, value) => {
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const validateInputs = () => {
		let errors = {};

		if (selectedTabIndex === 0) {
			tabs[selectedTabIndex].elementNames.forEach((element) => {
				if (!inputValues[element] && requiredElements.includes(element)) {
					errors[element] = "Input Required";
				}
			});
		}

		setErrors(errors);
	};

	const handleResetClick = () => {
		setInputValues({ ...settings });
	};

	const handleSaveClick = async () => {
		setIsSaveLoading(true);

		try {
			await updateSettings({
				...inputValues,
			});
		} catch (e) {
			console.log(e.response.data);
		}

		setIsSaveLoading(false);
	};

	useEffect(() => {
		if (settings) {
			setInputValues({ ...settings });
		} else {
			setInputValues({});
		}
	}, [settings]);

	useEffect(() => {
		validateInputs();
	}, [inputValues]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			await getSettings();
			setIsLoading(false);
		})();
	}, []);

	return (
		<PageContainer title={t("settings")}>
			<div style={{ padding: 40 }}>
				<Tabs
					tabs={tabs.map((tab) => tab.label)}
					selectedTabIndex={selectedTabIndex}
					onChange={setSelectedTabIndex}
				/>

				{isLoading ? (
					<Spinner animation="border" role="status" />
				) : (
					<TabInputs
						elements={tabs[selectedTabIndex].elementNames}
						onChange={handleChange}
						inputValues={inputValues}
						errors={errors}
					/>
				)}

				{!isLoading && (
					<ActionButtons
						isSaveLoading={isSaveLoading}
						errors={errors}
						onResetClick={handleResetClick}
						onSaveClick={handleSaveClick}
					/>
				)}
			</div>
		</PageContainer>
	);
};

export default SettingsLayout;
