import React, { useReducer, useEffect, useContext } from "react";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import { isExpired, decodeToken } from "react-jwt";
import { SET_USER_INFO, SET_USER_SETTINGS } from "../types";
import querystring from "querystring";
import config from "../../config";
import { useHistory } from "react-router-dom";
import { CognitoUserAttribute, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { getSettings as getSettingsApiReq, updateSettings as updateSettingsApiReq } from "../../api/settings";
import { axiosInstance } from "../../index";
import { LS_ACCESS_TOKEN_KEY, LS_ID_TOKEN_KEY, LS_REFRESH_TOKEN_KEY } from "../../constants/GlobalConstants";
import WorkspacesContext from "../workspaces/WorkspacesContext";

const initialState = {
	user: null,
	settings: null,
};

const { cognitoAuthUrl, cognitoClientId, redirect_uri, cognitoClientSecret } = config;

const poolData = {
	ClientId: config.cognitoClientId,
	UserPoolId: config.userPoolId,
};

const userPool = new CognitoUserPool(poolData);

export const refreshTokensUtil = async () => {
	const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN_KEY);
	if (!refreshToken) {
		return null;
	}

	try {
		const result = await axiosInstance.post(
			cognitoAuthUrl + "/oauth2/token",
			querystring.stringify({
				grant_type: LS_REFRESH_TOKEN_KEY,
				client_id: cognitoClientId,
				client_secret: cognitoClientSecret,
				refresh_token: refreshToken,
			}),
			{
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		);
		const { id_token, access_token } = result.data;
		localStorage.setItem(LS_ID_TOKEN_KEY, id_token);
		localStorage.setItem(LS_ACCESS_TOKEN_KEY, access_token);

		return access_token;
	} catch (e) {
		console.log(e.response);

		return null;
	}
};

const UserState = (props) => {
	const { setSelectedWorkspaceId } = useContext(WorkspacesContext);
	const [state, dispatch] = useReducer(UserReducer, initialState);
	const history = useHistory();

	const getSettings = async () => {
		const { settings, defaultWorkspace } = await getSettingsApiReq();

		dispatch({
			type: SET_USER_SETTINGS,
			payload: settings.settings,
		});

		setSelectedWorkspaceId(defaultWorkspace._id);

		return settings;
	};

	const updateSettings = async (payload) => {
		const result = await updateSettingsApiReq(payload);

		dispatch({
			type: SET_USER_SETTINGS,
			payload: result.settings.settings,
		});
	};

	const register = (name, email, password) => {
		return new Promise((resolve, reject) => {
			const emailAttribute = new CognitoUserAttribute({
				Name: "email",
				Value: email.toLowerCase(),
			});

			const nameAttribute = new CognitoUserAttribute({
				Name: "name",
				Value: name.toLowerCase(),
			});

			userPool.signUp(email, password, [emailAttribute, nameAttribute], [], async (err, result) => {
				if (err) {
					console.log(err);
					reject(err);
				} else if (result) {
					resolve(result);
				}
			});
		});
	};

	const resendConfirmation = (email) => {
		const userData = {
			Username: email,
			Pool: userPool,
		};

		const cognitoUser = new CognitoUser(userData);
		cognitoUser.resendConfirmationCode(function (err, result) {
			if (err) {
				alert(err.message || JSON.stringify(err));
				return;
			}
		});
	};

	const getCredentialsFromCode = async (code) => {
		try {
			const result = await axiosInstance.post(
				cognitoAuthUrl + "/oauth2/token",
				querystring.stringify({
					grant_type: "authorization_code",
					redirect_uri,
					client_id: cognitoClientId,
					scope: "email openid profile",
					code,
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			const { id_token, access_token, refresh_token } = result.data;

			localStorage.setItem(LS_ID_TOKEN_KEY, id_token);
			localStorage.setItem(LS_ACCESS_TOKEN_KEY, access_token);
			localStorage.setItem(LS_REFRESH_TOKEN_KEY, refresh_token);

			await getUserInfo();
			history.push("/");
		} catch (e) {
			console.log(e.response);
		}
	};

	const getUserInfo = async () => {
		const idToken = localStorage.getItem(LS_ID_TOKEN_KEY);
		const accessToken = localStorage.getItem(LS_ACCESS_TOKEN_KEY);

		if (!idToken || !accessToken) {
			history.push("/login");
			return;
		}

		try {
			const decodedToken = decodeToken(idToken);

			if (isExpired(accessToken)) {
				return await refreshTokens(true);
			} else {
				dispatch({
					type: SET_USER_INFO,
					payload: decodedToken,
				});

				return decodedToken;
			}
		} catch (e) {
			console.log(e);
		}
	};

	const refreshTokens = async (redirect) => {
		const result = await refreshTokensUtil();

		if (result) {
			if (redirect) {
				await getUserInfo();
				history.push(history.location.pathname);
			}

			return result;
		} else {
			dispatch({
				type: SET_USER_INFO,
				payload: null,
			});
			history.push("/login");
		}
	};

	const signOut = () => {
		localStorage.removeItem(LS_ID_TOKEN_KEY);
		localStorage.removeItem(LS_ACCESS_TOKEN_KEY);
		localStorage.removeItem(LS_REFRESH_TOKEN_KEY);

		dispatch({
			type: SET_USER_INFO,
			payload: null,
		});

		return true;
	};

	useEffect(() => {
		// Automatically process the API request with a new access token if the old one is expired
		// setTimeout(() => {
		//     setInterceptorsLoaded(true)
		// }, 500);
		// return () => {
		//     axiosInstance.interceptors.request.eject(requestInterceptor)
		//     axiosInstance.interceptors.request.eject(responseInterceptor)
		//     setInterceptorsLoaded(false)
		// }
	}, []);

	// if(!interceptorsLoaded) {
	//     return null
	// }

	return (
		<UserContext.Provider
			value={{
				...state,
				register,
				getUserInfo,
				refreshTokens,
				resendConfirmation,
				getCredentialsFromCode,
				getSettings,
				updateSettings,
				signOut,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserState;
