import React, { useContext, useEffect, useMemo, useState } from "react";
import { findElementPageNumber, getQueryParam, scrollToElement, updateQueryParam } from "../../../../router/utils";
import importGrouping from "../../../../constants/grouping/import/items-grouping.json";
import exportGrouping from "../../../../constants/grouping/export/items-grouping.json";
import ButtonGroup from "../../../../components/common/ButtonGroup/ButtonGroup";
import { ELEMENT_QUERY_PARAM, PAGE_QUERY_PARAM } from "../../../../constants/GlobalConstants";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import ItemsList from "../../../../components/layouts/declarations/DeclarationItems/ItemsList/ItemsList";
import SectionsMasonry from "../../SectionsMasonry";
import ItemHeader from "./ItemHeader";

const Items = () => {
	const [items, setItems] = useState([]);
	const { state, findElementObligations } = useContext(DeclarationsContext);
	const [selectedPageIndex, setSelectedPageIndex] = useState(0);
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);

	const pages = useMemo(() => {
		if (state.declaration?.service === "export") {
			return exportGrouping;
		} else {
			return importGrouping;
		}
	}, [selectedPageIndex]);

	const updatePageQueryParam = (index) => {
		updateQueryParam(ELEMENT_QUERY_PARAM, "");
		updateQueryParam(PAGE_QUERY_PARAM, index);
	};

	const findElementAndScroll = () => {
		const element = getQueryParam(ELEMENT_QUERY_PARAM);
		const pageIndex = parseInt(getQueryParam(PAGE_QUERY_PARAM));

		if (element) {
			const elementPageIndex = findElementPageNumber("items", element, state.declaration);

			// If element is not in the current tab, switch to it first
			// Otherwise, scroll to the element
			if (elementPageIndex === pageIndex || (elementPageIndex === 0 && !pageIndex)) {
				scrollToElement(element);
			} else {
				updateQueryParam(PAGE_QUERY_PARAM, elementPageIndex);
			}
		}
	};

	const getStateFromQuery = () => {
		const tab = getQueryParam(PAGE_QUERY_PARAM);
		setSelectedPageIndex(parseInt(tab) || 0);
		findElementAndScroll();
	};

	useEffect(() => {
		setTimeout(() => {
			getStateFromQuery();
		}, 500);

		const eventHandler = () => {
			getStateFromQuery();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, [state.declaration, selectedPageIndex]);

	useEffect(() => {
		setTimeout(() => {
			findElementAndScroll();
		}, 200);
	}, [selectedPageIndex, state.declaration, selectedItemIndex]);

	useEffect(() => {
		findElementObligations("items");

		setItems([...(state.declaration.data.declarationItems || [])]);

		const updateItemIndex = () => {
			const itemIndex = getQueryParam("item");

			if (itemIndex && state.declaration) {
				setSelectedItemIndex(parseInt(itemIndex));
			}
		};

		updateItemIndex();

		window.addEventListener("popstate", updateItemIndex);

		return function cleanup() {
			window.removeEventListener("popstate", updateItemIndex);
		};
	}, [state.declaration]);

	return (
		<div style={{ display: "flex" }}>
			<ItemsList items={items} setItems={setItems} selectedItemIndex={selectedItemIndex} />
			<div style={{ width: "calc(100% - 88px)", paddingLeft: 16 }}>
				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<ButtonGroup
						selectedIndex={selectedPageIndex}
						buttons={pages.map((tab, index) => ({
							label: `Page ${index + 1}`,
							onClick: () => updatePageQueryParam(index),
						}))}
					/>
				</div>
				<ItemHeader selectedItemIndex={selectedItemIndex} />
				<div style={{ marginTop: 12 }}>
					<SectionsMasonry
						sections={pages[selectedPageIndex]?.sections}
						selectedItemIndex={selectedItemIndex}
					/>
				</div>
			</div>
		</div>
	);
};

export default Items;
